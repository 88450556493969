import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const Remoment = ({ projectId }) => {
  const { t } = useTranslation();
  const rangeVal = 300 / 60;
  const [sliceMin, setSliceMin] = useState(rangeVal);

  const onSliceTimeChange = (event) => {
    setSliceMin(event.target.value);
  };

  return (
    <div className="remoment-tab" id="remoment-tab">
      <span>{t("modify_slice_time")}: </span>
      <input
        type="range"
        id="remoment-minLen"
        min="1"
        max="10"
        step="1"
        defaultValue={sliceMin}
        onChange={(e) => onSliceTimeChange(e)}
      />
      <label id="minute-time">
        {sliceMin} Minute{sliceMin > 1 ? "s" : null}
      </label>
      <span id="remoment-btn" className="button">
        {t("buttons.generate_moment")}
      </span>
    </div>
  );
};

export default Remoment;
