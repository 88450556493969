import React, { useEffect, useRef, useState } from "react";
import Camera from "./Camera";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faImage,
  faMicrophone,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";
import AudioLevel from "./AudioLevel";
import useGetMediaDevices from "../../../hooks/useGetMediaDevices";
import OptionSelector from "./OptionSelector";
import { tw } from "../../../helpers/Styles";

export interface SettingProps {
  onButtonAction: (status: boolean) => void;
  defaultBtnVal: boolean;
  btnLabel: string;
  canJoinShow: boolean;
  updateStream: (stream: MediaStream) => void;
  onName: (name: string) => void;
  onTagline: (tag: string) => void;
  onUserImg: (userimg: any) => void;
  name: string;
  tagline: string;
  myStream: MediaStream;
  headerLabel: string;
}

const JoinShow = ({
  onButtonAction,
  defaultBtnVal,
  btnLabel,
  updateStream,
  onName,
  onTagline,
  name,
  tagline,
  myStream,
  onUserImg,
  headerLabel,
}: SettingProps) => {
  const devices = useGetMediaDevices();

  const bgs = [
    /* "bg-gradient-to-t from-yellow-100 via-sangria-700 to-sky-700",
    "bg-gradient-to-b from-blue-charcoal-400 via-rose-400 to-indigo-700",
    "bg-gradient-to-t from-gray-700 via-pink-400 to-teal-100",
    "bg-gradient-to-b from-sky-600 via-purple-700 to-indigo-700", */
    "bg-gradient-to-b from-sky-900 via-indigo-900 to-slate-800",
  ];
  const [audioSource, setAudioSource] = useState<string>();
  const [videoSource, setVideoSource] = useState<string>();
  const [outputSource, setOutputSource] = useState<string>();
  const [videoStream, setVideoStream] = useState(myStream);
  const [showVideoPicker, setShowVideoPicker] = useState(false);
  const [showAudioPicker, setShowAudioPicker] = useState(false);
  const [userImg, setUserImg] = useState(null);
  const [camStatus, setCamStatus] = useState<string>("Awaiting Camera");
  const [joinBg] = useState<string>(
    bgs[Math.floor(Math.random() * bgs.length)]
  );

  const fileInputRef = useRef(null);

  /* const dataURLtoBlob = (dataURL) => {
    const arr = dataURL.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new Blob([u8arr], { type: mime });
  }; */

  const handleInputs = (e, target) => {
    if (target === "name") {
      onName(e.currentTarget.value);
      localStorage.setItem("stream_display_name", e.currentTarget.value);
    } else {
      onTagline(e.currentTarget.value);
      localStorage.setItem("stream_tag_line", e.currentTarget.value);
    }
  };

  const handleFileSelect = () => {
    const fileInput = fileInputRef.current;
    const file = fileInput.files[0];
    if (file) {
      const reader = new FileReader();

      reader.onload = function (e) {
        const imageDataUrl = e.target.result;
        //const blob = dataURLtoBlob(imageDataUrl);

        // Now you have the image data as a Blob
        //console.log(typeof blob);
        setUserImg(imageDataUrl);
        //setVideoSource(null);

        // Display the preview of the image
        //previewImgRef.current.src = imageDataUrl;
        //document.getElementById("previewImage").src = imageDataUrl;
      };

      reader.readAsDataURL(file);
    }
  };

  const handleDeviceSelection = (type: string, val: string) => {
    switch (type) {
      case "video":
        setUserImg(null);
        setVideoSource(val);
        break;
      case "audio":
        setAudioSource(val);
        break;
      case "output":
        setOutputSource(val);
        break;
    }
  };

  const onStreamUpdate = (stream) => {
    //updateStream(stream);
    setVideoStream(stream);
  };

  const browseFile = () => {
    fileInputRef.current.click();
  };

  useEffect(() => {
    onUserImg(userImg);
  }, [userImg]);

  useEffect(() => {
    if (videoStream) {
      setCamStatus(btnLabel);
      updateStream(videoStream);
    }
  }, [videoStream]);

  useEffect(() => {
    if (videoSource) {
      setShowVideoPicker(false);
    }
  }, [videoSource]);

  useEffect(() => {
    if (audioSource) {
      setShowAudioPicker(false);
    }
  }, [audioSource]);

  useEffect(() => {
    if (outputSource) {
      setShowAudioPicker(false);
    }
  }, [outputSource]);

  useEffect(() => {
    if (devices.audioDevices.length && devices.videoDevices.length) {
      if (userImg === null) {
        navigator.mediaDevices
          .getUserMedia({ video: true, audio: true })
          .then((stream) => {
            const videoTracks = stream.getVideoTracks();
            const audioTracks = stream.getAudioTracks();
            if (videoTracks.length > 0) {
              setVideoSource(videoTracks[0].getSettings().deviceId);
            }

            if (audioTracks.length > 0) {
              setAudioSource(audioTracks[0].getSettings().deviceId);
            }
            //console.log("STREAM", stream);
            if (videoStream == null) {
              //console.log(stream.id);
              setVideoStream(stream);
              updateStream(stream);
            }
          })
          .catch((err) => {
            console.log("Err", err);
          });
      } else {
        navigator.mediaDevices
          .getUserMedia({ video: false, audio: true })
          .then((stream) => {
            const videoTracks = stream.getVideoTracks();
            const audioTracks = stream.getAudioTracks();
            if (videoTracks.length > 0) {
              //setVideoSource(videoTracks[0].getSettings().deviceId);
            }

            if (audioTracks.length > 0) {
              //setAudioSource(audioTracks[0].getSettings().deviceId);
            }
            console.log("STREAM IMG", stream);
            //setVideoStream(stream);
            //updateStream(stream);
          })
          .catch((err) => {
            console.log("Err", err);
          });
      }
    }
  }, [devices.videoDevices, devices.audioDevices]);

  return (
    <>
      <div
        className={`flex-col sm:flex-row sm:flex-grow text-right flex h-[calc(100vh-40px)] `}
      >
        <div
          className={`w-full sm:w-[360px] flex flex-col justify-center items-center p-6 border-r border-gray-600  bg-center ${joinBg}`}
        >
          <div className="mb-8">
            <label className="text-4xl font-semibold">{headerLabel}</label>
            <span className="flex w-full justify-center items-center mt-2">
              LIVE STUDIO <sup className="ml-1 font-semibold">0.3</sup>
            </span>
          </div>
          <div className="my-2.5 w-full">
            <input
              className="text-center w-full rounded-md border p-1.5 bg-slate-300 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 border-gray-800"
              type="text"
              defaultValue={name}
              onChange={(e) => handleInputs(e, "name")}
              placeholder="Display Name"
            ></input>
          </div>
          <div className="my-2.5 w-full">
            <input
              defaultValue={tagline}
              type="text"
              className="text-center w-full rounded-md border p-1.5 bg-slate-300 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 border-gray-800"
              onChange={(e) => handleInputs(e, "tagline")}
              placeholder="Tagline Optional..."
            ></input>
          </div>
          <div className="flex my-2.5 w-full justify-center relative">
            <FontAwesomeIcon
              className="mr-4 px-2 py-1 rounded-sm border border-gray-800 bg-blue hover:bg-sky-500 transition-colors duration-300 pointer shadow-md"
              icon={faVideo}
              onClick={() => setShowVideoPicker(!showVideoPicker)}
            />
            {showVideoPicker && (
              <OptionSelector
                options={devices.videoDevices}
                onOptionClick={handleDeviceSelection}
                optionType="video"
                defaultValue={videoSource}
                onCancel={() => setShowVideoPicker(false)}
              />
            )}

            <FontAwesomeIcon
              className="mr-4 px-2 py-1 rounded-sm border border-gray-800 bg-blue hover:bg-sky-500 transition-colors duration-300 pointer shadow-md"
              icon={faMicrophone}
              onClick={() => setShowAudioPicker(!showAudioPicker)}
            />
            {showAudioPicker && (
              <OptionSelector
                options={[...devices.audioDevices, ...devices.outputDevices]}
                onOptionClick={handleDeviceSelection}
                optionType="audio"
                defaultValue={{ input: audioSource, output: outputSource }}
                onCancel={() => setShowAudioPicker(false)}
              />
            )}

            <FontAwesomeIcon
              className="px-2 py-1 rounded-sm border border-gray-800 bg-blue hover:bg-sky-500 transition-colors duration-300 pointer shadow-md"
              icon={faImage}
              onClick={browseFile}
            />
            <input
              ref={fileInputRef}
              type="file"
              onChange={handleFileSelect}
              accept="image/*"
              style={{ display: "none" }}
            ></input>
          </div>
          <div className="flex w-full justify-center border border-gray-700 max-w-[300px]">
            <AudioLevel stream={videoStream} />
          </div>
          <div className="mt-2 w-full">
            <button
              className={
                name === "" || camStatus === "Awaiting Camera"
                  ? `${tw.btn.reg} bg-disabled text-gray-500 w-full block rounded-sm border border-gray-800 shadow-md`
                  : `${tw.btn.reg} bg-blue-charcoal-500 hover:bg-blue-charcoal-700 hover:text-white w-full block transition-colors duration-300 rounded-sm border border-gray-800 shadow-md`
              }
              onClick={
                name === "" || camStatus === "Awaiting Camera"
                  ? null
                  : () => onButtonAction(!defaultBtnVal)
              }
            >
              {camStatus}
            </button>
          </div>
        </div>
        <div className="flex flex-grow">
          <Camera
            videoSource={videoSource}
            audioSource={audioSource}
            index={0}
            isAudioPlaybackEnabled={false}
            onVideoStream={onStreamUpdate}
            isMuted={true}
            userImg={userImg}
            cameraClass="full flex flex-grow"
            videoClass="object-cover w-full"
          />
        </div>
      </div>
    </>
  );
};

export default JoinShow;
