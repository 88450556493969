// path="/stream/:roomid/:peerid
import React, { useEffect, useState } from "react";

import useRoomCams from "../../hooks/useRoomCams";
import UserStream from "./components/UserStream";
import { useParams } from "react-router-dom";
import "../../sass/camSizes.scss";

const IndividualStream = () => {
  const { roomid, peerid } = useParams();
  const RoomCams = useRoomCams({ roomId: roomid });
  //const [hasASoloedStream, setHasASoloedStream] = useState<string>("");
  const [roomJoined, setRoomJoined] = useState<boolean>(false);
  const [videoStream, setVideoStream] = useState(null);

  //const [cameraClass, setCameraClass] = useState<string>("");

  const createEmptyAudioTrack = () => {
    const ctx = new AudioContext();
    const oscillator = ctx.createOscillator();
    const mediaStream = new MediaStream();

    const destination = ctx.createMediaStreamDestination();
    oscillator.connect(destination);
    mediaStream.addTrack(destination.stream.getAudioTracks()[0]);

    oscillator.start();

    const track = mediaStream.getAudioTracks()[0];
    return Object.assign(track, { enabled: false });
  };

  const createEmptyVideoTrack = ({ width, height }) => {
    const canvas = Object.assign(document.createElement("canvas"), {
      width,
      height,
    });
    canvas.getContext("2d").fillRect(0, 0, width, height);

    const stream = canvas.captureStream();
    const track = stream.getVideoTracks()[0];

    return Object.assign(track, { enabled: false });
  };

  useEffect(() => {
    console.log("WATCHER STREAM CREATED", videoStream);
  }, [videoStream]);

  useEffect(() => {
    if (RoomCams.userid && RoomCams.peerId && !roomJoined) {
      console.log("Watcher Id:", RoomCams.userid);

      const mediaStream = new MediaStream([
        createEmptyAudioTrack(),
        createEmptyVideoTrack({ width: 640, height: 480 }),
      ]);

      // Add video and audio tracks to the MediaStream
      //mediaStream.addTrack(videoTrack);
      //mediaStream.addTrack(audioTrack);
      const data = {
        roomid,
        userid: RoomCams.userid,
        peerId: RoomCams.peerId,
        name: "watcher",
        tagline: "",
        type: "watcher",
        pos: "bg",
        isMuted: true,
        isVideoShowing: false,
      };
      RoomCams.requestJoin(data, mediaStream);
      RoomCams.setMyUserData(data);

      RoomCams.addStream(mediaStream, data);
      RoomCams.setMyStream(mediaStream);
      setRoomJoined(true);
    }
  }, [RoomCams.userid, RoomCams.peerId]);

  useEffect(() => {}, []);

  return (
    <div className="scene">
      <div className={`video-wrap grid_1`}>
        {RoomCams.sceneCameras.map(
          (camera, index) =>
            camera.data.peerId === peerid && (
              <UserStream
                key={`${camera.userid}_${index}`}
                myid={RoomCams.userid}
                userid={camera.userid}
                stream={camera.stream}
                userData={camera.data}
                onCreatedStream={setVideoStream}
                cameraClass={`grid_1`}
                isAudioMuted={camera.data.isMuted}
                isVideoShowing={camera.data.isVideoShowing}
                showTitles={camera.data.isNameShowing}
                userImg={camera.data.userImg}
              />
            )
        )}
      </div>
    </div>
  );
};

export default IndividualStream;
