import React, { useContext } from "react";
import SideBar from "./SideBar";
import { Link } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import { tw } from "../../helpers/Styles";

import StandaloneControls from "./components/StandaloneControls";

export interface Headerprops {
  islive?: boolean;
  classname?: string;
  headerSectionLabel?: string;
}
export const Header = ({
  islive,
  classname,
  headerSectionLabel,
}: Headerprops) => {
  const { user } = useContext(UserContext);
  const isLoggedIn = user.isloggedin;
  const isPro = user.accounttype.toUpperCase() === "PRO" ? true : false;
  const showHeaderMenu = islive ? false : true;

  return (
    <header
      className={
        classname
          ? `relative flex items-center h-[40px] z-[250] ${classname}`
          : "relative flex items-center h-[40px] z-[250] bg-ssred border-b border-b-ssblue"
      }
    >
      <StandaloneControls isStudio={false} />
      <div className="flex-shrink-0">
        <Link to="/">
          {isPro ? (
            <img className="h-8 ml-2" src="/img/logo-pro.png" alt="Shopro" />
          ) : (
            <img className="h-8 ml-2" src="/img/logo.png" alt="Shopro" />
          )}
        </Link>
      </div>
      <div className="ml-4">
        {headerSectionLabel && (
          <span className="text-white text-xs font-bold">
            {headerSectionLabel}
          </span>
        )}
      </div>
      <div className="flex-grow text-right h-full">
        {!isLoggedIn ? (
          <>
            <Link
              className={`${tw.btn.reg} bg-ssred hover:bg-blue2 no-underline border-l border-r border-black px-6 text-white h-full transition-colors duration-300`}
              to="/login"
            >
              Log in
            </Link>
            <Link
              className={`${tw.btn.reg} bg-orange hover:bg-green no-underline px-6 text-black hover:text-white h-full transition-colors duration-300`}
              to="/signup"
            >
              Sign Up
            </Link>
          </>
        ) : (
          <>{showHeaderMenu && <SideBar />}</>
        )}
      </div>
    </header>
  );
};

export default Header;
