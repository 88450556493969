import React, { useRef, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
//import ApiRequest from "../../helpers/ApiRequest";
import Cropper from "./Components/Crop";
import KeyFrameEditor from "./Components/KeyframeEditor";
import Slider from "./Components/Slider";
import Controls from "./Components/Controls";
import Tools from "./Components/Tools";
import CaptionEditor from "./Components/Captions";
import {
  secondsToVideoTimecode,
  percentToSeconds,
  calcPercentage,
} from "./helpers/helpers";
import Header from "../../../../components/layout/Header";
import EditorHeader from "./Components/EditorHeader";

// import useKeyListener from "../../hooks/useKeyListenr";

const VideoEditor = () => {
  const location = useLocation();
  //const navigate = useNavigate();
  /* const keyPressed = useKeyListener();
  const memoizedKeyPressed = useMemo(() => keyPressed, [keyPressed]); */
  const { duration, start, end, videoPath, videoType } = location.state;
  const videoClipStart = start ? start : 0;
  const videoClipEnd = end ? end : duration;
  const [isCropActive, setIsCropActive] = useState(false);
  const [cropType, setCropType] = useState("tall");
  const [cropData, setCropData] = useState({});
  const [isPlaying, setIsPlaying] = useState(false);
  const [frameRate] = useState(30);
  const [isSliding, setIsSliding] = useState(false);
  const [isRepeatOn, setIsRepeatOn] = useState(true);
  const [videoPlayTime, setVideoPlayTime] = useState("00:00:00");
  const [playheadPosition, setPlayheadPosition] = useState("0%");
  const [playheadTimeOn, setPlayheadTimeOn] = useState("");
  const [keyframeData, setKeyframeData] = useState({});
  const [keyframeTrigger, setKeyframeTrigger] = useState(0);
  const [newCropPos, setNewCropPos] = useState({});
  const [slider1, setSlider1] = useState(0);
  const [slider2, setSlider2] = useState(100);
  const [currentTime, setCurrentTime] = useState(0);
  const [startTimeCode, setStartTimeCode] = useState(
    secondsToVideoTimecode(start ? start : 0, frameRate)
  );
  const [endTimeCode, setEndTimeCode] = useState(
    secondsToVideoTimecode(end ? end : duration, frameRate)
  );

  const videoRef = useRef(null);
  const vidBlock = useRef(null);
  const videoWrapper = useRef(null);
  //const editorMenu = useRef(null);

  /* const goBack = () => {
    navigate(-1);
  }; */

  const processVideo = () => {
    const startVideoTimecode = percentToSeconds(slider1, duration);
    const startSeconds = secondsToVideoTimecode(
      percentToSeconds(slider1, duration),
      frameRate
    );
    const endVideoTimecode = secondsToVideoTimecode(
      percentToSeconds(slider2, duration),
      frameRate
    );
    const endSeconds = percentToSeconds(slider2, duration);

    console.log(
      "processVideo",
      keyframeData,
      startVideoTimecode,
      startSeconds,
      endVideoTimecode,
      endSeconds
    );
  };

  const toggleVideoPlaying = () => {
    if (!isPlaying) {
      setIsPlaying(true);
      setPlayheadTimeOn("");
      videoRef.current.play();
    } else {
      setIsPlaying(false);
      setPlayheadTimeOn("on");
      videoRef.current.pause();
    }
  };

  const stepVideoFwdBak = (direction) => {
    if (isPlaying) {
      videoRef.current.pause();
      setIsPlaying(false);
    }
    const currentTime = videoRef.current.currentTime;
    const stepInc = frameRate / 1000;
    if (direction.toUpperCase() === "FWD") {
      videoRef.current.currentTime = currentTime + stepInc;
    } else {
      videoRef.current.currentTime = currentTime - stepInc;
    }
  };

  const updateVideoTime = (time) => {
    videoRef.current.currentTime = time;
  };

  const updatePlayHead = (playheadPosition, currentTime) => {
    const frames = !frameRate ? 30 : frameRate;
    setVideoPlayTime(secondsToVideoTimecode(currentTime, frames));
    setPlayheadPosition(`${playheadPosition}%`);
  };

  const onTimeUpdate = () => {
    const start = slider1;
    const end = slider2;

    const currentTime = videoRef.current.currentTime;
    const duration = videoRef.current.duration;
    const endSecond = (Number(end) / 100) * duration;
    const startSecond = (Number(start) / 100) * duration;
    const playheadPosition = calcPercentage(duration, currentTime);

    if (currentTime >= endSecond && isRepeatOn) {
      updateVideoTime(startSecond);
    } else if (currentTime >= endSecond && !isRepeatOn) {
      updateVideoTime(startSecond);
      videoRef.current.pause();
    }
    setCurrentTime(currentTime);
    updatePlayHead(playheadPosition, currentTime);
  };

  const toggleCrop = () => {
    setIsCropActive(isCropActive ? false : true);
  };

  const toggleAspect = (event) => {
    if (event.target.id === "crop-45") {
      setCropType("square_45");
    } else {
      setCropType("tall");
    }
  };

  const onCropData = (data) => {
    setCropData(data);
  };

  const onKeyFrameData = (data) => {
    if (isCropActive) {
      setKeyframeData(data);
    }
  };

  const onKeyFrameSelect = (cropData) => {
    // console.log("New Start", cropData.start, cropData);
    videoRef.current.currentTime = cropData.start;
    setNewCropPos(cropData);
  };

  const addKeyFrame = () => {
    setKeyframeTrigger(keyframeTrigger + 1);
  };

  const onSliderUpdate = (props) => {
    setStartTimeCode(
      secondsToVideoTimecode(
        percentToSeconds(props.slider1, duration),
        frameRate
      )
    );
    setEndTimeCode(
      secondsToVideoTimecode(
        percentToSeconds(props.slider2, duration),
        frameRate
      )
    );

    setIsSliding(props.isSliding);
    setSlider1(props.slider1);
    setSlider2(props.slider2);
  };

  useEffect(() => {
    if (isSliding && isPlaying) {
      videoRef.current.pause();
      setPlayheadTimeOn("on");
    } else {
      if (isPlaying) {
        videoRef.current.play();
        setPlayheadTimeOn("");
      }
    }
  }, [isSliding, isPlaying]);

  useEffect(() => {
    setIsPlaying(true);
    videoRef.current.currentTime = videoClipStart;
    videoRef.current.play();
  }, [duration, videoClipStart]);

  useEffect(() => {
    /* const data = {
      filePath: videoPath ? videoPath : location.state.clipPath,
    };
    ApiRequest.post("/api/getFrameRate", data).then((framerateData) => {
      // eslint-disable-next-line no-eval
      const framerate = eval(eval(framerateData));
      setFrameRate(framerate);
    }); */
    // eslint-disable-next-line
  }, []);

  /* useEffect(() => {
    console.log(memoizedKeyPressed);
  }, [memoizedKeyPressed]); */

  return (
    <>
      <Header />
      <div className="container w-full px-2 sm:p-0 sm:mx-auto max-w-6xl mt-2">
        <EditorHeader videoType={videoType} processVideo={processVideo} />
        <div className="flex flex-col sm:flex-row mt-2 gap-2 ">
          <div className="w-full sm:w-9/12 bg-ssblue border border-ssgray rounded-md">
            <div
              ref={videoWrapper}
              className="rounded-b-sm rounded-md overflow-hidden z-10"
              id=""
            >
              <div
                ref={vidBlock}
                className="flex relative justify-center rounded-md"
                id=""
              >
                <video
                  className="w-auto h-auto"
                  ref={videoRef}
                  id="video-player"
                  src={
                    videoPath
                      ? videoPath.replace("public/", "")
                      : location.state.clipPath
                  }
                  autoPlay={true}
                  onClick={() => toggleVideoPlaying()}
                  onTimeUpdate={() => onTimeUpdate()}
                >
                  <track
                    id="video-player-track"
                    kind="captions"
                    label="English"
                    srcLang="en"
                    default
                    className="caption-style2"
                  />
                </video>

                <Cropper
                  isActive={isCropActive}
                  vidBlock={vidBlock}
                  vidWrapper={videoWrapper}
                  cropType={cropType}
                  onCropData={onCropData}
                  newCropPos={newCropPos}
                />
              </div>

              <div className="video-controls">
                <Controls
                  isCropActive={isCropActive}
                  toggleVideoPlaying={toggleVideoPlaying}
                  isPlaying={isPlaying}
                  isRepeatOn={isRepeatOn}
                  isRepeatOnClick={() =>
                    setIsRepeatOn(isRepeatOn ? false : true)
                  }
                  addKeyFrame={addKeyFrame}
                  stepVideoFwdBak={stepVideoFwdBak}
                  playheadPosition={playheadPosition}
                />

                <Slider
                  duration={duration}
                  videoClipStart={videoClipStart}
                  videoClipEnd={videoClipEnd}
                  videoRef={videoRef}
                  frameRate={frameRate}
                  onSliderUpdate={onSliderUpdate}
                  videoPlayTime={videoPlayTime}
                  playheadTimeOn={playheadTimeOn}
                  playheadPosition={playheadPosition}
                />

                <Tools
                  isCropActive={isCropActive}
                  toggleCrop={toggleCrop}
                  toggleAspect={toggleAspect}
                  cropType={cropType}
                />
              </div>
            </div>
          </div>
          <div className="w-full sm:w-3/12 bg-ssblue border border-ssgray rounded-md overflow-y-scroll max-h-[690px]">
            <KeyFrameEditor
              isCropActive={isCropActive}
              editorOpen={isCropActive}
              videoPlayerRef={videoRef}
              cropType={cropType}
              cropData={cropData}
              onKeyframeData={onKeyFrameData}
              start={startTimeCode}
              end={endTimeCode}
              keyframeTrigger={keyframeTrigger}
              frameRate={frameRate}
              onKeyFrameSelect={onKeyFrameSelect}
              currentVideoTime={currentTime}
            />
            <CaptionEditor />
          </div>
        </div>
      </div>
    </>
  );
};

export default VideoEditor;
