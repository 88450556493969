import { useEffect, useRef, useState } from "react";
import { Socket } from "socket.io-client";

export interface StreamInfo {
  userid: string;
  stream: MediaStream;
  data: UserInfo;
}

export interface UserInfo {
  roomid: string;
  userid: string;
  peerId: string;
  name: string;
  tagline: string;
  type: string;
  pos: string;
  isMuted?: boolean;
  isVideoShowing?: boolean;
  isNameShowing?: boolean;
  userImg?: any;
  showid?: string;
  showSourceId?: string;
  roomsettings?: any;
  cameraClass?: string;
}

export type StreamProps = StreamInfo[];

export interface StreamManagerProps {
  websocket: Socket;
  roomid: string;
}

export type StreamUserProps = UserInfo[];

const useStreamManager = () => {
  const [streams, setStreams] = useState<StreamProps>([]);
  const [streamProps, setStreamProps] = useState<StreamUserProps>([]);
  const streamsRef = useRef(streams);
  streamsRef.current = streams;

  const addStream = (videoStream: MediaStream, data) => {
    const existingUserStream = streams.find(
      (cam) =>
        (cam.userid === data.userid || cam.data.peerId === data.peerId) &&
        data.pos !== "share" &&
        data.type !== "audio"
    );
    //console.log("existingUserStream", existingUserStream, data, streams);
    if (!existingUserStream) {
      setStreams((prev) => [
        ...prev,
        { userid: data.userid, stream: videoStream, data: data },
      ]);
    } else {
      setStreams((prev) =>
        prev.map((cam) => {
          return cam.userid === data.userid
            ? {
                userid: data.userid,
                stream: videoStream,
                data: cam.data,
              }
            : cam;
        })
      );
    }
  };

  const updateUsersStream = (userid, usersStream) => {
    //console.log("UPDATE STREAMS BY USER.", userid, usersStream, streams);
    setStreams((prev) =>
      prev.map((cam) => {
        return cam.userid === userid ? { ...cam, stream: usersStream } : cam;
      })
    );
    // console.log("updatedUsersStream", usersStream, streams);
  };

  const toggleMute = (userid, muted) => {
    ///console.log(userid, muted);
    const updatedStreams = streamsRef.current.map((cam) => {
      if (cam.userid === userid) {
        cam.data.isMuted = muted;
      }
      return cam;
    });
    //console.log("updatedStreams", updatedStreams);
    setStreams(updatedStreams);
    const camProps = updatedStreams.map((cam) => {
      return cam.data;
    });
    setStreamProps(camProps);
  };

  const addUserToShow = (userToAdd, pos, nameShowingStatus) => {
    const updatedUserStream = streams.map((cam) => {
      return cam.userid === userToAdd
        ? {
            ...cam,
            data: {
              ...cam.data,
              pos: pos,
              isNameShowing: nameShowingStatus,
            },
          }
        : cam;
    });
    //console.log("updatedUserStream", updatedUserStream);
    setStreams(updatedUserStream);
    const camProps = updatedUserStream.map((cam) => {
      return cam.data;
    });
    setStreamProps(camProps);
    //websocket.emit("update-cams", roomid, camProps);
  };

  const addUserToGreenRoom = (userToAdd) => {
    const updatedUserStream = streams.map((cam) => {
      return cam.userid === userToAdd
        ? {
            ...cam,
            data: {
              ...cam.data,
              pos: "green-room",
            },
          }
        : cam;
    });
    setStreams(updatedUserStream);
    const camProps = updatedUserStream.map((cam) => {
      return cam.data;
    });
    setStreamProps(camProps);
    //websocket.emit("update-cams", roomid, camProps);
  };

  const updateStreamDataByUser = (userid, userData) => {
    //console.log("UPDATE STREAM BY USER.", userid, userData, streamsRef.current);
    const updatedUsersStream = streamsRef.current.map((cam) => {
      return cam.userid === userid ? { ...cam, data: userData } : cam;
    });
    //console.log("UPDATE STREAM BY USER.", userData, updatedUsersStream);
    streamsRef.current = updatedUsersStream;
    setStreams(updatedUsersStream);
    const camProps = updatedUsersStream.map((cam) => {
      return cam.data;
    });
    setStreamProps((_prev) => camProps);
    //websocket.emit("update-cams", roomid, camProps);
  };

  const updateStreamDataByPeerId = (peerId, userData) => {
    const updatedUsersStream = streamsRef.current.map((cam) => {
      return cam.data.peerId === peerId
        ? { ...cam, userid: userData.userid, data: userData }
        : cam;
    });
    streamsRef.current = updatedUsersStream;
    setStreams(updatedUsersStream);
    const camProps = updatedUsersStream.map((cam) => {
      return cam.data;
    });
    setStreamProps(camProps);
    //websocket.emit("update-cams", roomid, camProps);
  };

  const updateStreamByPeerId = (peerId, userConnectedStream) => {
    const updatedStream = streamsRef.current.map((cam) => {
      return cam.data.peerId === peerId &&
        userConnectedStream.id !== cam.stream.id
        ? { ...cam, stream: userConnectedStream }
        : cam;
    });
    streamsRef.current = updatedStream;
    setStreams(updatedStream);
    //console.log("update stream", streamsRef.current, peerId, updatedStream);
    /* const camProps = updatedUsersStream.map((cam) => {
      return cam.data;
    });
    setStreamProps(camProps); */
    //websocket.emit("update-cams", roomid, camProps);
  };

  const soloUser = (userid) => {
    //console.log("soloUser", userid, streams);
    const updatedStreams = streamsRef.current.map((cam) => {
      if (cam.userid === userid) {
        cam.data.pos = "solo";
      } else if (cam.data.pos === "solo") {
        cam.data.pos = "stage";
      }
      return cam;
    });
    setStreams(updatedStreams);
    const camProps = updatedStreams.map((cam) => {
      return cam.data;
    });
    setStreamProps(camProps);
  };

  const pipUser = (userid, isPip) => {
    //console.log("soloUser", userid, streams);
    const updatedStreams = streamsRef.current.map((cam) => {
      if (cam.userid === userid) {
        cam.data.pos = isPip ? "pip" : "stage";
      } else if (cam.data.pos === "pip") {
        cam.data.pos = "stage";
      }
      return cam;
    });
    setStreams(updatedStreams);
    const camProps = updatedStreams.map((cam) => {
      return cam.data;
    });
    setStreamProps(camProps);
  };

  const unSoloUser = (userid) => {
    //console.log("unSoloUser", userid, streams);
    const updatedStreams = streamsRef.current.map((cam) => {
      if (cam.userid === userid) {
        if (cam.data.type === "share") {
          cam.data.pos = "share";
        } else {
          cam.data.pos = "stage";
        }
      }
      return cam;
    });
    setStreams(updatedStreams);
    const camProps = updatedStreams.map((cam) => {
      return cam.data;
    });
    setStreamProps(camProps);
  };

  const removeStream = (useridToRemove) => {
    //console.log("Remove stream", useridToRemove, streams);
    const updatedStreams = streamsRef.current.filter(
      (cam) => cam.userid !== useridToRemove
    );
    setStreams(updatedStreams);
    const camProps = updatedStreams.map((cam) => {
      return cam.data;
    });
    setStreamProps(camProps);
  };

  const toggleUserNames = (isNameShowing) => {
    const updatedStreams = streamsRef.current.map((cam) => {
      cam.data.isNameShowing = isNameShowing;

      return cam;
    });
    setStreams(updatedStreams);
    const camProps = updatedStreams.map((cam) => {
      return cam.data;
    });
    setStreamProps(camProps);
  };

  useEffect(() => {
    //console.log("streams", streams, streamProps);
  }, [streams]);

  return {
    streams,
    streamProps,
    addStream,
    addUserToShow,
    addUserToGreenRoom,
    updateStreamDataByUser,
    updateStreamDataByPeerId,
    removeStream,
    soloUser,
    unSoloUser,
    toggleUserNames,
    updateUsersStream,
    updateStreamByPeerId,
    toggleMute,
    pipUser,
  };
};

export default useStreamManager;
