import React, { useState, useEffect, useRef, useContext } from "react";
import ReactGA from "react-ga4";
import {
  secondsToTimecode,
  dateToTime,
  calculateTimeDuration,
} from "../../../helpers/TimeCode";
import { AlertLabelProps, CardProps } from "../../../types/Types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faPenToSquare,
  faGlasses,
  faCommentDots,
  faPenNib,
} from "@fortawesome/free-solid-svg-icons";
import EnlargedNote from "./EnlargedNote";
import SegmentTimes from "./SegmentTimes";
import CustomAlert from "../../../components/CustomAlert";
import SegmentStartTime from "./SegmentStartTime";
import { UserContext } from "../../../context/UserContext";
import { tw } from "../../../helpers/Styles";
import { useTranslation } from "react-i18next";
//import BrowserNotify from "./BrowserNotify";
ReactGA.initialize("G-ZL0ZP018E6");

const Topic = ({
  index,
  cardData,
  onTopicUpdate,
  onTopicDelete,
  topicStartTime,
  currentTopic,
  isComplete,
  isShowInProgress,
  lastTopic,
  setCurrentTopic,
  startTime,
  onPrompterRequest,
  triggerSave,
  showHasEnded,
  alltopics,
  showDate,
  onElapsedChange,
  onProFeature,
  isInControlCenter,
  controlCenterRef,
  editCard,
}: CardProps) => {
  const { user } = useContext(UserContext);
  const { t } = useTranslation();
  const [itemData, setItemData] = useState(cardData);
  const [tempItemData, setTempItemData] = useState(cardData);
  const [isEditable] = useState<boolean>(false);
  //const [topicStartTime, setTopicStartTime] = useState<number>(0);
  const [isNotesEnlarged, setIsNotesEnlarged] = useState<boolean>(false);
  //const [isPrompterShowing, setIsPrompterShowing] = useState<boolean>(false);
  const [currentTime, setCurrentTime] = useState(
    Number(cardData.topic_time) * 60
  );
  const [includeInRundown, setIncludeInRunDown] = useState<boolean>(
    cardData.includeInRundown !== undefined ? cardData.includeInRundown : true
  );

  const [isAlertVisible, setIsAlertVisible] = useState<boolean>(false);
  const [alertLabels, setAlertLabels] = useState<AlertLabelProps>();
  const [topicTimeDsp, setTopicTimeDsp] = useState<string>();
  const [isScriptShowing, setIsScriptShowing] = useState<boolean>(false);
  const [scriptTime, setScriptTime] = useState<number>(0);

  const targetDivRef = useRef<HTMLDivElement | null>(null);

  const setTopicAsActive = (index: number) => {
    //console.log("setCurrentTopic + topicStartTime", topicStartTime);
    setCurrentTopic(index);
  };

  const confirmTopicDelete = (index: number) => {
    onTopicDelete(index);
    setIsAlertVisible(false);
  };

  const alertProFeature = () => {
    //cancelEdit();
    onProFeature("Script");
  };

  const deleteTopic = (index: number) => {
    if (!showHasEnded) {
      const labels = {
        title: "Are you sure?",
        message:
          "Are you sure you want to delete this segment? This can't be undone once saved.",
        confirmLabel: "Yes, Delete",
        cancelLabel: "Cancel",
        onConfirm: () => confirmTopicDelete(index),
        onCancel: () => setIsAlertVisible(false),
        confirmClass: "bg-darkred hover:bg-red-500",
        cancelClass: "bg-blue hover:bg-blue2",
      };
      setAlertLabels(labels);
      setIsAlertVisible(true);
    }
  };

  const enlargeNotes = () => {
    setIsNotesEnlarged(isNotesEnlarged ? false : true);
  };

  const togglePrompter = () => {
    onPrompterRequest(true, tempItemData.script);
  };

  const calcReadingTime = (inputString: string): number => {
    if (inputString === undefined) {
      return 0;
    }
    const wordsPerMinute = 180;
    const words = inputString.split(/\s+|\b/);

    const filteredWords = words.filter((word) => word.trim() !== "");
    const readingTime = filteredWords.length / wordsPerMinute;

    return Number(readingTime.toFixed(2));
  };

  const convertTextLinksToHtml = (text: string) => {
    // Regular expression to match URLs in text
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const parts = text.split(urlRegex);

    const elements = parts.map((part, index) => {
      if (urlRegex.test(part)) {
        // If the part is a URL, return an anchor tag
        return (
          <a href={part} key={index} target="_blank" rel="noopener noreferrer">
            {part}
          </a>
        );
      } else {
        // If the part is not a URL, return plain text
        return part;
      }
    });

    return elements;
  };

  /* const lineBreaksToBr = (text: string):String => {

    return text.split("\n").map((line,index)=>(<p key={index}>{line}</p>));
  } */

  const onEditCard = () => {
    if (!showHasEnded) {
      //setIsEditable(isEditable ? false : true);
      editCard(true, index, cardData);
      ReactGA.event({
        category: "User Interction",
        action: "Save Show",
      });
    }
  };

  useEffect(() => {
    onElapsedChange(topicTimeDsp);
  }, [topicTimeDsp]);

  useEffect(() => {
    setIncludeInRunDown(tempItemData.includeInRundown);
    setScriptTime(calcReadingTime(tempItemData.script));
  }, [tempItemData]);

  useEffect(() => {
    //console.log("ITEM DATA", itemData);
    if (isShowInProgress) {
      triggerSave();
    } else {
      setTopicTimeDsp(secondsToTimecode(itemData.topic_time * 60));
    }
    setIncludeInRunDown(itemData.includeInRundown);
  }, [itemData]);

  useEffect(() => {
    setTopicTimeDsp(secondsToTimecode(currentTime));
  }, [currentTime]);

  useEffect(() => {
    let timerInt: NodeJS.Timeout;

    if (currentTopic === index && currentTime >= 0 && isShowInProgress) {
      if (itemData.start_time === null) {
        const currentStartTime = new Date();
        const topicData = {
          ...itemData,
          start_time: calculateTimeDuration(
            startTime,
            dateToTime(currentStartTime)
          ),
        };
        onTopicUpdate(index, topicData);
        setItemData(topicData);
        setTempItemData(topicData);
      }

      timerInt = setTimeout(() => {
        const nowTime = Date.now();
        const elapsed =
          nowTime - (topicStartTime === 0 ? Date.now() : topicStartTime);
        const remainingTime = Math.max(
          itemData.topic_time * 60 * 1000 - elapsed,
          0
        );
        const remainingSeconds = Math.ceil(remainingTime / 1000);
        if (topicStartTime === 0) {
          setCurrentTime(remainingSeconds - 1);
        } else {
          setCurrentTime(remainingSeconds);
        }
      }, 1000);

      //console.log("Current time", currentTime);
      if (currentTime === 30) {
        /* BrowserNotify(
          "Topic ending",
          `${itemData.topic} ending in ${currentTime}`
        ); */
      }
    }

    return () => {
      clearTimeout(timerInt);
    };
  }, [currentTopic, currentTime]);

  /* useEffect(() => {
    if (isEditable) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
  }, [isEditable]); */

  useEffect(() => {
    if (cardData.includeInRundown === undefined) {
      //console.log("CARD INCLUDE NEEDS SETTING");
      //for shows created before includeInRundown introduced.
      const topicData = {
        ...cardData,
        includeInRundown: includeInRundown,
      };

      setItemData(topicData);
      setTempItemData(topicData);
    } else {
      setItemData(cardData);
      setTempItemData(cardData);
    }

    if (cardData.script?.length > 0 && cardData.notes.length === 0) {
      setIsScriptShowing(true);
    }
  }, [cardData]);

  useEffect(() => {
    if (lastTopic === index && itemData.end_time === null) {
      const currentEndTime = new Date();
      const topicData = {
        ...itemData,
        end_time: calculateTimeDuration(startTime, dateToTime(currentEndTime)),
      };
      onTopicUpdate(index, topicData);
      setItemData(topicData);
    }
  }, [lastTopic]);

  useEffect(() => {
    if (currentTopic === index && targetDivRef.current && isShowInProgress) {
      const targetElement = targetDivRef.current;
      const headerHeight = 60; // Replace with the actual height of your fixed header
      const extraPixels = 20; // Adjust this value as needed for additional spacing

      const isControlCenterScroll = isInControlCenter === true;
      const topPosition =
        targetElement.getBoundingClientRect().top +
        (!isControlCenterScroll
          ? window.scrollY
          : controlCenterRef.current.scrollTop) -
        (!isControlCenterScroll ? headerHeight - extraPixels : 110);

      if (!isControlCenterScroll) {
        window.scrollTo({
          top: topPosition,
          behavior: "smooth",
        });
      } else {
        controlCenterRef.current.scrollTo({
          top: topPosition,
          behavior: "smooth",
        });
      }
    }
    if (currentTopic === index) {
      setCurrentTime(itemData.topic_time * 60);
    }
  }, [currentTopic]);

  return (
    <>
      <CustomAlert
        isAlertVisible={isAlertVisible}
        title={alertLabels?.title}
        message={alertLabels?.message}
        confirmLabel={alertLabels?.confirmLabel}
        cancelLabel={alertLabels?.cancelLabel}
        onConfirm={alertLabels?.onConfirm}
        onCancel={alertLabels?.onCancel}
        cancelClass={alertLabels?.cancelClass}
        confirmClass={alertLabels?.confirmClass}
      />

      <EnlargedNote
        isOpen={isNotesEnlarged}
        note={!isScriptShowing ? tempItemData.notes : tempItemData.script}
        onClose={() => setIsNotesEnlarged(false)}
      />

      <div
        ref={targetDivRef}
        className={
          currentTopic === index && isShowInProgress
            ? currentTime > 0
              ? isEditable
                ? `${tw.cardInner} bg-[#03090f] editable`
                : currentTime > 0 && currentTime < 30
                ? `${tw.cardInner} warningCard bg-yellow-700`
                : `${tw.cardInner} active bg-[#094b2d]`
              : `${tw.cardInner} overtimeCard`
            : isEditable
            ? `${tw.cardInner} editable `
            : isComplete && isShowInProgress
            ? `${tw.cardInner} read bg-[#03090f] text-[#25384a]`
            : includeInRundown
            ? `${tw.cardInner}`
            : `${tw.cardInner} bg-disabled2`
        }
        key={`card-${index}`}
      >
        <div className="action-grp sorthide">
          {isShowInProgress && currentTopic !== index && (
            <>
              <button
                className={`${tw.btn.action} bg-blue rounded-sm hover:bg-blue2`}
                onClick={() => setTopicAsActive(index)}
              >
                SET TOPIC
              </button>{" "}
            </>
          )}
          {!isShowInProgress && (
            <>
              <button
                className={
                  showHasEnded
                    ? `${tw.btn.action} bg-disabled text-gray-500 rounded-sm`
                    : `${tw.btn.action} bg-blue rounded-sm hover:bg-blue2`
                }
                onClick={() => deleteTopic(index)}
              >
                <FontAwesomeIcon icon={faTrash} />
              </button>{" "}
            </>
          )}
          <button
            className={
              showHasEnded
                ? `${tw.btn.action} bg-disabled text-gray-500 rounded-sm `
                : isEditable
                ? `${tw.btn.action} bg-blue rounded-sm hover:bg-blue2`
                : `${tw.btn.action} bg-blue rounded-sm hover:bg-blue2`
            }
            onClick={onEditCard}
          >
            <FontAwesomeIcon icon={faPenToSquare} />
          </button>
        </div>
        <br className="sorthide" />
        <div className="absolute top-0 right-0 text-xs p-3 bg-black sorthide">
          {topicTimeDsp}
        </div>
        <SegmentStartTime
          topics={alltopics}
          index={index}
          starttime={showDate}
        />

        {!includeInRundown ? (
          <span className="text-sm text-rose-700 leading-none sorthide">
            <span className="uppercase ">{t("planner.not_in_rundown")}</span> -{" "}
            {itemData.topic_time} {t("planner.minute_abrreviation")}
          </span>
        ) : (
          <>
            <span className="text-sm sorthide">
              {t("planner.est_segment_time")} - {itemData.topic_time}{" "}
              {t("planner.minute_abrreviation")}
            </span>
          </>
        )}
        <span className={tw.text.cardTitle}>{itemData.topic}</span>

        <span className="text-lg sorthide">{itemData.ticker_label}</span>

        {itemData.notes.length || itemData.script?.length ? (
          <>
            <div className="flex gap-1 text-white sorthide border-b border-b-gray-500 mt-5">
              {itemData.notes.length > 0 ? (
                <>
                  <span
                    className={
                      !isScriptShowing
                        ? `${tw.tabs.active} bg-gray-500`
                        : `${tw.tabs.reg} `
                    }
                    onClick={() => setIsScriptShowing(false)}
                  >
                    <FontAwesomeIcon icon={faCommentDots} />{" "}
                    {t("planner.notes")}
                  </span>{" "}
                </>
              ) : null}
              {itemData.script ? (
                <>
                  <span
                    className={
                      isScriptShowing
                        ? `${tw.tabs.active} bg-gray-500`
                        : `${tw.tabs.reg}`
                    }
                    onClick={
                      user.accounttype.toUpperCase() === "PRO"
                        ? () => setIsScriptShowing(true)
                        : alertProFeature
                    }
                  >
                    <FontAwesomeIcon icon={faPenNib} /> {t("planner.script")}
                    {scriptTime > 0 ? (
                      <span className="smaller">
                        {" "}
                        ({scriptTime} {t("planner.minute_abrreviation")})
                      </span>
                    ) : null}
                  </span>
                </>
              ) : null}
            </div>
            {!isScriptShowing ? (
              <span
                className="text-sm leading-1 block max-h-52 overflow-y-auto overflow-x-hidden sorthide mt-3"
                onDoubleClick={enlargeNotes}
              >
                {itemData.notes.split("\n").map((line, index) => (
                  <span key={index}>
                    {convertTextLinksToHtml(line)}
                    <br />
                  </span>
                ))}
              </span>
            ) : null}
            {isScriptShowing ? (
              <>
                <span
                  className="text-sm leading-1 block max-h-52 overflow-y-auto overflow-x-hidden sorthide mt-3"
                  onDoubleClick={enlargeNotes}
                >
                  {itemData.script.split("\n").map((line, index) => (
                    <span key={index}>
                      {line}
                      <br />
                    </span>
                  ))}
                </span>
              </>
            ) : null}
            {itemData.script ? (
              <button
                className={`${tw.btn.action} bg-blue rounded-sm hover:bg-blue2 mt-2 sorthide`}
                onClick={togglePrompter}
              >
                <FontAwesomeIcon icon={faGlasses} />
              </button>
            ) : null}
            <hr className={`${tw.hr} sorthide`} />
          </>
        ) : null}
        <SegmentTimes itemData={itemData} />
      </div>
    </>
  );
};

export default Topic;
