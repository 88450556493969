import React, { useEffect, useState } from "react";
import Header from "../layout/Header";
//import { UserContext } from "../../context/UserContext";
import { useParams } from "react-router-dom";
import axios from "axios";
import { getBearerHeaders } from "../../helpers/GetBearerHeaders";

const Success = () => {
  //const {user} = useContext(UserContext);
  const [msg, setMsg] = useState<string>("Validating...");
  const [showRedirect, setShowRedirect] = useState<boolean>(false);
  const { sessionid } = useParams();

  /* const manageSub = async () => {
    console.log(user);
    const response = await axios.post("/create-portal-session", {
      session_id: sessionid,
    });
    if (response.data.success) {
      window.location.href = response.data.url;
    }
    console.log(response.data);
  }; */

  const doRedirect = () => {
    window.location.href = "/";
  };

  useEffect(() => {
    // console.log("call api");
    axios
      .post(
        "/checkout/success",
        {
          session_id: sessionid,
        },
        getBearerHeaders()
      )
      .then((response) => {
        //console.log("response", response);
        if (response.data) {
          setShowRedirect(true);
        }
        setMsg(response.data.message);
      });
  }, []);
  return (
    <>
      <Header />
      <div className="main">
        <div className="pages-wrap centered">
          <div className="col">
            <p>
              {msg}{" "}
              {showRedirect && (
                <a href="#" onClick={doRedirect}>
                  Go to app
                </a>
              )}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Success;
