import React, { useState } from "react";
import { formatTimestamp } from "../../helpers/Dates";
import axios from "axios";
import { getBearerHeaders } from "../../helpers/GetBearerHeaders";

export interface UserProps {
  email: string;
  accounttype: string;
  createdAt: any;
}
const User = (userdata: any) => {
  const { username, accounttype, createdAt, id, updatedAt, totalShows } =
    userdata.userdata;
  const [userType, setUserType] = useState(accounttype);

  const upgrade = () => {
    console.log("Upgrade", username, id);
    axios
      .post(
        "/api/v1/admin/upgradeuser",
        { accounttype: "pro", userid: id },
        getBearerHeaders()
      )
      .then((response) => {
        if (response.data.success) {
          setUserType("pro");
        }
      });
  };

  return (
    <>
      <tr>
        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-300 sm:pl-0">
          {id}
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">
          {username}
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">
          {userType.toUpperCase()}{" "}
          {userType === "free" && (
            <button
              className="ml-1 text-xs bg-green-700 py-0.5 px-2 rounded-sm uppercase border border-green-800"
              onClick={upgrade}
            >
              Upgrade?
            </button>
          )}
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">
          {totalShows}
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">
          {formatTimestamp(new Date(createdAt))}
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">
          {formatTimestamp(new Date(updatedAt))}
        </td>
      </tr>
    </>
  );
};

export default User;
