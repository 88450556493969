import { faPlay, faStop } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export interface AudioProps {
  audio: any;
  onAudioPlay: (audio: any) => void;
  onAudioStop: () => void;
  playingId: number;
}

const Audio = ({ audio, onAudioPlay, onAudioStop, playingId }: AudioProps) => {
  const playAudio = () => {
    console.log("Play audio: ", audio.fileLocation);
    onAudioPlay(audio);
  };

  return (
    <div
      className={`flex border ${
        playingId === audio.id
          ? "bg-blue-charcoal-400"
          : "border-slate-800 hover:bg-slate-700"
      } rounded-md mt-2 p-2 transition-colors duration-300 pointer`}
      onClick={playingId === audio.id ? onAudioStop : playAudio}
    >
      <div className="flex w-3/12 justify-center items-center border border-blue-charcoal-900 bg-blue-charcoal-700 mr-2 py-4 rounded-md">
        <FontAwesomeIcon icon={playingId === audio.id ? faStop : faPlay} />
      </div>
      <div className="w-9/12">
        <span className="text-sm">{audio.fileName}</span>
      </div>
    </div>
  );
};

export default Audio;
