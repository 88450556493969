import React from "react";
import { Route } from "react-router-dom";
import Login from "../components/auth/Login";
import SignUp from "../components/auth/SignUp";
import Logout from "../components/auth/Logout";

const AuthRoutes = (
  <>
    <Route path="/login" element={<Login />} />
    <Route path="/signup" element={<SignUp />} />
    <Route path="/logout" element={<Logout />} />
  </>
);

export default AuthRoutes;
