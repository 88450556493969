import React from "react";
import { ShowData } from "../../../types/Types";
import { SaveToLocalStorage } from "../../../helpers/LocalStorage";
import { useNavigate } from "react-router-dom";
import { formatTimestamp } from "../../../helpers/Dates";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDay, faVideo } from "@fortawesome/free-solid-svg-icons";
import {
  calculateTimeDuration,
  dateToTime,
  secondsToTimecode,
} from "../../../helpers/TimeCode";
import { useTranslation } from "react-i18next";

export interface ShowCardProps {
  showData: ShowData;
  shows: any;
  roomid: string;
}

const ShowCard = ({ showData, shows, roomid }: ShowCardProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const cardColor =
    showData.endtime === null
      ? "bg-ssblue hover:bg-ssblue2 border-slate-800"
      : "bg-sangria-950 border-slate-700 text-black";

  const setShowId = (id, dest) => {
    const index = shows.findIndex((obj) => obj.id === id);
    SaveToLocalStorage("lvs", index);
    if (dest === "studio") {
      //navigate(`/studio/${roomid}`);
      window.location.href = `/studio/${roomid}`;
    } else {
      navigate("/planner");
    }
  };

  return (
    <li
      className={`col-span-1 divide-y divide-slate-800 border rounded-md shadow transition-all duration-300 mt-3 sm:mt-0 ${cardColor}`}
    >
      <div className="flex w-full items-center justify-between space-x-6 p-6">
        <div className="flex-1 truncate">
          <div className="flex items-center space-x-3">
            <h3 className="truncate text-lg font-medium text-white">
              {showData.show.showTitle}
            </h3>
          </div>
          <p className="mt-1 truncate text-sm text-gray-500">
            {formatTimestamp(showData.show.show_date)}
            <br />
            <span className="uppercase">
              {showData.endtime !== null && showData.starttime !== null
                ? t("dashboard.runtime")
                : t("dashboard.est_runtime")}
              :
            </span>{" "}
            {showData.endtime !== null && showData.starttime !== null
              ? calculateTimeDuration(
                  dateToTime(new Date(showData.starttime)),
                  dateToTime(new Date(showData.endtime))
                )
              : secondsToTimecode(
                  showData.show.task_list_items.reduce((total, item) => {
                    return total + (Number(item.topic_time) || 0);
                  }, 0) * 60
                )}
            <br />
            <span className="uppercase">{t("segment_plural")}:</span>
            {""}
            {showData.show.task_list_items.length}
          </p>
        </div>
      </div>
      <div>
        <div className="-mt-px flex divide-x divide-slate-900 bg-blue rounded-bl-md rounded-br-md">
          <div className="flex w-0 flex-1 items-center">
            <span
              className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-2 rounded-bl-md border border-transparent py-2 text-sm font-semibold  text-white cursor-pointer hover:bg-blueHover transition-colors duration-300 uppercase"
              onClick={() => setShowId(showData.id, "plan")}
            >
              <FontAwesomeIcon icon={faCalendarDay} className={`h-4`} />

              {t("dashboard.btn.show_plan")}
            </span>
          </div>
          <div className="-ml-px flex w-0 flex-1 items-center ">
            <span
              className={`relative inline-flex w-0 flex-1 items-center justify-center gap-x-2 rounded-br-md border border-transparent py-2 text-sm font-semibold  transition-colors duration-300 uppercase ${
                showData.endtime !== null
                  ? "bg-gray-800 text-gray-600 cursor-default"
                  : "hover:bg-blueHover text-white cursor-pointer"
              }`}
              onClick={
                showData.endtime === null &&
                showData.show.task_list_items.length
                  ? () => setShowId(showData.id, "studio")
                  : null
              }
            >
              <FontAwesomeIcon icon={faVideo} className={`h-4`} />
              {t("dashboard.btn.add_cam")}
            </span>
          </div>
        </div>
      </div>
    </li>
  );
};

export default ShowCard;
