import { useEffect, useRef, useState } from "react";
import { Socket, io } from "socket.io-client";
import axios from "axios";
import { getBearerHeaders } from "../../../helpers/GetBearerHeaders";
import { getServer } from "../../../helpers/GetServer";

export interface OverlayManagerProps {
  socketserver: Socket;
  showOwnerId: string;
  isShowHost: boolean;
  rundownDefaultVisible?: boolean;
  chyronDefaultVisible?: boolean;
}
export const useOverlayManager = (
  socketserver,
  showOwnerId,
  isShowHost,
  rundownDefaultVisible: boolean | null,
  chyronDefaultVisible: boolean | null
) => {
  const [websocket, setWebSocket] = useState<Socket>(null);
  const [showTopics, setShowTopics] = useState<any>([]);
  const [showTicker, setShowTicker] = useState<string>();
  const [showTopic, setShowTopic] = useState<string>();
  const [sourceid, setSourceId] = useState<string>("");
  const [isRundownVisible, setRundownVisible] = useState<boolean>(
    rundownDefaultVisible !== null ? rundownDefaultVisible : true
  );
  const [isChyronVisible, setChyronVisible] = useState<boolean>(
    chyronDefaultVisible !== null ? chyronDefaultVisible : true
  );
  const [currentTopic, setCurrentTopic] = useState<string>("");
  const [overlayDataReady, setOverlayDataReady] = useState(null);
  const [isChyronEnabled, setIsChyronEnabled] = useState<boolean>(true);
  const [isTickerEnabled, setIsTickerEnabled] = useState<boolean>(true);
  const [isRundownEnabled, setIsRundownEnabled] = useState<boolean>(true);
  const [autoHide, setAutoHide] = useState<number>(10);
  //console.log("RUNDOWN SHAREID", shareid);

  const sourceidRef = useRef(null);
  const autohideTimeout = useRef(null);
  sourceidRef.current = sourceid;

  const onTopicUpdate = (data) => {
    const res = JSON.parse(data);
    //console.log("On TOPIC UPDATE", res.data);
    if (res.data) {
      setCurrentTopic(data);
      setShowTopic(res.data);
      setShowTopics(res.show);
    }
  };

  const onRundownVisibilityChange = (data) => {
    const { visible } = JSON.parse(data);
    //console.log("ON RUNDOWN VISIBILITY", visible);
    setRundownVisible(visible);
  };

  const onTickerUpdate = (data) => {
    //const { data } = JSON.parse(data);
    //console.log("ON TICKER DATA", JSON.parse(data));
    setShowTicker(JSON.parse(data).data);
  };

  const onChyronVisibleChange = (data) => {
    const { visible } = JSON.parse(data);
    //console.log("ON CHYRON VISIBILITY", visible);
    setChyronVisible(visible);
  };

  const onPing = (data) => {
    if (data) {
    }
    //console.log("PING", data);
    //setPingCount((prev) => prev + 1);
    // setPingData(data);
  };

  const onUpdateCams = (data) => {
    const hostCam = data.filter((cam) => cam.type === "host");
    //console.log("on update cams", hostCam);
    setIsChyronEnabled(hostCam[0].roomsettings.chyronEnabled);
    setIsTickerEnabled(hostCam[0].roomsettings.tickerEnabled);
    setIsRundownEnabled(hostCam[0].roomsettings.RundownEnabled);
  };

  const handleShowTopicUpdate = (data) => {
    const resultData = JSON.parse(data);
    //console.log("handleShowTopicUpdate", resultData);

    // should we show the chyron if autohide is enabled
    if (resultData.show.length) {
      const topicid = resultData.data.topicid;
      const topicdata = resultData.show[topicid];
      if (!topicdata.is_complete && autoHide) {
        if (autohideTimeout.current) {
          clearTimeout(autohideTimeout.current);
        }

        setChyronVisible(true);
        if (isShowHost) {
          const msg = {
            sourceid: sourceidRef.current,
            eventtype: "CHYRONTOGGLE",
            visible: true,
          };
          websocket.emit("obs-wss", msg);
        }
        if (autoHide) {
          autohideTimeout.current = setTimeout(() => {
            setChyronVisible(false);
            if (isShowHost) {
              const msg = {
                sourceid: sourceidRef.current,
                eventtype: "CHYRONTOGGLE",
                visible: false,
              };
              websocket.emit("obs-wss", msg);
            }
          }, autoHide * 1000);
        }
      }
    }
  };

  const handleShowStatusUpdate = (_data) => {
    //const resultData = JSON.parse(data);
    //console.log("handleShowStatusUpdate", resultData);
  };

  const handleShowDataUpdate = (_data) => {
    //const resultData = JSON.parse(data);
    //console.log("handleShowDataUpdate", resultData);
  };

  const emitToggleMsg = () => {
    const msg = {
      sourceid: "",
      eventtype: "RUNDOWNTOGGLE",
      visible: true,
    };
    //console.log("emit", msg);
    return msg;
  };

  const connectShow = async () => {
    if (socketserver) {
      setWebSocket(socketserver);
    } else {
      setWebSocket(io(getServer()));
    }

    const sources = await axios.post(
      "/api/showsourceid",
      { userid: showOwnerId },
      getBearerHeaders()
    );
    if (sources.data.success) {
      //console.log("socketserver", sources.data);
      const { show, topic, ticker, autohide, sourceid } = sources.data.items;
      setShowTopics(show);
      setShowTopic(topic);
      setShowTicker(ticker);
      setAutoHide(autohide);
      setSourceId(sourceid);
      setOverlayDataReady(true);
    }
  };

  useEffect(() => {
    if (websocket) {
      if (!socketserver) {
        websocket.connect();
        //console.log("RUNDOWN NEW WS CONNECTION", websocket);
        websocket.on("connect", () => {
          console.log("Connected?", websocket);
          websocket.emit("obs-wss", emitToggleMsg());

          //console.log("RUNDOWN CONNECTED TO WS");
          websocket.on("topic-update", onTopicUpdate);
          websocket.on("obs-rundown-toggle", onRundownVisibilityChange);
          websocket.on("ticker-update", onTickerUpdate);
          websocket.on("obs-chyron-toggle", onChyronVisibleChange);
          websocket.on("bs-ping", onPing);
          websocket.on("update-cams", onUpdateCams);
          websocket.on("shareable-topic-update", handleShowTopicUpdate);
          websocket.on("shareable-show-status-update", handleShowStatusUpdate);
          websocket.on("shareable-show-data-update", handleShowDataUpdate);
        });
      } else {
        //console.log("CONNECTING TO EXISTING WS");
        websocket.on("topic-update", onTopicUpdate);
        websocket.on("obs-rundown-toggle", onRundownVisibilityChange);
        websocket.on("ticker-update", onTickerUpdate);
        websocket.on("obs-chyron-toggle", onChyronVisibleChange);
        websocket.on("bs-ping", onPing);
        websocket.on("update-cams", onUpdateCams);
        websocket.on("shareable-topic-update", handleShowTopicUpdate);
        websocket.on("shareable-show-status-update", handleShowStatusUpdate);
        websocket.on("shareable-show-data-update", handleShowDataUpdate);
      }

      websocket.on("disconnect", () => {
        //console.log("RUNDOWN DISCONNECTED FROM WS");
        websocket.off("topic-update", onTopicUpdate);
        websocket.off("obs-rundown-toggle", onRundownVisibilityChange);
        websocket.off("ticker-update", onTickerUpdate);
        websocket.off("obs-chyron-toggle", onChyronVisibleChange);
        websocket.off("bs-ping", onPing);
        websocket.off("update-cams", onUpdateCams);
        websocket.off("shareable-topic-update", handleShowTopicUpdate);
        websocket.off("shareable-show-status-update", handleShowStatusUpdate);
        websocket.off("shareable-show-data-update", handleShowDataUpdate);
        websocket.disconnect();
      });
    }

    return () => {
      if (websocket) {
        websocket.off("topic-update", onTopicUpdate);
        websocket.off("obs-rundown-toggle", onRundownVisibilityChange);
        websocket.off("ticker-update", onTickerUpdate);
        websocket.off("obs-chyron-toggle", onChyronVisibleChange);
        websocket.off("bs-ping", onPing);
        websocket.off("update-cams", onUpdateCams);
      }
    };
  }, [websocket]);

  useEffect(() => {
    //console.log("RUNDOWN SHAREID", shareid);
  }, [showOwnerId]);

  useEffect(() => {
    if (showOwnerId) {
      connectShow();
    }
  }, [showOwnerId]);

  useEffect(() => {
    /* if (socketserver) {
      setWebSocket(socketserver);
    } else {
      setWebSocket(io(getServer()));
    } */
  }, []);

  return {
    autoHide,
    showTopics,
    isRundownVisible,
    currentTopic,
    isChyronVisible,
    showTicker,
    showTopic,
    overlayDataReady,
    isChyronEnabled,
    isTickerEnabled,
    isRundownEnabled,
  };
};
