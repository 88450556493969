import React, { useEffect, useState, Fragment, ChangeEvent } from "react";
import { Dialog, Transition } from "@headlessui/react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { tw } from "../../../helpers/Styles";
import { useTranslation } from "react-i18next";
import { getBearerHeaders } from "../../../helpers/GetBearerHeaders";
import { CreateFeed } from "../../../api-requests/Create";

/*  import axios from "axios"; */

export interface ShowPickerProps {
  isOpen: boolean;
  onClose: (isOpen: boolean) => void;
  onFeedUpdate: (feedData: any) => void;
}
const AddFeed = ({ isOpen, onClose, onFeedUpdate }: ShowPickerProps) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(isOpen);
  const [tempItemData, setTempItemData] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);

  //const [editorDspProps, setEditorDspProps] = useState("translate-x-0");
  const handleValueUpdate = (
    event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>,
    field: string
  ) => {
    const fieldVal = event.target.value;
    const topicData = {
      ...tempItemData,
      [field]: fieldVal,
    };

    //setCurrentTime(Number(topicData.topic_time) * 60);
    setTempItemData(topicData);
  };

  const handleSave = async () => {
    setErrorMsg(null);
    const isValidUrl = (url: string) => {
      try {
        new URL(url);
        return true;
      } catch (error) {
        return false;
      }
    };
    if (tempItemData) {
      if (tempItemData.feed_title.length < 3) {
        console.log("Invalid URL");
        setErrorMsg(`Feed Title Required`);
        return;
      }
      if (!isValidUrl(tempItemData.feed_url)) {
        setErrorMsg(`Invalid Feed URL`);
        return;
      }
      const postData = {
        feed_url: tempItemData.feed_url,
        feed_title: tempItemData.feed_title,
      };
      const feedAdded = await CreateFeed(postData, getBearerHeaders());
      if (feedAdded.success === false) {
        setErrorMsg(feedAdded.error);
        return;
      }
      onFeedUpdate(feedAdded);
      setTempItemData(null);
      onClose(false);
    } else {
      setErrorMsg("Feed title and URL are required.");
    }
  };

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-[260]" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className={tw.modalBg} />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 left-0 flex max-w-full pr-5">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-300 sm:duration-300"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-300 sm:duration-300"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col overflow-y-auto bg-white drop-shadow-lg border-r border-ssblue ">
                    <div className="bg-ssblue px-4 py-6 sm:px-6">
                      <div className="flex items-center justify-between">
                        <Dialog.Title className="text-base font-semibold leading-6 text-white">
                          Add A New RSS Feed
                        </Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <FontAwesomeIcon
                            className="relative rounded-md bg-ssbluehover text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white pointer"
                            icon={faCircleXmark}
                            onClick={() => onClose(false)}
                          />
                        </div>
                      </div>
                      <div className="mt-1">
                        <p className="text-sm text-indigo-300">
                          {t("planner.editor.subtitle")}
                        </p>
                      </div>
                    </div>

                    <div className="relative flex-1 px-4 py-1 sm:px-6 bg-ssblue2">
                      {errorMsg && (
                        <div className="border border-sangria-800 bg-sangria-400 p-2 rounded mt-3">
                          <p className=" text-black font-semibold">ERROR</p>
                          <p className="text-sm text-black">{errorMsg}</p>
                        </div>
                      )}
                      <div className="space-y-6 pb-5 pt-6">
                        <div>
                          <label className="block text-sm font-medium leading-6 text-white">
                            {t("feed_title")}
                          </label>
                          <div className="mt-2">
                            <input
                              type="text"
                              name="feed-title"
                              id="feed-title"
                              className="block w-full rounded-md border-0 p-1.5 bg-slate-300 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              onChange={(e) =>
                                handleValueUpdate(e, "feed_title")
                              }
                              placeholder={`Title of feed`}
                            />
                          </div>
                        </div>
                        <div>
                          <label className="block text-sm font-medium leading-6 text-white">
                            {t("feed_url")}
                          </label>
                          <div className="mt-2">
                            <input
                              type="text"
                              name="feed-url"
                              id="feed-url"
                              className="block w-full rounded-md border-0 p-1.5 bg-slate-300 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              onChange={(e) => handleValueUpdate(e, "feed_url")}
                              placeholder={"Enter feed url"}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-shrink-0 justify-end px-4 py-4 bg-ssblue">
                      <button
                        type="button"
                        className={`${tw.btn.reg} bg-red hover:bg-redHover text-white mr-1`}
                        onClick={() => onClose(false)}
                      >
                        {t("cancel")}
                      </button>
                      <button
                        type="button"
                        className={`${tw.btn.reg} bg-green-800 hover:bg-green-950 text-white hover:text-white`}
                        onClick={handleSave}
                      >
                        {t("save_feed")}
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default AddFeed;
