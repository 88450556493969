import React from "react";

export interface TabList {
  value: string;
  label: string;
}
export interface TabProps {
  tabList: TabList[];
  onFilter: (value: string) => void;
  currentFilter: string;
}

const Tabs = ({ tablist, onFilter, currentFilter }) => {
  const selectFilter = (e, value) => {
    e.preventDefault();
    onFilter(value);
  };

  return (
    <div>
      <div className="sm:hidden">
        <label className="sr-only">Select a tab</label>

        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
          onChange={(e) => selectFilter(e, e.target.value)}
        >
          {tablist.map((tab, index) => {
            return <option key={index}>{tab.label}</option>;
          })}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="flex text-white sorthide border-b-gray-500 my-5">
          <div className="-mb-px flex space-x-2" aria-label="Tabs">
            {tablist.map((tab, index) => {
              return (
                <a
                  href="#"
                  className={
                    currentFilter.toUpperCase() === tab.value.toUpperCase()
                      ? `text-white rounded-md px-4 py-1 border text-base border-slate-700 bg-sky-700 hover:bg-sky-700 pointer transition-colors duration-300 ease-in-out no-underline active`
                      : `text-white rounded-md border border-slate-800 px-4 py-1 text-base hover:bg-sky-500 pointer transition-colors duration-300 ease-in-out no-underline`
                  }
                  onClick={(e) => selectFilter(e, tab.value)}
                  key={index}
                >
                  {tab.label}
                </a>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tabs;
