import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faClose,
  faCircleStop,
  faCalendarDay,
} from "@fortawesome/free-solid-svg-icons";
import { formatTimestamp } from "../../../helpers/Dates";
import { UserContext } from "../../../context/UserContext";
import { useNavigate } from "react-router-dom";
import { tw } from "../../../helpers/Styles";
import { useTranslation } from "react-i18next";

export interface PastShowsProps {
  selectedShow: number;
  loadShow: (event: ChangeEvent<HTMLSelectElement> | number) => void;
  shows: any;
  onRefresh?: () => void;
  showid: number;
  isShowInProgress: boolean;
  eventTracker?: any;
}

const PastShows = ({
  selectedShow,
  loadShow,
  shows,
  onRefresh,
  showid,
  isShowInProgress,
  eventTracker,
}: PastShowsProps) => {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [initialLoaded, setInitialLoaded] = useState(false);
  const [listShowing, setListShowing] = useState(false);
  const [showsDislayed, setShowsDisplayed] = useState<number>(0);
  const [currentFilter, setCurrentFilter] = useState<string>("all");
  const [filteredShows, setFilteredShows] = useState(shows);
  const [currentShowId, setCurrentShowId] = useState(-1);
  const sortingEnabled = true;

  //console.log("SHOWS", shows);
  const showLoader = (showIndex) => {
    const index = shows.findIndex((obj) => obj.id === showIndex);
    eventTracker({ category: "APP_BTN", action: "Load Show" });

    loadShow(index);
    setListShowing(false);
  };

  const filterShows = (filterType: string) => {
    setCurrentFilter(filterType);
    switch (filterType.toUpperCase()) {
      case "ALL":
        const filteredShows = [...shows];
        filteredShows.sort((a, b) => {
          const dateA = new Date(a.updatedAt);
          const dateB = new Date(b.updatedAt);

          // Compare the Date objects
          if (dateA > dateB) return -1;
          if (dateA < dateB) return 1;
          return 0;
        });
        setFilteredShows(filteredShows);
        break;
      case "SCHEDULED":
        const scheduledShows = shows.filter((show) => show.endtime === null);
        scheduledShows.sort((a, b) => {
          const dateA = new Date(a.updatedAt);
          const dateB = new Date(b.updatedAt);

          // Compare the Date objects
          if (dateA > dateB) return -1;
          if (dateA < dateB) return 1;
          return 0;
        });
        setFilteredShows(scheduledShows);
        break;
      case "ENDED":
        const endedShows = shows.filter((show) => show.endtime !== null);
        endedShows.sort((a, b) => {
          const dateA = new Date(a.updatedAt);
          const dateB = new Date(b.updatedAt);

          // Compare the Date objects
          if (dateA > dateB) return -1;
          if (dateA < dateB) return 1;
          return 0;
        });
        setFilteredShows(endedShows);
        break;
    }
  };

  useEffect(() => {
    if (initialLoaded) {
      filterShows(currentFilter);
      setShowsDisplayed(shows.length);
    }
  }, [shows]);

  useEffect(() => {
    if (!initialLoaded && selectedShow > -1) {
      setCurrentShowId(currentShowId);
      loadShow(selectedShow);
      setInitialLoaded(true);
    }
  }, [selectedShow]);

  useEffect(() => {
    if (listShowing) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
  }, [listShowing]);

  useEffect(() => {
    //console.log("Initial Load:", selectedShow);
    if (onRefresh) {
    }
    setShowsDisplayed(shows.length);
    //loadShow(selectedShow);
  }, []);

  return (
    <>
      {!isShowInProgress ? (
        <>
          <button
            className={`${tw.btn.action} bg-blue hover:bg-blueHover mr-0`}
            onClick={() => setListShowing(!listShowing)}
          >
            {t("all_shows")}
          </button>
        </>
      ) : null}
      <div
        className={listShowing ? "show-list-modal on" : "show-list-modal"}
        onClick={() => setListShowing(false)}
      ></div>
      <div className={listShowing ? "show-list on" : "show-list"}>
        <div className="nav-header">
          <span>SHOW BROWSER</span>{" "}
          <span className="small">({showsDislayed})</span>
          <i className="close" onClick={() => setListShowing(!listShowing)}>
            <FontAwesomeIcon icon={faClose} />
          </i>
        </div>
        {sortingEnabled && (
          <div className="show-list-options">
            <ul>
              <li
                className={currentFilter === "all" ? "selected" : ""}
                onClick={() => filterShows("all")}
              >
                All
              </li>
              <li
                className={currentFilter === "scheduled" ? "selected" : ""}
                onClick={() => filterShows("scheduled")}
              >
                Scheduled
              </li>
              <li
                className={currentFilter === "ended" ? "selected" : ""}
                onClick={() => filterShows("ended")}
              >
                Ended
              </li>
            </ul>
          </div>
        )}
        <div className="show-list-items">
          {filteredShows?.length ? (
            filteredShows.map((show: any, index: number) => (
              <div
                key={index}
                onClick={() => showLoader(show.id)}
                className={showid === show.id ? "item selected" : "item"}
              >
                <div className="item-inner">
                  <span className="no-wrap">
                    {typeof show.show === "string"
                      ? JSON.parse(String(show.show)).episode
                      : show.show.episode}{" "}
                    -
                    {typeof show.show === "string"
                      ? JSON.parse(String(show.show)).showTitle
                      : show.show.showTitle}{" "}
                  </span>
                  <br />
                  <span
                    className={
                      show.endtime === null ? "small" : "small red-text"
                    }
                  >
                    {show.endtime === null ? (
                      <>
                        <FontAwesomeIcon icon={faCalendarDay} />{" "}
                        {formatTimestamp(
                          new Date(
                            typeof show.show === "string"
                              ? JSON.parse(String(show.show)).show_date
                              : show.show.show_date
                          )
                        )}
                      </>
                    ) : (
                      <>
                        <FontAwesomeIcon icon={faCircleStop} />{" "}
                        {formatTimestamp(new Date(show.endtime))}
                      </>
                    )}
                  </span>
                </div>
              </div>
            ))
          ) : (
            <div key="noitems" className="item">
              <div className="item-inner">No Shows</div>
            </div>
          )}
        </div>
        {user.accounttype.toUpperCase() === "FREE" && (
          <>
            <div className="show-browser-footer">
              <span>
                Free accounts can save a maximum of 2 shows. Upgrade to create
                unlimited shows.
              </span>
              <br />
              <br />
              <button className="yellow" onClick={() => navigate("/upgrade")}>
                Upgrade Now
              </button>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default PastShows;
