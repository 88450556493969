import React from "react";
import Header from "../layout/Header";
import { Link } from "react-router-dom";
import useTracking from "../../hooks/useTracking";
import useScrollTop from "../../hooks/useScrollTop";

const TermsOfService = () => {
  useTracking();
  useScrollTop();
  return (
    <>
      <Header />
      <div className="container mx-auto max-w-3xl my-6">
        <div className="w-full space-y-5">
          <h1 className="text-3xl">Terms of Service</h1>

          <p>
            <strong>Last Updated:</strong> [9-25-23]
          </p>

          <p>
            Welcome to ShoPro.live! Please carefully read these Terms of Service
            ("Terms") before using our website and services. By accessing or
            using ShoPro.live, you agree to be bound by these Terms.
          </p>

          <h2 className="text-2xl">1. Acceptance of Terms</h2>

          <p>
            By using ShoPro.live, you acknowledge that you have read,
            understood, and agree to these Terms. If you do not agree to these
            Terms, please do not use our services.
          </p>

          <h2 className="text-2xl">2. Description of Services</h2>

          <p>
            ShoPro.live is a real-time live event manager that allows users to
            create, manage, and share live events and segments. Users are solely
            responsible for the accuracy of the information they input.
          </p>

          <h2 className="text-2xl">3. User Responsibilities</h2>
          <p>
            <ol type="a">
              <li>
                Users are responsible for the content and accuracy of the
                information they provide on ShoPro.live.
              </li>
              <li>
                Users must not use ShoPro.live for any illegal, harmful, or
                unauthorized purposes.
              </li>
              <li>
                Users must not attempt to disrupt or interfere with the
                functionality of ShoPro.live or the experience of other users.
              </li>
            </ol>
          </p>
          <h2 className="text-2xl">4. Limitation of Liability</h2>
          <p>
            <ol type="a">
              <li>
                ShoPro.live is provided on an "as is" and "as available" basis.
                We do not guarantee the accuracy, reliability, or availability
                of the services.
              </li>
              <li>
                {" "}
                We are not responsible for any incorrect information, data loss,
                or damage that may result from using ShoPro.live.
              </li>
              <li>
                We are not liable for any losses or damages, including but not
                limited to direct, indirect, incidental, consequential, or
                punitive damages, arising from the use of ShoPro.live.
              </li>
            </ol>
          </p>
          <h2 className="text-2xl">5. Indemnification</h2>

          <p>
            You agree to indemnify and hold ShoPro.live, its owners, employees,
            and affiliates harmless from any claims, liabilities, damages, or
            expenses arising from your use of the service or violation of these
            Terms.
          </p>

          <h2 className="text-2xl">6. Termination</h2>

          <p>
            We reserve the right to terminate or suspend your access to
            ShoPro.live at any time, without prior notice, for any reason,
            including a breach of these Terms.
          </p>

          <h2 className="text-2xl">7. Privacy</h2>

          <p>
            Your use of ShoPro.live is also governed by our Privacy Policy,
            which can be found here. [ <Link to="/privacy">Privacy Policy</Link>{" "}
            ]
          </p>

          <h2 className="text-2xl">8. Changes to Terms</h2>

          <p>
            We may update these Terms from time to time. Any changes will be
            posted on this page, and your continued use of ShoPro.live after
            such changes constitute your acceptance of the revised Terms.
          </p>

          <h2 className="text-2xl">9. Governing Law</h2>

          <p>
            These Terms are governed by and construed in accordance with the
            laws of [Your Jurisdiction], without regard to its conflict of law
            principles.
          </p>

          <h2 className="text-2xl">10. Data Collection and Analytics</h2>
          <p>
            <ol type="a">
              <li>
                User Data: ShoPro.live may collect certain information from
                users, including but not limited to personal information such as
                names and email addresses, as well as usage data. We collect and
                process this data in accordance with our Privacy Policy, which
                can be found here.
              </li>
              <li>
                Third-Party Analytics: ShoPro.live utilizes third-party
                analytics services, such as Google Analytics, to analyze user
                behavior and improve the quality of our services. These services
                may collect and process data on our behalf. We do not have
                control over the data collected by these third-party services or
                their use of that data. Users are encouraged to review the
                privacy policies of these third parties for more information on
                their data collection and usage practices.
              </li>
              <li>
                Cookies: ShoPro.live may use cookies and similar technologies to
                enhance the user experience. Users can manage their cookie
                preferences through their browser settings.
              </li>
              <li>
                {" "}
                Data Security: While we take reasonable measures to protect user
                data, we cannot guarantee the security of information
                transmitted to or from our services. Users are responsible for
                maintaining the confidentiality of their login credentials.
              </li>
              <li>
                {" "}
                Data Deletion: Upon request, we will make reasonable efforts to
                delete user data in accordance with our Privacy Policy. However,
                some data may be retained for legal or legitimate business
                purposes.
              </li>
            </ol>
          </p>
          <h2 className="text-2xl">11. Third-Party Links and Services</h2>
          <p>
            ShoPro.live may contain links to third-party websites or services.
            We are not responsible for the privacy practices or content of these
            third parties. Users are encouraged to review the terms and privacy
            policies of these third-party websites and services.
          </p>

          <p>
            By using ShoPro.live, you acknowledge and agree to the data
            collection and usage practices outlined in this section.
          </p>

          <h2 className="text-2xl">12. Entire Agreement</h2>

          <p>
            These Terms constitute the entire agreement between you and
            ShoPro.live and supersede any prior agreements or understandings.
          </p>
        </div>
      </div>
    </>
  );
};

export default TermsOfService;
