import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useRef, useState } from "react";
import { tw } from "../../../helpers/Styles";

export const PasswordPrompt = ({ onpassword }) => {
  const [open, setOpen] = useState(true);
  const passRef = useRef(null);

  const updatePass = (pass: string) => {
    onpassword(pass);
  };

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-[250]"
          initialFocus={passRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className={tw.modalBg} />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-md border border-slate-500 bg-ssblue2 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                      <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-300 sm:mx-0 sm:h-10 sm:w-10">
                        <FontAwesomeIcon
                          icon={faExclamationTriangle}
                          className="text-red-900"
                        />
                      </div>
                      <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-base font-semibold leading-6 text-white"
                        >
                          Password Required
                        </Dialog.Title>
                        <div className="mt-2">
                          <input
                            className="px-2 py-1 rounded-md text-black"
                            ref={passRef}
                            type="password"
                            maxLength={20}
                            placeholder="Enter a password"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-ssblue px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    <button
                      className="mt-3 inline-flex w-full justify-center px-3 py-2 text-sm font-semibold shadow-sm bg-green hover:bg-greenHover sm:mt-0 sm:w-auto transition-colors duration-200 "
                      onClick={() => updatePass(passRef.current.value)}
                    >
                      Authenticate
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};
