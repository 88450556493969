interface TickerSettings {
  textSize: number;
  textPadding: number;
  textColor: string;
  textActiveTopicColor: string;
  speed: number;
  bgColor: string;
  borderColor: string;
  borderWidth: number;
}

interface RundownSettings {
  width: number;
  height: number;
  bgColor: string;
  BgColorActive: string;
  borderColor: string;
  borderWidth: number;
  fontSize: number;
  fontColor: string;
  fontColorActive: string;
  padding: number;
  spacing: number;
  leftMargin: number;
}

interface ChyronSettings {
  bgColor: string;
  borderColor: string;
  borderWidth: number;
  textColor: string;
  textSize: number;
  tickerMargin: number;
  height: number;
  widthPercent: number;
}

export interface OverlayTheme {
  theme: {
    name: string;
    ticker: TickerSettings;
    rundown: RundownSettings;
    chyron: ChyronSettings;
  };
}

export const themeKharysCrib: OverlayTheme = {
  theme: {
    name: "kharyscrib",
    ticker: {
      textSize: 25,
      textPadding: 15,
      textColor: "#fff",
      textActiveTopicColor: "#FF0000",
      speed: 2,
      bgColor: "#000000",
      borderColor: "#FFF",
      borderWidth: 5,
    },
    rundown: {
      width: 350,
      height: 60,
      bgColor: "#cfc9c9d1",
      BgColorActive: "#ff0202d1",
      borderColor: "#ffffff",
      borderWidth: 5,
      fontSize: 26,
      fontColor: "#000",
      fontColorActive: "#FFF",
      padding: 25,
      spacing: 5,
      leftMargin: 30,
    },
    chyron: {
      bgColor: "#ff0000",
      borderColor: "#ffffff",
      borderWidth: 10,
      textColor: "#ffffff",
      textSize: 50,
      tickerMargin: 50,
      height: 125,
      widthPercent: 0.6,
    },
  },
};
