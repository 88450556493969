import React, { ChangeEvent, useState } from "react";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import useTracking from "../../hooks/useTracking";

const ConfirmPassword = () => {
  const navigate = useNavigate();
  const { code } = useParams();
  const [userCode, setUserCode] = useState<string | null>(code);
  const [password, setPassword] = useState<string>("");
  const [passwordMatch, setPasswordMatch] = useState<string>("");
  const [isError, setIsError] = useState(false);
  const [errors, setErrors] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  useTracking();

  const submitRequest = (e: ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (password !== passwordMatch) {
      setIsError(true);
      setErrors(`Provided passwords don't match`);
    } else {
      setIsError(false);
      axios
        .post("/api/validate_reset_request", {
          code: userCode,
          password: password,
        })
        .then((response) => {
          console.log(response.data);
          if (response.data.success) {
            console.log("PASSWORD CHANGED");
            setIsSuccess(true);
            setIsError(false);
            setSuccessMessage(response.data.message);
          } else {
            console.log("ERROR");
            setIsError(true);
            setErrors(response.data.message);
          }
        });
    }
  };

  return (
    <>
      <div className="form-main">
        <div className="flex h-screen flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8 m-6 sm:m-auto">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <Link to="/">
              <img
                className="mx-auto h-10 w-auto"
                src="/img/logo-white.png"
                alt="ShoPro.live"
              />
            </Link>
          </div>

          <div className="mt-6 sm:mx-auto sm:w-full sm:max-w-[480px]">
            <div className="bg-white px-6 py-12 shadow rounded-md sm:rounded-lg sm:px-12">
              {isSuccess ? (
                <div className="text-center text-black">
                  <p>{successMessage}</p>
                  <p>
                    You may now log into your account using your new password.
                  </p>
                  <hr />
                  <button
                    className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    onClick={() => navigate("/login")}
                  >
                    Go To Login
                  </button>
                </div>
              ) : (
                <form className="space-y-6" onSubmit={submitRequest}>
                  {isError && (
                    <>
                      <div
                        className={
                          "border-2 border-red p-4 bg-gray-200 text-black text-sm mb-5 rounded-md"
                        }
                      >
                        {errors}
                      </div>
                    </>
                  )}
                  <div>
                    <label
                      htmlFor="code"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Code
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        id="code"
                        value={userCode ? userCode : ""}
                        onChange={(e) => setUserCode(e.target.value)}
                        placeholder="Confirmation Code"
                        required
                        className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div>
                    <div className="mt-2">
                      <input
                        type="password"
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="New Password"
                        required
                        className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div>
                    <div className="mt-2">
                      <input
                        type="password"
                        id="password_confirm"
                        value={passwordMatch}
                        onChange={(e) => setPasswordMatch(e.target.value)}
                        placeholder="Confirm Password"
                        required
                        className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div>
                    <button
                      type="submit"
                      className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      Update Password
                    </button>
                  </div>
                </form>
              )}
            </div>
          </div>

          <p className="mt-10 text-center text-sm text-white">
            <Link
              className="font-semibold text-gray-300 hover:text-gray-100"
              to="/login"
            >
              Login
            </Link>{" "}
            Or{" "}
            <Link
              className="font-semibold text-gray-300 hover:text-gray-100"
              to="/signup"
            >
              Sign Up
            </Link>
          </p>
        </div>
      </div>
    </>
  );
};

export default ConfirmPassword;
