import React, { useEffect, useState } from "react";
import { formatTimestamp } from "../../../helpers/Dates";
import {
  calOverUnder,
  calculateTimeDuration,
  dateToTime,
  secondsToTimecode,
  timecodeToSeconds,
} from "../../../helpers/TimeCode";
import { TopicDataArray } from "../../../types/Types";

export interface ShowtimeProps {
  isLive: boolean;
  showHasEnded: boolean;
  showDate: any;
  elapsed: string;
  startTime: number;
  endTime: number;
  estimatedShowTime: number;
  topics?: TopicDataArray;
}
const ShowTime = ({
  isLive,
  showHasEnded,
  showDate,
  elapsed,
  startTime,
  endTime,
  estimatedShowTime,
  topics,
}: ShowtimeProps) => {
  const [elapsedTime, setElapsedTime] = useState<string>(elapsed);
  const [overUnder, setOverUnder] = useState<string>("");
  const [hiLowEven, setHiLowEven] = useState<string>("even");

  useEffect(() => {
    let timerInt: NodeJS.Timeout;
    if (isLive) {
      timerInt = setTimeout(() => {
        const now = new Date();
        const elapsed = calculateTimeDuration(
          dateToTime(new Date(startTime)),
          dateToTime(now)
        );
        setElapsedTime(elapsed);
      }, 1000);
    }
    return () => {
      clearTimeout(timerInt);
    };
  }, [elapsedTime, isLive, showDate, elapsed]);

  useEffect(() => {
    //console.log(isLive, showHasEnded, showDate, elapsed, startTime, endTime);
    if (showHasEnded && endTime > 0) {
      const calcEnd = calculateTimeDuration(
        dateToTime(new Date(startTime)),
        dateToTime(new Date(endTime))
      );

      setElapsedTime(calcEnd);
    } else {
      setElapsedTime(elapsed === "00:00:00" ? "--:--:--" : elapsed);
    }
    const hiloStr = calOverUnder(topics);
    const isNegative = hiloStr.startsWith("-");
    setHiLowEven(isNegative ? "LO" : hiloStr.startsWith("+") ? "HI" : "EVEN");
    setOverUnder(hiloStr === "00:00:00" ? "--:--:--" : hiloStr);
  }, [isLive, showHasEnded, elapsed]);

  /* useEffect(() => {
    console.log("estimatedShowTime", estimatedShowTime);
    console.log("elapsedTime", elapsedTime);
    console.log("overUnder", overUnder);
    console.log("hiLowEven", hiLowEven);
  }); */

  return (
    <div className="flex flex-col items-center sm:items-start">
      {showHasEnded ? (
        <>
          <span className="text-4xl text-sangria-600">SHOW ENDED</span>
        </>
      ) : (
        <>
          <span className="text-4xl uppercase block">Scheduled</span>
          <span className="text-2xl text-sangria-600 uppercase block">
            {formatTimestamp(showDate)}
          </span>
        </>
      )}
      <div className="flex flex-row justify-center sm:justify-start">
        <div className="w-auto mr-5 justify-items-center">
          <span className="text-2xl block w-full ">Estimated:</span>
          <span className="text-4xl block w-full">
            {secondsToTimecode(estimatedShowTime * 60)}
          </span>
        </div>
        <div className="w-auto justify-center sm:justify-start">
          <span className="text-2xl block w-full">Actual:</span>

          <span
            className={
              timecodeToSeconds(elapsedTime) > estimatedShowTime * 60
                ? "text-red-600 text-4xl"
                : "text-4xl"
            }
          >
            {elapsedTime}{" "}
            <span
              className={
                hiLowEven === "LO"
                  ? "time-offset text-green text-2xl block sm:inline"
                  : hiLowEven === "HI"
                  ? "time-offset text-red-600 text-2xl block sm:inline"
                  : "time-offset text-2xl block sm:inline"
              }
            >
              {overUnder}
            </span>
          </span>
          <br />
        </div>
      </div>
    </div>
  );
};

export default ShowTime;
