const apiCall = async (method, url, data) => {
  const requestOptions = {
    method: method,
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
  };

  const result = await fetch(`http://localhost:5010${url}`, requestOptions);
  const response = await result.json();
  return response;
};

const get = async (url, data) => {
  const results = await apiCall("GET", url, data);
  return results;
};

const post = async (url, data) => {
  const results = await apiCall("POST", url, data);
  return results;
};

const ApiRequest = {
  get,
  post,
};

export default ApiRequest;
