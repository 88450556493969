import { useEffect } from "react";
import { fetchAiPrompt } from "../../../api-requests/Index";
import { getBearerHeaders } from "../../../helpers/GetBearerHeaders";

const useAi = () => {
  const videoPrompt =
    "Here are the titles for my topics for my next show. Can you read and rate these topics then come up with a good title and description to use for a youtube live stream where we talk about these topics. Note all these topics will be discussed in one show. Can you give me 3 options and when possible use content from the topics in the titles. Here are the topics list each topic prefixed with -tp- ";
  const aiTopics = async (topics) => {
    console.log("topics", topics.task_list_items);

    const filtered = topics.task_list_items.filter((item) => {
      return item.includeInRundown;
    });

    const topicList = filtered
      .map((item) => {
        return `-t-${item.topic}`;
      })
      .toString();

    const promptResults = await fetchAiPrompt(
      { prompt: videoPrompt + topicList },
      getBearerHeaders()
    );

    return new Promise((resolve) => {
      resolve(promptResults);
    });
  };

  useEffect(() => {
    return () => {};
  }, []);

  return { aiTopics, videoPrompt };
};

export default useAi;
