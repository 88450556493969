// i18n.js

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enTranslation from "../locales/en.json"; // Import translation resources
import esTranslation from "../locales/es.json";
import ruTranslation from "../locales/ru.json";

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: enTranslation },
    es: { translation: esTranslation },
    ru: { translation: ruTranslation },
  },
  lng: "en", // Set the default language
  fallbackLng: "en", // Fallback to English if a translation is missing
  interpolation: {
    escapeValue: false, // React already escapes by default
  },
});

export default i18n;
