import React, { CSSProperties, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { io, Socket } from "socket.io-client";
import axios from "axios";
import Marquee from "react-fast-marquee";
import { getBearerHeaders } from "../../../helpers/GetBearerHeaders";
import { getServer } from "../../../helpers/GetServer";
import "../../../sass/themes/theme-kharyscrib.scss";
import "../../../sass/themes/theme-pinky.scss";
import "../../../sass/themes/theme-playhouse.scss";

export interface TickerProps {
  tickerString?: string;
  defaultTheme?: string;
  socketserver?: Socket;
  isProAccount?: boolean;
  isBrandingGlobal?: boolean;
  isInControlCenter?: boolean;
  joinedShowid?: string;
  isEmbeded?: boolean;
}

const Ticker = ({
  tickerString,
  defaultTheme,
  socketserver,
  isProAccount,
  isBrandingGlobal,
  isInControlCenter,
  joinedShowid,
  isEmbeded,
}: TickerProps) => {
  const { sourceid } = useParams();
  const [showidToLoad, setShowIdToLoad] = useState(
    isInControlCenter === true ? joinedShowid : sourceid
  );
  const [tickerText, setTickerText] = useState<string>(tickerString || "");
  const [sourceUser, setSourceUser] = useState<number>(0);
  const [wsData, setWsData] = useState<string>(null);
  const [sourceisPro, setSourceIsPro] = useState<boolean>(
    isProAccount ? isProAccount : false
  );
  const [theme, setTheme] = useState<string>(
    defaultTheme ? defaultTheme : "kharyscrib"
  );

  const powerdPosition: CSSProperties = {
    backgroundColor: "black",
    position: "fixed",
    bottom: "0px",
    right: "0px",
    padding: "10px 10px 0px 10px",
    zIndex: "900",
    borderRadius: "8px 0px 0px 0px",
    borderLeft: "solid 2px #2d3f52",
    borderTop: "solid 2px #2d3f52",
  };

  const onTickerUpdate = (data) => {
    setWsData(data);
  };

  const connectShow = () => {
    const postData = { showData: showidToLoad };
    const apiUrl = showidToLoad ? "/api/sources/ticker" : "/api/v1/getticker";
    axios.post(apiUrl, postData, getBearerHeaders()).then((response) => {
      if (response.data.success) {
        //console.log(response.data);
        setTickerText(response.data.items.ticker);
        setTheme(response.data.items.theme);
      }
    });

    if (showidToLoad) {
      axios
        .post("/api/sources/userbysource", postData, getBearerHeaders())
        .then((response) => {
          if (response.data.success) {
            if (!!isInControlCenter) {
              setSourceIsPro(
                response.data.items.accounttype.toUpperCase() === "PRO"
                  ? true
                  : false
              );
            }

            setSourceUser(Number(response.data.items.userid));
            const socket = socketserver ? socketserver : io(getServer());
            if (!socketserver) {
              socket.connect();
              socket.on("connect", () => {
                //console.log("TICKER CONNECTED TO WS");
                socket.on("ticker-update", onTickerUpdate);
              });
            } else {
              socket.on("ticker-update", onTickerUpdate);
            }

            socket.on("disconnect", () => {
              //console.log("TICKER DISCONNECTED FROM WS");
              socket.off("ticker-update", onTickerUpdate);
            });
          }
        });
    }
  };

  useEffect(() => {
    if (defaultTheme) {
      setTheme(defaultTheme);
    }
  }, [defaultTheme]);

  useEffect(() => {
    if (wsData) {
      const resultData = JSON.parse(wsData);
      //console.log("ticker-update", resultData.id, sourceUser);
      if (resultData.id === sourceUser) {
        //console.log("SET TOPIC", resultData.data);
        setTickerText(resultData.data);
      }
    }
  }, [wsData]);

  useEffect(() => {
    if (joinedShowid) {
      setShowIdToLoad(joinedShowid);
    }
  }, [joinedShowid]);

  useEffect(() => {
    if (showidToLoad) {
      connectShow();
    }
  }, [showidToLoad]);

  useEffect(() => {}, []);

  return (
    <>
      {!sourceisPro && !isBrandingGlobal ? (
        <div style={powerdPosition}>
          <img src="/img/shopro-powered.png" width="175px" />
        </div>
      ) : null}
      <div
        className={`scrolling-text-container sources hidden md:flex ${theme} ${
          isInControlCenter && "studio"
        } ${isEmbeded && "emdeded"}`}
      >
        <Marquee className="ticker" key="ticker">
          {tickerText}
        </Marquee>
      </div>
    </>
  );
};

export default Ticker;
