import React, { ChangeEvent, useEffect, useState, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { TopicData } from "../../../types/Types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { tw } from "../../../helpers/Styles";
import { useTranslation } from "react-i18next";

export interface CardEditorProps {
  isEditing?: boolean;
  onCancelEdit: (isEdit: boolean) => void;
  setCurrentTime?: (time: number) => void;
  onSaveCard?: (cardIndex: number, data: TopicData) => void;
  cardInfo?: any;
  cardData?: any;
  onTopicUpdate?: (
    event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>,
    cardIndex: number
  ) => void;
  onTopicDelete?: (cardIndex: number) => void;
  setCurrentTopic?: (cardIndex: number) => void;
  onSave?: (cardIndex: number) => void;
}

const CardEditor = ({
  cardInfo,
  isEditing,
  onCancelEdit,
  onSaveCard,
}: CardEditorProps) => {
  const { t } = useTranslation();
  const [tempItemData, setTempItemData] = useState(cardInfo.data);
  const [isEditorOpen, setIsEditorOpen] = useState(false);
  const [open, setOpen] = useState(isEditing);
  const [checked, setChecked] = useState<boolean>(
    cardInfo.data && cardInfo.data.includeInRundown !== undefined
      ? cardInfo.data.includeInRundown
      : true
  );

  //const [editorDspProps, setEditorDspProps] = useState("translate-x-0");

  const topicPlaceHolder = t("planner.editor.topic_placeholder");
  const tickerPlaceHolder = t("planner.editor.ticker_placeholder");
  const notesPlaceHolder = t("planner.editor.notes_placeholder");
  const scriptPlaceHolder = t("planner.editor.script_placeholder");

  /* const handleValueUpdate = (
    event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>,
    field: string
  ) => {
    const fieldVal = event.target.value;
    const topicData = {
      ...tempItemData,
      [field]: fieldVal,
      isNewTopic: false,
    };

    setCurrentTime(Number(topicData.topic_time) * 60);
    setTempItemData(topicData);
  }; */

  /*
    <!-- Background backdrop, show/hide based on slide-over state. -->
    <!--
          Slide-over panel, show/hide based on slide-over state.

          Entering: "transform transition ease-in-out duration-500 sm:duration-700"
            From: "translate-x-full"
            To: "translate-x-0"
          Leaving: "transform transition ease-in-out duration-500 sm:duration-700"
            From: "translate-x-0"
            To: "translate-x-full"
        -->
  */

  const handleValueUpdate = (
    event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>,
    field: string
  ) => {
    const fieldVal = event.target.value;
    const topicData = {
      ...tempItemData,
      [field]: fieldVal,
      isNewTopic: false,
    };

    //setCurrentTime(Number(topicData.topic_time) * 60);
    setTempItemData(topicData);
  };

  const handleCheck = () => {
    //console.log("Is Checked?", !checked);
    setChecked(!checked);
    const topicData = {
      ...tempItemData,
      includeInRundown: !checked,
      isNewTopic: false,
    };
    setTempItemData(topicData);
  };

  const saveCard = () => {
    onSaveCard(cardInfo.index, tempItemData);
    setIsEditorOpen(false);
    onCancelEdit(false);
  };

  const onCancel = () => {
    setIsEditorOpen(false);
    onCancelEdit(false);
  };

  useEffect(() => {
    //setEditorDspProps(isEditorOpen ? "translate-x-0" : "translate-x-full");
  }, [isEditorOpen]);

  useEffect(() => {
    setOpen(isEditing);
  }, [isEditing]);

  useEffect(() => {
    if (open !== null) {
      if (!open) {
        setIsEditorOpen(false);
        onCancelEdit(false);
      }
    }
  }, [open]);

  useEffect(() => {
    //setIncludeInRunDown(tempItemData.includeInRundown);
    if (tempItemData) {
      setChecked(tempItemData.includeInRundown);
    }
  }, [tempItemData]);

  useEffect(() => {
    setTempItemData(cardInfo.data);
  }, [cardInfo]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-[260]" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className={tw.modalBg} />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 left-0 flex max-w-full pr-5">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-300 sm:duration-300"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-300 sm:duration-300"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col overflow-y-auto bg-white drop-shadow-lg border-r border-ssblue ">
                    <div className="bg-ssblue px-4 py-6 sm:px-6">
                      <div className="flex items-center justify-between">
                        <Dialog.Title className="text-base font-semibold leading-6 text-white">
                          {t("planner.editor.title")} {cardInfo.index + 1}
                        </Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <FontAwesomeIcon
                            className="relative rounded-md bg-ssbluehover text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white pointer"
                            icon={faCircleXmark}
                            onClick={onCancel}
                          />
                        </div>
                      </div>
                      <div className="mt-1">
                        <p className="text-sm text-indigo-300">
                          {t("planner.editor.subtitle")}
                        </p>
                      </div>
                    </div>
                    <div className="relative flex-1 px-4 py-1 sm:px-6 bg-ssblue2">
                      <div className="space-y-6 pb-5 pt-6">
                        <div>
                          <label className="block text-sm font-medium leading-6 text-white">
                            {t("planner.editor.topic_time")}
                          </label>
                          <div className="mt-2">
                            <input
                              type="number"
                              min="1"
                              max="500"
                              name="project-name"
                              id="project-name"
                              className="block w-16 rounded-md border-0 p-1.5 bg-slate-300 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              value={
                                tempItemData ? tempItemData.topic_time : ""
                              }
                              onChange={(e) =>
                                handleValueUpdate(e, "topic_time")
                              }
                            />
                          </div>
                        </div>
                        <div>
                          <label className="block text-sm font-medium leading-6 text-white">
                            {t("planner.editor.slug")}
                          </label>
                          <div className="mt-2">
                            <input
                              type="text"
                              name="project-name"
                              id="project-name"
                              className="block w-full rounded-md border-0 p-1.5 bg-slate-300 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              value={tempItemData ? tempItemData.topic : ""}
                              onChange={(e) => handleValueUpdate(e, "topic")}
                              placeholder={topicPlaceHolder}
                            />
                          </div>
                        </div>
                        <div>
                          <label className="block text-sm font-medium leading-6 text-white">
                            {t("ticker")}
                          </label>
                          <div className="mt-2">
                            <input
                              type="text"
                              name="project-name"
                              id="project-name"
                              className="block w-full rounded-md border-0 p-1.5 bg-slate-300 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              value={
                                tempItemData ? tempItemData.ticker_label : ""
                              }
                              onChange={(e) =>
                                handleValueUpdate(e, "ticker_label")
                              }
                              placeholder={tickerPlaceHolder}
                            />
                          </div>
                        </div>
                        <div>
                          <label className="block text-sm font-medium leading-6 text-white">
                            {t("planner.notes")}
                          </label>
                          <div className="mt-2">
                            <textarea
                              id="notes"
                              name="notes"
                              rows={4}
                              className="block w-full rounded-md border-0 p-1.5 bg-slate-300 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              value={tempItemData ? tempItemData.notes : ""}
                              onChange={(e) => handleValueUpdate(e, "notes")}
                              placeholder={notesPlaceHolder}
                            ></textarea>
                          </div>
                        </div>
                        <div>
                          <label className="block text-sm font-medium leading-6 text-white">
                            {t("planner.script")}
                          </label>
                          <div className="mt-2">
                            <textarea
                              id="script"
                              name="script"
                              rows={4}
                              className="block w-full rounded-md border-0 p-1.5 bg-slate-300 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              value={
                                tempItemData && tempItemData.script
                                  ? tempItemData.script
                                  : ""
                              }
                              onChange={(e) => handleValueUpdate(e, "script")}
                              placeholder={scriptPlaceHolder}
                            ></textarea>
                          </div>
                        </div>
                        <fieldset>
                          <div className="mt-2 space-y-4">
                            <div className="relative flex items-start">
                              <div className="absolute flex h-6 items-center">
                                <input
                                  id="privacy-public"
                                  name="privacy"
                                  aria-describedby="privacy-public-description"
                                  type="checkbox"
                                  className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                  defaultChecked={checked}
                                  onChange={handleCheck}
                                />
                              </div>
                              <div className="pl-7 text-sm leading-6">
                                <label className="font-medium text-white">
                                  {t("planner.editor.rundown_include")}
                                </label>
                                <p
                                  id="privacy-public-description"
                                  className="text-white"
                                >
                                  {t("planner.editor.rundown_sub")}
                                </p>
                              </div>
                            </div>
                          </div>
                        </fieldset>
                      </div>
                    </div>
                    <div className="flex flex-shrink-0 justify-end px-4 py-4 bg-ssblue">
                      <button
                        type="button"
                        className={`${tw.btn.reg} bg-red hover:bg-redHover text-white mr-1`}
                        onClick={onCancel}
                      >
                        {t("cancel")}
                      </button>
                      <button
                        type="button"
                        className={`${tw.btn.reg} bg-green-800 hover:bg-green-950 text-white hover:text-white`}
                        onClick={saveCard}
                      >
                        {t("planner.save_segment")}
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default CardEditor;
