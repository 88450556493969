import React, { useState, useRef, Fragment, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { tw } from "../../../helpers/Styles";

const TickerOptions = ({
  onClose,
  onTickerOptions,
  prefix,
  suffix,
  seperator,
  isTickerOpen,
}) => {
  const [open, setOpen] = useState(isTickerOpen);
  const prefixRef = useRef(null);
  const suffixRef = useRef(null);
  const seperatorRef = useRef(null);
  const closeEditor = () => {
    setOpen(false);
    onClose(false);
  };

  const saveOptions = () => {
    onTickerOptions(
      prefixRef.current.value,
      suffixRef.current.value,
      seperatorRef.current.value
    );
    onClose(false);
  };

  useEffect(() => {
    setOpen(isTickerOpen);
  }, [isTickerOpen]);

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-[250]"
          initialFocus={prefixRef}
          onClose={closeEditor}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className={tw.modalBg} />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-md border border-slate-500 bg-ssblue2 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-ssblue">
                    <div className="px-4 py-3 sm:px-6">
                      <h2 className="text-base font-semibold text-white">
                        Ticker Options
                      </h2>
                    </div>
                  </div>
                  <div className="">
                    <div className="px-4 py-5 sm:p-6 grid grid-cols-2 gap-2">
                      <div className="">
                        <label className="block text-sm font-medium leading-6 text-white">
                          Prefix
                        </label>
                        <div className="mt-2">
                          <input
                            ref={prefixRef}
                            type="text"
                            defaultValue={prefix}
                            maxLength={250}
                            className="block w-full rounded-md border-0 p-1.5 bg-slate-300 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>

                      <div className="">
                        <label className="block text-sm font-medium leading-6 text-white">
                          Suffix
                        </label>
                        <div className="mt-2">
                          <input
                            ref={suffixRef}
                            type="text"
                            defaultValue={suffix}
                            maxLength={250}
                            className="block w-full rounded-md border-0 p-1.5 bg-slate-300 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>

                      <div className="w-full block">
                        <label className="block text-sm font-medium leading-6 text-white">
                          Seperator
                        </label>
                        <div className="mt-2">
                          <input
                            ref={seperatorRef}
                            type="text"
                            maxLength={1}
                            defaultValue={seperator}
                            className="block w-16 rounded-md border-0 p-1.5 bg-slate-300 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="bg-ssblue px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 ">
                      <button
                        type="button"
                        className={`inline-flex w-full justify-center px-3 py-2 text-sm font-semibold shadow-sm sm:ml-2 sm:w-auto transition-colors duration-200 bg-green text-white hover:bg-greenHover hover:text-white`}
                        onClick={saveOptions}
                      >
                        Yes, Save Options!
                      </button>

                      <button
                        type="button"
                        className={`mt-3 inline-flex w-full justify-center px-3 py-2 text-sm font-semibold shadow-sm ring-1 ring-inset sm:mt-0 sm:w-auto transition-colors duration-200 bg-red text-white hover:bg-redHover mr-1`}
                        onClick={closeEditor}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default TickerOptions;
