import React, { useContext, useEffect, useState } from "react";
import Header from "../layout/Header";
import { UserContext } from "../../context/UserContext";
import { Link } from "react-router-dom";
import axios from "axios";
import { getBearerHeaders } from "../../helpers/GetBearerHeaders";
import useTracking from "../../hooks/useTracking";
import useScrollTop from "../../hooks/useScrollTop";
import { tw } from "../../helpers/Styles";
import {
  SaveToLocalStorage,
  GetFromLocalStorage,
} from "../../helpers/LocalStorage";
import i18n from "../../helpers/i18n";
import { useTranslation } from "react-i18next";
import LoadingSpinner from "../../features/Shopro/components/LoadingSpinner";
import { UpdateUserAccount } from "../../api-requests/Updates";
import AuthMonitor from "../../helpers/AuthMonitor";

const Account = () => {
  const { user, setUser } = useContext(UserContext);
  const { t } = useTranslation();
  useTracking();
  useScrollTop();
  AuthMonitor();
  console.log("user", user);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [manageSubUrl, setManageSubUrl] = useState<string>("");
  const [selectedLocale, setSelectedLocale] = useState<string>("en");
  const [isAccountSaved, setIsAccountSaved] = useState<boolean>(true);
  const [roomid, setRoomId] = useState<string>(user.roomid);
  const [name, setName] = useState<string>(
    GetFromLocalStorage("stream_display_name")
  );
  const [tagline, setTagline] = useState<string>(
    GetFromLocalStorage("stream_tag_line")
  );

  const locales = [
    { code: "en", name: t("lang.english") },
    { code: "es", name: t("lang.spanish") },
    { code: "ru", name: t("lang.russian") },
  ];

  const changeLanguage = (e: React.ChangeEvent<HTMLSelectElement>) => {
    SaveToLocalStorage("language", e.target.value);
    i18n.changeLanguage(e.target.value);
  };

  const handleInputs = (e, target) => {
    if (target === "name") {
      setName(e.currentTarget.value);
      localStorage.setItem("stream_display_name", e.currentTarget.value);
      setIsAccountSaved(false);
    } else {
      setTagline(e.currentTarget.value);
      localStorage.setItem("stream_tag_line", e.currentTarget.value);
      setIsAccountSaved(false);
    }
  };

  const handleRoomId = (e, _target) => {
    const fixedRoomId = e.currentTarget.value
      .replace(/\s+/g, "-")
      .toLowerCase();
    setRoomId(fixedRoomId);
    setIsAccountSaved(false);
  };

  const updateAccount = async () => {
    const data = {
      roomid: roomid,
      display_name: name !== null ? name : "",
      tag_line: tagline !== null ? tagline : "",
    };

    const headers = getBearerHeaders();
    const response = await UpdateUserAccount(data, headers);
    console.log("Account update response", response);
    if (response.success) {
      setIsAccountSaved(true);
      setUser({ ...user, roomid: roomid });
    } else {
      console.log("Error updating account", response);
    }
  };

  useEffect(() => {
    axios.post("/api/v1/mefull", {}, getBearerHeaders()).then((response) => {
      //console.log("SUB", response.data.items.subscriptionid);
      if (response.data.success && response.data.items.subscriptionid) {
        //console.log("CALL CREATE PORTAL", response.data.items.subscriptionid);
        if (response.data.items.subscriptionid.length > 5) {
          axios
            .post(
              "/create-portal-session",
              {
                session_id: response.data.items.subscriptionid,
                returnUrlPage: "/account",
              },
              getBearerHeaders()
            )
            .then((stripurlResponse) => {
              console.log("stripurlResponse", stripurlResponse);
              setManageSubUrl(stripurlResponse.data.url);
            });
        }
      }
    });

    const lang = GetFromLocalStorage("language");
    setSelectedLocale(lang ? lang : "en");
    setIsLoading(false);
  }, []);
  return (
    <>
      <Header />
      {isLoading && <LoadingSpinner />}
      <div className="container mx-auto max-w-4xl ">
        <div
          className={`${tw.cardPlain} md:flex md:items-center md:justify-between md:space-x-5  mt-6 p-4`}
        >
          <div className="flex items-start space-x-5">
            <div className="flex-shrink-0">
              <div className="relative">
                <img
                  className="h-16 w-16 rounded-full"
                  src="img/icon-big.png"
                  alt=""
                />
                <span
                  className="absolute inset-0 rounded-full shadow-inner"
                  aria-hidden="true"
                />
              </div>
            </div>
            {/*
          Use vertical padding to simulate center alignment when both lines of text are one line,
          but preserve the same layout if the text wraps without making the image jump around.
        */}
            <div className="pt-1.5">
              <h1 className="text-2xl font-bold text-white">{user.username}</h1>
              <p className="text-xs font-medium text-gray-400 uppercase">
                {t("account.account_type")}: {user.accounttype.toUpperCase()}
              </p>
            </div>
          </div>
          <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-3 sm:space-y-0 sm:space-x-reverse md:mt-0 md:flex-row md:space-x-3">
            <Link
              type="button"
              className="no-underline inline-flex items-center justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 uppercase"
              to={"/reset-password"}
            >
              {t("account.reset_password_btn")}
            </Link>
            <button
              type="button"
              className={`no-underline inline-flex items-center justify-center rounded-md ${
                !isAccountSaved
                  ? "bg-green-600 hover:bg-green-800"
                  : "bg-gray-800 text-gray-600 cursor-not-allowed"
              } px-3 py-2 text-sm font-semibold  shadow-sm ring-1 ring-inset ring-slate-900 transition duration-300 uppercase`}
              onClick={updateAccount}
            >
              {t("account.update_account_btn")}
            </button>
          </div>
        </div>

        <div
          className={`${tw.cardPlain} flex flex-col sm:grid sm:grid-cols-2 mt-6 p-4`}
        >
          <div className="space-y-6 pb-5 w-full">
            <div>
              <label className="block text-sm font-medium leading-6 text-white">
                {t("account.email")}
              </label>
              <div className="mt-2">
                <input
                  readOnly
                  type="text"
                  value={user.username}
                  placeholder={t("account.email")}
                  className="block w-64 rounded-md border-0 p-1.5 bg-slate-300 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
          </div>
          <div className="space-y-6 pb-5 w-full">
            <div>
              <label className="block text-sm font-medium leading-6 text-white">
                {t("account.display_name")}
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  defaultValue={name}
                  placeholder={t("account.display_name")}
                  className="block w-64 rounded-md border-0 p-1.5 bg-slate-300 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  onChange={(e) => handleInputs(e, "name")}
                />
              </div>
            </div>
          </div>
          <div className="space-y-6 pb-5 w-full ">
            <div>
              <label className="block text-sm font-medium leading-6 text-white">
                {t("account.tag_line")}
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  placeholder={t("account.tag_line")}
                  defaultValue={tagline}
                  className="block w-64 rounded-md border-0 p-1.5 bg-slate-300 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  onChange={(e) => handleInputs(e, "tagline")}
                />
              </div>
            </div>
          </div>
          <div className="pb-5 w-full sm:w-64 ">
            <label className="block text-sm font-medium leading-6 text-white">
              {t("account.custom_room")}
            </label>
            <div className="mt-2 flex rounded-md shadow-sm bg-slate-300">
              <span className="inline-flex items-center rounded-l-md border border-r-0  border-gray-300 pl-3 pr-1 text-gray-500 sm:text-sm">
                shopro.live/
              </span>
              <input
                type="text"
                className="block w-32 min-w-0 flex-1 rounded-none rounded-r-md border-0 pl-2  border-l-1 border-l-gray-700 py-1.5 bg-slate-300 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder={t("account.custom_room_placeholder")}
                onChange={(e) => handleRoomId(e, "tagline")}
                value={roomid}
              />
            </div>
          </div>
          <div className="space-y-6 pb-5 w-full sm:w-1/2">
            <div>
              <label className="block text-sm font-medium leading-6 text-white">
                {t("account.language")}
              </label>
              <div className="mt-2">
                <select
                  onChange={changeLanguage}
                  value={selectedLocale}
                  className="block w-64 rounded-md border-0 p-1.5 bg-slate-300 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                >
                  {locales.map((locale, index) => (
                    <option key={index} value={locale.code}>
                      {locale.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          {user.accounttype.toUpperCase() === "PRO" && manageSubUrl && (
            <>
              <hr />
              <span>
                <Link to={manageSubUrl}>Manage your subscription</Link>
              </span>
              <br />
              <span className="small">
                View details and manage your account.
              </span>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Account;
