import React from "react";
import { useTranslation } from "react-i18next";
import { secondsToVideoTimecode, secondsToTimecode } from "../helpers/helpers";

const Keyframe = ({
  frame,
  index,
  frameRate,
  selectedKeyFrame,
  onSelectKeyframe,
}) => {
  const { t } = useTranslation();
  return (
    <div
      key={index}
      className={
        selectedKeyFrame === index
          ? "keyframe-item selected p-2 bg-rose-500 text-slate-900 font-semibold"
          : "keyframe-item p-2"
      }
      onClick={onSelectKeyframe}
    >
      {t("keyframe")} {index + 1}
      <br />
      <span className="text-xs font-semibold">
        {secondsToVideoTimecode(frame.start, frameRate)} -{" "}
        {secondsToVideoTimecode(frame.end, frameRate)}
      </span>
      <br />
      <span className="text-xs">
        Duration: {secondsToTimecode(frame.end - frame.start)}
      </span>
      <br />
      <span className="text-xs">Left: {Math.floor(frame.left)}%</span>
    </div>
  );
};

export default Keyframe;
