// eslint-disable-next-line
import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import Keyframe from "./Keyframe";
import { timeCodeToSeconds } from "../helpers/helpers";

export const KeyFrameEditor = ({
  isCropActive,
  videoPlayerRef,
  cropData,
  cropType,
  onKeyframeData,
  start,
  end,
  keyframeTrigger,
  frameRate,
  onKeyFrameSelect,
  currentVideoTime,
  editorOpen,
}) => {
  const { t } = useTranslation();

  const [keyFrameData, setKeyFrameData] = useState([]);
  const [selectedKeyFrame, setSelectedKeyframe] = useState(-1);

  const divRef = useRef(null);

  const timeLineType = "LINEAR";

  const selectKeyFrame = (item) => {
    videoPlayerRef.current.currentTime = keyFrameData[item].start;
    onKeyFrameSelect(keyFrameData[item]);
    setSelectedKeyframe(item);
  };

  const createKeyframe = () => {
    const KeyFrameArrPos = keyFrameData.length > 0 ? keyFrameData.length : 0;
    const currentTime = videoPlayerRef.current.currentTime;
    // const duration = videoPlayerRef.current.duration;
    const endSecond = timeCodeToSeconds(end);
    const startSecond = timeCodeToSeconds(start);
    const keyFrameDataModified = [...keyFrameData];
    const isLastFrame = selectedKeyFrame === keyFrameDataModified.length - 1;

    let cropDataFinal;

    if (timeLineType.toUpperCase() === "LINEAR") {
      if (KeyFrameArrPos === 0) {
        // if no keyframes
        cropDataFinal = { ...cropData, start: startSecond, end: endSecond };
        const newKeyframes = [...keyFrameDataModified, cropDataFinal];
        setKeyFrameData(newKeyframes);
        onKeyframeData(newKeyframes);
      } else {
        if (
          isLastFrame &&
          keyFrameDataModified[keyFrameDataModified.length - 1].start <
            currentTime
        ) {
          keyFrameDataModified[keyFrameDataModified.length - 1].end =
            currentTime;
          cropDataFinal = { ...cropData, start: currentTime, end: endSecond };
          const newKeyframes = [...keyFrameDataModified, cropDataFinal];
          setKeyFrameData(newKeyframes);
          onKeyframeData(newKeyframes);
          setSelectedKeyframe(keyFrameDataModified.length);
        }
      }
    }
  };

  useEffect(() => {
    divRef.current.scrollTop = selectedKeyFrame * 95.38;
  }, [selectedKeyFrame]);

  useEffect(() => {
    const matchingIndex = keyFrameData.findIndex(
      (item) => currentVideoTime >= item.start && currentVideoTime < item.end
    );
    if (matchingIndex !== -1) {
      if (selectedKeyFrame !== matchingIndex) {
        selectKeyFrame(matchingIndex);
      }
    } else {
      if (selectedKeyFrame !== -1) {
        setSelectedKeyframe(-1);
      }
    }
  }, [currentVideoTime]);

  useEffect(() => {
    // console.log("keyFrameData Updated", keyFrameData);
  }, [keyFrameData]);

  useEffect(() => {
    if (selectedKeyFrame !== -1) {
      const updateCropInfo = { ...keyFrameData[selectedKeyFrame], ...cropData };
      const newKeyFrameData = [...keyFrameData];
      newKeyFrameData[selectedKeyFrame] = updateCropInfo;
      setKeyFrameData(newKeyFrameData);
      onKeyframeData(newKeyFrameData);
    }
  }, [cropData]);

  useEffect(() => {
    if (keyFrameData.length) {
      const matchingIndexes = keyFrameData
        .map((item, index) =>
          item.end > timeCodeToSeconds(end) &&
          item.start > timeCodeToSeconds(end)
            ? index
            : -1
        )
        .filter((index) => index !== -1);
      const filteredKeyData = keyFrameData.filter(
        (_, index) => !matchingIndexes.includes(index)
      );
      filteredKeyData[filteredKeyData.length - 1].end = timeCodeToSeconds(end);
      setKeyFrameData(filteredKeyData);
      onKeyframeData(filteredKeyData);
    }
  }, [end]);

  useEffect(() => {
    // console.log(start);
    const filteredKeyData = [...keyFrameData];
    if (
      filteredKeyData.length &&
      filteredKeyData[0].end > timeCodeToSeconds(start)
    ) {
      filteredKeyData[0].start = timeCodeToSeconds(start);
      setKeyFrameData(filteredKeyData);
    }
  }, [start]);

  useEffect(() => {
    if (keyFrameData.length) {
      const newAspect = cropType === "CROP-45" ? "1080x1350" : "1080x1920";
      const updatedKeyFrameData = keyFrameData.map((item) => ({
        ...item,
        aspect: newAspect,
      }));
      setKeyFrameData(updatedKeyFrameData);
    }
  }, [cropType]);

  useEffect(() => {
    if (keyframeTrigger) {
      createKeyframe();
    }
  }, [keyframeTrigger]);

  useEffect(() => {
    if (isCropActive && !keyFrameData.length) {
      createKeyframe();
    }
  }, [isCropActive]);

  return (
    <>
      <div className="p-2 bg-rose-800 rounded-tl-md sticky top-0 ">
        <h2 className="text-base">{t("streamslicer.keyframe_editor")}</h2>
      </div>
      <div
        ref={divRef}
        className="keyframe-list divide-y divide-slate-800"
        id="keyframe-list"
      >
        {keyFrameData.map((frame, index) => (
          <Keyframe
            frame={frame}
            index={index}
            selectedKeyFrame={selectedKeyFrame}
            onSelectKeyframe={() => selectKeyFrame(index)}
            frameRate={frameRate}
          />
        ))}
      </div>
    </>
  );
};

export default KeyFrameEditor;
