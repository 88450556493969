import React, { useEffect, useState } from "react";

export interface SwitchProps {
  checked?: boolean;
  onCheckChange?: (checked: boolean) => void;
  disabled?: boolean;
}

const Switch = ({ checked, onCheckChange, disabled }: SwitchProps) => {
  const [isChecked, setIsChecked] = useState(checked ? checked : false);

  useEffect(() => {
    if (onCheckChange && disabled !== true) {
      onCheckChange(isChecked);
    }
  }, [isChecked]);
  return (
    <label className={disabled === true ? "switch disabled" : "switch"}>
      <input
        type="checkbox"
        onChange={() => setIsChecked(!isChecked)}
        checked={!!isChecked}
        disabled={disabled === true}
      />
      <span className="slider"></span>
    </label>
  );
};

export default Switch;
