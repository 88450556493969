import React, { useEffect, useRef, useState } from "react";

export interface CameraDeviceProps {
  videoSource: any;
  audioSource?: any;
  cameraClass?: string;
  videoClass?: string;
  index: number;
  onVideoStream?: any;
  isAudioPlaybackEnabled: boolean;
  isMuted?: boolean;
  userImg?: string | null;
}

const Camera = ({
  videoSource,
  audioSource,
  cameraClass,
  videoClass,
  index,
  onVideoStream,
  isAudioPlaybackEnabled,
  isMuted,
  userImg,
}: CameraDeviceProps) => {
  const [videoStream, setVideoStream] = useState(null);
  const cam = useRef(null);
  const placeHolderImg = useRef(null);
  if (cam.current) {
    const playPromise = cam.current.play();

    if (playPromise !== undefined) {
      playPromise
        .then(() => {
          // Video is playing
          //console.log("VIDEO IS PLAYING");
        })
        .catch((error) => {
          console.error("Autoplay was prevented: ", error);
          // You can inform the user and provide a play button or other user-initiated action.

          if (cam.current) {
            cam.current.play();
          }
        });
    }
  }

  const stopCamera = (stream) => {
    stream.getTracks().forEach((track) => {
      track.stop();
    });
  };

  useEffect(() => {
    if (videoStream) {
      //console.log("SET VIDEO SRCOBJECT");
      cam.current.srcObject = videoStream;
    }
  }, [videoStream]);

  useEffect(() => {
    if (videoStream && userImg === null) {
      stopCamera(videoStream);
    }
    const videoConstraints = {
      deviceId: { exact: videoSource },
    };

    const audioConstraints = {
      deviceId: { exact: audioSource },
      enabled: isAudioPlaybackEnabled,
      echoCancellation: true,
      noiseSuppression: true,
      sampleRate: 44100,
    };

    if (audioSource || videoSource) {
      navigator.mediaDevices
        .getUserMedia({
          video: videoSource ? videoConstraints : true,
          audio: audioSource ? audioConstraints : true,
        })
        .then(function (stream) {
          if (userImg === null) {
            if (cam.current) {
              // console.log("new srcObject", stream);
              //cam.current.srcObject = stream;

              cam.current.classList.remove("hide");
              if (index === 0) {
                //  console.log("update stream", stream.id);

                setVideoStream(stream);
                onVideoStream(stream);
              }
            } else {
              console.log("NO CAM");
            }

            placeHolderImg.current.classList.add("hide");
          }
        })
        .catch(function (error) {
          console.error(
            "Access to camera and audio was denied: " + error,
            cam.current
          );
          if (userImg === null) {
            if (cam.current !== null) {
              cam.current.classList.add("hide");

              //alert(error);
            }
            placeHolderImg.current.classList.remove("hide");
          }
        });
    }

    return () => {
      if (videoStream) {
        console.log("STOP CAMERA");
        stopCamera(videoStream);
      }
    };
  }, [videoSource, audioSource, userImg]);

  return (
    <>
      <div className={`video-window ${cameraClass}`}>
        {userImg ? (
          <div className="flex h-full w-full justify-center items-center bg-[#14202e]">
            <div className="circle-container">
              <img className="preview-img" src={userImg} />
            </div>
          </div>
        ) : (
          <>
            <video
              className={videoClass ? `${videoClass} hide` : "hide"}
              ref={cam}
              id="webcam"
              autoPlay={true}
              playsInline
              muted={isMuted ? isMuted : false}
            ></video>
            <img ref={placeHolderImg} src="/img/vid-placeholder.png" />
          </>
        )}
      </div>
    </>
  );
};

export default Camera;
