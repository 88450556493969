// RssFeedReader.js
import React, { useState, useEffect } from "react";
import { tw } from "../../../helpers/Styles";
import {
  faChevronDown,
  faChevronUp,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getBearerHeaders } from "../../../helpers/GetBearerHeaders";
import { fetchGetFeed } from "../../../api-requests/Index";
import ShowPicker from "./ShowPicker";

const RssFeedReader = ({
  feedUrl,
  title,
  onOpenChange,
  selectedFeedIndex,
  index,
  shows,
  feedid,
}) => {
  const [feedData, setFeedData] = useState(null);
  const [progressMessage, setProgressMessage] = useState("Loading...");
  const [isError, setIsError] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isAddingCard, setIsAddingCard] = useState(false);
  const [cardData, setCardData] = useState(null);
  const [renderFeed, setRenderFeed] = useState(false);
  const [renderRssFeed, setRenderRssFeed] = useState(false);

  const addToShowAsCard = (item) => {
    const { title, link, description } = item;
    //console.log({ title, link, description });
    setCardData({
      color: null,
      start_time: null,
      end_time: null,
      includeInRundown: true,
      isNewTopic: false,
      is_complete: false,
      topic_time: 10,
      topic: title,
      ticker_label: "",
      notes: `${description} \n ${link}`,
      script: "",
      show_in_list: false,
    });
    setIsAddingCard(true);
  };

  const removeFeed = () => {
    console.log("removeFeed", feedid);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setProgressMessage("Parsing feed...");

        const response = await fetchGetFeed(
          { feedurl: feedUrl },
          getBearerHeaders()
        );
        if (response.success) {
          setFeedData(response.items);
        } else {
          setIsError(true);
        }
      } catch (error) {
        console.error("Error fetching or parsing feed:", error);
        setProgressMessage("Error fetching or parsing feed");
      }
    };

    fetchData();
  }, [feedUrl]);

  useEffect(() => {
    onOpenChange(isOpen, index);
  }, [isOpen]);

  useEffect(() => {
    //console.log("feedData", feedData);
    if (feedData) {
      //console.log("feedData", feedData);

      setRenderRssFeed(feedData.rss?.channel.item ? true : false);
      setRenderFeed(feedData.feed?.entry.map ? true : false);
    }
  }, [feedData]);

  useEffect(() => {
    if (index !== selectedFeedIndex) {
      setIsOpen(false);
    }
  }, [selectedFeedIndex]);

  return (
    <>
      {!isError && (
        <div>
          <div className="flex justify-between items-center mt-1  border border-slate-700 py-2 px-2 rounded-md uppercase">
            <h2 className="text-md">
              {feedid !== undefined && (
                <FontAwesomeIcon
                  onClick={removeFeed}
                  className="text-red mr-2 pointer"
                  icon={faXmark}
                />
              )}{" "}
              <span className="pointer" onClick={() => setIsOpen(!isOpen)}>
                {title}
              </span>
            </h2>
            <FontAwesomeIcon
              className="pointer"
              onClick={() => setIsOpen(!isOpen)}
              icon={!isOpen ? faChevronDown : faChevronUp}
            />
          </div>

          {feedData ? (
            <ul
              className={`grid grid-cols-2 gap-2 transition-height mt-1  ${
                isOpen ? "h-auto" : "h-0 overflow-hidden"
              }`}
            >
              {renderRssFeed &&
                (Array.isArray(feedData.rss?.channel.item)
                  ? feedData.rss?.channel.item
                  : [feedData.rss?.channel.item]
                ).map((item, index) => (
                  <li
                    key={index}
                    className="px-2 py-3 hover:bg-slate-800 transition duration-300 text-gray-500 hover:text-white rounded-sm border-slate-900 hover:border-slate-700"
                  >
                    <a
                      className="font-semibold text-lg mb-4 text-blue-charcoal-400 no-underline hover:underline"
                      href={item.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {item.title}
                    </a>
                    <p className="text-slate-400 text-xs">{item.pubDate}</p>
                    <div
                      dangerouslySetInnerHTML={{ __html: item.description }}
                    />
                    <button
                      className={`${tw.btn.reg} text-white bg-blue-charcoal-500 hover:bg-blue-charcoal-700 mt-3 rounded-sm text-xs`}
                      onClick={() => addToShowAsCard(item)}
                    >
                      Add To Show As Segment
                    </button>
                  </li>
                ))}

              {renderFeed &&
                feedData.feed?.entry.map((item, index) => (
                  <li
                    key={index}
                    className="px-2 py-3 hover:bg-slate-800 transition-colors"
                  >
                    <a
                      className="font-semibold text-lg mb-4 text-blue-charcoal-400 no-underline hover:underline"
                      href={item.link.$.href}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {item.title}
                    </a>
                    <p className="text-slate-400 text-xs">{item.published}</p>
                    <div
                      dangerouslySetInnerHTML={{ __html: item.author.name }}
                    />
                    <button
                      className={`${tw.btn.reg} bg-blue-charcoal-500 hover:bg-blue-charcoal-700 mt-3 rounded-sm text-xs`}
                      onClick={() => addToShowAsCard(item)}
                    >
                      Add To Show As Segment
                    </button>
                  </li>
                ))}
            </ul>
          ) : (
            <>{isOpen && <p>{progressMessage}</p>}</>
          )}
        </div>
      )}

      <ShowPicker
        storyData={cardData}
        isEditOpen={isAddingCard}
        onClose={setIsAddingCard}
        shows={shows}
      />
    </>
  );
};

export default RssFeedReader;
