import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import NavJson from "../../Data/Nav.json";
import { useLocation } from "react-router-dom";
import logo from "../../img/logo.png";
import { tw } from "../../../../helpers/Styles";

const StreamSlicerNav = () => {
  const { t } = useTranslation();
  const location = useLocation();

  return (
    <div className="sidebar">
      <nav>
        <ul className="flex m-3">
          {NavJson
            ? NavJson.map((navItem) => (
                <li
                  key={t(navItem.label)}
                  className={
                    location.pathname === navItem.link
                      ? `${tw.sstabs.active}`
                      : `${tw.sstabs.reg}`
                  }
                >
                  <Link to={navItem.link} className="no-underline">
                    <i className={`fa-solid ${navItem.icon}`}></i>
                    <span className="menu-text">{t(navItem.label)}</span>
                  </Link>
                </li>
              ))
            : null}
        </ul>
      </nav>
    </div>
  );
};

export default StreamSlicerNav;
