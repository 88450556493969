import React, { ChangeEvent, useRef, useState } from "react";
import { Link } from "react-router-dom";
import useTracking from "../../hooks/useTracking";
import useScrollTop from "../../hooks/useScrollTop";

const SignUp = () => {
  useTracking();
  useScrollTop();
  const formRef = useRef(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordMatch, setPasswordMatch] = useState("");
  const [isError, setIsError] = useState(false);
  const [errors, setErrors] = useState("");

  const getCookie = (name) => {
    const cookies = document.cookie.split("; ");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].split("=");
      if (cookie[0] === name) {
        return decodeURIComponent(cookie[1]);
      }
    }
    return null;
  };

  const deleteCookie = (name) => {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  };

  const handleSubmit = async (e: ChangeEvent<HTMLFormElement>) => {
    setIsError(false);
    e.preventDefault();

    // const hasErrors = false;

    if (password !== passwordMatch) {
      setIsError(true);
      setErrors(`Provided passwords don't match`);
    } else {
      try {
        const response = await fetch("/api/signup", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email, password }),
        });

        if (response.status === 201) {
          const buyval = getCookie("ral");
          if (buyval) {
            deleteCookie("ral");
            formRef.current.submit();
          } else {
            window.location.href = "/";
          }
        } else {
          const errorMessage = await response.json();
          if (errorMessage.error.errors[0].type === "unique violation") {
            setErrors(`That email is already in use.`);
          } else {
            setErrors("Unable to create account");
          }

          setIsError(true);
        }
      } catch (error) {
        setErrors("Error creating account:");
        setIsError(true);
      }
    }
  };
  return (
    <>
      <div className="flex h-screen flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8 m-6 sm:m-auto">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <Link to="/">
            <img
              className="mx-auto h-10 w-auto"
              src="/img/logo-white.png"
              alt="ShoPro.live"
            />
          </Link>
          <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-white">
            Create your account
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
          <div className="bg-white px-6 py-12 shadow rounded-md sm:rounded-lg sm:px-12">
            {isError && (
              <>
                <div className="border border-red p-4 bg-rose-500 text-black font-semibold text-sm mb-5 rounded-md">
                  {errors}
                </div>
              </>
            )}
            <form className="space-y-6" onSubmit={handleSubmit}>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Email address
                </label>
                <div className="mt-2">
                  <input
                    type="email"
                    id="email"
                    value={email}
                    className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Your email"
                    required
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Password
                </label>
                <div className="mt-2">
                  <input
                    type="password"
                    id="password"
                    value={password}
                    className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Your Password"
                    required
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Confirm Password
                </label>
                <div className="mt-2">
                  <input
                    type="password"
                    id="confirm_password"
                    value={passwordMatch}
                    className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    onChange={(e) => setPasswordMatch(e.target.value)}
                    placeholder="Confirm Password"
                    required
                  />
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Create Account
                </button>
              </div>
            </form>
          </div>

          <p className="mt-10 text-center text-sm text-white">
            Already have an account?{" "}
            <Link
              className="font-semibold text-gray-300 hover:text-gray-100"
              to="/login"
            >
              Login
            </Link>
          </p>
          <p className="mt-5 text-center text-sm text-white">
            By signing up, you agree to the{" "}
            <Link
              to="/tos"
              className="font-semibold text-gray-300 hover:text-gray-100"
            >
              Terms of Service
            </Link>{" "}
            and{" "}
            <Link
              to="/privacy"
              className="font-semibold text-gray-300 hover:text-gray-100"
            >
              Privacy Policy
            </Link>
            .
          </p>
        </div>
      </div>

      <form
        ref={formRef}
        action="/checkout/create-checkout-session"
        method="POST"
      >
        {/* Add a hidden field with the lookup_key of your Price */}
        <input type="hidden" name="lookup_key" value="Shopro1" />
      </form>
    </>
  );
};

export default SignUp;
