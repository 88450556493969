import { useState, useEffect, useContext } from "react";
import { UserContext } from "../context/UserContext";
import { ExperimentUsers } from "../data/ExperimentUsers";

const useExperiments = () => {
  const { user } = useContext(UserContext);
  const [isInExperiment, setIsInExperiment] = useState(false);

  useEffect(() => {
    setIsInExperiment(
      ExperimentUsers.filter((experiment) => experiment === user.username)
        .length > 0
    );
    return () => {};
  }, []);

  return isInExperiment;
};

export default useExperiments;
