import React, { useContext, useEffect } from "react";

import { Link } from "react-router-dom";
import { UserContext } from "../../context/UserContext";

import { Socket } from "socket.io-client";
import { tw } from "../../helpers/Styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCameraRetro,
  faCircle,
  faPerson,
  faShare,
} from "@fortawesome/free-solid-svg-icons";
import useExperiments from "../../hooks/useExperiments";
import StandaloneControls from "./components/StandaloneControls";

export interface StudioHeaderProps {
  islive?: boolean;
  classname?: string;
  startShow?: (action) => void;
  captureScreen?: () => void;
  websocket?: Socket;
  onNextTopic?: (rand: number) => void;
  onPrevTopic?: (rand: number) => void;
  headerSectionLabel?: string;
  inviteUser?: () => void;
  shareShow?: () => void;
  isRoomReady: boolean;
  startRecording: () => void;
  startStreaming: () => void;
  isRecording: boolean;
  isStreaming: boolean;
}
export const StudioHeader = ({
  islive,
  classname,
  headerSectionLabel,
  inviteUser,
  shareShow,
  isRoomReady,
  startRecording,
  startStreaming,
  isRecording,
  isStreaming,
  captureScreen,
}: StudioHeaderProps) => {
  const { user } = useContext(UserContext);
  const inExperiment = useExperiments();
  const isLoggedIn = user.isloggedin;
  const isPro = user.accounttype.toUpperCase() === "PRO" ? true : false;

  useEffect(() => {
    if (islive) {
    }
  }, []);

  return (
    <header
      className={
        classname
          ? `relative flex items-center h-[40px] z-[250] ${classname}`
          : "relative flex items-center h-[40px] z-[250] bg-ssred border-b border-b-ssblue"
      }
    >
      <StandaloneControls isStudio={true} />
      <div className="flex-shrink-0">
        <a href="/">
          {isPro ? (
            <img className="h-8 ml-2" src="/img/logo-pro.png" alt="Shopro" />
          ) : (
            <img className="h-8 ml-2" src="/img/logo.png" alt="Shopro" />
          )}
        </a>
      </div>
      <div className="ml-4">
        {headerSectionLabel && (
          <span className="text-white text-xs font-bold">
            {headerSectionLabel}
          </span>
        )}
      </div>
      <div className="flex-grow text-right h-full items-center">
        {inviteUser && isRoomReady && (
          <>
            {inExperiment && captureScreen && (
              <>
                <button
                  className="bg-blue-charcoal-600 mr-2 px-3 py-1 text-xs rounded-sm border border-gray-800 font-semibold shadow-md transition-colors duration-200"
                  onClick={captureScreen}
                  title="Capture Screen"
                >
                  <FontAwesomeIcon icon={faCameraRetro} />
                </button>
                <button
                  onClick={startStreaming}
                  className={`mt-1.5 mr-2 py-1 px-3 ${
                    isRecording
                      ? "bg-sangria-700 hover:bg-sangria-900 text-white"
                      : "bg-green-500 hover:bg-green-700 text-black hover:text-white"
                  } text-xs rounded-sm border border-gray-800 font-semibold shadow-md transition-colors duration-200`}
                >
                  <FontAwesomeIcon
                    icon={faCircle}
                    className={`mr-1 ${
                      isStreaming ? "text-white" : "text-sangria-700"
                    }`}
                  />
                  {isStreaming ? "Stop Streaming" : "Start Streaming"}
                </button>
                <button
                  onClick={startRecording}
                  className={`mt-1.5 mr-2 py-1 px-3 ${
                    isRecording
                      ? "bg-sangria-700 hover:bg-sangria-900 text-white"
                      : "bg-green-500 hover:bg-green-700 text-black hover:text-white"
                  } text-xs rounded-sm border border-gray-800 font-semibold shadow-md transition-colors duration-200`}
                >
                  <FontAwesomeIcon
                    icon={faCircle}
                    className={`mr-1 ${
                      isRecording ? "text-white" : "text-sangria-700"
                    }`}
                  />
                  {isRecording ? "Stop Recording" : "Start Recording"}
                </button>
              </>
            )}
            <button
              onClick={inviteUser}
              className="mt-1.5 mr-2 py-1 px-3 bg-white hover:bg-blue-charcoal-500 text-black text-xs rounded-sm border border-gray-800 font-semibold shadow-md transition-colors duration-300"
            >
              <FontAwesomeIcon icon={faPerson} className="mr-1" />
              Invite Guest
            </button>
            <button
              onClick={shareShow}
              className="mt-1.5 mr-4 py-1 px-3 bg-white hover:bg-blue-charcoal-500 text-black text-xs rounded-sm border border-gray-800 font-semibold shadow-md transition-colors duration-300"
            >
              <FontAwesomeIcon icon={faShare} className="mr-1" />
              Share Watch Link
            </button>
          </>
        )}
        {!isLoggedIn && (
          <>
            <Link
              className={`${tw.btn.reg} bg-ssred hover:bg-blue2 no-underline border-l border-r border-black px-6 text-white h-full transition-colors duration-300`}
              to="/login"
            >
              Log in
            </Link>
            <Link
              className={`${tw.btn.reg} bg-orange hover:bg-green no-underline px-6 text-black hover:text-white h-full transition-colors duration-300`}
              to="/signup"
            >
              Sign Up
            </Link>
          </>
        )}
      </div>
    </header>
  );
};

export default StudioHeader;
