import axios from "axios";
import { useState, useEffect } from "react";
import { getBearerHeaders } from "../helpers/GetBearerHeaders";

const useSourceid = () => {
  const [sourceId, setSourceId] = useState("");

  useEffect(() => {
    axios
      .post("/api/v1/GETSOURCEID", {}, getBearerHeaders())
      .then((response) => {
        if (response.data.success) {
          //console.log(response.data.items.sourceid);
          setSourceId(response.data.items.sourceid);
        }
      });
    return () => {};
  }, []);

  return sourceId;
};

export default useSourceid;
