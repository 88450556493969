export const secondsToTimecode = (seconds: number): string => {
  const isNegative = seconds < 0;
  seconds = Math.abs(seconds); // Convert to positive value for calculation

  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = Math.floor(seconds % 60);

  // Ensure leading zeros for single-digit minutes and seconds
  const formattedHours = String(hours).padStart(2, "0");
  const formattedMinutes = String(minutes).padStart(2, "0");
  const formattedSeconds = String(remainingSeconds).padStart(2, "0");

  return isNegative
    ? `-${formattedHours}:${formattedMinutes}:${formattedSeconds}`
    : `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
};

export const timecodeToSeconds = (timecode: string): number => {
  const [hours, minutes, seconds] = timecode.split(":").map(Number);

  if (isNaN(hours) || isNaN(minutes) || isNaN(seconds)) {
    //throw new Error("Invalid timecode format");
    return 0;
  }

  const totalSeconds = hours * 3600 + minutes * 60 + seconds;
  return totalSeconds;
};

export const timeStringToMilliseconds = (timeString: string) => {
  // Split the time string into hours, minutes, and seconds
  const [hours, minutes, seconds] = timeString.split(":").map(Number);

  // Calculate the total milliseconds
  const totalMilliseconds = (hours * 60 * 60 + minutes * 60 + seconds) * 1000;

  return totalMilliseconds;
};

export const dateToTime = (currentTime: Date): string => {
  const currentHour = String(currentTime.getHours()).padStart(2, "0");
  const currentMinute = String(currentTime.getMinutes()).padStart(2, "0");
  const currentSecond = String(currentTime.getSeconds()).padStart(2, "0");
  // const currentMillisecond = String(currentTime.getMilliseconds()).padStart(2,"0");

  return `${currentHour}:${currentMinute}:${currentSecond}`;
};

export const calculateTimeDuration = (
  startTime: string,
  endTime: string
): string => {
  // Split the time strings into hours, minutes, and seconds
  const startTimeParts = startTime.split(":");
  const endTimeParts = endTime.split(":");
  const midnight = new Date();
  midnight.setHours(0, 0, 0, 0);

  // Convert the time strings to seconds
  const startTimeInSeconds =
    parseInt(startTimeParts[0]) * 3600 +
    parseInt(startTimeParts[1]) * 60 +
    parseInt(startTimeParts[2]);

  let endTimeInSeconds =
    parseInt(endTimeParts[0]) * 3600 +
    parseInt(endTimeParts[1]) * 60 +
    parseInt(endTimeParts[2]);

  const testMidnight = endTimeInSeconds - midnight.getSeconds();
  if (testMidnight < 0) {
    console.log("UPDATE END TIME");
    //endTimeInSeconds += 24 * 60 * 60;
  }
  // Calculate the time difference in seconds
  const timeDifferenceInSeconds = endTimeInSeconds - startTimeInSeconds;

  // Handle negative time differences
  const isNegative = timeDifferenceInSeconds < 0;
  const absTimeDifferenceInSeconds = Math.abs(timeDifferenceInSeconds);

  // Convert the time difference back to hh:mm:ss format
  const hours = Math.floor(absTimeDifferenceInSeconds / 3600);
  const minutes = Math.floor((absTimeDifferenceInSeconds % 3600) / 60);
  const seconds = absTimeDifferenceInSeconds % 60;

  const formattedDifference = `${isNegative ? "-" : ""}${hours
    .toString()
    .padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds
    .toString()
    .padStart(2, "0")}`;

  return formattedDifference;
};

export interface StartEndOffsets {
  timedif: string;
  hilo: string;
}

export const startEndTimeOffest = (
  startTime: string,
  endTime: string,
  targetMinutes: number
): StartEndOffsets => {
  let result: StartEndOffsets;
  if (startTime !== null && endTime !== null) {
    // Split the time strings into hours, minutes, and seconds
    const startTimeParts = startTime.split(":");
    const endTimeParts = endTime.split(":");

    // Create Date objects with today's date (you can adjust the date if needed)
    const startDate = new Date();
    startDate.setHours(
      Number(startTimeParts[0]),
      Number(startTimeParts[1]),
      Number(startTimeParts[2]),
      0
    );

    const endDate = new Date();
    endDate.setHours(
      Number(endTimeParts[0]),
      Number(endTimeParts[1]),
      Number(endTimeParts[2]),
      0
    );

    // Calculate the time difference in milliseconds
    const timeDifference = Number(endDate) - Number(startDate);
    // Calculate hours, minutes, and seconds from the time difference
    //const hrs = Math.floor(timeDifference / 3600000);
    //const min = Math.floor((timeDifference % 3600000) / 60000);
    //const sec = Math.floor((timeDifference % 60000) / 1000);

    // Calculate minutes from the time difference
    const minutes = Math.floor(timeDifference / 60000);
    const seconds = Math.floor((timeDifference % 60000) / 1000);
    const remainingMinutes = minutes % 60;

    // Check if it's over or under the targetMinutes

    if (minutes > targetMinutes) {
      result = {
        timedif: `+${String(remainingMinutes - targetMinutes).padStart(
          2,
          "0"
        )}:${String(seconds).padStart(2, "0")}`,
        hilo: "hi",
      };
    } else if (minutes < targetMinutes) {
      result = {
        timedif: `-${String(targetMinutes - (remainingMinutes + 1)).padStart(
          2,
          "0"
        )}:${String(60 - seconds).padStart(2, "0")}`,
        hilo: "lo",
      };
    } else if (seconds > 0) {
      result = {
        timedif: `+00:${String(seconds).padStart(2, "0")}`,
        hilo: "hi",
      };
    } else if (seconds < 0) {
      result = {
        timedif: `-${String(targetMinutes - remainingMinutes).padStart(
          2,
          "0"
        )}:${String(Math.abs(seconds)).padStart(2, "0")}`,
        hilo: "lo",
      };
    } else {
      result = { timedif: `00:00`, hilo: "even" };
    }
  } else {
    result = { timedif: ``, hilo: "" };
  }
  return result;
};

export const calOverUnder = (topics) => {
  let totalEstimatedMinutes = 0;
  let totalActualSeconds = 0;
  topics.forEach((topic) => {
    if (topic.start_time !== null && topic.end_time !== null) {
      totalEstimatedMinutes += Number(topic.topic_time);
      const topicSeconds =
        timecodeToSeconds(topic.end_time) - timecodeToSeconds(topic.start_time);
      totalActualSeconds += topicSeconds;
    }
  });
  const overUnderSecs = totalActualSeconds - totalEstimatedMinutes * 60;
  const sign = overUnderSecs > 1 ? "+" : "";
  return `${sign}${secondsToTimecode(overUnderSecs)}`;
};
