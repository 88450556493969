export const dateDisplay = (date: Date) => {
  // Get the individual components of the date (day, month, year)
  const day = date.getDate(); // Returns the day of the month (1-31)
  const month = date.getMonth() + 1; // Returns the month (0-11), so we add 1 to make it 1-12
  const year = date.getFullYear(); // Returns the full year (e.g., 2023)

  // Format the components as strings with leading zeros if needed
  const formattedDay = String(day).padStart(2, "0");
  const formattedMonth = String(month).padStart(2, "0");
  const formattedYear = String(year).slice(-2); // Take the last two digits of the year

  // Create the formatted date string
  const formattedDate = `${formattedMonth}/${formattedDay}/${formattedYear}`;
  return formattedDate;
};

export const dateAndTimeDisplay = (date: Date) => {
  // Get the individual components of the date (day, month, year, hours, and minutes)
  const day = date.getDate(); // Returns the day of the month (1-31)
  const month = date.getMonth() + 1; // Returns the month (0-11), so we add 1 to make it 1-12
  const year = date.getFullYear(); // Returns the full year (e.g., 2023)
  const hours = date.getHours(); // Returns the hour (0-23)
  const minutes = date.getMinutes(); // Returns the minutes (0-59)

  // Format the date components as strings with leading zeros if needed
  const formattedDay = String(day).padStart(2, "0");
  const formattedMonth = String(month).padStart(2, "0");
  const formattedYear = String(year).slice(-2); // Take the last two digits of the year

  // Format the time components as strings with leading zeros if needed
  const formattedHours = String(hours).padStart(2, "0");
  const formattedMinutes = String(minutes).padStart(2, "0");

  // Create the formatted date and time string
  const formattedDateAndTime = `${formattedMonth}/${formattedDay}/${formattedYear} ${formattedHours}:${formattedMinutes}`;
  return formattedDateAndTime;
};

export const timeDisplay = (date: Date) => {
  // Get the individual components of the date (day, month, year, hours, and minutes)
  const hours = date.getHours(); // Returns the hour (0-23)
  const minutes = date.getMinutes(); // Returns the minutes (0-59)
  const seconds = date.getSeconds(); // Returns the minutes (0-59)

  // Determine whether it's AM or PM
  const ampm = hours >= 12 ? "PM" : "AM";

  // Convert hours to 12-hour format
  const formattedHours = hours % 12 || 12;

  // Format the time components as strings with leading zeros if needed
  const formattedMinutes = String(minutes).padStart(2, "0");
  const formattedSeconds = String(seconds).padStart(2, "0");

  // Create the formatted date and time string
  const formattedDateAndTime = `${formattedHours}:${formattedMinutes}:${formattedSeconds} ${ampm}`;
  return formattedDateAndTime;
};

export const chatTimeDisplay = (date: Date) => {
  // Get the individual components of the date (day, month, year, hours, and minutes)
  const hours = date.getHours(); // Returns the hour (0-23)
  const minutes = date.getMinutes(); // Returns the minutes (0-59)

  // Determine whether it's AM or PM
  const ampm = hours >= 12 ? "PM" : "AM";

  // Convert hours to 12-hour format
  const formattedHours = hours % 12 || 12;

  // Format the time components as strings with leading zeros if needed
  const formattedMinutes = String(minutes).padStart(2, "0");

  // Create the formatted date and time string
  const formattedDateAndTime = `${formattedHours}:${formattedMinutes} ${ampm}`;
  return formattedDateAndTime;
};

/* export const formatTimestamp = (timestamp) => {
  const date = new Date(timestamp);
  const day = date.getDate();
  const dayAbbreviation = date.toLocaleString("default", { weekday: "short" });
  const month = date.toLocaleString("default", { month: "short" });
  const year = date.getFullYear().toString().slice(-2);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";

  const formattedDate = `${dayAbbreviation} ${month} ${day}, ${year} ${
    hours % 12 || 12
  }:${minutes.toString().padStart(2, "0")} ${ampm}`;
  return formattedDate;
}; */

export const formatTimestamp = (timestamp) => {
  const date = new Date(timestamp);
  const timeZone = "America/New_York";

  const options: Intl.DateTimeFormatOptions = {
    timeZone,
    weekday: "short",
    month: "short",
    day: "2-digit",
    year: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  };

  const formatter = new Intl.DateTimeFormat("en-US", options);

  return formatter.format(date);
};

export const formatDateForInputField = (date: Date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Adding 1 because months are zero-based
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  // Construct the formatted string
  const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}`;

  return formattedDate;
};
