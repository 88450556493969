// eslint-disable-next-line
import React, { useRef, useState, useEffect, useCallback } from "react";

export const Cropper = ({
  isActive,
  vidBlock,
  vidWrapper,
  cropType,
  onCropData,
  newCropPos,
}) => {
  const [isCropDragging, setIsCropDragging] = useState(false);
  const [cropperLeftPos, setCropperLeftPos] = useState(236);
  const [cropOverlayLeft, setCropOverlayLeft] = useState(0);
  const [cropOverlayRight, setCropOverlayRight] = useState(0);
  const [aspect, setAspect] = useState("1080x1920");
  const [cropData, setCropData] = useState({});

  const [dragOffsetX, setDragOffsetX] = useState(0);

  const cropOverlayLeftRef = useRef(null);
  const cropOverlayRightRef = useRef(null);
  const cropper = useRef(null);

  const cropOverlay =
    "absolute t-0 pointer-events-none h-full bg-slate-900 bg-opacity-50";

  const cropClass =
    "absolute t-0 lef-[260px] h-full border-dashed border-2 border-red";

  const handleMouseDown = (event) => {
    const editorRect = vidWrapper.current.getBoundingClientRect();
    const cropperRect = cropper.current.getBoundingClientRect();
    setIsCropDragging(true);
    setDragOffsetX(event.clientX);
    setDragOffsetX(event.clientX - (cropperRect.left - editorRect.left));
  };

  const handleMouseMove = (event) => {
    if (isCropDragging) {
      const containerWidth = vidBlock.current.offsetWidth;
      const newLeft = event.clientX - dragOffsetX;
      if (
        newLeft >= 0 &&
        newLeft + cropper.current.offsetWidth <= containerWidth
      ) {
        setCropperLeftPos(newLeft);
        setCropOverlayLeft(newLeft);
        setCropOverlayRight(
          containerWidth - newLeft - cropper.current.offsetWidth
        );
      }
    }
  };

  const calculateCropPosition = useCallback(() => {
    const containerWidth = vidBlock.current.offsetWidth;
    const containerHeight = vidWrapper.current.offsetHeight;
    const cropperWidth = cropper.current.offsetWidth;
    const cropperHeight = cropper.current.offsetHeight;
    const cropperLeft = cropper.current.offsetLeft;
    const cropperTop = cropper.current.offsetTop;

    const percentageLeft = (cropperLeft / containerWidth) * 100;
    const percentageTop = (cropperTop / containerHeight) * 100;
    const percentageWidth = (cropperWidth / containerWidth) * 100;
    const percentageHeight = (cropperHeight / containerHeight) * 100;
    const newCropData = {
      left: percentageLeft,
      top: percentageTop,
      width: percentageWidth,
      height: percentageHeight,
      aspect: aspect,
    };
    setCropData(newCropData);
    onCropData(newCropData);
  }, [aspect, cropData, vidBlock, vidWrapper]);

  const updateCropperAndOverlay = () => {
    const containerWidth = vidBlock.current.offsetWidth;
    const cropperLeft = parseFloat(cropper.current.style.left);
    const cropperWidth = cropper.current.offsetWidth;
    setCropOverlayLeft(cropperLeft);
    setCropOverlayRight(containerWidth - cropperLeft - cropperWidth);
  };

  const handleMouseUp = () => {
    setIsCropDragging(isCropDragging ? false : true);
    calculateCropPosition();
  };

  useEffect(() => {
    setAspect(cropType === "CROP-45" ? "1080x1350" : "1080x1920");
    calculateCropPosition();
    // eslint-disable-next-line
  }, [cropType]);

  useEffect(() => {
    updateCropperAndOverlay();
  }, [isCropDragging, cropType, vidBlock, calculateCropPosition]);

  useEffect(() => {
    const containerWidth = vidBlock.current.offsetWidth;
    const cropperWidth = cropper.current.offsetWidth;
    const originalNumber = (newCropPos.left / 100) * containerWidth;
    setCropperLeftPos(originalNumber);
    setCropOverlayLeft(originalNumber);
    const rightCalc = containerWidth - originalNumber - cropperWidth;
    setCropOverlayRight(rightCalc > 0 ? rightCalc : 0);
  }, [newCropPos]);

  useEffect(() => {
    calculateCropPosition();
  }, [isActive]);

  return (
    <>
      <div
        onMouseMove={(e) => handleMouseMove(e)}
        onMouseUp={() => handleMouseUp()}
      >
        <div
          className={
            isActive ? `${cropOverlay} crop-overlay left-0 on` : ` hidden`
          }
          id="crop-overlay-left"
          ref={cropOverlayLeftRef}
          style={{ width: `${cropOverlayLeft}px` }}
        ></div>
        <div
          className={isActive ? `crop ${cropClass} ${cropType} on` : `hidden`}
          ref={cropper}
          id="cropper"
          style={{ left: `${cropperLeftPos}px` }}
          onMouseDown={(e) => handleMouseDown(e)}
        >
          <div className="handle tl"></div>
          <div className="handle tr"></div>
          <div className="handle bl"></div>
          <div className="handle br"></div>
        </div>
        <div
          className={isActive ? `${cropOverlay} right-0 on` : "hidden"}
          id="crop-overlay-right"
          ref={cropOverlayRightRef}
          style={{ width: `${cropOverlayRight}px` }}
        ></div>
      </div>
    </>
  );
};

export default Cropper;
