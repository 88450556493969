import {
  faVideo,
  faVideoSlash,
  faMicrophone,
  faMicrophoneSlash,
  faPersonWalkingDashedLineArrowRight,
  faGear,
  faUserPlus,
  faMessage,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Tooltip } from "react-tooltip";
import OptionSelector from "./OptionSelector";
import useGetMediaDevices from "../../../hooks/useGetMediaDevices";

export interface GuestControlProps {
  onVideoToggle: (showing: boolean) => void;
  onAudioToggle: (showing: boolean) => void;
  onSettingsToggle?: (showing: boolean) => void | null;
  onNewVideoStream?: (stream: any) => void | null;
  defaultSettingsValue?: boolean;
  leaveRoom?: () => void;
  inviteUser?: () => void;
  toggleChat?: (chatOpen: boolean) => void;
  videoSource?: string;
  audioSource?: string;
  currentStream?: any;
}
const GuestControls = ({
  onVideoToggle,
  onAudioToggle,
  onNewVideoStream,
  defaultSettingsValue,
  leaveRoom,
  inviteUser,
  toggleChat,
  onSettingsToggle,
  videoSource,
  audioSource,
  currentStream,
}: GuestControlProps) => {
  const devices = useGetMediaDevices();
  const [isVideoShowing, setIsVideoShowing] = useState(true);
  const [isAudioMuted, setIsAudioMuted] = useState(false);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [showVideoPicker, setShowVideoPicker] = useState(false);
  const btn = {
    active:
      "bg-gray-900 border border-slate-700 border border-slate-500 p-3 rounded-sm w-10 h-10 flex justify-center items-center pointer text-red transition-color duration-300",
    reg: "bg-[#2e4155] hover:bg-blue-charcoal-600 border border-slate-900 p-3 rounded-sm w-10 h-10 flex justify-center items-center pointer transition-color duration-300 shadow-md",
  };

  const onDeviceSelection = async (type: string, val: string) => {
    if (type === "video") {
      console.log("onDeviceSelection", val);
      if (onNewVideoStream) {
        const videoConstraints = {
          deviceId: { exact: val },
        };

        const audioConstraints = {
          deviceId: { exact: devices.defaultAudio.audioId },
          enabled: true,
        };

        if (currentStream) {
          console.log("stopping stream", currentStream.getTracks());
          currentStream.getTracks().forEach((track) => track.stop());
        }

        console.log("video", val, "Audio", devices.defaultAudio.audioId);
        const stream = await navigator.mediaDevices.getUserMedia({
          video: videoConstraints,
          audio: audioConstraints,
        });

        console.log("Got new stream", stream.id, stream);
        stream.getTracks().forEach((track) => {
          console.log("TRACK", track.label);
        });
        onNewVideoStream(stream);
      }
    }
  };

  useEffect(() => {
    //console.log("showVideoPicker", showVideoPicker, videoSource);
    if (videoSource || audioSource) {
    }
  }, [showVideoPicker]);

  useEffect(() => {
    onAudioToggle(isAudioMuted);
  }, [isAudioMuted]);

  useEffect(() => {
    onVideoToggle(isVideoShowing);
  }, [isVideoShowing]);
  useEffect(() => {
    if (toggleChat) {
      toggleChat(isChatOpen);
    }
  }, [isChatOpen]);

  useEffect(() => {
    console.log("currentStream", currentStream, defaultSettingsValue);
  }, []);

  return (
    <div className="flex flex-row fixed bottom-0 bg-[#1f2935] border-[#2e4155] border-t border-r border-l rounded-sm rounded-b-none px-2 py-2 gap-2">
      <span
        className={
          isVideoShowing ? `${btn.reg} relative` : `${btn.active} relative`
        }
        data-tooltip-id="tooltip-video-show"
        data-tooltip-content={isVideoShowing ? "Hide Video" : "Un-hide Video"}
        data-tooltip-delay-show={1000}
        data-tooltip-offset={20}
      >
        <FontAwesomeIcon
          icon={isVideoShowing ? faVideo : faVideoSlash}
          onClick={() => setIsVideoShowing(!isVideoShowing)}
        />
        {/* <div
          className="absolute -top-1.5 -right-1.5 z-20 bg-slate-600 hover:bg-blue-charcoal-500 px-1 py-1 text-xs rounded-sm shadow-md"
          onClick={() => setShowVideoPicker(true)}
        >
          <FontAwesomeIcon icon={faChevronUp} />
        </div> */}
        {showVideoPicker && (
          <OptionSelector
            options={devices.videoDevices}
            onOptionClick={onDeviceSelection}
            optionType="video"
            defaultValue={devices.defaultCam.videoId}
            onCancel={() => setShowVideoPicker(false)}
          />
        )}
      </span>
      <Tooltip id="tooltip-video-show" className="toolTip" />
      <span
        className={isAudioMuted ? `${btn.active}` : `${btn.reg}`}
        onClick={() => setIsAudioMuted(!isAudioMuted)}
        data-tooltip-id="tooltip-audio-show"
        data-tooltip-content={isAudioMuted ? "Un-Mute Audio" : "Mute Audio"}
        data-tooltip-delay-show={1000}
        data-tooltip-offset={20}
      >
        <FontAwesomeIcon
          icon={isAudioMuted ? faMicrophoneSlash : faMicrophone}
        />
      </span>
      <Tooltip id="tooltip-audio-show" className="toolTip" />
      {inviteUser && (
        <>
          <span
            className={`${btn.reg} `}
            data-tooltip-id="tooltip-invite-user"
            data-tooltip-content="Invite a guest"
            data-tooltip-delay-show={1000}
            data-tooltip-offset={20}
          >
            <FontAwesomeIcon icon={faUserPlus} onClick={inviteUser} />
          </span>
          <Tooltip id="tooltip-invite-user" className="toolTip" />
        </>
      )}

      {toggleChat && (
        <>
          <span
            className={isChatOpen ? btn.active : btn.reg}
            onClick={() => setIsChatOpen(!isChatOpen)}
            data-tooltip-id="tooltip-toggle-chat"
            data-tooltip-content="Toggle Chat"
            data-tooltip-delay-show={1000}
            data-tooltip-offset={20}
          >
            <FontAwesomeIcon icon={faMessage} />
          </span>
          <Tooltip id="tooltip-toggle-chat" className="toolTip" />
        </>
      )}

      {onSettingsToggle && (
        <span onClick={() => onSettingsToggle(!defaultSettingsValue)}>
          <FontAwesomeIcon icon={faGear} />
        </span>
      )}

      {leaveRoom && (
        <>
          <Tooltip id="tooltip-leave" className="toolTip" />
          <span
            className={btn.reg}
            onClick={leaveRoom}
            data-tooltip-id="tooltip-leave"
            data-tooltip-content="Leave show"
            data-tooltip-delay-show={1000}
            data-tooltip-offset={20}
          >
            <FontAwesomeIcon icon={faPersonWalkingDashedLineArrowRight} />
          </span>
        </>
      )}
    </div>
  );
};

export default GuestControls;
