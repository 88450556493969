import React, { useEffect, useState } from "react";
import { TopicDataArray } from "../../../types/Types";
import { useTranslation } from "react-i18next";

export interface SegmentTimeProps {
  topics: TopicDataArray;
  index: number;
  starttime: number;
}

const SegmentStartTime = ({ topics, index, starttime }) => {
  const { t } = useTranslation();
  const [estSegStart, setEstSegStart] = useState<string>("--:--:--");

  const calcEstimatedStart = () => {
    const total = topics
      .slice(0, index) // Get a slice of the array up to the target index
      .reduce(
        (accumulator, currentValue) =>
          Number(accumulator) + Number(currentValue.topic_time),
        0
      );

    const showStartTime = new Date(starttime);
    showStartTime.setMinutes(showStartTime.getMinutes() + total);
    const amPmTime = showStartTime.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
    setEstSegStart(`${t("planner.est_segment_start")}: ${amPmTime}`);
  };

  useEffect(() => {
    calcEstimatedStart();
  }, [topics]);

  useEffect(() => {
    calcEstimatedStart();
  }, [starttime]);

  return (
    <span className="text-sm text-gray-400 block leading-none">
      {estSegStart}
    </span>
  );
};

export default SegmentStartTime;
