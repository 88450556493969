// src/App.js
import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import "./css/index.css";
import ReactGA from "react-ga4";
import { I18nextProvider } from "react-i18next";
import i18n from "./helpers/i18n";
import AuthRoutes from "./routes/auth";
import Shopro from "./features/Shopro/Index";
import BrowserSources from "./components/sources/BrowserSources/BrowserSources";
import SelectedSources from "./components/sources/BrowserSources/SelectedSource";
import Ticker from "./components/sources/BrowserSources/Ticker";
import TopicSource from "./components/sources/BrowserSources/ChyronSource";
import Upgrade from "./components/upgrade/Upgrade";
import ShareableShow from "./components/Sharable/SharableShow";
import axios from "axios";
import { getBearerHeaders } from "./helpers/GetBearerHeaders";
import { UserContext } from "./context/UserContext";
import { User } from "./types/Types";
// import Login from "./components/auth/Login";
import Cancel from "./components/upgrade/Cancel";
import Success from "./components/upgrade/Success";
import Account from "./components/account";
import NotFound from "./components/notfound";
import RequestPassword from "./components/account/RequestPassword";
import ConfirmPassword from "./components/account/ConfirmPassword";
import Admin from "./components/admin/Index";
import Tos from "./components/pages/Tos";
import Privacy from "./components/pages/Privacy";
import Rundown from "./components/sources/BrowserSources/RunndownSource";
import Overlay from "./components/sources/BrowserSources/OverlaySource";
//import Homepage from "./components/pages/Homepage";
import Settings from "./components/settings/Index";
import ControlCenter from "./features/controlCenter/Index";
import JoinRoom from "./features/controlCenter/Join";
import ChatScene from "./features/controlCenter/ChatScene";
import IndividualStream from "./features/controlCenter/IndividualStream";
import Dashboard from "./features/dashboard/Index";
import ProjectsPage from "./features/streamslicer/Pages/Projects/Projects";
import ClipsPage from "./features/streamslicer/Pages/ProjectClips/ProjectClips";
import SlicesPage from "./features/streamslicer/Pages/Slices/SavedSlices";
import MomentsPage from "./features/streamslicer/Pages/Projects/MomentsPage";
import VideoEditor from "./features/streamslicer/Pages/Video/Editor";
import Home from "./components/pages/Home/Index";
import { GetFromLocalStorage } from "./helpers/LocalStorage";
import CanvasStream from "./features/controlCenter/CanvasStream";
import Watch from "./features/controlCenter/Watch";
//import { fetchFixDbShows } from "./api-requests/Index";

//dotenv.config();

function App() {
  const [userChecked, setUserChecked] = useState<boolean>(false);

  const [user, setUser] = useState<User>({
    id: -1,
    username: "",
    accounttype: "trial",
    isloggedin: false,
    config: {},
    expires: null,
    roomid: null,
    roompass: null,
  });

  useEffect(() => {
    // console.log("INITIALIZE ANALYTICS");
    const storageLang = GetFromLocalStorage("language");
    const navLang = navigator.language.substring(0, 2);

    i18n.changeLanguage(storageLang ? storageLang : navLang);
    const host = window.location.hostname;
    if (
      !host.includes("localhost") &&
      !host.includes("alpha.shopro.live") &&
      !host.includes("shopro.live")
    ) {
      console.log("NOT OUR DOMAIN REDIRECT", host);
      window.location.href = "https://shopro.live";
    }
    ReactGA.initialize("G-ZL0ZP018E6");
    //fetchFixDbShows({}, getBearerHeaders());
    axios
      .post("/api/isauthenticated", {}, getBearerHeaders())
      .then((response) => {
        setUserChecked(true);
        if (response.data.success) {
          console.log(response.data.items);
          setUser(response.data.items);
        }
      });
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      <I18nextProvider i18n={i18n}>
        {userChecked ? (
          <Router>
            <Routes>
              <Route
                path="/"
                element={user.isloggedin ? <Dashboard /> : <Home />}
              />
              <Route
                path="/planner"
                element={
                  user.isloggedin ? <Shopro /> : <Navigate to="/login" />
                }
              />
              <Route
                path="/demo"
                element={user.isloggedin ? <Navigate to="/" /> : <Shopro />}
              />
              <Route
                path="/account"
                element={
                  user.isloggedin ? <Account /> : <Navigate to="/login" />
                }
              />
              <Route
                path="/sources"
                element={
                  user.isloggedin ? (
                    <BrowserSources />
                  ) : (
                    <Navigate to="/login" />
                  )
                }
              />
              <Route
                path="/sources/:sourceType"
                element={
                  user.isloggedin ? (
                    <SelectedSources />
                  ) : (
                    <Navigate to="/login" />
                  )
                }
              />
              <Route
                path="/settings"
                element={
                  user.isloggedin ? <Settings /> : <Navigate to="/login" />
                }
              />
              <Route
                path="/studio"
                element={
                  user.isloggedin ? <ControlCenter /> : <Navigate to="/login" />
                }
              />
              <Route
                path="/studio/:roomid"
                element={
                  user.isloggedin ? <ControlCenter /> : <Navigate to="/login" />
                }
              />
              <Route
                path="/streamslicer"
                element={
                  user.isloggedin ? (
                    <ProjectsPage userId={user.id} />
                  ) : (
                    <Navigate to="/login" />
                  )
                }
              />
              <Route
                path="/streamslicer/clips"
                element={
                  user.isloggedin ? (
                    <ClipsPage userId={user.id} />
                  ) : (
                    <Navigate to="/login" />
                  )
                }
              />
              <Route
                path="/streamslicer/slices"
                element={
                  user.isloggedin ? <SlicesPage /> : <Navigate to="/login" />
                }
              />
              <Route
                path="/streamslicer/moments/:projectId"
                element={
                  user.isloggedin ? <MomentsPage /> : <Navigate to="/login" />
                }
              />
              <Route
                path="/streamslicer/video-editor/:id"
                element={
                  user.isloggedin ? <VideoEditor /> : <Navigate to="/login" />
                }
              />
              <Route
                path="/shopro-app"
                element={
                  user.isloggedin ? (
                    <Shopro showAppHeader={false} />
                  ) : (
                    <Navigate to="/login" />
                  )
                }
              />
              <Route
                path="/thor"
                element={user.isloggedin ? <Admin /> : <Navigate to="/login" />}
              />

              <Route path="/sources/ticker/:sourceid" element={<Ticker />} />
              <Route
                path="/sources/topic/:sourceid"
                element={<TopicSource />}
              />
              <Route path="/sources/rundown/:sourceid" element={<Rundown />} />
              <Route path="/sources/overlay/:sourceid" element={<Overlay />} />
              <Route path="/shared/:shareid" element={<ShareableShow />} />
              <Route path="/join/:roomid" element={<JoinRoom />} />
              <Route path="/room-scene/:roomid" element={<ChatScene />} />
              <Route path="/watch/:roomid" element={<Watch />} />
              <Route
                path="/room-scene/:roomid/:noHostCam"
                element={<ChatScene />}
              />
              <Route
                path="/stream/:roomid/:peerid"
                element={<IndividualStream />}
              />
              <Route
                path="/canvas-stream/:roomid/"
                element={<CanvasStream />}
              />
              <Route path="/upgrade" element={<Upgrade />} />
              <Route path="/upgrade/success/:sessionid" element={<Success />} />
              <Route path="/upgrade/cancel" element={<Cancel />} />
              <Route path="/forgot-password" element={<RequestPassword />} />
              <Route path="/reset-password" element={<RequestPassword />} />
              <Route path="/update-password" element={<ConfirmPassword />} />
              <Route
                path="/update-password/:code"
                element={<ConfirmPassword />}
              />
              <Route path="/tos" element={<Tos />} />
              <Route path="/privacy" element={<Privacy />} />

              {AuthRoutes}
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Router>
        ) : (
          <></>
        )}
      </I18nextProvider>
    </UserContext.Provider>
  );
}

export default App;
