import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Clip from "./Clip";
//import ApiRequest from "../../helpers/ApiRequest";
import Header from "../../../../components/layout/Header";
import SSHeader from "../../components/SSHeader";
import CreateNew from "../../components/CreateNew";

const ClipsPage = (props) => {
  const { userId } = props;
  const { t } = useTranslation();
  const [clipData] = useState([]);

  useEffect(() => {
    /* ApiRequest.post("/api/userClips", {
      userId: userId,
    }).then((clipDataResults) => {
      setClipData(clipDataResults.data);
    }); */
  }, [userId]);

  return (
    <>
      <Header />
      <SSHeader />
      {clipData.length ? (
        <div className="video-grid" key="clip-grid">
          {clipData.map((clip) => (
            <Clip clipData={clip} />
          ))}
        </div>
      ) : (
        <CreateNew label={t("notices.no_project_clips")} />
      )}
    </>
  );
};

export default ClipsPage;
