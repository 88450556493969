import axios from "axios";
import { useState, useEffect } from "react";
import { getBearerHeaders } from "../helpers/GetBearerHeaders";

const useIsAuthenticated = () => {
  const [isLoggedin, setIsLoggedIn] = useState(false);

  useEffect(() => {
    axios
      .post("/api/isauthenticated", {}, getBearerHeaders())
      .then((response) => {
        if (response.data.success) {
          setIsLoggedIn(true);
        }
      });
    return () => {};
  }, []);

  return isLoggedin;
};

export default useIsAuthenticated;
