import axios from "axios";

export const UpdateUserAccount = async (data: any, headers: any) => {
  const res = await axios.post("/api/v1/updateUserWithFields", data, headers);
  return res.data;
};

export const UpdateShowByFields = async (data: any, headers: any) => {
  const res = await axios.post("/api/v1/update-show-by-fields", data, headers);
  return res.data;
};

export const UploadShowCapture = async (data: any, headers: any) => {
  const res = await axios.post("/api/v1/update-show-by-fields", data, headers);
  return res.data;
};
