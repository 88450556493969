import React, { useEffect, useState } from "react";
import { SceneItemProps } from "../../../types/Types";
import Cookies from "js-cookie";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const SceneItem = ({
  scene,
  selectedScene,
  websocket,
  id,
  hiddenBtns,
  onHideToggle,
  showAll,
}: SceneItemProps) => {
  const [btnVisible, setBtnVisible] = useState<boolean>(
    hiddenBtns.includes(scene.sceneName) ? false : true
  );
  const jwtToken = Cookies.get("jwt");
  const loadScene = (sceneName: string) => {
    console.log({
      eventtype: "CHANGESCENE",
      scene: sceneName,
      token: jwtToken,
    });
    websocket.emit("obs-control", {
      eventtype: "CHANGESCENE",
      scene: sceneName,
      token: jwtToken,
    });
  };

  const toggleBtn = (visible: boolean) => {
    setBtnVisible(visible);
    onHideToggle(scene.sceneName, visible);
  };

  useEffect(() => {
    if (hiddenBtns.length) {
      console.log(
        "isVisible",
        hiddenBtns,
        scene.sceneName,
        hiddenBtns.includes(scene.sceneName)
      );
      setBtnVisible(hiddenBtns.includes(scene.sceneName) ? false : true);
    }
  }, [hiddenBtns]);

  return (
    <>
      {btnVisible || showAll ? (
        <button
          key={id}
          data-testid="toggle-icon"
          className={
            selectedScene.toUpperCase() === scene.sceneName.toUpperCase()
              ? "scene-btn active"
              : "scene-btn"
          }
          onClick={() => loadScene(scene.sceneName)}
        >
          {scene.sceneName}
          {showAll ? (
            <div
              data-testid="toggle-icon"
              className="toggle-icon"
              onClick={() => toggleBtn(!btnVisible)}
            >
              {btnVisible ? (
                <FontAwesomeIcon icon={faEye} />
              ) : (
                <FontAwesomeIcon className="red-text" icon={faEyeSlash} />
              )}
            </div>
          ) : null}
        </button>
      ) : null}
    </>
  );
};

export default SceneItem;
