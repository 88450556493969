// src/App.js
import React, {
  ChangeEvent,
  useContext,
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { io, Socket } from "socket.io-client";
import "../../sass/styles.scss";
import Card from "./components/Card";
import { calculateTimeDuration, dateToTime } from "../../helpers/TimeCode";
import Marquee from "react-fast-marquee";
import SceneSelector from "./components/SceneSelector";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  faFloppyDisk,
  faForwardStep,
  faBackwardStep,
  faShareNodes,
  faTrashCan,
  faArrowsRotate,
  faSort,
  faSquarePlus,
  faChartSimple,
  faEye,
  faEyeSlash,
  faGears,
  faComments,
  faPen,
  faPlus,
  faCamera,
  faRobot,
} from "@fortawesome/free-solid-svg-icons";

import {
  TopicData,
  TopicDataArray,
  Topics,
  ShowDataArray,
  SceneDataArray,
  AlertLabelProps,
  ShowData,
} from "../../types/Types";
import { dateAndTimeDisplay } from "../../helpers/Dates";
import config from "../../config/config.json";
import {
  SaveToLocalStorage,
  GetFromLocalStorage,
  DeleteFromLocalStorage,
} from "../../helpers/LocalStorage";
import ShowStats from "./components/ShowStats";

import Header from "./../../components/layout/Header";
import PastShows from "./components/PastShows";
import { getBearerHeaders } from "../../helpers/GetBearerHeaders";

import ShareWindow from "./components/ShareWindow";
import { UserContext } from "../../context/UserContext";
import CustomAlert from "./../../components/CustomAlert";
import Teleprompter from "../../components/teleprompter";
import ShowTime from "./components/ShowTime";
import { activityCheck } from "../../helpers/ActivityCheck";
import { getServer } from "../../helpers/GetServer";
import Cookies from "js-cookie";
import TickerOptions from "./components/TickerOptions";
import useTracking from "../../hooks/useTracking";
import useScrollTop from "../../hooks/useScrollTop";
import Chat from "../controlCenter/components/Chat/Index";
import { apiUpdateConfig, apiWriteTopic } from "../../api-requests/Index";
import { tw } from "../../helpers/Styles";
import CardEditor from "./components/CardEditor";
import ShowEditor from "./components/ShowEditor";
import LoadingSpinner from "./components/LoadingSpinner";
import { useTranslation } from "react-i18next";
import AuthMonitor from "../../helpers/AuthMonitor";
import useAi from "./hooks/useAi";
import useExperiments from "../../hooks/useExperiments";
import ChatBotDsp from "./components/ChatBotDsp";
//import useShowHistory from "../../hooks/useShowHistory";

export interface ShoProProps {
  showAppHeader?: boolean;
  isInControlCenter?: boolean;
  useChat?: boolean;
  onElapsedTime?: (timeStr: string) => void;
  onShowStatusChange?: (status: boolean) => void;
  onNextTopic?: (topicNumber: number) => void;
  onPrevTopic?: (topicNumber: number) => void;
  onShareIdChange?: (id: number | string) => void;
  onShowIdChange?: (showid: number) => void;
  onSourceId?: (sourceid: string) => void;
  controlCenterRef?: any;
  goLive?: boolean;
}

const Shopro = forwardRef(
  (
    {
      showAppHeader,
      isInControlCenter,
      useChat,
      onElapsedTime,
      onShowStatusChange,
      onNextTopic,
      onPrevTopic,
      onShareIdChange,
      onShowIdChange,
      onSourceId,
      controlCenterRef,
      goLive,
    }: ShoProProps,
    ref
  ) => {
    const location = useLocation();
    const { t } = useTranslation();
    const inExperiment = useExperiments();
    const { aiTopics, videoPrompt } = useAi();
    const fullWidthClass =
      isInControlCenter && isInControlCenter === true ? "full-width" : "";
    const navigate = useNavigate();
    const { user } = useContext(UserContext);
    const eventTracker = useTracking();
    // const { addToHistory } = useShowHistory();
    useScrollTop();
    AuthMonitor();

    const jwtToken = Cookies.get("jwt");
    const queryparam = jwtToken ? `?token=${jwtToken}` : null;

    const useAppHeader = showAppHeader !== undefined ? showAppHeader : true;
    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(user.isloggedin);
    const today = new Date();
    today.setHours(today.getHours() + 4);
    const defaultShowTitleLabel = "Show Slug";
    const defaultEpisodeLabel = "Episode #";
    const defaultShowDate = dateAndTimeDisplay(today);
    const defaultShowInfo = "https://youtube.com/watch?v=Lqyk8GAvHGU";

    // const [isWelcomeMsgViewed, setIsWelcomeMsgViewed] = useState<boolean>(true);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [selectedShow, setSelectedShow] = useState(-1);
    const [lastSelectedShow, setLastSelectedShow] = useState(-2);
    const [shows, setShows] = useState<ShowDataArray>([]);
    const [data, setData] = useState<Topics | null>(null);
    const [topics, setTopics] = useState<TopicDataArray>([]);
    const [history, setHistory] = useState([]);
    const [tickerText, setTickerText] = useState<string>("");
    const [lastSessionShowLoaded, setLastSessionShowLoaded] =
      useState<number>(-1);
    // const [items, setItems] = useState<TopicDataArray>([]);

    const [episode, setEpidsode] = useState<string | number>(
      defaultEpisodeLabel
    );
    const [showTitle, setShowTitle] = useState<string | number>(
      defaultShowTitleLabel
    );
    const [chatBotText, setChatBotText] = useState<string>("");
    const [fullShowData, setFullShowData] = useState<ShowData>(null);
    const [showDate, setShowDate] = useState<string | number>(defaultShowDate);
    const [showUrl, setShowUrl] = useState<string | number>(defaultShowInfo);
    const [estimatedShowTime, setEstimatedShowTime] = useState<number>(0);
    const [currentTopic, setCurrentTopic] = useState<number>(-1);
    const [showId, setShowId] = useState<number>(-1);
    const [isStatsShowing, setIsStatsShowing] = useState<boolean>(false);
    const [lastTopic, setLastTopic] = useState<number | null>(null);
    const [startTime, setStartTime] = useState<string>("");
    const [elapsedTime, setElapsedTime] = useState<string>("--:--:--");
    const [scenes, setScenes] = useState<SceneDataArray>([]);
    const [selectedScene, setSelectedScene] = useState<string>("");
    const [isSceneSelectorActive, setIsSceneSelectorActive] =
      useState<boolean>(false);
    const [isShowInProgress, setShowInProgress] = useState<boolean>(false);
    const [topicTickerId, setTopicTickerId] = useState<number>(-1);
    const [shareid, setShareid] = useState<string>("");
    const [sourceid, setSourceid] = useState<string>("");
    const [showShare, setShowShare] = useState<boolean>(false);
    const [topicStartTime, settopicStartTime] = useState<number>(0);
    const [showStartTime, setShowStartTime] = useState<number>(0);
    const [showEndTime, setShowEndTime] = useState<number>(0);
    const [hasBeenReloaded, setHasBeenReloaded] = useState(false);
    const [isPrompterShowing, setisPrompterShowing] = useState<boolean>(false);
    const [prompterScript, setPrompterScript] = useState<string>("");
    const [prePrompterPos, setPrePrompterPos] = useState(0);
    const [isReorderDragDisabled, setIsReorderEnabled] = useState(true);
    const [isTickerOptionsEnabled, setIsTickerOptionsEnabled] = useState(false);
    const [showHasEnded, setShowHasEnded] = useState(false);
    const [prepShowEnd, setPrepShowEnd] = useState(false);
    //const [startOrShowTime, setStartOrShowTime] = useState<number | string>(0);
    const [scrollSettings, setScrollSettings] = useState({
      speed: user.config?.speed ? user.config?.speed : 16,
      textsize: user.config?.textsize ? user.config?.textsize : 80,
    });

    const [isObsConnected, setIsObsConnected] = useState<boolean>(false);
    const [isObsStreaming, setIsObsStreaming] = useState<boolean>(false);
    const [isObsRecording, setIsObsRecording] = useState<boolean>(false);
    const [chyronVisible, setChyronVisible] = useState<boolean>(true);
    const [rundownVisible, setRundownVisible] = useState<boolean>(true);
    const [websocketConnected, setWebsocketConnected] =
      useState<boolean>(false);
    const [websocket, setWebSocket] = useState<Socket>();
    const [obsWssData, setObsWssData] = useState<any>();
    const [webSocketId, setWebSocketId] = useState(null);
    const [chatOpen, setChatOpen] = useState(false);
    const [isEditingCard, setIsEditingCard] = useState<boolean>(false);
    const [isEditingShow, setIsEditingShow] = useState<boolean>(false);
    const [cardToEdit, setCardToEdit] = useState({ index: -1, data: null });
    const [showChatBot, setShowChatBot] = useState<boolean>(false);

    const [elapsedTopicTimeFromCard, setElapsedTopicTimeFromCard] =
      useState<number>();

    const [isAlertVisible, setIsAlertVisible] = useState<boolean>(false);
    const [alertLabels, setAlertLabels] = useState<AlertLabelProps>();

    const [tickerPrefix, setTickerPrefix] = useState<string>(
      user.config?.tickerPrefix
        ? user.config?.tickerPrefix
        : "Powered By ShoPro.Live"
    );
    const [tickerSuffix, setTickerSuffix] = useState<string>(
      user.config?.tickerSuffix
        ? user.config?.tickerSuffix
        : "Visit shopro.live"
    );
    const [tickerSeperator, setTickerSeperator] = useState<string>(
      user.config?.tickerSeperator ? user.config?.tickerSeperator : "•"
    );

    const [hasBeenModified, setHasBeenModified] = useState<boolean>(false);
    const storageConfig = config.storagetype.toUpperCase();

    const hasCookieExpired = () => {
      let hasExpired = false;
      if (isLoggedIn) {
        hasExpired = activityCheck() ? false : true;
        if (hasExpired && !isAlertVisible) {
          const labels = {
            title: t("unavailable"),
            message: t("inactivity_msg"),
            confirmLabel: t("login"),
            onConfirm: () => navigate("/login"),
            confirmClass: "bg-blue hover:bg-blueHover",
          };
          setAlertLabels(labels);
          setIsAlertVisible(true);
        }
      }
      return hasExpired;
    };

    const handleVisibilityChange = () => {
      hasCookieExpired();
    };

    const updateShareId = (showId) => {
      const postData = {
        showid: showId,
      };
      axios
        .post("/api/v1/generateshareid", postData, getBearerHeaders())
        .then((response) => {
          if (response.data.success) {
            setShareid(response.data.items.shareid);
            //onshareId(response.data.items.shareid);
          }
        });
    };

    const loadShow = (event: ChangeEvent<HTMLSelectElement> | number) => {
      if (hasCookieExpired()) {
      }
      if (event !== null) {
        /* ReactGA.event({
        category: "User Interction",
        action: "Show Loaded",
      }); */
        let targetValAsNumber;
        if (typeof event === "number") {
          targetValAsNumber = Number(event);
        } else {
          targetValAsNumber = Number(event.target.value);
        }

        if (targetValAsNumber !== -1) {
          setIsEditingShow(false);
          if (shows[targetValAsNumber]) {
            const showDataParsed =
              typeof shows[targetValAsNumber] === "string"
                ? JSON.parse(String(shows[targetValAsNumber].show))
                : shows[targetValAsNumber].show;

            setFullShowData(shows[targetValAsNumber]);
            setSelectedShow(targetValAsNumber);
            setShowId(Number(shows[targetValAsNumber].id));
            setShareid(shows[targetValAsNumber].shareid);

            if (shows[targetValAsNumber].shareid === null) {
              updateShareId(Number(shows[targetValAsNumber].id));
            }

            //websocket.disconnect();
            //websocket.connect();
            setHasBeenModified(false);
            setShowTitle(showDataParsed.showTitle);
            setShowDate(showDataParsed.show_date);
            setEpidsode(showDataParsed.episode);
            setHistory(showDataParsed.history ? showDataParsed.history : []);

            setTopics(showDataParsed.task_list_items);
            setShowUrl(showDataParsed.epUrlId);
            createTickerString();
            setCurrentTopic(-1);

            setShowEndTime(
              shows[targetValAsNumber].endtime === null
                ? 0
                : shows[targetValAsNumber].endtime
            );
            const startDate = new Date(shows[targetValAsNumber].starttime);
            setShowStartTime(shows[targetValAsNumber].starttime);
            setStartTime(dateToTime(startDate));

            if (shows[targetValAsNumber].islive && !hasBeenReloaded) {
              setShowInProgress(true);
              setCurrentTopic(shows[targetValAsNumber].currenttopic);
              settopicStartTime(shows[targetValAsNumber].lasttopicstart);
              //setHistory(shows[targetValAsNumber].show.history);

              const now = new Date();
              const elapsed = calculateTimeDuration(
                dateToTime(startDate),
                dateToTime(now)
              );

              setElapsedTime(elapsed);
              setShowHasEnded(false);
            }

            if (shows[targetValAsNumber].endtime !== null) {
              const elapsed = calculateTimeDuration(
                dateToTime(new Date(shows[targetValAsNumber].starttime)),
                dateToTime(new Date(shows[targetValAsNumber].endtime))
              );
              setShowHasEnded(true);

              setElapsedTime(elapsed);
            } else if (!shows[targetValAsNumber].islive) {
              setElapsedTime("--:--:--");
              setShowHasEnded(false);
            }
            if (shareid) {
              //console.log("empty");
            }
            setHasBeenReloaded(true);
            setIsLoading(false);
          }
          //setIsEditingShow(false);
        } else {
          //console.log("NO SHOW", targetValAsNumber);
          //setIsEditingShow(true);
          //newShow();
        }
      }
    };

    const setTimeForPrompter = (time) => {
      setElapsedTopicTimeFromCard(time);
    };

    const editCard = (doEdit: boolean, index: number, cardData) => {
      setIsEditingCard(doEdit);
      setCardToEdit({ index, data: cardData });
    };

    const updateShowPass = (pass) => {
      //setShareid(pass);
      const showdataUpdate = { ...fullShowData, password: pass };
      setFullShowData(showdataUpdate);
    };

    const newShow = () => {
      if (
        (user.accounttype === "free" && shows.length >= 2) ||
        (user.accounttype === "trial" && shows.length >= 1)
      ) {
        const labels = {
          title: t("save_limit_exceeded"),
          message: t("save_limit_exceeded_msg"),
          confirmLabel: t("upgrade_confirm"),
          cancelLabel: t("cancel"),
          onConfirm: () => navigate("/upgrade"),
          onCancel: () => setIsAlertVisible(false),
          confirmClass: "bg-green-700 hover:bg-greeHover",
          cancelClass: "bg-blue hover:bg-ssblueHover",
        };
        setAlertLabels(labels);
        setIsAlertVisible(true);
      } else {
        setCurrentTopic(-1);
        setShowId(-1);
        setShareid("");
        setShowTitle("");
        setEpidsode("");
        setShowDate(defaultShowDate);
        setShowUrl("");
        setSelectedShow(-1);
        setTopics([]);
        setHasBeenModified(false);
        setShowHasEnded(false);
        setElapsedTime("--:--:--");
        eventTracker({ category: "APP_BTN", action: "New Show" });
        setIsEditingShow(true);
        setIsLoading(false);
        /* ReactGA.event({
        category: "User Interction",
        action: "New Loaded",
      }); */
      }
    };

    /* const addSampleData = () => {
      setShowTitle("The Sample Show");
      setEpidsode("100");
      setTopics([...topics, ...SampleData]);
      const showData: Topics = {
        showid: showId,
        task_list_title: "",
        showTitle: showTitle,
        show_date: showDate,
        episode: episode,
        epUrlId: showUrl,
        task_list_items: [...topics, ...SampleData],
      };
      setHasBeenModified(false);
      setData(showData);
    }; */

    const newTopic = () => {
      const topicData: TopicData = {
        topic: "",
        show_in_list: false,
        ticker_label: "",
        color: null,
        topic_time: 10,
        is_complete: false,
        start_time: null,
        end_time: null,
        notes: "",
        isNewTopic: true,
        includeInRundown: true,
      };
      setTopics([...topics, topicData]);
      setHasBeenModified(true);
      eventTracker({ category: "APP_BTN", action: "New Segment" });
      editCard(true, topics.length, topicData);
      /* ReactGA.event({
      category: "User Interction",
      action: "New Topic",
    }); */
    };

    const saveShow = () => {
      const historyEntry = {
        item: topics,
        userid: user.username,
        action: "update",
        time: new Date(),
      };
      setHistory((prevArray) => [...prevArray, historyEntry]);
      const showData: Topics = {
        showid: showId !== -1 ? showId : -1,
        task_list_title: "",
        showTitle: showTitle,
        show_date: showDate,
        episode: episode,
        epUrlId: showUrl,
        task_list_items: topics,
        history: history,
      };

      if (shareid === null) {
        updateShareId(showId);
      }
      //const updatedShow = addToHistory(showData, topics, "update");
      //console.log("SET SHOW DATA", showData);
      setData(showData);
      eventTracker({ category: "APP_BTN", action: "Save Show" });
      /* eventTracker({ category: "APP_BTN", action: "New Segment" });
    ReactGA.event({
      category: "User Interction",
      action: "Save Show",
    }); */
    };

    const onTopicUpdate = (index: number, topicData: TopicData) => {
      setTopics((prevTopics: TopicDataArray) =>
        prevTopics.map((topic, i) =>
          i === index ? { ...topic, ...topicData } : topic
        )
      );
      if (
        index === currentTopic &&
        isShowInProgress &&
        storageConfig !== "LOCAL"
      ) {
        if (isLoggedIn) {
          const postData = {
            showid: showId,
            topicid: currentTopic,
            showData: topicData?.topic,
            topicstart: topicStartTime,
            topicData: topics,
          };

          axios
            .post("/api/v1/writecurrenttopic", postData, getBearerHeaders())
            .then(() => {});
        }
      }
      setHasBeenModified(true);
    };

    const shareAlert = () => {
      const labels = {
        title: t("unavailable"),
        message: t("share_unavailable_msg"),
        confirmLabel: t("upgrade_confirm"),
        cancelLabel: t("cancel"),
        onConfirm: () => navigate("/upgrade"),
        onCancel: () => setIsAlertVisible(false),
        confirmClass: "bg-green-700 hover:bg-greenhover",
        cancelClass: "bg-blue hover:bg-blue2",
      };
      setAlertLabels(labels);
      setIsAlertVisible(true);
    };

    const saveShowAlert = () => {
      const labels = {
        title: t("show_not_saved"),
        message: t("show_not_saved_msg"),
        confirmLabel: t("confirm_ok"),
        onConfirm: () => setIsAlertVisible(false),
        confirmClass: "bg-green-700 hover:bg-greenhover",
      };
      setAlertLabels(labels);
      setIsAlertVisible(true);
    };

    const alertProFeature = (feature?: string) => {
      const featureText = feature ? feature : "This";
      const labels = {
        title: t("unavailable"),
        message: t("feature_upgrade", { feature: featureText }),
        confirmLabel: t("upgrade_confirm"),
        cancelLabel: t("cancel"),
        onConfirm: () => navigate("/upgrade"),
        onCancel: () => setIsAlertVisible(false),
        confirmClass: "bg-green-700 hover:bg-greenhover",
        cancelClass: "bg-blue hover:bg-ssblueHove",
      };
      setAlertLabels(labels);
      setIsAlertVisible(true);
    };

    const updateTickerOptions = (prefix, suffix, seperator) => {
      setTickerPrefix(prefix);
      setTickerSuffix(suffix);
      setTickerSeperator(seperator);
    };

    const onPrompterRequest = (show: boolean, script: string) => {
      if (user.accounttype === "pro") {
        if (show) {
          setPrePrompterPos(window.scrollY);
          window.scrollTo(0, 0);
        } else {
          window.scrollTo(0, prePrompterPos);
        }
        setisPrompterShowing(show);
        setPrompterScript(script);
      } else {
        alertProFeature("Teleprompter");
      }
    };

    const processDelete = () => {
      if (!isLoggedIn) {
        DeleteFromLocalStorage("show");
        newShow();
      } else {
        const postData = { showid: showId };
        axios
          .post("/api/v1/deleteshow", postData, getBearerHeaders())
          .then((response) => {
            if (response.data.success) {
              SaveToLocalStorage("lvs", -1);
              setShowTitle(defaultShowTitleLabel);
              setShowDate(defaultShowDate);
              setEpidsode(defaultEpisodeLabel);
              setCurrentTopic(-1);
              setSelectedShow(-1);
              setShowId(-1);
              setTopics([]);
              getAllShows();
            }
          });
      }
      setIsAlertVisible(false);
    };

    const deleteShow = () => {
      const labels = {
        title: t("are_you_sure"),
        message: t("delete_show_msg"),
        confirmLabel: t("delete_confirm_msg"),
        cancelLabel: t("cancel"),
        headerClass: "darkred",
        bgClass: "darkred",
        onConfirm: () => processDelete(),
        onCancel: () => setIsAlertVisible(false),
        confirmClass: "bg-darkred hover:bg-red-500",
        cancelClass: "bg-blue hover:bg-blue2",
      };
      setAlertLabels(labels);
      setIsAlertVisible(true);
    };

    const onTopicDelete = (index: number) => {
      const modifiedTopics = [...topics];
      modifiedTopics.splice(index, 1);
      setTopics(modifiedTopics);
      setHasBeenModified(true);
    };

    const onTeleprompterSettings = (speed: number, textsize: number) => {
      const scrollSettings = {
        speed,
        textsize,
      };
      SaveToLocalStorage("scs", JSON.stringify(scrollSettings));
      setScrollSettings(scrollSettings);
    };

    const startShow = () => {
      if (!showHasEnded && showId !== -1 && topics.length > 0) {
        const now = new Date();
        settopicStartTime(Number(now));
        setCurrentTopic((last) => last + 1);
        setIsReorderEnabled(true);
        //setShowProgressLabel(storageConfig === "LOCAL" ? "LIVE" : "STREAMING");

        const currentTime = new Date();
        setShowStartTime(Number(currentTime));
        setStartTime(dateToTime(currentTime));
        setShowInProgress(true);
        if (elapsedTime === "00:00:00") {
          setElapsedTime("00:00:01");
        }
        eventTracker({ category: "APP_BTN", action: "Start Show" });
        /* ReactGA.event({
        category: "User Interction",
        action: "Start Show",
      }); */
      } else if (showId === -1) {
        const labels = {
          title: "Unable to start show",
          message: "No show is currently selected. Please select a show.",
          confirmLabel: "Ok",
          confirmClass: "bg-blue hover:bg-blue2",
          onConfirm: () => setIsAlertVisible(false),
          onCancel: () => setIsAlertVisible(false),
        };
        setAlertLabels(labels);
        setIsAlertVisible(true);
      } else if (topics.length === 0) {
        const labels = {
          title: "Unable to start show",
          message:
            "This show currently has no segments. Please add a at least 1 segment before starting a show.",
          confirmLabel: "Ok",
          confirmClass: "bg-blue hover:bg-blue2",
          onConfirm: () => setIsAlertVisible(false),
          onCancel: () => setIsAlertVisible(false),
        };
        setAlertLabels(labels);
        setIsAlertVisible(true);
      } else {
        const labels = {
          title: "Unable to start show",
          message:
            'This show has already ended. To re-start the show you will need to "Reset Segments". NOTE: Reseting segments will erase all previous show times.',
          confirmLabel: "Ok",
          confirmClass: "bg-blue hover:bg-blue2",
          onConfirm: () => setIsAlertVisible(false),
          onCancel: () => setIsAlertVisible(false),
        };
        setAlertLabels(labels);
        setIsAlertVisible(true);
      }
    };

    const confirmShowEnd = () => {
      const currentTime = new Date();
      setShowEndTime(Number(currentTime));
      setCurrentTopic(-1);
      setIsAlertVisible(false);
      setPrepShowEnd(true);
      setLastSessionShowLoaded(-1);
      setShowInProgress(false);

      const showdataUpdate = {
        ...fullShowData,
        endtime: Number(currentTime),
        starttime: showStartTime,
      };

      setFullShowData(showdataUpdate);
      saveShow();

      eventTracker({ category: "APP_BTN", action: "End Show" });
    };

    const endShow = () => {
      const labels = {
        title: "Ending your Show?",
        message: "Do you really want to end your show?",
        confirmLabel: "Yes, End Show",
        cancelLabel: "No, Continue",
        headerClass: "darkred",
        bgClass: "darkred",
        onConfirm: () => confirmShowEnd(),
        onCancel: () => setIsAlertVisible(false),
        confirmClass: "bg-darkred hover:bg-red-50",
        cancelClass: "bg-blue hover:bg-ssblueHover",
      };
      setAlertLabels(labels);
      setIsAlertVisible(true);
    };

    const nextTopic = () => {
      if (currentTopic < topics.length) {
        const modifiedTopics = [...topics];
        if (currentTopic !== -1) {
          modifiedTopics[currentTopic].is_complete = true;
        }

        const now = new Date();
        setTopics(modifiedTopics);
        setLastTopic(currentTopic);
        settopicStartTime(Number(now));
        setCurrentTopic((last) => last + 1);
        if (onNextTopic) {
          onNextTopic(currentTopic + 1);
        }
        eventTracker({ category: "APP_BTN", action: "Next Segment" });
      }
    };

    const setCurrentTopicFromComponent = (index: number) => {
      const modifiedTopics = [...topics];
      if (currentTopic !== -1) {
        modifiedTopics[currentTopic].is_complete = true;
      }
      const now = new Date();
      settopicStartTime(Number(now));
      setTopics(modifiedTopics);
      setLastTopic(currentTopic === -1 ? 0 : currentTopic);
      createTickerString();
      setCurrentTopic(index);
      eventTracker({ category: "APP_BTN", action: "Set Topic" });
    };

    const prevTopic = () => {
      if (currentTopic > -1) {
        createTickerString();
        setCurrentTopic((last) => last - 1);
        const now = new Date();
        settopicStartTime(Number(now));
        if (onPrevTopic) {
          onPrevTopic(currentTopic - 1);
        }
        eventTracker({ category: "APP_BTN", action: "Prev Segment" });
      }
    };

    const handleInputChange = (value: string | number, field: string) => {
      switch (field.toUpperCase()) {
        case "EPISODE-NUMBER":
        case "EPISODE":
          setEpidsode(value);
          break;
        case "SHOW-TITLE":
        case "SHOWTITLE":
          setShowTitle(value);
          break;
        case "SHOW-DATE":
        case "SHOWDATE":
          setShowDate(value);
          break;
        case "SHOW-URL":
        case "SHOWURL":
          setShowUrl(value);
          break;
      }
      setHasBeenModified(true);
    };

    const refresShow = () => {
      getAllShows();
    };

    const confirmSegmentReset = () => {
      const updatedData = [...topics];
      updatedData.forEach((item) => {
        item.is_complete = false;
        item.start_time = null;
        item.end_time = null;
      });
      setTopics(updatedData);
      setHasBeenModified(true);
      setIsAlertVisible(false);
      setShowHasEnded(false);
      setShowStartTime(0);
      setShowEndTime(null);
      setPrepShowEnd(true);
      setElapsedTime("--:--:--");
      eventTracker({ category: "APP_BTN", action: "Reset Segments" });
    };
    const resetCompleteTopics = () => {
      const labels = {
        title: "Are you sure?",
        message:
          "Resetting all segments will erase all previous time data. This can not be undone once you re-save your show.",
        confirmLabel: "Yes, Reset Now!",
        cancelLabel: "Cancel",
        headerClass: "darkred",
        onConfirm: () => confirmSegmentReset(),
        onCancel: () => setIsAlertVisible(false),
        confirmClass: "bg-darkred hover:bg-red-500",
        cancelClass: "bg-blue hover:bg-ssblueHover",
      };
      setAlertLabels(labels);
      setIsAlertVisible(true);
    };

    const showStats = () => {
      setIsStatsShowing(isStatsShowing ? false : true);
      eventTracker({ category: "APP_BTN", action: "Show Stats" });
    };

    const createTickerString = () => {
      if (currentTopic < topics.length) {
        const nonEmptyTickerValues = topics
          .filter((topic) => topic.ticker_label) // Filters out objects with empty or falsy names
          .map((topic) => topic.ticker_label);
        const hasSeperator =
          tickerSeperator.trim().length > 0 ? tickerSeperator : "•";
        const tickerString = nonEmptyTickerValues.join(` ${hasSeperator} `);
        const currentTopicForTicker = isShowInProgress
          ? `${hasSeperator} [ Current Topic: ${
              currentTopic !== -1 ? topics[currentTopic].topic : "..."
            } ] ${hasSeperator} `
          : hasSeperator;

        const hasPrefix =
          tickerPrefix.trim().length > 0
            ? `${tickerPrefix} ${hasSeperator}`
            : "";
        const hasSuffix =
          tickerSuffix.trim().length > 0
            ? `${hasSeperator} ${tickerSuffix}`
            : "";
        const finalTicker = `${currentTopicForTicker} ${hasPrefix} ${tickerString} ${hasSuffix} ${hasSeperator} `;

        setTickerText(finalTicker);
      }
    };

    const loadLocalShow = (data: Topics) => {
      if (data) {
        setShowTitle(data.showTitle);
        setShowDate(data.show_date);
        setEpidsode(data.episode);
        setTopics(data.task_list_items);
        setShowId(-2);
        //setSaveShowTitle(`${data.episode}-${data.showTitle}`);
        setCurrentTopic(-1);
        createTickerString();
      }
    };

    const getAllShows = (callback?) => {
      if (!isLoggedIn) {
        const showData = GetFromLocalStorage("show");
        if (showData !== null) {
          loadLocalShow(JSON.parse(showData));
        }
      } else {
        axios
          .post("/api/v1/getshows", {}, getBearerHeaders())
          .then((response) => {
            const showResultData = response.data.items.filter((show: any) => {
              const parsedShow =
                typeof show.show === "string"
                  ? JSON.parse(show.show)
                  : show.show;
              return show.show !== null && parsedShow !== null;
            });

            if (lastSessionShowLoaded <= 0) {
              const lastViewedShow = GetFromLocalStorage("lvs");
              if (!prepShowEnd) {
                setElapsedTime("--:--:--");
              }
              setPrepShowEnd(false);
              setSelectedShow(Number(lastViewedShow));
              setLastSessionShowLoaded(1);
              //newShow();
            } else {
              //setSelectedShow(-1);
            }
            setShows(showResultData);
            if (callback) {
              callback();
            }
          });
      }
    };

    const getSourceId = async () => {
      const sourceData = axios.post(
        "/api/v1/getsourceid",
        {},
        getBearerHeaders()
      );
      const res = await sourceData;
      setSourceid(res.data.items.sourceid);
    };

    const toggleChat = () => {
      setChatOpen(!chatOpen);
    };

    const chyronToggle = () => {
      if (websocketConnected) {
        const jwtToken = Cookies.get("jwt");
        setChyronVisible(!chyronVisible);
        websocket.emit(
          "obs-chyron-toggle",
          JSON.stringify({
            id: user.id,
            visible: !chyronVisible,
            token: jwtToken,
          })
        );
      }
    };

    const rundownToggle = () => {
      if (websocketConnected) {
        const jwtToken = Cookies.get("jwt");
        setRundownVisible(!rundownVisible);
        websocket.emit(
          "obs-rundown-toggle",
          JSON.stringify({
            id: user.id,
            visible: !rundownVisible,
            token: jwtToken,
          })
        );
      }
    };

    /* const onWelcomeClose = () => {
      const data = { viewed: true };
      SaveToLocalStorage("welcome-viewed", JSON.stringify(data));
      setIsWelcomeMsgViewed(true);
      saveShow();
    }; */

    const onDragEnd = (result: any) => {
      if (!result.destination) return;

      const newItems = [...topics];
      const [reorderedItem] = newItems.splice(result.source.index, 1);
      newItems.splice(result.destination.index, 0, reorderedItem);

      setTopics(newItems);
      setHasBeenModified(true);
    };

    const writeCurrentTopic = () => {
      if (isLoggedIn) {
        const triggerTopic = isShowInProgress ? currentTopic : 0;
        const postData = {
          showid: topicTickerId,
          showData: {
            topic: topics[triggerTopic]?.topic,
            topicid: triggerTopic,
            showid: showId,
            topicstart: topicStartTime,
            starttime: showStartTime,
            endtime: showEndTime,
          },
          topicData: topics,
        };

        if (!showHasEnded) {
          apiWriteTopic(postData, getBearerHeaders()).then(() => {});
        }
      }
    };

    const onShowLoadTrigger = () => {
      if (isLoggedIn) {
        const triggerTopic = topics.length > 0 ? 0 : -1;
        const postData = {
          showid: topicTickerId,
          showData: {
            topic: topics[triggerTopic]?.topic,
            topicid: triggerTopic,
            showid: showId,
            topicstart: topicStartTime,
            starttime: showStartTime,
            endtime: showEndTime,
          },
          topicData: topics,
        };

        apiWriteTopic(postData, getBearerHeaders()).then(() => {});
      }
    };

    const handleObsWs = (data) => {
      setObsWssData(data);
    };

    const hostAShow = () => {
      SaveToLocalStorage("lvs", selectedShow);
      navigate(`/studio/${user.roomid}`);
    };

    useImperativeHandle(
      ref,
      () => {
        return {
          startShow,
        };
      },
      []
    );

    useEffect(() => {
      if (onShareIdChange) {
        if (shareid) {
          onShareIdChange(shareid);
        }
      }
    }, [shareid]);

    useEffect(() => {
      createTickerString();
    }, [tickerPrefix, tickerSuffix, tickerSeperator]);

    useEffect(() => {
      const config = {
        ...scrollSettings,
        tickerPrefix,
        tickerSuffix,
        tickerSeperator,
      };
      axios;
      apiUpdateConfig({ config }, getBearerHeaders()).then(() => {});
    }, [tickerPrefix, tickerSuffix, tickerSeperator, scrollSettings]);

    useEffect(() => {
      if (obsWssData) {
        if (obsWssData.userid === user.id || obsWssData.sourceid === sourceid) {
          switch (obsWssData.eventtype.toUpperCase()) {
            case "STATECHANGE":
              setIsObsConnected(obsWssData.isconnected);
              setIsObsStreaming(obsWssData.isstreaming);
              setIsObsRecording(obsWssData.isrecording);
              setSelectedScene(obsWssData.currentscene);
              break;

            case "GETSCENELIST":
              setScenes(obsWssData.obsdata);
              setSelectedScene(obsWssData.currentscene);
              break;

            case "RUNDOWNTOGGLE":
              setRundownVisible(obsWssData.visible);
              break;

            case "CHYRONTOGGLE":
              setChyronVisible(obsWssData.visible);
              break;

            case "CURRENTPROGRAMSCENECHANGED":
              setSelectedScene(obsWssData.currentscene);
              break;
          }
        }
      }
    }, [obsWssData]);

    useEffect(() => {
      if (goLive) {
        startShow();
      } else {
        if (isShowInProgress) {
          endShow();
        }
      }
    }, [goLive]);

    useEffect(() => {
      if (websocketConnected && sourceid) {
        websocket.emit("bs-ping", {
          sourceid: sourceid,
        });
      }

      if (sourceid && onSourceId) {
        onSourceId(sourceid);
      }
    }, [sourceid]);

    useEffect(() => {
      getAllShows();
    }, [isLoggedIn]);

    /* useEffect(() => {
    const timeToUse = showStartTime !== 0 ? showStartTime : showDate;
    //setStartOrShowTime(timeToUse);
  }, [showStartTime, showDate]); */

    useEffect(() => {
      const estTime = topics.reduce(
        (total, topic) => total + Number(topic.topic_time),
        0
      );
      setEstimatedShowTime(estTime);
      createTickerString();
    }, [topics]);

    useEffect(() => {
      if (currentTopic !== -1 && topics[currentTopic]) {
        if (isShowInProgress) {
          createTickerString();
        }

        writeCurrentTopic();
      }
    }, [currentTopic]);

    useEffect(() => {
      if (isLoggedIn) {
        const postData = {
          showid: topicTickerId,
          showData: tickerText,
          topicData: topics,
        };
        axios
          .post("/api/v1/writeticker", postData, getBearerHeaders())
          .then(() => {});
      } else {
        SaveToLocalStorage("ticker", tickerText);
      }
    }, [tickerText]);

    useEffect(() => {
      if (data?.task_list_items) {
        if (inExperiment) {
          aiTopics(data).then((response: any) => {
            console.log(
              "Suggestions",
              response.items.candidates[0].content.parts[0].text
            );
            setChatBotText(response.items.candidates[0].content.parts[0].text);
          });
        }

        if (isLoggedIn) {
          writeCurrentTopic();
          const apiPath =
            showId !== -1 ? "/api/v1/saveshow" : "/api/v1/createshow";
          const actionType = data?.showid !== -1 ? "UPDATE" : "CREATE";
          const postData = { showid: showId, showData: data };

          axios.post(apiPath, postData, getBearerHeaders()).then((response) => {
            if (actionType === "CREATE" && response.data.success) {
              const resShowId = response.data.items;

              setSelectedShow(shows.length);
              if (typeof resShowId === "number") {
                setShowId(resShowId);
              }

              saveShow();
            }

            getAllShows();
            setHasBeenModified(false);
          });

          //make sure endshow is null
          if (prepShowEnd) {
            const saveData = {
              showid: showId,
              showData: isShowInProgress,
              starttime: showStartTime,
              endtime: showEndTime,
            };
            setLastSessionShowLoaded(0);

            axios
              .post("/api/v1/setshowlive", saveData, getBearerHeaders())
              .then(() => {
                setPrepShowEnd(false);
                setLastSessionShowLoaded((prev) => prev - 1);
                getAllShows();
              });
          }
        } else {
          SaveToLocalStorage("show", data);
          setShowId(-2);
          setHasBeenModified(false);
        }
      }
    }, [data]);

    useEffect(() => {
      let timerInt: NodeJS.Timeout;
      if (isShowInProgress) {
        if (onElapsedTime !== undefined) {
          onElapsedTime(elapsedTime);
        }

        timerInt = setTimeout(() => {
          const now = new Date();
          const elapsed = calculateTimeDuration(startTime, dateToTime(now));
          setElapsedTime(elapsed);
        }, 1000);
      }
      return () => {
        clearTimeout(timerInt);
      };
    }, [elapsedTime]);

    useEffect(() => {
      if (onShowIdChange) {
        onShowIdChange(showId);
      }
    }, [showId]);

    useEffect(() => {
      if (isLoggedIn) {
        const postData = {
          showid: showId,
          showData: isShowInProgress,
          starttime: showStartTime,
          endtime: prepShowEnd ? showEndTime : null,
        };
        if (!showHasEnded) {
          axios
            .post("/api/v1/setshowlive", postData, getBearerHeaders())
            .then(() => {
              setPrepShowEnd(false);
              if (prepShowEnd) {
                setShowHasEnded(true);
              }
            });
        }
      }
      if (isShowInProgress) {
        const now = new Date();
        const elapsed = calculateTimeDuration(startTime, dateToTime(now));
        setElapsedTime(elapsed);
      }
      if (onShowStatusChange !== undefined) {
        onShowStatusChange(isShowInProgress);
      }
    }, [isShowInProgress]);

    useEffect(() => {
      if (selectedShow > -1) {
        SaveToLocalStorage("lvs", selectedShow);
        if (lastSelectedShow !== selectedShow) {
          loadShow(selectedShow);
          setLastSelectedShow(selectedShow);
        } else {
        }
      } else {
        //newShow();
      }
    }, [selectedShow]);

    const handleLoginCheck = () => {
      const minuteInterval = 5;
      setTimeout(() => {
        axios
          .post("/api/isauthenticated", {}, getBearerHeaders())
          .then((response) => {
            setIsLoggedIn(response.data.success);

            if (!response.data.success) {
              hasCookieExpired();
            } else {
              handleLoginCheck();
            }
          });
      }, minuteInterval * 60 * 1000);
    };

    useEffect(() => {
      if (isLoggedIn && websocket) {
        websocket.connect();
        websocket.on("connect", () => {
          setWebSocketId(websocket.id);
          websocket.on("obs-wss", handleObsWs);

          setWebsocketConnected(true);
        });

        websocket.on("disconnect", () => {
          websocket.off("obs-wss", handleObsWs);
          setWebsocketConnected(false);
        });
      }
    }, [websocket]);

    useEffect(() => {
      const showdataUpdate = { ...fullShowData, endtime: showEndTime };
      setFullShowData(showdataUpdate);
    }, [showEndTime]);

    useEffect(() => {
      onShowLoadTrigger();
    }, [fullShowData]);

    useEffect(() => {
      const getIsViewed = GetFromLocalStorage("welcome-viewed");
      if (!getIsViewed || !JSON.parse(getIsViewed).viewed) {
        //setIsWelcomeMsgViewed(false);
        // addSampleData();
      }
      const fetchData = async () => {
        if (isLoggedIn) {
          setWebSocket(io(getServer() + queryparam));
          getAllShows();
          handleLoginCheck();
          await getSourceId();

          const connections = await axios.post(
            "/api/v1/getobsconnections",
            {},
            getBearerHeaders()
          );
          const connectionResults = await connections;
          if (connectionResults.data.success) {
            if (connectionResults.data.items) {
              const {
                currentscene,
                isconnected,
                isrecording,
                isstreaming,
                obsdata,
              } = connectionResults.data.items;
              setIsObsConnected(isconnected);
              setIsObsRecording(isrecording);
              setIsObsStreaming(isstreaming);
              setSelectedScene(currentscene);
              setScenes(obsdata);
            }
          }
        }
      };

      const scrollSettings = GetFromLocalStorage("scs");
      if (scrollSettings) {
        setScrollSettings(JSON.parse(scrollSettings));
      }

      axios
        .post("/api/v1/getTopicTickerId", {}, getBearerHeaders())
        .then((response) => {
          if (response.data.success) {
            setTopicTickerId(response.data.items[0].id);
          }
        });
      fetchData();
      const queryParams = new URLSearchParams(location.search);
      const nsValue = queryParams.get("ns");

      if (nsValue === "t") {
        newShow();
      }
      eventTracker({ category: "PAGE_LOAD", action: "SHOPRO_APP" });
      document.addEventListener("visibilitychange", handleVisibilityChange);

      return () => {
        if (isLoggedIn) {
          if (websocketConnected) {
            websocket.off("obs-wss", handleObsWs);
            websocket.disconnect();
          }
        }
        document.removeEventListener(
          "visibilitychange",
          handleVisibilityChange
        );
      };
    }, []);

    return (
      <>
        {isPrompterShowing && (
          <Teleprompter
            script={prompterScript}
            onTeleprompterSettings={onTeleprompterSettings}
            onClose={() => onPrompterRequest(false, prompterScript)}
            settings={scrollSettings}
            elapsedTopicTime={elapsedTopicTimeFromCard}
          />
        )}

        <CardEditor
          isEditing={isEditingCard}
          onCancelEdit={setIsEditingCard}
          cardInfo={cardToEdit}
          onSaveCard={onTopicUpdate}
        />

        <ShowEditor
          isEditing={isEditingShow}
          onCancelEdit={setIsEditingShow}
          showData={{ episode, showTitle, showDate, showUrl }}
          onSaveShow={handleInputChange}
        />
        {useAppHeader ? (
          <Header
            islive={isShowInProgress}
            headerSectionLabel="PLANNER | BETA"
          />
        ) : null}

        {fullShowData && (
          <ShowStats
            isStatsOpen={isStatsShowing}
            key="stats"
            topics={topics}
            onClose={showStats}
            showdata={fullShowData}
          />
        )}

        <ShareWindow
          isOpen={showShare}
          showid={showId}
          shareid={shareid}
          close={() => setShowShare(false)}
          onshareId={setShareid}
          onSharePassUpdate={updateShowPass}
          pass={
            fullShowData && fullShowData.password ? fullShowData.password : ""
          }
        />

        {isLoading && !isInControlCenter && <LoadingSpinner />}

        <CustomAlert
          isAlertVisible={isAlertVisible}
          title={alertLabels?.title}
          message={alertLabels?.message}
          confirmLabel={alertLabels?.confirmLabel}
          cancelLabel={alertLabels?.cancelLabel}
          onConfirm={alertLabels?.onConfirm}
          onCancel={alertLabels?.onCancel}
          cancelClass={alertLabels?.cancelClass}
          confirmClass={alertLabels?.confirmClass}
          headerClass={alertLabels?.headerClass}
          bgClass={alertLabels?.bgClass}
        />

        <div
          id="action-wrap-ctrls"
          className={
            isShowInProgress
              ? ` live ${
                  isInControlCenter
                    ? "studio p-0.5 mt-0 mb-0.5 sticky top-0 z-10 bg-[#ff002a]"
                    : "flex flex-col sm:flex-row px-1 pt-1 sm:py-2 mb-1 sm:mb-2 sticky top-0 z-10 bg-[#ff002a] gap-x-1"
                }`
              : ` ${
                  isInControlCenter
                    ? "studio flex flex-col sticky top-0 z-10 bg-[#050f19]"
                    : "flex flex-col sm:flex-row gap-x-1 px-1 pt-1 pb-0.5"
                }`
          }
        >
          <div
            id="action-wrap-ctrls-1"
            className={`grid grid-cols-${
              isInControlCenter ? (isShowInProgress ? "3" : "2") : "3"
            } gap-x-1 pb-0.5 mb-0.5 sm:pb-1 md:pb-0`}
          >
            {!isShowInProgress && (
              <>
                {isInControlCenter !== true && (
                  <button
                    className={`${tw.btn.action} bg-grey hover:bg-greyHover mr-0`}
                    onClick={!hasCookieExpired() ? newShow : null}
                  >
                    {t("new")} <span className="mobile-hide">{t("show")}</span>
                  </button>
                )}
                <button
                  className={
                    hasBeenModified
                      ? `${tw.btn.action} bg-green1 hover:bg-green1Hover text-black mr-0 `
                      : `${tw.btn.action} bg-disabled text-gray-500 mr-0 `
                  }
                  onClick={() => (hasBeenModified ? saveShow() : null)}
                >
                  <FontAwesomeIcon className="text-xs" icon={faFloppyDisk} />{" "}
                  {t("save")} <span className="mobile-hide">{t("show")}</span>
                </button>
                <button
                  className={
                    showHasEnded || showId === -1
                      ? `${tw.btn.action} bg-disabled text-gray-500 mr-0 m-0`
                      : `${tw.btn.action} bg-yellow-600 hover:bg-yellow-800 text-black mr-0 m-0`
                  }
                  onClick={() => {
                    !hasCookieExpired() || showId !== -1 ? startShow() : null;
                  }}
                >
                  {t("start")} <span className="mobile-hide">{t("show")}</span>
                </button>
              </>
            )}
            {isShowInProgress && (
              <>
                <button
                  className={`${tw.btn.action} bg-gray-700 hover:bg-darkgreen text-white mr-0`}
                  onClick={() => prevTopic()}
                >
                  <FontAwesomeIcon className="text-xs" icon={faBackwardStep} />{" "}
                  {t("prev")}{" "}
                  {isInControlCenter !== true && (
                    <span className="mobile-hide">{t("segment")}</span>
                  )}
                </button>
                <button
                  className={`${tw.btn.action} bg-gray-700 hover:bg-darkgreen text-white mr-0 text-xs`}
                  onClick={() => nextTopic()}
                >
                  {t("next")}{" "}
                  {isInControlCenter !== true && (
                    <span className="mobile-hide">{t("segment")} </span>
                  )}
                  <FontAwesomeIcon icon={faForwardStep} />
                </button>
                <button
                  className={`${tw.btn.action} bg-red hover:bg-redHover text-white mr-0 text-xs`}
                  onClick={() => endShow()}
                >
                  {t("end")} <span className="mobile-hide">{t("show")}</span>
                  {isInControlCenter !== true ? `- ${elapsedTime}` : ""}
                </button>
              </>
            )}
            {isObsConnected ? (
              <>
                <button
                  className={
                    isSceneSelectorActive
                      ? "${tw.btn.action} bg-green-700 text-black mr-0 "
                      : "${tw.btn.action} bg-gray-600 text-black mr-0"
                  }
                  onClick={() =>
                    setIsSceneSelectorActive(
                      isSceneSelectorActive ? false : true
                    )
                  }
                >
                  OBS <span className="mobile-hide">CTRL</span>
                </button>
              </>
            ) : null}
          </div>
          {isLoggedIn && websocketConnected ? (
            <div
              className={`grid ${
                !isInControlCenter && !isShowInProgress
                  ? "grid-cols-3"
                  : "grid-cols-2"
              } gap-x-1 pb-0.5 mb-0.5 sm:pb-1 md:pb-0`}
              id="action-wrap-obs"
            >
              <button
                className={
                  chyronVisible
                    ? `${tw.btn.action} bg-green1 hover:bg-green1Hover text-black mr-0 `
                    : `${tw.btn.action} bg-gray-700 text-white mr-0 `
                }
                onClick={() => chyronToggle()}
              >
                {chyronVisible ? (
                  <FontAwesomeIcon className="text-xs" icon={faEye} />
                ) : (
                  <FontAwesomeIcon
                    className="red-text text-xs"
                    icon={faEyeSlash}
                  />
                )}{" "}
                {t("chyron")}
              </button>
              <button
                className={
                  rundownVisible
                    ? `${tw.btn.action} bg-green1 hover:bg-green1Hover text-black mr-0 `
                    : `${tw.btn.action} bg-gray-700 text-white mr-0 `
                }
                onClick={() => rundownToggle()}
              >
                {rundownVisible ? (
                  <FontAwesomeIcon className="text-xs" icon={faEye} />
                ) : (
                  <FontAwesomeIcon
                    className="red-text text-xs"
                    icon={faEyeSlash}
                  />
                )}{" "}
                {t("rundown")}
              </button>
              {!isInControlCenter && !isShowInProgress && (
                <button
                  className={`${tw.btn.action} bg-blue-charcoal-500 hover:bg-blue-charcoal-700`}
                  onClick={() => hostAShow()}
                >
                  <FontAwesomeIcon
                    className="red-black text-xs"
                    icon={faCamera}
                  />{" "}
                  {t("dashboard.btn.add_cam")}
                </button>
              )}
            </div>
          ) : null}
        </div>

        <TickerOptions
          isTickerOpen={isTickerOptionsEnabled}
          onClose={setIsTickerOptionsEnabled}
          onTickerOptions={updateTickerOptions}
          prefix={tickerPrefix}
          suffix={tickerSuffix}
          seperator={tickerSeperator}
        />

        {isObsConnected && isSceneSelectorActive ? (
          <SceneSelector
            websocket={websocket}
            scenes={scenes}
            selectedScene={selectedScene}
            isActive={isSceneSelectorActive}
            isRecording={isObsRecording}
            isStreaming={isObsStreaming}
          />
        ) : (
          <div
            className={
              !isPrompterShowing
                ? `px-1 pb-14 ${fullWidthClass}`
                : `px-1 pb-14 prompter-on ${fullWidthClass}`
            }
            key="main"
          >
            <div className="">
              {isInControlCenter !== true ? (
                <div
                  className="grid grid-cols-2 gap-x-1 gap-y-1 sm:flex sm:gap-x-1 max-w-screen-sm "
                  id="action-wrap-shows"
                >
                  <PastShows
                    selectedShow={selectedShow}
                    loadShow={loadShow}
                    shows={shows}
                    onRefresh={refresShow}
                    showid={showId}
                    isShowInProgress={isShowInProgress}
                    eventTracker={eventTracker}
                  />

                  {!isShowInProgress && (
                    <>
                      <button
                        className={`${tw.btn.action} bg-red hover:bg-redHover mr-0 `}
                        onClick={!hasCookieExpired() ? deleteShow : null}
                      >
                        <FontAwesomeIcon icon={faTrashCan} /> {t("delete")}{" "}
                        <span className="mobile-hide">{t("show")}</span>
                      </button>
                    </>
                  )}

                  <button
                    className={`${tw.btn.action} bg-green-700 hover:bg-green-900 mr-0`}
                    onClick={() => {
                      !isLoggedIn
                        ? shareAlert()
                        : !hasCookieExpired()
                        ? showId === -1
                          ? saveShowAlert()
                          : setShowShare(showShare ? false : true)
                        : null;
                    }}
                  >
                    <FontAwesomeIcon icon={faShareNodes} /> Share{" "}
                    <span className="mobile-hide">{t("show")}</span>
                  </button>

                  {!isShowInProgress && (
                    <button
                      className={`${tw.btn.action} bg-grey hover:bg-greyHover mr-0`}
                      onClick={!hasCookieExpired() ? showStats : null}
                    >
                      <FontAwesomeIcon icon={faChartSimple} />{" "}
                      <span className="mobile-hide">{t("show")}</span>{" "}
                      {t("stats")}
                    </button>
                  )}
                  {inExperiment && chatBotText && (
                    <button
                      className={`${tw.btn.action} bg-grey hover:bg-greyHover mr-0`}
                      onClick={() => setShowChatBot(!showChatBot)}
                    >
                      <FontAwesomeIcon icon={faRobot} /> Chat Bot
                    </button>
                  )}
                </div>
              ) : (
                <>
                  {!isShowInProgress && (
                    <div
                      className={`${
                        isInControlCenter
                          ? "grid grid-cols-2 gap-x-1"
                          : "grid grid-cols-2 sm:flex gap-x-1"
                      }`}
                    >
                      <PastShows
                        selectedShow={selectedShow}
                        loadShow={loadShow}
                        shows={shows}
                        onRefresh={refresShow}
                        showid={showId}
                        isShowInProgress={isShowInProgress}
                        eventTracker={eventTracker}
                      />
                      <button
                        className={`${tw.btn.action} bg-red hover:bg-redHover`}
                        onClick={
                          !hasCookieExpired() ? resetCompleteTopics : null
                        }
                      >
                        <FontAwesomeIcon icon={faArrowsRotate} /> {t("reset")}{" "}
                        <span className="mobile-hide">
                          {t("segment", { count: 0 })}
                        </span>
                      </button>
                    </div>
                  )}
                </>
              )}
              <div
                className={`${
                  isInControlCenter
                    ? "flex flex-col mb-3"
                    : "flex flex-col sm:flex-row"
                } p-2 bg-ssblue gap-2 mt-1 ${fullWidthClass}`}
              >
                <div
                  className={`${
                    isInControlCenter
                      ? "w-full"
                      : "w-full sm:w-1/2 lg:w-3/12 sm:border-r sm:border-white"
                  }`}
                >
                  <span className="text-sm block">
                    {t("episode_label")}: {episode}
                  </span>
                  <span className="text-4xl block">{showTitle}</span>

                  <span className="text-sm block">
                    {dateAndTimeDisplay(new Date(showDate))}
                  </span>

                  <span className="block text-md">{showUrl}</span>
                  <br />
                  {!isInControlCenter ? (
                    <button
                      className={`${tw.btn.action} bg-blue hover:bg-blueHover text-white mr-1`}
                      onClick={() => setIsEditingShow(!isEditingShow)}
                    >
                      <FontAwesomeIcon icon={faPen} /> {t("edit_details")}
                    </button>
                  ) : null}
                  {user.accounttype.toUpperCase() === "PRO" &&
                  !isInControlCenter ? (
                    <button
                      className={`${tw.btn.action} bg-grey hover:bg-greyHover text-white`}
                      onClick={() =>
                        setIsTickerOptionsEnabled(!isTickerOptionsEnabled)
                      }
                    >
                      <FontAwesomeIcon icon={faGears} />{" "}
                      {t("buttons.ticker_options")}
                    </button>
                  ) : null}
                </div>
                <div
                  className={`${
                    isInControlCenter
                      ? "w-full"
                      : "w-full sm:w-1/2 lg:w-9/12 sm:pl-5"
                  }`}
                >
                  <ShowTime
                    isLive={isShowInProgress}
                    showHasEnded={showHasEnded}
                    showDate={showDate}
                    elapsed={elapsedTime}
                    startTime={showStartTime}
                    endTime={showEndTime}
                    estimatedShowTime={estimatedShowTime}
                    topics={topics}
                  />
                </div>
              </div>

              <div className="show-controls" key="show-controls">
                <>
                  <button
                    key="new-topic"
                    className={
                      !showHasEnded
                        ? `${tw.btn.action} bg-green-700 hover:bg-greenHover mr-1`
                        : `${tw.btn.action} bg-disabled mr-1`
                    }
                    onClick={() => {
                      !showHasEnded
                        ? !hasCookieExpired()
                          ? newTopic()
                          : null
                        : null;
                    }}
                  >
                    <FontAwesomeIcon icon={faSquarePlus} />{" "}
                    <span className="mobile-hide">{t("new")}</span>{" "}
                    {t("segment")}
                  </button>
                  {isInControlCenter !== true && (
                    <>
                      <button
                        key="reOrder"
                        className={
                          showHasEnded
                            ? `${tw.btn.action} bg-disabled mr-1`
                            : isReorderDragDisabled
                            ? `${tw.btn.action} bg-blue hover:bg-blueHover mr-1`
                            : `${tw.btn.action} bg-red hover:bg-redHover mr-1`
                        }
                        onClick={() =>
                          !showHasEnded
                            ? !hasCookieExpired()
                              ? setIsReorderEnabled(!isReorderDragDisabled)
                              : null
                            : null
                        }
                      >
                        <FontAwesomeIcon icon={faSort} />{" "}
                        {isReorderDragDisabled ? t("sort") : t("unsort")}
                      </button>
                      {!isShowInProgress && (
                        <>
                          <button
                            className={`${tw.btn.action} bg-red hover:bg-redHover`}
                            onClick={
                              !hasCookieExpired() ? resetCompleteTopics : null
                            }
                          >
                            <FontAwesomeIcon icon={faArrowsRotate} />{" "}
                            {t("reset")}{" "}
                            <span className="mobile-hide">
                              {t("segment", { count: 0 })}
                            </span>
                          </button>
                        </>
                      )}
                    </>
                  )}
                </>
              </div>

              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="topics" key="topics">
                  {(provided) => (
                    <div
                      className={`grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-1 sm:gap-3 topics altcard ${fullWidthClass} ${
                        isReorderDragDisabled ? "" : "reorder"
                      }`}
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      {topics.length ? (
                        topics.map((topic, index) => (
                          <Draggable
                            key={`topicitem-${index}`}
                            draggableId={`topic-${index}`}
                            index={index}
                            isDragDisabled={isReorderDragDisabled}
                          >
                            {(providedDraggable, snapshot) => (
                              <div
                                className={
                                  isReorderDragDisabled
                                    ? `${tw.card}`
                                    : snapshot.isDragging
                                    ? `${tw.card} full dragging`
                                    : `${tw.card} full`
                                }
                                ref={providedDraggable.innerRef}
                                {...providedDraggable.draggableProps}
                                {...providedDraggable.dragHandleProps}
                              >
                                <Card
                                  key={index}
                                  index={index}
                                  cardData={topic}
                                  onTopicUpdate={onTopicUpdate}
                                  onTopicDelete={onTopicDelete}
                                  setCurrentTopic={setCurrentTopicFromComponent}
                                  currentTopic={currentTopic}
                                  lastTopic={lastTopic}
                                  isComplete={topic.is_complete}
                                  isShowInProgress={isShowInProgress}
                                  startTime={startTime}
                                  topicStartTime={topicStartTime}
                                  onPrompterRequest={onPrompterRequest}
                                  triggerSave={saveShow}
                                  showHasEnded={showHasEnded}
                                  alltopics={topics}
                                  showDate={showDate}
                                  onElapsedChange={setTimeForPrompter}
                                  onProFeature={alertProFeature}
                                  controlCenterRef={controlCenterRef}
                                  isInControlCenter={isInControlCenter}
                                  editCard={editCard}
                                />
                              </div>
                            )}
                          </Draggable>
                        ))
                      ) : (
                        <div className="rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 m-3">
                          <p className="mt-1 text-lg font-semibold text-gray-400">
                            No Segments
                          </p>

                          <button
                            onClick={newTopic}
                            className="mt-5 inline-flex items-center rounded-sm bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 no-underline"
                          >
                            <FontAwesomeIcon className="mr-1" icon={faPlus} />{" "}
                            {t("new")} {t("segment")}
                          </button>
                        </div>
                      )}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          </div>
        )}

        {shareid && !isInControlCenter && (
          <>
            <div
              className={
                chatOpen
                  ? `w-[250px] h-[450px] bottom-0 right-3 fixed border-[#213343] bg-[#202935] z-10 transition-all duration-300 border `
                  : "w-[250px] h-[450px] bottom-[-100%] right-3 fixed border-[#213343] z-10 bg-[#202935] transition-all duration-300 border"
              }
            >
              <Chat
                socket={websocket}
                name={"Khary"}
                roomid={shareid}
                userid={webSocketId}
                chatType="SHOW_CHAT"
                headerLabel="Show Chat"
                closeBtn={toggleChat}
                isOpen={chatOpen}
                chatBotText={chatBotText}
              />
            </div>
            {useChat !== false && (
              <div className="chat-btn fixed z-5 bottom-[60px] right-[20px] text-sm border rounded-full p-2 bg-sky-800 hover:bg-sky-500 transition-colors duration-300 pointer">
                <FontAwesomeIcon icon={faComments} onClick={toggleChat} />
              </div>
            )}
          </>
        )}

        {isInControlCenter !== true && (
          <div className="scrolling-text-container">
            <Marquee className="ticker" key="ticker">
              {tickerText}
            </Marquee>
          </div>
        )}

        {showChatBot && (
          <ChatBotDsp
            prompt={videoPrompt}
            promptResults={chatBotText}
            isOpen={showChatBot}
            onClose={() => setShowChatBot(!showChatBot)}
          />
        )}
      </>
    );
  }
);

export default Shopro;
