import { faCropSimple, faExpand } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useTranslation } from "react-i18next";

const Tools = ({ isCropActive, toggleCrop, toggleAspect, cropType }) => {
  const { t } = useTranslation();

  const toolBtn =
    "rounded-md mr-2 px-2 py-1.5 text-xs bg-transparent pointer leading-none";
  const toolOn =
    "rounded-md mr-2 px-2 py-1.5 text-xs bg-slate-800 pointer leading-none";

  return (
    <div
      className="flex justify-center items-center p-4 border-t border-t-slate-800 "
      id="editor-tools"
    >
      <div className={toolBtn} id="caption-tool">
        <i className="fa-solid fa-closed-captioning"></i>
        <label>{t("captions")}</label>
      </div>
      <button
        className={isCropActive ? `${toolOn} ` : `${toolBtn} `}
        id="crop-tool"
        onClick={toggleCrop}
      >
        <FontAwesomeIcon icon={faCropSimple} /> <label>{t("crop")}</label>
      </button>
      {isCropActive && (
        <>
          <button
            className={cropType === "tall" ? `${toolOn} ` : `${toolBtn} `}
            id="crop-916"
            onClick={toggleAspect}
          >
            <FontAwesomeIcon icon={faExpand} /> <label>9:16</label>
          </button>
          <button
            className={
              cropType === "square_45"
                ? `${toolOn} pointer`
                : `${toolBtn} pointer`
            }
            id="crop-45"
            onClick={toggleAspect}
          >
            <FontAwesomeIcon icon={faExpand} /> <label>4:5</label>
          </button>
        </>
      )}
    </div>
  );
};

export default Tools;
