import React, { useEffect, useState } from "react";
import { getBearerHeaders } from "../../helpers/GetBearerHeaders";
import axios from "axios";
import Header from "../layout/Header";
import User from "./User";
import LoadingSpinner from "../../features/Shopro/components/LoadingSpinner";

const Admin = () => {
  const [allUsers, setUsers] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  useEffect(() => {
    axios
      .post("/api/v1/admin/allusers", {}, getBearerHeaders())
      .then((response) => {
        if (response.data.success) {
          console.log(response.data.items);
          setUsers(response.data.items);
        }
        setIsLoading(false);
      });
  }, []);

  return (
    <>
      <Header />
      {isLoading && <LoadingSpinner />}
      <div className="container w-full  mx-auto mt-6">
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="sm:flex sm:items-center border-b border-b-gray-800 pb-3">
            <div className="sm:flex-auto">
              <h1 className="text-2xl font-semibold leading-6 text-white">
                Users
              </h1>
              <p className="mt-2 text-sm text-white">
                A list of all registered users.
              </p>
            </div>
          </div>
          <div className="mt-8 flow-root">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <table className="min-w-full divide-y divide-gray-800">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-500 sm:pl-0"
                      >
                        ID
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-500"
                      >
                        Email
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-500"
                      >
                        Account Type
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-500"
                      >
                        Total Shows
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-500"
                      >
                        Joined
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-500"
                      >
                        Updated
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-800">
                    {allUsers.length > 0 &&
                      allUsers.map((user, index) => (
                        <User key={index} userdata={user} />
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Admin;
