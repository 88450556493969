import React, { useEffect, useState } from "react";
import { Tooltip } from "react-tooltip";

export interface CamSceneControlProps {
  onNameToggle: (isShowing: boolean) => void;
  onShare: () => void;
  onSceneView: (view: string) => void;
  isUserNameShowing: boolean;
  isSharingScreen: boolean;
  isSoloed: string;
  streams: any;
  onSoloStream?: (userid) => void;
  onUnSoloStream?: (userid) => void;
  onPipStream?: (userid, isPip) => void;
  userid?: string;
}

const CamSceneControl = ({
  onNameToggle,
  onShare,
  isUserNameShowing,
  isSharingScreen,
  streams,
  isSoloed,
  onSceneView,
  onSoloStream,
  onUnSoloStream,
  userid,
  onPipStream,
}: CamSceneControlProps) => {
  const [isNamesShowing, setIsNamesShowing] = useState(isUserNameShowing);
  const [layoutType, setLayoutType] = useState(
    isSoloed === "has-solo" ? "solo" : "auto"
  );
  //const [lastLayoutType, setLastLayoutType] = useState(layoutType);

  const viewableStreams = (thisStreams) => {
    const availStreams = thisStreams.filter((cam) => {
      return cam.data.pos === "stage" || cam.data.pos === "solo" ? cam : null;
    });
    return availStreams;
  };

  const hasSharedStream = (thisStreams) => {
    const availStreams = thisStreams.filter((cam) => {
      return cam.data.type === "share" ? cam : null;
    });
    return availStreams;
  };

  const camBtn = {
    reg: "relative p-0.5 rounded-sm z-2 pointer max-w-[55px] pointer hover:bg-[#425568]",
    disabled: "relative p-0.5 rounded-sm z-2 max-w-[55px] opacity-50",
  };
  const updateLayout = (layout: string, isButton: boolean) => {
    //setLastLayoutType(layoutType);
    setLayoutType(layout);
    //console.log("LAYOUT", layout, isButton, layoutType);
    // this is checking the last layout
    if (layoutType === "pip" && layout !== "pip") {
      //console.log("UNSET LAYOUT PIP");
      onPipStream(userid, false);
    }
    if (isButton) {
      if (layout !== "solo" && layoutType === "solo") {
        onUnSoloStream(userid);
      } else if (layout === "solo" && layoutType !== "solo") {
        onSoloStream(userid);
      } else if (layout === "pip" && layoutType !== "pip") {
        //console.log("SET LAYOUT PIP");
        onPipStream(userid, true);
      }
    }
  };

  useEffect(() => {
    onSceneView(layoutType);
  }, [layoutType]);

  useEffect(() => {
    updateLayout(
      isSoloed === "has-solo"
        ? "solo"
        : layoutType === "solo"
        ? "auto"
        : layoutType,
      false
    );
  }, [isSoloed]);

  useEffect(() => {
    if (isUserNameShowing !== null) {
      onNameToggle(isNamesShowing);
    }
  }, [isNamesShowing]);

  return (
    <div className="cam-scene-ctrl flex p-0.5 justify-center w-full max-w-[1300px]">
      <div
        className={`${camBtn.reg} cam-scene-btn`}
        onClick={() => setIsNamesShowing(!isNamesShowing)}
        data-tooltip-id="tooltip-dspname"
        data-tooltip-content="Toggle Display Names"
        data-tooltip-offset={20}
        data-tooltip-delay-show={1000}
      >
        {isNamesShowing ? (
          <img src="/img/chat/cam-bar/tags-show.png" />
        ) : (
          <img src="/img/chat/cam-bar/tags-hide.png" />
        )}
      </div>
      <Tooltip id="tooltip-dspname" className="toolTip" />

      <div
        className={
          hasSharedStream(streams).length
            ? `${camBtn.reg} cam-scene-btn`
            : `${camBtn.disabled} cam-scene-btn`
        }
        onClick={
          hasSharedStream(streams).length
            ? () => updateLayout(layoutType === "pip" ? "auto" : "pip", true)
            : null
        }
        data-tooltip-id="tooltip-pip"
        data-tooltip-content="Toggle PIP"
        data-tooltip-offset={20}
        data-tooltip-delay-show={1000}
      >
        {layoutType === "pip" ? (
          <img src="/img/chat/cam-bar/pip-on.png" />
        ) : (
          <img src="/img/chat/cam-bar/pip.png" />
        )}
      </div>
      <Tooltip id="tooltip-pip" className="toolTip" />

      <div
        className={
          viewableStreams(streams).length > 0
            ? `${camBtn.reg} cam-scene-btn`
            : `${camBtn.disabled} cam-scene-btn`
        }
        data-tooltip-id="tooltip-auto"
        data-tooltip-content="Auto Layout"
        data-tooltip-offset={20}
        data-tooltip-delay-show={1000}
        onClick={() => updateLayout("auto", true)}
      >
        {layoutType === "auto" ? (
          <img src="/img/chat/cam-bar/auto-on.png" />
        ) : (
          <img src="/img/chat/cam-bar/auto.png" />
        )}
      </div>
      <Tooltip id="tooltip-auto" className="toolTip" />

      <div
        className={
          viewableStreams(streams).length > 0
            ? `${camBtn.reg} cam-scene-btn`
            : `${camBtn.disabled} cam-scene-btn`
        }
        data-tooltip-id="tooltip-solo"
        data-tooltip-content="Solo host"
        data-tooltip-offset={20}
        data-tooltip-delay-show={1000}
        onClick={
          viewableStreams(streams).length > 0
            ? () => updateLayout("solo", true)
            : null
        }
      >
        {layoutType === "solo" ? (
          <img src="/img/chat/cam-bar/1-up-on.png" />
        ) : (
          <img src="/img/chat/cam-bar/1-up.png" />
        )}
      </div>
      <Tooltip id="tooltip-solo" className="toolTip" />

      <div
        className={
          viewableStreams(streams).length > 1
            ? `${camBtn.reg} cam-scene-btn`
            : `${camBtn.disabled} cam-scene-btn`
        }
        data-tooltip-id="tooltip-2up"
        data-tooltip-content="Host + 1 Guest"
        data-tooltip-offset={20}
        data-tooltip-delay-show={1000}
        onClick={
          viewableStreams(streams).length > 1
            ? () => updateLayout("2up", true)
            : null
        }
      >
        {layoutType === "2up" ? (
          <img src="/img/chat/cam-bar/2-up-on.png" />
        ) : (
          <img src="/img/chat/cam-bar/2-up.png" />
        )}
      </div>
      <Tooltip id="tooltip-2up" className="toolTip" />

      <div
        className={
          viewableStreams(streams).length > 2
            ? `${camBtn.reg} cam-scene-btn`
            : `${camBtn.disabled} cam-scene-btn`
        }
        data-tooltip-id="tooltip-3up"
        data-tooltip-content="Host + 2 Guest"
        data-tooltip-offset={20}
        data-tooltip-delay-show={1000}
        onClick={
          viewableStreams(streams).length > 2
            ? () => updateLayout("3up", true)
            : null
        }
      >
        {layoutType === "3up" ? (
          <img src="/img/chat/cam-bar/3-up-on.png" />
        ) : (
          <img src="/img/chat/cam-bar/3-up.png" />
        )}
      </div>
      <Tooltip id="tooltip-3up" className="toolTip" />

      <div
        className={
          viewableStreams(streams).length > 3
            ? `${camBtn.reg} cam-scene-btn`
            : `${camBtn.disabled} cam-scene-btn`
        }
        data-tooltip-id="tooltip-4up"
        data-tooltip-content="Host + 3 Guest"
        data-tooltip-offset={20}
        data-tooltip-delay-show={1000}
        onClick={
          viewableStreams(streams).length > 3
            ? () => updateLayout("4up", true)
            : null
        }
      >
        {layoutType === "4up" ? (
          <img src="/img/chat/cam-bar/4-up-on.png" />
        ) : (
          <img src="/img/chat/cam-bar/4-up.png" />
        )}
      </div>
      <Tooltip id="tooltip-4up" className="toolTip" />

      <div
        className={
          viewableStreams(streams).length > 4
            ? `${camBtn.reg} cam-scene-btn`
            : `${camBtn.disabled} cam-scene-btn`
        }
        data-tooltip-id="tooltip-5up"
        data-tooltip-content="Host + 5 Guest"
        data-tooltip-offset={20}
        data-tooltip-delay-show={1000}
        onClick={
          viewableStreams(streams).length > 4
            ? () => updateLayout("5up", true)
            : null
        }
      >
        {layoutType === "5up" ? (
          <img src="/img/chat/cam-bar/5-up-on.png" />
        ) : (
          <img src="/img/chat/cam-bar/5-up.png" />
        )}
      </div>
      <Tooltip id="tooltip-5up" className="toolTip" />

      <div
        className={`${camBtn.reg} cam-scene-btn`}
        onClick={onShare}
        data-tooltip-id="tooltip-share"
        data-tooltip-content="Share a screen"
        data-tooltip-offset={20}
        data-tooltip-delay-show={1000}
      >
        {isSharingScreen ? (
          <img src="/img/chat/cam-bar/is-sharing.png" />
        ) : (
          <img src="/img/chat/cam-bar/share.png" />
        )}
      </div>
      <Tooltip id="tooltip-share" className="toolTip" />
    </div>
  );
};

export default CamSceneControl;
