import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import Header from "../../components/layout/Header";
import UserStream from "./components/UserStream";
import useRoomCams from "../../hooks/useRoomCams";

import Chat from "./components/Chat/Index";
import GuestControls from "./components/GuestControls";
import ShareableShow from "../../components/Sharable/SharableShow";
import { Socket, io } from "socket.io-client";
import { getServer } from "../../helpers/GetServer";

import "../../sass/room.scss";
import JoinShow from "./components/JoinShow";
import CustomAlert from "../../components/CustomAlert";
import { AlertLabelProps } from "../../types/Types";
import OverlayDrawer from "../../components/sources/OverlayDrawer";
import { themeKharysCrib } from "../../data/Themes";
import StageManager from "../Shopro/components/StageManager";

const JoinRoom = () => {
  const { roomid } = useParams();
  const [websocket, setWebsocket] = useState<Socket>(null);
  const [myStream, setMyStream] = useState<MediaStream>(null);
  const [, setHasASoloedStream] = useState<string>("");
  const [showOwnerId, setShowOwnerId] = useState<string>("");

  const [hasJoinedRoom, setHasJoinedRoom] = useState<boolean>(false);
  const [cameraClass, setCameraClass] = useState<string>("");
  const [canJoin, setCanJoin] = useState<boolean>(false);
  const [, setCamStatus] = useState<string>("Awaiting Camera");
  const [isUserNameShowing, setIsUserNameShowing] = useState(true);
  const [isShowLive, setIsShowLive] = useState(false);
  const [elapsedTime, setElapsedTime] = useState("--:--:--");
  const [isRundownVisible, setIsRundownVisible] = useState<boolean>(false);
  const [isChyronVisible, setIsChyronVisible] = useState<boolean>(false);
  const [name, setName] = useState(
    localStorage.getItem("stream_display_name")
      ? localStorage.getItem("stream_display_name")
      : ""
  );
  const [tagline, setTagline] = useState(
    localStorage.getItem("stream_tag_line")
      ? localStorage.getItem("stream_tag_line")
      : ""
  );
  const [isVideoShowing, setIsVideoShowing] = useState(true);
  const [isMuted, setIsMuted] = useState(false);
  const [userImg, setUserImg] = useState(null);

  const [streamSet, setStreamSet] = useState(false);
  const [myPos, setMyPos] = useState("green-room");
  const [sourceid, setSourceid] = useState(null);
  const [_browserSourceId, setBrowserSourceId] = useState(null);
  const [hasRundown, setHasRundown] = useState<boolean>(true);
  const [hasChyron, setHasChyron] = useState<boolean>(true);
  const [hasTicker, setHasTicker] = useState<boolean>(true);
  const [isMobileChatOpen, setIsMobileChatOpen] = useState<boolean>(false);
  const [guestRunDownEnabled, setGuestRundownEnabled] = useState<boolean>(true);
  const [isAlertVisible, setIsAlertVisible] = useState<boolean>(false);
  const [alertLabels, setAlertLabels] = useState<AlertLabelProps>();

  const controlCenterRef = useRef(null);

  const RoomCams = useRoomCams({ roomId: roomid });

  const updateStream = (stream) => {
    setStreamSet(false);
    setMyStream(stream);
  };

  /*  const onComponentUpdate = (props) => {
    console.log(props);
  }; */

  const requestJoinRoom = () => {
    //RoomCams.requestJoin();
    const data = {
      roomid,
      userid: RoomCams.userid,
      peerId: RoomCams.peerId,
      name,
      tagline,
      type: "guest",
      pos: myPos,
      isMuted: isMuted,
      isVideoShowing,
      isNameShowing: true,
      userImg,
    };
    //RoomCams.knockKnock(data);
    RoomCams.requestJoin(data, myStream);
    RoomCams.setMyUserData(data);
    setHasJoinedRoom(true);
  };

  const leaveRoom = () => {
    const labels = {
      title: "Are You Sure?",
      message: "You are about to leave the show.",
      confirmLabel: "LEAVE SHOW",
      cancelLabel: "STAY HERE",
      headerClass: "darkred",
      bgClass: "darkred",
      onConfirm: () => processLeave(),
      onCancel: () => setIsAlertVisible(false),
      confirmClass: "bg-darkred hover:bg-red-500",
      cancelClass: "bg-blue hover:bg-blue2",
    };
    setAlertLabels(labels);
    setIsAlertVisible(true);
  };

  const processLeave = () => {
    RoomCams.leave();
    window.location.reload();
  };

  const toggleChat = (isOpen) => {
    setIsMobileChatOpen(!isOpen);
  };

  useEffect(() => {
    //console.log("isAudioOn", isAudioOn);
    if (RoomCams.userid && RoomCams.peerId) {
      const data = {
        roomid,
        userid: RoomCams.userid,
        peerId: RoomCams.peerId,
        name,
        tagline,
        type: "guest",
        pos: myPos,
        isMuted,
        isVideoShowing,
        isNameShowing: isUserNameShowing,
        userImg,
      };
      RoomCams.setEmitCamUpdate(true);
      RoomCams.setMyUserData(data);
    }
  }, [isMuted, isVideoShowing]);

  useEffect(() => {
    RoomCams.setName(name);
    RoomCams.setTagline(tagline);
  }, [name, tagline]);

  useEffect(() => {
    if (myStream && RoomCams.userid && !streamSet) {
      const data = {
        roomid,
        userid: RoomCams.userid,
        peerId: RoomCams.peerId,
        name,
        tagline,
        type: "guest",
        pos: myPos,
        isMuted: false,
        isVideoShowing,
        isNameShowing: isUserNameShowing,
        userImg,
      };

      RoomCams.addStream(myStream, data);
      RoomCams.setMyStream(myStream);
      setStreamSet(false);
    }
  }, [myStream, RoomCams.userid]);

  useEffect(() => {
    if (myStream && RoomCams.userid && name !== "") {
      setCanJoin(true);
    }
  }, [myStream, RoomCams.userid, name]);

  useEffect(() => {
    if (myStream) {
      setCamStatus("Join the Show");
    }
  }, [myStream]);

  useEffect(() => {
    if (RoomCams.sceneCameras.length) {
      const hostCam = RoomCams.sceneCameras.filter(
        (cam) => cam.data.type === "host"
      );
      const myCamInfo = RoomCams.sceneCameras.filter(
        (cam) => cam.data.userid === RoomCams.userid
      );
      if (myCamInfo.length && myCamInfo[0]?.data?.pos !== myPos) {
        setMyPos(myCamInfo[0].data.pos);
      }

      setMyPos((prev) => prev);

      if (hostCam.length) {
        //console.log("hostCam", hostCam[0].data);
        setSourceid(hostCam[0].data.showid);
        setHasTicker(hostCam[0].data.roomsettings.tickerEnabled);
        setHasChyron(hostCam[0].data.roomsettings.chyronEnabled);
        setHasRundown(hostCam[0].data.roomsettings.rundownEnabled);
        setCameraClass(hostCam[0].data.cameraClass);
        setIsUserNameShowing(hostCam[0].data.isNameShowing);
        setGuestRundownEnabled(
          hostCam[0].data.roomsettings.guestRundownEnabled
        );
        setIsRundownVisible(hostCam[0].data.roomsettings.isRundownVisible);
        setIsChyronVisible(hostCam[0].data.roomsettings.isChyronVisible);
      }
    }
  }, [RoomCams.sceneCameras]);

  useEffect(() => {
    if (websocket) {
      websocket.connect();
    }
  }, [websocket]);

  useEffect(() => {
    setWebsocket(io(getServer()));
  }, []);
  return (
    <>
      <Header />

      <CustomAlert
        isAlertVisible={isAlertVisible}
        title={alertLabels?.title}
        message={alertLabels?.message}
        confirmLabel={alertLabels?.confirmLabel}
        cancelLabel={alertLabels?.cancelLabel}
        onConfirm={alertLabels?.onConfirm}
        onCancel={alertLabels?.onCancel}
        cancelClass={alertLabels?.cancelClass}
        confirmClass={alertLabels?.confirmClass}
        headerClass={alertLabels?.headerClass}
        bgClass={alertLabels?.bgClass}
      />

      {hasJoinedRoom && RoomCams.sceneCameras ? (
        <div className="room flex flex-col sm:flex-row h-[calc(100vh-40px)]">
          <div
            key={`col`}
            className="flex flex-1 h-full justify-start flex-col items-center p-2 sticky top-0 z-[100] bg-[#050f19]"
          >
            <div
              className={`video-wrap aspect-video w-full flex flex-wrap relative overflow-hidden items-center max-w-[1300px] border border-slate-900 drop-shadow-lg ${cameraClass}`}
            >
              <StageManager
                RoomCams={RoomCams}
                onCameraClass={setCameraClass}
                onSoloedStream={setHasASoloedStream}
                onMyPos={setMyPos}
              />

              {RoomCams.webSocket && showOwnerId && (
                <OverlayDrawer
                  showTheTicker={hasTicker}
                  showChyron={hasChyron}
                  showRundown={hasRundown}
                  rundownTopic={""}
                  topics={{}}
                  themeConfig={themeKharysCrib}
                  socket={RoomCams.webSocket.current}
                  shareid={showOwnerId}
                  rundownDefaultVisible={isRundownVisible}
                  chyronDefaultVisible={isChyronVisible}
                  elapsedTime={elapsedTime}
                  showElapsedTime={true}
                  isLive={isShowLive}
                />
              )}
            </div>

            <div className="guests float fixed left-2 bottom-2 max-h-[200px] w-full">
              {RoomCams.sceneCameras.map(
                (camera, index) =>
                  camera.data.userid === RoomCams.userid && (
                    <>
                      <div
                        key={`wrap_${index}`}
                        className={
                          camera.data.pos === "stage"
                            ? "wrap stage hidden"
                            : "wrap p-[2px] bg-[#708570] max-w-[150px]"
                        }
                      >
                        <UserStream
                          key={`guest_cam_${index}`}
                          myid={RoomCams.userid}
                          userid={camera.userid}
                          stream={camera.stream}
                          userData={camera.data}
                          onCreatedStream={null}
                          cameraClass={cameraClass}
                          isAudioMuted={camera.data.isMuted}
                          isVideoShowing={camera.data.isVideoShowing}
                          showTitles={camera.data.isNameShowing}
                          userImg={camera.data.userImg}
                          isGreenRoom={true}
                        />
                      </div>
                      <div
                        className={
                          camera.data.pos === "stage"
                            ? "guest-details px-2 py-1 bg-red left-1 shadow-md text-sm rounded-sm max-w-[150px]"
                            : "guest-details p-1 bg-[#345883] left-1 shadow-md text-xs max-w-[150px]"
                        }
                      >
                        <span>
                          {(camera.data.pos === "stage" ||
                            camera.data.pos === "solo") && (
                            <>You're in the show!</>
                          )}
                          {camera.data.pos === "green-room" && (
                            <>
                              Get ready!
                              <br /> You'll be in the show shortly...
                            </>
                          )}
                        </span>
                      </div>
                    </>
                  )
              )}
            </div>
            <GuestControls
              onVideoToggle={setIsVideoShowing}
              onAudioToggle={setIsMuted}
              leaveRoom={leaveRoom}
              toggleChat={toggleChat}
            />
          </div>

          <div className="w-full xs:w-[300px] sm:w-[320px] overflow-x-hidden overflow-y-auto flex-col justify-start p-0.5 border-l border-l-[#14202e] z-1">
            {guestRunDownEnabled && (
              <>
                <div
                  ref={controlCenterRef}
                  className={`${
                    isMobileChatOpen
                      ? "h-1/2 mb-16 sm:mb-auto"
                      : "h-full mb-16 sm:mb-auto"
                  } overflow-y-auto`}
                >
                  <div className="bg-redHover p-3">
                    <span>Rundown</span>
                  </div>
                  <div className="p-1">
                    <ShareableShow
                      joinedShowid={sourceid}
                      hasHeader={false}
                      isInControlCenter={true}
                      useChat={false}
                      onShowStatusChanged={setIsShowLive}
                      onElapsedTime={setElapsedTime}
                      onSourceId={setBrowserSourceId}
                      controlCenterRef={controlCenterRef}
                      onOwnerId={setShowOwnerId}
                    />
                  </div>
                </div>
              </>
            )}
            <div
              className={`${
                isMobileChatOpen
                  ? guestRunDownEnabled
                    ? "fixed bottom-0 rounded-t-md sm:rounded-t-none w-full pb-[60px] sm:pb-0 h-[60%] bg-ssblue sm:b-auto sm:t-auto sm:relative sm:h-1/2 sm:right-auto sm:bottom-auto border-slate-700 transition-all"
                    : "fixed right-5 bottom-[70px] h-[400px] bg-ssblue sm:b-auto sm:t-auto z-[100] sm:relative sm:h-full sm:right-auto sm:bottom-auto border-slate-700 transition-all"
                  : "fixed bottom-[-100%] rounded-t-md w-full pb-[70px] h-[60%] bg-ssblue sm:b-auto sm:t-auto sm:relative sm:h-1/2 sm:right-auto sm:bottom-auto border-slate-700 transition-all sm:hidden"
              }`}
            >
              <Chat
                socket={RoomCams.webSocket.current}
                name={RoomCams.myUserData?.name}
                roomid={roomid}
                userid={RoomCams.userid}
                headerLabel="Private Chat"
              />
            </div>
          </div>
        </div>
      ) : (
        <JoinShow
          onButtonAction={requestJoinRoom}
          defaultBtnVal={true}
          btnLabel="JOIN THE SHOW"
          canJoinShow={canJoin}
          updateStream={updateStream}
          myStream={myStream}
          onName={setName}
          onTagline={setTagline}
          name={name}
          tagline={tagline}
          onUserImg={setUserImg}
          headerLabel="Join The Sho..."
        />
      )}
    </>
  );
};

export default JoinRoom;
