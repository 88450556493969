import React, { useEffect, useState } from "react";
import missingImg from "../img/missing-image.png";

const ImageWithFallback = (props) => {
  const { imgPath, className, imgAlt, onClick } = props;
  const [currentSrc, setCurrentSrc] = useState(imgPath);
  const handleImageError = () => {
    setCurrentSrc(missingImg);
  };
  useEffect(() => {
    setCurrentSrc(imgPath);
  }, [imgPath]);

  return (
    <img
      className={className}
      src={currentSrc}
      alt={imgAlt}
      onClick={onClick}
      onError={handleImageError}
    ></img>
  );
};

export default ImageWithFallback;
