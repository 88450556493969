import React, { useEffect, useRef, useState } from "react";
import { OverlayTheme } from "../../data/Themes";
import { Socket } from "socket.io-client";
import { useOverlayManager } from "./Managers/useOverlayManager";

export interface CanvasDrawerProps {
  showTheTicker: boolean;
  showChyron?: boolean;
  chyronText?: string;
  showRundown?: boolean;
  topics?: any;
  themeConfig: OverlayTheme;
  rundownTopic?: string;
  socket?: Socket;
  shareid?: string | number;
  isShowHost?: boolean;
  showElapsedTime?: boolean;
  elapsedTime?: string;
  isLive?: boolean;
  onOverlayUpdate?: (data: any) => void;
  rundownDefaultVisible?: boolean;
  chyronDefaultVisible?: boolean;
}
const OverlayDrawer = ({
  showChyron,
  chyronText,
  showRundown,
  topics,
  themeConfig,
  rundownTopic,
  socket,
  shareid,
  showTheTicker,
  isShowHost,
  onOverlayUpdate,
  rundownDefaultVisible,
  chyronDefaultVisible,
  showElapsedTime,
  elapsedTime,
  isLive,
}: CanvasDrawerProps) => {
  const [, setCurrentTopic] = useState(rundownTopic ? rundownTopic : "waiting");
  const {
    isRundownVisible,
    isChyronVisible,
    showTopics,
    overlayDataReady,
    showTopic,
    showTicker,
    isChyronEnabled,
    isTickerEnabled,
  } = useOverlayManager(
    socket,
    shareid,
    isShowHost,
    rundownDefaultVisible,
    chyronDefaultVisible
  );
  const [allTopics, setTopics] = useState(null);

  const [rundownEnabled, setRundownEnabled] = useState(showRundown);
  const [chyronEnabled, setChyronEnabled] = useState(showChyron);
  const [tickerEnabled, setTickerEnabled] = useState(showTheTicker);
  const [rundownOffset, setRundownOffset] = useState(0);
  const [chyronOffset, setChyronOffset] = useState(0);
  const [ctx, setCtx] = useState(null);
  const easeInOutQuad = (t) => (t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t);
  //const text = tickerString;
  const tickerTextSize = themeConfig.theme.ticker.textSize; // Font size
  const tickerPadding = themeConfig.theme.ticker.textPadding; // Padding around the text
  const tickerTextColor = themeConfig.theme.ticker.textColor; // Text color: ;
  const tickerTextColorActive = themeConfig.theme.ticker.textActiveTopicColor; // Text color: ;
  const speed = themeConfig.theme.ticker.speed; // Scrolling speed
  const tickerHeight = tickerTextSize + tickerPadding * 2; // Height of the ticker
  const tickerBgColor = themeConfig.theme.ticker.bgColor; // Background color: ;
  const tickerBorderColor = themeConfig.theme.ticker.borderColor; // Border color: ;
  const tickerBorderWidth = themeConfig.theme.ticker.borderWidth; // Border width: ;
  const [tickerXPos, setTickerXpos] = useState(0);
  const [tickerText, setTickerText] = useState("Ticker Text");

  const chyronBgColor = themeConfig.theme.chyron.bgColor; // Background color: ;
  const chyronBorderColor = themeConfig.theme.chyron.borderColor; // Background color: ;
  const chyronBorderWidth = themeConfig.theme.chyron.borderWidth;
  const chyronTextColor = themeConfig.theme.chyron.textColor; // Text color: ;
  const chyronTextSize = themeConfig.theme.chyron.textSize; // Font size: ;
  const chyronTickerMargin = themeConfig.theme.chyron.tickerMargin;
  const chyronHeight = themeConfig.theme.chyron.height;
  const chyronWidthPercentDecimal = themeConfig.theme.chyron.widthPercent;

  const rundownWidth = themeConfig.theme.rundown.width;
  const rundownHeight = themeConfig.theme.rundown.height;
  const rundownBgColor = themeConfig.theme.rundown.bgColor;
  const rundownBgActive = themeConfig.theme.rundown.BgColorActive;
  const rundownBorderColor = themeConfig.theme.rundown.borderColor; // Background color: ;
  const rundownBorderWidth = themeConfig.theme.rundown.borderWidth;
  const rundownFontSize = themeConfig.theme.rundown.fontSize;
  const rundownFontColor = themeConfig.theme.rundown.fontColor;
  const rundownFontActive = themeConfig.theme.rundown.fontColorActive;
  const rundownPadding = themeConfig.theme.rundown.padding;
  const rundownSpacing = themeConfig.theme.rundown.spacing;
  const rundownLeftMargin = themeConfig.theme.rundown.leftMargin;

  const canvasRef = useRef(null);
  const topicRef = useRef(null);
  const tickerRef = useRef(null);
  const rundownEnabledRef = useRef(null);
  const chyronEnabledRef = useRef(null);
  const tickerEnabledRef = useRef(null);
  const isRundownVisibleRef = useRef(null);
  const isChyronVisibleRef = useRef(null);
  const allTopicsRef = useRef(null);
  const xPosRef = useRef(null);
  const rundownOffsetRef = useRef(null);
  const chyronOffsetRef = useRef(null);
  const showElapsedTimeRef = useRef(null);
  const elapsedTimeRef = useRef(null);
  const isLiveRef = useRef(null);

  topicRef.current = showTopic;
  tickerRef.current = tickerText;
  rundownEnabledRef.current = rundownEnabled;
  chyronEnabledRef.current = chyronEnabled;
  tickerEnabledRef.current = tickerEnabled;
  isRundownVisibleRef.current = isRundownVisible;
  isChyronVisibleRef.current = isChyronVisible;
  xPosRef.current = tickerXPos;
  allTopicsRef.current = allTopics;
  rundownOffsetRef.current = rundownOffset;
  chyronOffsetRef.current = chyronOffset;
  showElapsedTimeRef.current = showElapsedTime;
  elapsedTimeRef.current = elapsedTime;
  isLiveRef.current = isLive;

  /* const measureMultilineTextHeight = (text, font, maxWidth, ctx) => {
    const words = text.split(" ");
    let line = "";
    let lines = 1;

    for (let i = 0; i < words.length; i++) {
      const testLine = line + words[i] + " ";
      const testWidth = ctx.measureText(testLine).width;
      console.log(
        `testLine: ${testLine}, testWidth: ${testWidth}, lines: ${lines}`
      );

      if (testWidth > maxWidth && i > 0) {
        lines++;
        line = words[i] + " ";
      } else {
        line = testLine;
      }
    }

    // Parse the font size, assuming it might include units (e.g., "px")
    const fontSize = parseInt(font, 10) || parseInt(font.match(/\d+/)[0], 10);

    return lines * fontSize;
  }; */

  const splitTextIntoLines = (text, _font, maxWidth) => {
    const words = text.split(" ");
    const lines = [];
    let currentLine = "";

    for (let i = 0; i < words.length; i++) {
      const testLine = currentLine ? currentLine + " " + words[i] : words[i];
      const testWidth = ctx.measureText(testLine).width;

      if (testWidth > maxWidth && i > 0) {
        lines.push(currentLine);
        currentLine = words[i];
      } else {
        currentLine = testLine;
      }
    }

    lines.push(currentLine);

    return lines;
  };

  const drawTicker = () => {
    if (tickerRef.current) {
      ctx.fillStyle = tickerBgColor;
      ctx.fillRect(
        0,
        canvasRef.current.height - tickerHeight,
        canvasRef.current.width,
        tickerHeight
      );

      ctx.fillStyle = tickerBorderColor;
      ctx.fillRect(
        0,
        canvasRef.current.height - tickerHeight - tickerBorderWidth,
        canvasRef.current.width,
        tickerBorderWidth
      );

      // Set font properties
      ctx.font = `bold ${tickerTextSize}px Roboto Condensed`;
      ctx.fillStyle = tickerTextColor;

      // Duplicate text for seamless scroll
      let duplicatedText =
        ctx.measureText(tickerRef.current).width < canvasRef.current.width
          ? tickerRef.current.toUpperCase() +
            "  " +
            tickerRef.current.toUpperCase()
          : tickerRef.current.toUpperCase();
      if (ctx.measureText(duplicatedText).width < canvasRef.current.width) {
        duplicatedText = duplicatedText + "  " + duplicatedText;
      }
      const textWidth = ctx.measureText(duplicatedText).width;
      const startIndex = duplicatedText.indexOf("[ CURRENT");
      const endIndex = duplicatedText.indexOf(" ]");

      ctx.fillText(
        duplicatedText.substring(0, startIndex),
        xPosRef.current,
        canvasRef.current.height - 20
      );

      ctx.fillStyle = tickerTextColorActive;

      ctx.fillText(
        duplicatedText.substring(startIndex, endIndex + 2), // Highlighted part
        xPosRef.current +
          ctx.measureText(duplicatedText.substring(0, startIndex)).width,
        canvasRef.current.height - 20
      );

      ctx.fillStyle = tickerTextColor;

      ctx.fillText(
        duplicatedText.substring(endIndex + 2), // After the highlighted part
        xPosRef.current +
          ctx.measureText(duplicatedText.substring(0, startIndex)).width +
          ctx.measureText(duplicatedText.substring(startIndex, endIndex + 2))
            .width,
        canvasRef.current.height - 20
      );

      // Draw text at the end of the previous text
      ctx.fillText(
        duplicatedText.substring(0, startIndex),
        xPosRef.current + textWidth,
        canvasRef.current.height - 20
      );

      ctx.fillStyle = tickerTextColorActive;

      ctx.fillText(
        duplicatedText.substring(startIndex, endIndex + 2), // Highlighted part
        xPosRef.current +
          textWidth +
          ctx.measureText(duplicatedText.substring(0, startIndex)).width,
        canvasRef.current.height - 20
      );

      ctx.fillStyle = tickerTextColor;

      ctx.fillText(
        duplicatedText.substring(endIndex + 2), // After the highlighted part
        xPosRef.current +
          textWidth +
          ctx.measureText(duplicatedText.substring(0, startIndex)).width +
          ctx.measureText(duplicatedText.substring(startIndex, endIndex + 2))
            .width,
        canvasRef.current.height - 20
      );

      // Update xPos
      xPosRef.current -= speed;
      setTickerXpos((prevXPos) => prevXPos - speed);

      if (xPosRef.current + textWidth < 0) {
        xPosRef.current = 0;
        setTickerXpos(0);
      }
    }
  };

  const drawChyron = () => {
    ctx.fillStyle = chyronBgColor;
    ctx.strokeStyle = chyronBorderColor;
    ctx.shadowColor = "#000"; // Specify your shadow color here
    ctx.shadowBlur = 10; // Specify your shadow blur value here
    ctx.shadowOffsetX = 5; // Specify your shadow offset X value here
    ctx.shadowOffsetY = 5;

    const chyronWidth = canvasRef.current.width * chyronWidthPercentDecimal;

    const chyronX = (canvasRef.current.width - chyronWidth) / 2;
    /* const destYOn =
        canvasRef.current.height -
        chyronHeight -
        tickerHeight -
        tickerBorderWidth -
        chyronTickerMargin;
      const destYOff = -destYOn; */
    //console.log(destYOn, destYOff);

    const chyronY =
      canvasRef.current.height -
      chyronHeight -
      tickerHeight -
      tickerBorderWidth -
      chyronTickerMargin +
      chyronOffsetRef.current;

    ctx.fillRect(chyronX, chyronY, chyronWidth, chyronHeight);
    ctx.lineWidth = chyronBorderWidth; // Specify your border width here
    ctx.strokeRect(chyronX, chyronY, chyronWidth, chyronHeight);

    ctx.shadowColor = "rgba(0, 0, 0, 0)";
    ctx.shadowBlur = 0;
    ctx.shadowOffsetX = 0;
    ctx.shadowOffsetY = 0;
    ctx.font = `bold ${chyronTextSize}px Roboto Condensed`; // Adjust the font size and style as needed
    ctx.fillStyle = chyronTextColor; // Specify the text color

    ctx.textAlign = "center";
    ctx.shadowColor = "rgba(0, 0, 0, 0.8)"; // Color of the shadow; RGBA format allows for transparency
    ctx.shadowBlur = 4; // Amount of blur
    ctx.shadowOffsetX = 3; // Horizontal distance of shadow from text
    ctx.shadowOffsetY = 4;

    const longText = topicRef.current.toUpperCase();
    const words = longText.split(" ");
    const textX = canvasRef.current.width / 2;
    let textY =
      chyronY + (chyronHeight - chyronTextSize) / 2 + chyronTextSize - 10;

    let line = "";
    for (let i = 0; i < words.length; i++) {
      const testLine = line + words[i] + " ";
      const testWidth = ctx.measureText(testLine).width;

      if (testWidth > chyronWidth - 20) {
        ctx.fillText(line.trim(), textX, textY);
        line = words[i] + " ";
        textY += chyronTextSize;
      } else {
        line = testLine;
      }
    }

    ctx.fillText(line.trim(), textX, textY);
    ctx.shadowColor = "rgba(0, 0, 0, 0)";
    ctx.shadowBlur = 0;
    ctx.shadowOffsetX = 0;
    ctx.shadowOffsetY = 0;
  };

  const drawRundown = () => {
    if (allTopicsRef.current) {
      const pageTopMargin = 80;
      let currentY = pageTopMargin; // Track the current Y position

      allTopicsRef.current.forEach((topic, _index) => {
        const topicText = topic.topic;
        let topicX = rundownLeftMargin + rundownOffsetRef.current;

        ctx.fillStyle =
          topic.topic === topicRef.current ? rundownBgActive : rundownBgColor;
        ctx.strokeStyle = rundownBorderColor;

        // Add shadow properties for inner shadow
        ctx.shadowColor = "#000";
        ctx.shadowBlur = 10;
        ctx.shadowOffsetX = 5;
        ctx.shadowOffsetY = 5;

        // Calculate text height for multiline text
        const lines = splitTextIntoLines(
          topicText.trim(),
          `bold ${rundownFontSize}px Roboto Condensed`,
          rundownWidth - rundownPadding
        );

        // Adjust box height based on text height
        const adjustedRundownHeight = Math.max(
          rundownHeight + rundownBorderWidth,
          lines.length * (rundownFontSize + 20)
        );

        // Update current Y position
        const topicY = currentY;

        ctx.fillRect(topicX, topicY, rundownWidth, adjustedRundownHeight);
        ctx.lineWidth = rundownBorderWidth;
        ctx.strokeRect(topicX, topicY, rundownWidth, adjustedRundownHeight);

        // Reset shadow properties to avoid affecting other drawings
        ctx.shadowColor = "rgba(0, 0, 0, 0)";
        ctx.shadowBlur = 0;
        ctx.shadowOffsetX = 0;
        ctx.shadowOffsetY = 0;

        ctx.textAlign = "left";
        ctx.font = `bold ${rundownFontSize}px Roboto Condensed`;
        ctx.fillStyle =
          topic.topic === topicRef.current
            ? rundownFontActive
            : rundownFontColor;

        // Draw each line separately
        lines.forEach((line, i) => {
          const textY =
            (i === 0 ? 35 : 20) +
            topicY +
            (adjustedRundownHeight / lines.length) * i;
          ctx.fillText(line, topicX + rundownPadding, textY);
        });

        // Update the current Y position for the next topic
        currentY += adjustedRundownHeight + rundownSpacing * 2;
      });
    }
  };

  const drawElapsedTime = () => {
    const padding = 25; // adjust as needed
    const rectHeight = 50;
    const rectX = 20;
    const rectY = 15;
    const cornerRadius = 20;
    const text = `LIVE ${elapsedTimeRef.current}`;

    // Measure the text width
    ctx.font = "bold 28px Roboto Condensed";
    const textWidth = ctx.measureText(text).width;

    // Adjust the rectangle width based on the text width and padding
    const rectWidth = textWidth + padding * 2;

    ctx.fillStyle = "#920119";
    ctx.strokeStyle = "#920119";
    ctx.lineWidth = 2;
    ctx.beginPath();
    ctx.moveTo(rectX + cornerRadius, rectY);
    ctx.arcTo(
      rectX + rectWidth,
      rectY,
      rectX + rectWidth,
      rectY + cornerRadius,
      cornerRadius
    );
    ctx.arcTo(
      rectX + rectWidth,
      rectY + rectHeight,
      rectX + rectWidth - cornerRadius,
      rectY + rectHeight,
      cornerRadius
    );
    ctx.arcTo(
      rectX,
      rectY + rectHeight,
      rectX,
      rectY + rectHeight - cornerRadius,
      cornerRadius
    );
    ctx.arcTo(rectX, rectY, rectX + cornerRadius, rectY, cornerRadius);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();

    ctx.fillStyle = "white";
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    ctx.fillText(text, rectX + rectWidth / 2, rectY + rectHeight / 2 + 2);
  };

  const drawOverlay = () => {
    if (ctx) {
      if (canvasRef.current) {
        ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);

        if (chyronEnabledRef.current) {
          drawChyron();
        }
        if (rundownEnabledRef.current) {
          drawRundown();
        }
        if (tickerEnabledRef.current) {
          drawTicker();
        }

        if (showElapsedTimeRef.current && isLiveRef.current) {
          drawElapsedTime();
        }
      }
    }

    requestAnimationFrame(drawOverlay);
  };

  // RUNDOWN SLIDE ANIMATION
  useEffect(() => {
    if (isRundownVisibleRef.current) {
      // Slide back on when isRundownVisible is true

      const animationStartTime = Date.now();
      const slideAnimation = () => {
        const currentTime = Date.now();
        const elapsedTime = currentTime - animationStartTime;
        const slideDuration = 300; // Adjust the duration of the slide animation (in milliseconds)

        if (elapsedTime < slideDuration) {
          // Continue sliding until the duration is reached
          const progress = easeInOutQuad(elapsedTime / slideDuration);
          const newOffset = -rundownWidth + rundownWidth * progress;
          setRundownOffset(newOffset);
          requestAnimationFrame(slideAnimation);
        } else {
          // Set the final offset when the slide animation is complete
          setRundownOffset(0);
        }
      };

      slideAnimation();
    } else {
      // Slide off the screen when isRundownVisible is false

      const animationStartTime = Date.now();
      const slideAnimation = () => {
        const currentTime = Date.now();
        const elapsedTime = currentTime - animationStartTime;
        const slideDuration = 300; // Adjust the duration of the slide animation (in milliseconds)

        if (elapsedTime < slideDuration) {
          // Continue sliding until the duration is reached
          const progress = easeInOutQuad(elapsedTime / slideDuration);
          const newOffset = -rundownWidth * progress - rundownPadding;
          setRundownOffset(newOffset);
          requestAnimationFrame(slideAnimation);
        } else {
          // Do not reset offset when the slide animation is complete
          setRundownOffset(-rundownWidth - rundownLeftMargin - rundownPadding);
        }
      };

      slideAnimation();
    }
    if (onOverlayUpdate) {
      onOverlayUpdate({ isRundownVisible, isChyronVisible });
    }
  }, [isRundownVisible]);

  // CHYRON SLIDE ANIMATION
  useEffect(() => {
    if (isChyronVisibleRef.current) {
      // Slide back on when isRundownVisible is true
      const animationStartTime = Date.now();
      const slideAnimation = () => {
        const currentTime = Date.now();
        const elapsedTime = currentTime - animationStartTime;
        const slideDuration = 300; // Adjust the duration of the slide animation (in milliseconds)

        if (elapsedTime < slideDuration) {
          // Continue sliding until the duration is reached
          const progress = easeInOutQuad(elapsedTime / slideDuration);
          const newOffset = chyronHeight - chyronHeight * progress;
          setChyronOffset(newOffset);
          requestAnimationFrame(slideAnimation);
        } else {
          // Set the final offset when the slide animation is complete
          setChyronOffset(0);
        }
      };

      slideAnimation();
    } else {
      // Slide off the screen when isRundownVisible is false
      const animationStartTime = Date.now();
      const slideAnimation = () => {
        const currentTime = Date.now();
        const elapsedTime = currentTime - animationStartTime;
        const slideDuration = 300; // Adjust the duration of the slide animation (in milliseconds)
        let progress;
        if (elapsedTime < slideDuration) {
          // Continue sliding until the duration is reached
          progress = easeInOutQuad(elapsedTime / slideDuration);
          const newOffset = chyronHeight * progress + chyronOffsetRef.current;
          setChyronOffset(newOffset);
          requestAnimationFrame(slideAnimation);
        } else {
          // Do not reset offset when the slide animation is complete
        }
      };

      slideAnimation();
    }
    if (onOverlayUpdate) {
      onOverlayUpdate({ isRundownVisible, isChyronVisible });
    }
  }, [isChyronVisible]);

  useEffect(() => {
    if (chyronText && topics) {
    }
    setCurrentTopic(rundownTopic);
  }, [rundownTopic]);

  useEffect(() => {
    setRundownEnabled(showRundown);
    rundownEnabledRef.current = showRundown;
  }, [showRundown]);

  useEffect(() => {
    setChyronEnabled(showChyron);
    chyronEnabledRef.current = showChyron;
  }, [showChyron]);

  useEffect(() => {
    setTickerEnabled(showTheTicker);
    tickerEnabledRef.current = showTheTicker;
  }, [showTheTicker]);

  useEffect(() => {
    //setChyronEnabled(showChyron);
  }, [showTopic]);

  useEffect(() => {
    //console.log("tickertext", showTicker);
    setTickerText(showTicker);
  }, [showTicker]);

  useEffect(() => {}, [isRundownVisible]);

  useEffect(() => {
    //console.log("isChyronEnabled", isChyronEnabled);
    setChyronEnabled(isChyronEnabled);
  }, [isChyronEnabled]);

  useEffect(() => {
    //console.log("isTickerEnabled", isTickerEnabled);
    setTickerEnabled(isTickerEnabled);
  }, [isTickerEnabled]);

  useEffect(() => {
    if (showTopics && showTopics.length > 0) {
      //console.log("RECEIVED NEW TOPICS", showTopics);
      const filteredTopics = showTopics.filter(
        (topic) => topic.includeInRundown === true
      );
      setTopics(filteredTopics);
      allTopicsRef.current = filteredTopics;
    }
  }, [showTopics]);

  useEffect(() => {
    if (overlayDataReady) {
      drawOverlay();
    }
  }, [overlayDataReady]);

  useEffect(() => {
    const canvas = canvasRef.current;
    setCtx(canvas.getContext("2d"));
    setTickerXpos(0); // Initial X position
    // Initial X position
  }, []);

  return (
    <canvas
      id="overlay-canvas"
      ref={canvasRef}
      width={1920}
      height={1080}
      style={{ border: "1px solid #000" }}
      className="absolute top-0 aspect-video w-full flex flex-wrap overflow-hidden max-w-[1920px] z-20"
    ></canvas>
  );
};

export default OverlayDrawer;
