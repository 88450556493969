import {
  faBackwardStep,
  faFilm,
  faForwardStep,
  faPause,
  faPlay,
  faRepeat,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useTranslation } from "react-i18next";

const Controls = ({
  isCropActive,
  toggleVideoPlaying,
  isPlaying,
  stepVideoFwdBak,
  isRepeatOn,
  isRepeatOnClick,
  addKeyFrame,
  playheadPosition,
}) => {
  const { t } = useTranslation();
  const btnClass =
    "rounded-md ml-1 py-1 px-2 bg-sky-500 border border-slate-900 hover:bg-sky-800 hover:border-[#262525] text-sm leading-none pointer transition duration-300 ease-in-out";
  const btnClassOn =
    "rounded-md ml-1 py-1 px-2 border border-slate-900 hover:bg-sky-800 hover:border-[#262525] text-sm leading-none pointer transition duration-300 ease-in-out bg-slate-700";
  const onFwdBackHandler = (direction) => {
    stepVideoFwdBak(direction);
  };

  return (
    <>
      <div className="player-controls flex justify-center p-4">
        <span
          className={`${btnClass}`}
          id="vid-step-back"
          onClick={() => onFwdBackHandler("BACK")}
        >
          <FontAwesomeIcon icon={faBackwardStep} />
        </span>
        <span
          className={!isPlaying ? `${btnClassOn}` : `${btnClass}`}
          id="vid-pause"
          onClick={toggleVideoPlaying}
        >
          <FontAwesomeIcon icon={faPause} />
        </span>
        <span
          className={isPlaying ? `${btnClassOn}` : `${btnClass}`}
          id="vid-play"
          onClick={toggleVideoPlaying}
        >
          <FontAwesomeIcon icon={faPlay} />
        </span>
        <span
          className={`${btnClass}`}
          id="vid-step-fwd"
          onClick={() => onFwdBackHandler("FWD")}
        >
          <FontAwesomeIcon icon={faForwardStep} />
        </span>
        <span
          id="vid-repeat"
          className={isRepeatOn ? `${btnClassOn}` : `${btnClass}`}
          onClick={() => isRepeatOnClick(isRepeatOn ? false : true)}
        >
          <FontAwesomeIcon icon={faRepeat} />
        </span>
        <span
          className={!isCropActive ? `${btnClass} hidden` : `${btnClass}`}
          id="vid-keyframe"
          onClick={() => addKeyFrame()}
        >
          <FontAwesomeIcon icon={faFilm} />{" "}
          <label className="text-xs uppercase">{t("add_keyframe")}</label>
        </span>
      </div>
    </>
  );
};

export default Controls;
