import html2canvas from "html2canvas";
import { useEffect } from "react";

const useCanvaseScreenshot = () => {
  const takeScreenshot = async (canvasref) => {
    const canv = await html2canvas(canvasref);
    const base64Image = canv.toDataURL("image/png");
    return base64Image;
  };

  useEffect(() => {}, []);

  return { takeScreenshot };
};

export default useCanvaseScreenshot;
