import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { fetchTkRf } from "../api-requests/Index";

export const AuthMonitor = () => {
  const navigate = useNavigate();
  const idleTimeout = 1 * 60 * 1000;
  const timer = useRef<any>(null);

  const checkToken = async () => {
    const jwtToken = Cookies.get("jwt");
    const jwtRefreshToken = Cookies.get("rftk");
    const hasCookie = jwtToken ? true : false;
    const hasRefreshCookie = jwtRefreshToken ? true : false;
    //console.log("hasCookie", hasCookie);
    //console.log("hasRefreshCookie", hasRefreshCookie);
    if (!hasCookie && !hasRefreshCookie) {
      navigate("/login");
    }
    if (!hasCookie && hasRefreshCookie) {
      const refreshToken = await fetchTkRf({ urtk: jwtRefreshToken }, {});
      if (refreshToken.success) {
        Cookies.set("jwt", refreshToken.items.token, {
          expires: new Date(refreshToken.items.expires * 1000),
        });
        //Cookies.set("rftk", refreshToken.items.refreshToken, { expires: new Date(refreshToken.items.refreshTokenExpires * 1000) });
      } else {
        navigate("/login");
      }
    }
    return { hasCookie, hasRefreshCookie };
  };

  useEffect(() => {
    checkToken();

    timer.current = setTimeout(() => {
      //localStorage.removeItem("token");
      // localStorage.removeItem("tokenExpiration");
      checkToken();
      resetTimer();
    }, idleTimeout);

    window.addEventListener("mousemove", resetTimer);
    window.addEventListener("keypress", resetTimer);

    return () => {
      clearTimeout(timer.current);
      window.removeEventListener("mousemove", resetTimer);
      window.removeEventListener("keypress", resetTimer);
    };
  }, [history, idleTimeout]);

  const resetTimer = () => {
    clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      checkToken();
      resetTimer();
    }, idleTimeout);
  };
};

export default AuthMonitor;
