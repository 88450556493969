import { faFolderPlus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useNavigate } from "react-router-dom";
import { SaveToLocalStorage } from "../../../helpers/LocalStorage";

const NoPlans = () => {
  const navigate = useNavigate();

  const planNewShow = () => {
    SaveToLocalStorage("lvs", -1);
    navigate("/planner?ns=t");
  };

  return (
    <div className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 m-6">
      <FontAwesomeIcon className="text-3xl" icon={faFolderPlus} />
      <h3 className="mt-1 text-lg font-semibold text-gray-400">
        No Shows Planned
      </h3>
      <p className="mt-1 text-base text-gray-500">
        Get started by creating a new show plan.
      </p>
      <div className="mt-6">
        <button
          className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 no-underline"
          onClick={planNewShow}
        >
          <FontAwesomeIcon className="mr-1" icon={faPlus} /> New Show Plan
        </button>
      </div>
    </div>
  );
};

export default NoPlans;
