import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { tw } from "../../../../helpers/Styles";
import { getBearerHeaders } from "../../../../helpers/GetBearerHeaders";
import {
  fetchImportVideo,
  postImportLocal,
} from "../../../../api-requests/Index";

export interface UploadFormProps {
  processing: boolean;
  onUploadSuccess?: () => void;
}

const UploadForm = ({ processing, onUploadSuccess }: UploadFormProps) => {
  const { t } = useTranslation();
  const [buttonLabel, setButtonLabel] = useState(t("buttons.import_video"));
  const [isProcessing, setIsProcessing] = useState(processing);
  const [percentUploaded, setPercentUploaded] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const fileInput = useRef(null);

  const openFileDialog = () => {
    if (!isProcessing) {
      fileInput.current.click();
    }
  };

  const importFile = async (videoId) => {
    const data = {
      videoId: videoId,
      source: "YOUTUBE",
    };
    console.log("importFile", data);
    const importVideoResults = await fetchImportVideo(data, getBearerHeaders());
    if (importVideoResults.success && onUploadSuccess) {
      console.log("importVideoResults", importVideoResults);
      onUploadSuccess();
    }
  };

  const importFromYoutube = (event) => {
    event.preventDefault();
    const videoId = getYouTubeVideoId(event.target.value);
    const platform = checkVideoPlatform(event.target.value).toUpperCase();
    const isAllowedPlatform = platform === "YOUTUBE" ? true : false;
    if (isAllowedPlatform && !isProcessing) {
      setIsProcessing(true);
      importFile(videoId);
    }
  };

  const importFromLocal = async (event) => {
    event.preventDefault();
    const fileInput = event.target;
    const fileNameList = Array.from(fileInput.files).map(
      (file: any) => file.name
    );
    const fileNameDisplay = fileNameList.join(", ");
    const file = fileInput.files[0];
    setButtonLabel(fileNameDisplay);
    if (file && isFileValid(file)) {
      console.log("VALID FILE TYPE TO UPLOAD");
      try {
        setIsUploading(true);
        const formData = new FormData();
        formData.append("file", file);
        formData.append("source", "LOCAL");
        console.log("formData", formData);
        const uploadHeaders = getBearerHeaders("FILE_UPLOAD");
        console.log("uploadHeaders", [uploadHeaders]);
        const config = {
          ...uploadHeaders,
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            console.log(`Upload progress: ${percentCompleted}%`);
            setPercentUploaded(percentCompleted);
          },
        };
        const importVideoResults = await postImportLocal(formData, config);
        if (importVideoResults) {
          if (importVideoResults.success && onUploadSuccess) {
            onUploadSuccess();
            setIsUploading(false);
          }
        }

        console.log("config", config);
      } catch (error) {
        console.error("Error uploading file:", error);
        setIsUploading(false);
      }
    }
  };

  const getYouTubeVideoId = (url) => {
    // match the video ID pattern (11 characters after "v=" or after the last "/" character)
    const pattern = /(?:\?v=|\/embed\/|\/watch\?v=)([a-zA-Z0-9_-]{11})/;
    const match = url.match(pattern);
    return match ? match[1] : null;
  };

  const isFileValid = (file) => {
    console.log("file.type", file.type);
    const validTypes = [
      "video/mp4",
      "video/avi",
      "video/quicktime",
      "video/x-matroska",
      "audio/mpeg",
      "audio/wav",
    ];
    // const maxFileSize = 100000000; // 100 MB  && file.size <= maxFileSize
    return validTypes.includes(file.type);
  };

  const checkVideoPlatform = (url) => {
    // Check if the URL matches YouTube's pattern
    var youtubePattern =
      /^(?:https?:\/\/)?(?:www\.)?youtu(?:\.be\/|be\.com\/watch\?v=)(\w+)/;
    var youtubeMatch = url.match(youtubePattern);

    // Check if the URL matches Twitch's pattern
    var twitchPattern = /^(?:https?:\/\/)?(?:www\.)?twitch\.tv\/(\w+)/;
    var twitchMatch = url.match(twitchPattern);

    // Check if the URL matches Facebook's pattern
    var facebookPattern =
      /^(?:https?:\/\/)?(?:www\.)?facebook\.com\/video\.php\?v=(\d+)/;
    var facebookMatch = url.match(facebookPattern);

    // Check if the URL matches Kick's pattern
    var kickPattern = /^(?:https?:\/\/)?(?:www\.)?kick\.com\/video/;
    var kickMatch = url.match(kickPattern);

    if (youtubeMatch) {
      return "YouTube";
    } else if (twitchMatch) {
      return "Twitch";
    } else if (facebookMatch) {
      return "Facebook";
    } else if (kickMatch) {
      return "Kick";
    } else {
      return "Unknown";
    }
  };

  return (
    <form method="POST" id="upload-form" className="flex m-2">
      {isUploading && (
        <div className="fixed flex flex-col top-0 left-0 w-screen h-screen bg-slate-700 bg-opacity-90 z-[500] justify-center items-center">
          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <div className="relative transform overflow-hidden rounded-lg border-slate-500 bg-ssblue2 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <svg
                      className="h-6 w-6 text-red-600"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
                      />
                    </svg>
                  </div>
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <h3
                      className="text-base font-semibold leading-6 text-white"
                      id="modal-title"
                    >
                      {t("notices.importing_video")}
                    </h3>
                    <div className="mt-2">
                      <p className="text-sm text-white">
                        {percentUploaded} % {t("uploaded")}
                        <div className="text-xs text-gray-400">
                          {t("notices.upload_inprogress")}
                        </div>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div
        className={`${tw.cardPlain} flex flex-col md:flex-row w-full bg-ssblue p-4`}
      >
        <div
          className="upload-btn p-4 bg-gray-700 rounded-md w-full md:w-2/12 align-middle text-center cursor-pointer uppercase"
          onClick={() => openFileDialog()}
        >
          {buttonLabel}
        </div>
        <input
          name="videoUrl"
          id="videoUrl"
          placeholder={t("youtube_import")}
          onChange={(e) => importFromYoutube(e)}
          className="mt-3 md:mt-0 md:ml-3 p-3 w-full md:w-10/12 rounded-md text-black"
        />
        <input
          ref={fileInput}
          type="file"
          id="video"
          name="video"
          onChange={(e) => importFromLocal(e)}
          className="hidden"
        />
      </div>
    </form>
  );
};

export default UploadForm;
