import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ActionButton from "../../components/ActionButton";
import ImageWithFallback from "../../components/ImageWithFallback";

const Clip = ({ clipData }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const loadVideo = (projectData) => {
    navigate(`/vido-editor`, { state: { ...projectData } });
  };

  const titleUpdate = (e) => {};

  return (
    <div className="video-box" key={clipData.id}>
      <div className="img-container">
        <ImageWithFallback
          className="pointer edit-video"
          imgPath={clipData.thumbnail}
          imgAlt=""
          onClick={() => loadVideo(clipData)}
        />
        <div className="controls">
          <i className="fa-solid fa-play"></i>
        </div>
      </div>
      <div className="details">
        <input
          className="title-input clip-edit"
          value={clipData.title}
          onChange={(e) => titleUpdate(e)}
        />
        <span>
          {t("project_id")}: {clipData.projectId}
        </span>
        <br />
        <span className="date-created">
          {t("clip_length")}:
          <span className="timecode">{clipData.duration}</span>
          <br />
          <span className="transcription-price">{clipData.duration}</span>
          <br />
          <span className="file-size">{clipData.fileSize}</span>
        </span>
      </div>
      <div className="action-wrapper">
        <ActionButton
          icon="fa-solid fa-download"
          label={t("buttons.export")}
          extraclassName="button download-btn"
        />
        <ActionButton
          icon="fa-solid fa-trash"
          label={t("buttons.delete_moment")}
          extraclassName="button download-btn"
        />
      </div>
    </div>
  );
};

export default Clip;
