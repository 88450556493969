import Cookies from "js-cookie";

export const getBearerHeaders = (type?) => {
  const jwtToken = Cookies.get("jwt");

  const bearerToken = jwtToken ? jwtToken : "n/a";
  const contentType =
    type !== undefined
      ? type === "FILE_UPLOAD"
        ? "multipart/form-data"
        : "application/json"
      : "application/json";

  const PostHeaders = {
    Authorization: `Bearer ${bearerToken}`,
    "Content-Type": contentType,
  };
  return {
    headers: PostHeaders,
  };
};
