import React, { Fragment, useEffect, useState } from "react";
import { ShowStatsProps } from "../../../types/Types";
import {
  calOverUnder,
  calculateTimeDuration,
  dateToTime,
  startEndTimeOffest,
  timeStringToMilliseconds,
} from "../../../helpers/TimeCode";
import { timeDisplay } from "../../../helpers/Dates";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark, faClock } from "@fortawesome/free-solid-svg-icons";
import { Dialog, Transition } from "@headlessui/react";
import { tw } from "../../../helpers/Styles";

const ShowStats = ({
  topics,
  onClose,
  showdata,
  isStatsOpen,
}: ShowStatsProps) => {
  const defaultTime = "--:--:--";
  const [showLength, setShowLength] = useState<string>(defaultTime);
  const [showOffsetTime, setShowOffsetTime] = useState<string>("--:--");
  const [hiLowEven, setHiLowEven] = useState<string>("EVEN");
  const [showStartEnds, setShowStartEnds] = useState<boolean>(false);
  const [open, setOpen] = useState(isStatsOpen);

  const offsetTimes = (itemData) => {
    const offsetResults = startEndTimeOffest(
      itemData.start_time,
      itemData.end_time,
      itemData.topic_time
    );

    const hilo = offsetResults.hilo;
    const dif = offsetResults.timedif;

    return (
      <>
        <span
          className={
            hilo.toLocaleUpperCase() === "HI"
              ? "red-text"
              : hilo.toLocaleUpperCase() === "LO"
              ? "green-text"
              : ""
          }
        >
          {dif ? dif : defaultTime}
        </span>
      </>
    );
  };

  const closeWindow = () => {
    setOpen(false);
    onClose();
  };

  useEffect(() => {
    if (showdata) {
      const elapsed = calculateTimeDuration(
        dateToTime(new Date(showdata.starttime)),
        dateToTime(new Date(showdata.endtime))
      );
      setShowLength(elapsed === "00:00:00" ? "--:--:--" : elapsed);
      const offsets = calOverUnder(topics);

      const isNegative = offsets.startsWith("-");
      setHiLowEven(isNegative ? "LO" : offsets === "--:--" ? "EVEN" : "HI");
      /*  console.log(
        offsets,
        isNegative,
        isNegative ? "LO" : offsets === "--:--" ? "EVEN" : "HI"
      ); */
      setShowOffsetTime(offsets === "00:00:00" ? "--:--:--" : offsets);
    }
  }, [showdata]);

  useEffect(() => {
    setOpen(isStatsOpen);
  }, [isStatsOpen]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-[250]" onClose={closeWindow}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className={tw.modalBg} />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full min-w-full max-w-4xl items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="divide-y divide-slate-900 overflow-hidden relative transform rounded-md border border-slate-500 bg-ssblue2 text-left shadow-xl transition-all w-full sm:my-8 sm:w-full sm:max-w-6xl mx-2 ">
                <div className="px-4 py-5 sm:px-6 bg-ssblue w-full">
                  <div className="flex items-center justify-between">
                    <Dialog.Title className="title text-base font-semibold leading-6 text-white">
                      Segment Stats
                      <span className="mt-2 text-base text-white b-1 block">
                        {showdata.starttime && showdata.endtime ? (
                          <>
                            <span className="small">
                              {timeDisplay(new Date(showdata.starttime))} -{" "}
                              {timeDisplay(new Date(showdata.endtime))}
                            </span>{" "}
                            <span
                              className={`${tw.btn.action} bg-blue ml-2 cursor-pointer tgl-times`}
                              onClick={() => setShowStartEnds(!showStartEnds)}
                              data-testid="toggle-time-btn"
                            >
                              <FontAwesomeIcon
                                icon={faClock}
                                className={showStartEnds ? "" : "text-red-600"}
                              />{" "}
                              Toggle Segment Times
                            </span>
                          </>
                        ) : null}
                      </span>
                    </Dialog.Title>
                    <div className="ml-3 flex h-7 items-center">
                      <FontAwesomeIcon
                        className="relative rounded-md bg-ssbluehover text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white cursor-pointer"
                        icon={faCircleXmark}
                        onClick={closeWindow}
                      />
                    </div>
                  </div>
                </div>
                <div className="px-4 py-2 sm:p-4">
                  <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8 ">
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-white sm:pl-0 uppercase"
                          >
                            Start
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-white uppercase"
                          >
                            End
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-white uppercase"
                          >
                            Over
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-white uppercase"
                          >
                            Under
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200">
                        {topics.length > 0 &&
                          topics.map((topic, index) => (
                            <tr key={index}>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-white sm:pl-0 segment-time">
                                {topic.start_time
                                  ? topic.start_time
                                  : defaultTime}
                                {topic.start_time &&
                                topic.end_time &&
                                showStartEnds ? (
                                  <>
                                    <br />
                                    <span
                                      className="text-xs text-gray-400"
                                      data-testid={`showtimespan-${index}`}
                                    >
                                      {timeDisplay(
                                        new Date(
                                          showdata.starttime +
                                            timeStringToMilliseconds(
                                              topic.start_time
                                            )
                                        )
                                      )}
                                    </span>
                                  </>
                                ) : null}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-white">
                                {topic.end_time ? topic.end_time : defaultTime}
                                {topic.start_time &&
                                topic.end_time &&
                                showStartEnds ? (
                                  <>
                                    <br />
                                    <span className="text-xs text-gray-400">
                                      {timeDisplay(
                                        new Date(
                                          showdata.starttime +
                                            timeStringToMilliseconds(
                                              topic.end_time
                                            )
                                        )
                                      )}
                                    </span>
                                  </>
                                ) : null}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-white">
                                {offsetTimes(topic)}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-white">
                                {topic.topic}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="px-4 py-4 sm:px-6 bg-ssblue text-sm">
                  <span>
                    Total Show Time: {showLength}{" "}
                    <span
                      className={
                        hiLowEven === "LO"
                          ? "green-text"
                          : hiLowEven === "HI"
                          ? "red-text"
                          : ""
                      }
                    >
                      {showOffsetTime}
                    </span>
                  </span>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ShowStats;
