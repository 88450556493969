import React, { useEffect, useState } from "react";
import UserStream from "../../controlCenter/components/UserStream";

export interface StageManagerProps {
  RoomCams: any;
  layout?: string;
  offlineMsg?: string;
  onCameraClass: (cameraClass: string) => void;
  onMyPos?: (myPos: string) => void;
  onSoloedStream: (hasASoloedStream: string) => void;
  isOwner?: boolean | null;
}

const StageManager = ({
  RoomCams,
  layout,
  offlineMsg,
  onCameraClass,
  onMyPos,
  onSoloedStream,
  isOwner,
}: StageManagerProps) => {
  const [cameraClass, setCameraClass] = useState<string>("");
  const [hasASoloedStream, setHasASoloedStream] = useState<string>("");
  const [myPos, setMyPos] = useState("stage");
  const [viewableCameras, setViewableCamera] = useState(0);

  useEffect(() => {
    if (onCameraClass && cameraClass) {
      onCameraClass(cameraClass);
    }
  }, [cameraClass]);

  useEffect(() => {
    if (onMyPos && myPos) {
      onMyPos(myPos);
    }
  }, [myPos]);

  useEffect(() => {
    if (onSoloedStream && hasASoloedStream) {
      onSoloedStream(hasASoloedStream);
    }
  }, [hasASoloedStream]);

  useEffect(() => {
    if (RoomCams.sceneCameras) {
      const hasSoloStream = RoomCams.sceneCameras.filter(
        (cam) => cam.data.pos === "solo"
      );
      if (layout) {
        if (layout === "auto") {
          const availcams = RoomCams.sceneCameras.filter(
            (cam) =>
              (cam.data.pos === "stage" ||
                cam.data.pos === "solo" ||
                cam.data.pos === "pip") &&
              (cam.data.type === "host" || cam.data.type === "guest")
          );

          setCameraClass(`grid_${availcams.length}`);
        } else {
          switch (layout) {
            case "solo":
              setCameraClass(`grid_1 ${hasSoloStream ? "off" : ""}`);
              break;

            case "2up":
              setCameraClass(`grid_2 ${hasSoloStream ? "off" : ""}`);
              break;

            case "3up":
              setCameraClass(`grid_3 ${hasSoloStream ? "off" : ""}`);
              break;

            case "4up":
              setCameraClass(`grid_4 ${hasSoloStream ? "off" : ""}`);
              break;

            case "5up":
              setCameraClass(`grid_5 ${hasSoloStream ? "off" : ""}`);
              break;
          }
        }
      } else {
        const availcams = RoomCams.sceneCameras.filter(
          (cam) =>
            (cam.data.pos === "stage" ||
              cam.data.pos === "solo" ||
              cam.data.pos === "pip") &&
            (cam.data.type === "host" || cam.data.type === "guest")
        );
        setCameraClass(`grid_${availcams.length}`);
      }

      const viewableCams = RoomCams.sceneCameras.filter(
        (cam) =>
          cam.data.pos === "stage" ||
          cam.data.pos === "solo" ||
          cam.data.pos === "pip"
      );
      setViewableCamera(viewableCams.length);

      const myCamInfo = RoomCams.sceneCameras.filter(
        (cam) => cam.data.userid === RoomCams.userid
      );
      if (myCamInfo.length && myCamInfo[0]?.data?.pos !== myPos) {
        setMyPos(myCamInfo[0].data.pos);
      }

      setHasASoloedStream(hasSoloStream.length > 0 ? "has-solo" : "");
    }
    //console.log("RoomCams.sceneCameras: ", RoomCams.sceneCameras);
  }, [RoomCams.sceneCameras, layout]);

  return (
    <>
      {RoomCams.sceneCameras.length && viewableCameras ? (
        RoomCams.sceneCameras
          .filter(
            (camera) =>
              camera.data.pos === "stage" ||
              camera.data.pos === "solo" ||
              camera.data.pos === "pip" ||
              camera.data.type === "share"
          )
          .sort((a, b) => {
            // Sort by type, putting 'host' first
            if (a.data.type === "host" && b.data.type !== "host") {
              return -1;
            } else if (a.data.type !== "host" && b.data.type === "host") {
              return 1;
            }

            // If types are equal, sort by 'stage'
            if (a.data.type === "guest" && b.data.type !== "guest") {
              return -1;
            } else if (a.data.type !== "guest" && b.data.type === "guest") {
              return 1;
            }

            // If types are equal, sort by 'share'
            if (a.data.type === "share" && b.data.type !== "share") {
              return -1;
            } else if (a.data.type !== "share" && b.data.type === "share") {
              return 1;
            }

            return 0;
          })
          .map(
            (camera, index) =>
              (camera.data.pos === "stage" ||
                camera.data.pos === "solo" ||
                camera.data.pos === "pip" ||
                camera.data.type === "share") && (
                <UserStream
                  key={`${camera.userid}_${index}`}
                  myid={RoomCams.userid}
                  userid={camera.userid}
                  stream={camera.stream}
                  userData={camera.data}
                  onCreatedStream={null}
                  cameraClass={`${cameraClass} ${
                    camera.data.pos
                  } ${hasASoloedStream} ${
                    layout === "pip" && camera.data.type === "host"
                      ? "pip"
                      : camera.data.type === "share"
                      ? "share"
                      : ""
                  }`}
                  isAudioMuted={camera.data.isMuted}
                  isVideoShowing={camera.data.isVideoShowing}
                  showTitles={camera.data.isNameShowing}
                  userImg={camera.data.userImg}
                  isGreenRoom={false}
                  isOwner={isOwner}
                />
              )
          )
      ) : (
        <>
          {offlineMsg ? (
            <div className="flex justify-center items-center h-auto w-full  ">
              <p className="uppercase text-sm text-gray-500">{offlineMsg}</p>
            </div>
          ) : (
            <img className="placeholder" src="/img/awaiting-host.png" />
          )}
        </>
      )}
    </>
  );
};

export default StageManager;
