import React from "react";
import Header from "../layout/Header";

const Cancel = () => {
  return (
    <>
      <Header />
      <div className="pages-wrap">
        <div className="row">
          <div className="col full">
            <p>Your order has been canceled.</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Cancel;
