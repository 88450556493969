import axios from "axios";
import React, { useRef, useEffect } from "react";

export interface VideoChatCanvasProps {
  videoStreams: any;
  layout: string;
  isStreaming: boolean;
  isRecording: boolean;
}

const VideoChatCanvas = ({
  videoStreams,
  layout,
  isStreaming,
  isRecording,
}: VideoChatCanvasProps) => {
  const canvasRef = useRef(null);
  const videoRefs = useRef({});
  const ctxRef = useRef(null);
  const overlayCanvasRef = useRef(null);
  const isStreamingRef = useRef(null);
  const isRecordingRef = useRef(null);
  const targetWidth = 1920;
  const targetHeight = 1080;

  isStreamingRef.current = isStreaming;
  isRecordingRef.current = isRecording;

  const getVidPositions = (_vid, parent, target, _totalWidth) => {
    const widthRatio = targetWidth / parent.width;
    const heightRatio = targetHeight / parent.height;
    const scalingFactor = Math.min(widthRatio, heightRatio);

    const scaledWidth = target.width * scalingFactor;
    const scaledHeight = target.height * scalingFactor;

    const left = target.left - parent.left;
    const top = target.top - parent.top;

    return {
      left: left,
      top: top,
      width: scaledWidth,
      height: scaledHeight,
    };
  };

  const convertPercentageToPixels = (percentage, parentWidth) => {
    return (percentage / 100) * parentWidth;
  };

  /*   const is16x9 = (width, height) => {
    // Calculate the ratio
    const aspectRatio = width / height;

    // Define the expected 16:9 ratio
    const expectedRatio = 16 / 9;

    // Test if the aspect ratio is approximately equal to the expected ratio
    const is16x9 = Math.abs(aspectRatio - expectedRatio) < 0.01;

    if (is16x9) {
      return true;
    } else {
      return false;
    }
  }; */

  const sendFrameToServer = async (_imageData) => {
    try {
      // Send the frame data to your streaming endpoint
      const blob: Blob = await new Promise((resolve) =>
        canvasRef.current.toBlob(resolve, "image/jpeg")
      );

      //console.log("Sending frame to server", blob, imageData);
      const formData = new FormData();
      formData.append("rtmpserver", "rtmp://a.rtmp.youtube.com/live2");
      formData.append("streamkey", "prqt-kw72-kr4a-ttsp-5t8s");
      formData.append("video", blob, "video.jpg");
      //formData.append('audio', audioBlob, 'audio.wav')
      await axios.post("/streamer", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    } catch (error) {
      console.error("Error sending frame to server:", error);
    }
  };

  useEffect(() => {
    if (ctxRef.current) {
      const renderStreams = () => {
        if (isStreamingRef.current || isRecordingRef.current) {
          const parentEl = document
            .getElementById("video-stage")
            .getBoundingClientRect();

          // Clear the canvas before rendering each frame
          ctxRef.current.clearRect(
            0,
            0,
            canvasRef.current.width,
            canvasRef.current.height
          );

          let totalWidth = 0; // Initialize the total width

          videoStreams.forEach((stream, _index) => {
            if (
              (stream.data.pos === "stage" || stream.data.pos === "solo") &&
              document.getElementById(`wrap-${stream.data.userid}`)
            ) {
              const thisEl = document
                .getElementById(`wrap-${stream.data.userid}`)
                .getBoundingClientRect();

              if (!videoRefs.current[stream.data.userid]) {
                videoRefs.current[stream.data.userid] =
                  document.createElement("video");
                videoRefs.current[stream.data.userid].srcObject = stream.stream;
                videoRefs.current[stream.data.userid].play();
              }

              const vid = videoRefs.current[stream.data.userid];
              const vidPos = getVidPositions(
                vid,
                parentEl,
                thisEl,
                totalWidth // Pass the accumulated total width
              );

              ctxRef.current.drawImage(
                vid,

                convertPercentageToPixels(
                  document.getElementById(`wrap-${stream.data.userid}`).dataset
                    .left,
                  targetWidth
                ),
                convertPercentageToPixels(
                  document.getElementById(`wrap-${stream.data.userid}`).dataset
                    .top,
                  targetHeight
                ),
                convertPercentageToPixels(
                  document.getElementById(`wrap-${stream.data.userid}`).dataset
                    .width,
                  targetWidth
                ),
                convertPercentageToPixels(
                  document.getElementById(`wrap-${stream.data.userid}`).dataset
                    .height,
                  targetHeight
                )
              );

              // Accumulate the total width for the next video
              totalWidth += vidPos.width;
            }
          });
          ctxRef.current.drawImage(overlayCanvasRef.current, 0, 0);
          if (isStreamingRef.current) {
            const imageData = ctxRef.current.getImageData(
              0,
              0,
              canvasRef.current.width,
              canvasRef.current.height
            ).data;
            sendFrameToServer(imageData);
          }
        }
      };

      const animate = () => {
        renderStreams();
        requestAnimationFrame(animate);
      };

      animate();
    }
  }, [videoStreams]);

  useEffect(() => {
    const canvas = canvasRef.current;
    ctxRef.current = canvas.getContext("2d");
    overlayCanvasRef.current = document.getElementById("overlay-canvas");
    //console.log("layout", layout);
    if (layout) {
    }
  }, []);

  return (
    <canvas
      id="video-canvas"
      className="absolute top-0 left-0 max-w-[320px] z-1"
      ref={canvasRef}
      width={targetWidth}
      height={targetHeight}
    />
  );
};

export default VideoChatCanvas;
