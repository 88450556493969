import React from "react";
import UserStream from "./UserStream";

export interface GreenRoomCamProps {
  cameraInfo: any;
  userid: string;
  cameraClass: string;
  addStreamToShow: (userid: number | string, pos: number) => void;
  soloStream: (userid: number) => void;
  unsoloStream: (userid: number | string) => void;
  toggleMute: (userid: number | string, mutedStatus: boolean) => void;
}

const GreenRoomCam = ({
  cameraInfo,
  userid,
  cameraClass,
  addStreamToShow,
  soloStream,
  unsoloStream,
  toggleMute,
}: GreenRoomCamProps) => {
  return (
    <UserStream
      myid={userid}
      userid={cameraInfo.userid}
      stream={cameraInfo.stream}
      userData={cameraInfo.data}
      onCreatedStream={null}
      cameraClass={cameraClass}
      isAudioMuted={true}
      isVideoShowing={cameraInfo.data.isVideoShowing}
      addToShow={
        cameraInfo.data.pos === "green-room" || cameraInfo.data.pos === "stage"
          ? addStreamToShow
          : null
      }
      cameraPos={cameraInfo.data.pos}
      showTitles={true}
      hasAdminTools={true}
      onSoloStream={soloStream}
      onUnSoloStream={unsoloStream}
      userImg={cameraInfo.data.userImg}
      isGreenRoom={true}
      isOwner={true}
      toggleMute={toggleMute}
    />
  );
};

export default GreenRoomCam;
