import React, { useState, useEffect, useRef } from "react";
import ReactGA from "react-ga4";
import { secondsToTimecode } from "../../../helpers/TimeCode";
import { CardPropsReadOnly } from "../../../types/Types";
import EnlargedNote from "../../../features/Shopro/components/EnlargedNote";
import SegmentTimes from "../../../features/Shopro/components/SegmentTimes";
import SegmentStartTime from "../../../features/Shopro/components/SegmentStartTime";
import { tw } from "../../../helpers/Styles";
ReactGA.initialize("G-ZL0ZP018E6");

const CardReadOnly = ({
  index,
  cardData,
  islive,
  currentTopic,
  startTime,
  allTopics,
  showStartTimeStamp,
  showDate,
  isInControlCenter,
  controlCenterRef,
  onTopicTime,
}: CardPropsReadOnly) => {
  const [itemData, setItemData] = useState(cardData);
  //const [topicStartTime, setTopicStartTime] = useState<number>(startTime);
  const [isNotesEnlarged, setIsNotesEnlarged] = useState<boolean>(false);
  const [currentTime, setCurrentTime] = useState(
    Number(cardData.topic_time) * 60
  );
  const targetDivRef = useRef<HTMLDivElement | null>(null);
  const topicTimeDsp = secondsToTimecode(currentTime);
  const enlargeNotes = () => {
    setIsNotesEnlarged(isNotesEnlarged ? false : true);
  };

  /* const calcEstimatedStart = () => {
    const total = allTopics
      .slice(0, index) // Get a slice of the array up to the target index
      .reduce(
        (accumulator, currentValue) =>
          Number(accumulator) + Number(currentValue.topic_time),
        0
      );

    const showStartTime = new Date(showStartTimeStamp);
    showStartTime.setMinutes(showStartTime.getMinutes() + total);
    const amPmTime = showStartTime.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
    setEstSegStart(`Est Segment Start: ${amPmTime}`);
  }; */

  const convertTextLinksToHtml = (text: string) => {
    // Regular expression to match URLs in text
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const parts = text.split(urlRegex);

    const elements = parts.map((part, index) => {
      if (urlRegex.test(part)) {
        // If the part is a URL, return an anchor tag
        return (
          <a href={part} key={index} target="_blank" rel="noopener noreferrer">
            {part}
          </a>
        );
      } else {
        // If the part is not a URL, return plain text
        return part;
      }
    });

    return elements;
  };

  /* useEffect(() => {
    calcEstimatedStart();
  }, [showStartTimeStamp]); */

  useEffect(() => {
    let timerInt: NodeJS.Timeout;

    timerInt = setTimeout(() => {
      if (islive && currentTopic === index) {
        const nowTime = Date.now();
        const elapsed = nowTime - (startTime === 0 ? Date.now() : startTime);
        const remainingTime = Math.max(
          itemData.topic_time * 60 * 1000 - elapsed,
          0
        );
        const remainingSeconds = Math.ceil(remainingTime / 1000);
        if (startTime === 0) {
          setCurrentTime((prev) => prev - 1);
        } else {
          setCurrentTime(remainingSeconds);
        }
      }
    }, 1000);

    return () => {
      clearTimeout(timerInt);
    };
  }, [currentTopic, currentTime, islive]);

  useEffect(() => {
    setItemData(cardData);
    if (currentTopic !== index || !islive) {
      setCurrentTime(Number(cardData.topic_time) * 60);
    }
  }, [cardData]);

  useEffect(() => {
    if (onTopicTime) {
      onTopicTime(currentTime);
    }
  }, [currentTime]);

  useEffect(() => {
    if (currentTopic === index && targetDivRef.current && islive) {
      const targetElement = targetDivRef.current;
      const headerHeight = 60; // Replace with the actual height of your fixed header
      const extraPixels = 0; // Adjust this value as needed for additional spacing

      const isControlCenterScroll = isInControlCenter === true;
      const topPosition =
        targetElement.getBoundingClientRect().top +
        (!isControlCenterScroll
          ? window.scrollY
          : controlCenterRef.current.scrollTop) -
        (!isControlCenterScroll ? headerHeight - extraPixels : 110);

      if (!isControlCenterScroll) {
        window.scrollTo({
          top: topPosition,
          behavior: "smooth",
        });
      } else {
        controlCenterRef.current.scrollTo({
          top: topPosition,
          behavior: "smooth",
        });
      }
    }
    if (currentTopic === index) {
      setCurrentTime(itemData.topic_time * 60);
      //setTopicStartTime(startTime);
    }
  }, [currentTopic, islive]);

  //console.log("showStartTimeStamp", showStartTimeStamp, startTime, showDate);

  return (
    <>
      <EnlargedNote
        isOpen={isNotesEnlarged}
        note={itemData.notes}
        onClose={() => setIsNotesEnlarged(false)}
      />

      <div
        ref={targetDivRef}
        className={
          islive && currentTopic === index
            ? currentTime > 0
              ? currentTime > 0 && currentTime < 30
                ? `${tw.cardInner} warningCard pt-2`
                : `${tw.cardInner} bg-activeCard pt-2`
              : `${tw.cardInner} overtimeCard pt-2`
            : cardData.is_complete && islive
            ? `${tw.cardInner} bg-readCard pt-2`
            : `${tw.cardInner} pt-2`
        }
        key={`card-${index}`}
      >
        <div className="">
          <SegmentStartTime
            topics={allTopics}
            index={index}
            starttime={showStartTimeStamp !== 0 ? showStartTimeStamp : showDate}
          />

          <br className="sorthide" />
          <div className="absolute top-0 right-0 text-xs p-3 bg-black sorthide">
            {topicTimeDsp}
          </div>
          <span className="text-sm sorthide">
            Segment Time - {itemData.topic_time} min
          </span>
          <span className={tw.text.cardTitle}>{itemData.topic}</span>
          <span className="text-lg sorthide">{itemData.ticker_label}</span>
          {itemData.notes.length ? (
            <>
              <hr className="sorthide" />
              <span className={`${tw.tabs.reg} sorthide`}>NOTES</span>
              <br className="sorthide" />
              <span
                className="text-sm leading-1 block max-h-52 overflow-y-auto overflow-x-hidden sorthide"
                onDoubleClick={enlargeNotes}
              >
                {itemData.notes.split("\n").map((line) => (
                  <>
                    {convertTextLinksToHtml(line)}
                    <br />
                  </>
                ))}
              </span>
            </>
          ) : null}
          <hr className="sorthide" />
          <SegmentTimes itemData={itemData} />
        </div>
      </div>
    </>
  );
};

export default CardReadOnly;
