import React from "react";
import { useTranslation } from "react-i18next";
import Header from "../../../../components/layout/Header";
import SSHeader from "../../components/SSHeader";
import CreateNew from "../../components/CreateNew";

const SlicesPage = () => {
  const { t } = useTranslation();
  return (
    <>
      <Header />
      <SSHeader />
      <CreateNew label={t("notices.no_moments")} />
    </>
  );
};

export default SlicesPage;
