import React, { useEffect, useState } from "react";

import useRoomCams from "../../hooks/useRoomCams";
import { useParams } from "react-router-dom";
import "../../sass/camSizes.scss";
import Header from "../../components/layout/Header";
import { fetchShowByRoom } from "../../api-requests/Index";
import { getBearerHeaders } from "../../helpers/GetBearerHeaders";
import { useTranslation } from "react-i18next";
import Chat from "./components/Chat/Index";
import OverlayDrawer from "../../components/sources/OverlayDrawer";
import { topicData } from "../../components/sources/SampleTopicData";
import { themeKharysCrib } from "../../data/Themes";
import StageManager from "../Shopro/components/StageManager";
import { tw } from "../../helpers/Styles";

const Watch = () => {
  const { t } = useTranslation();
  const { roomid } = useParams();
  const RoomCams = useRoomCams({ roomId: roomid });
  const [hasStreams, setHasStreams] = useState<boolean>(false);
  const [watchConfirm, setWatchConfirm] = useState<boolean>(false);
  const [availableCams, setAvailableCams] = useState<number>(0);
  const [, setHasASoloedStream] = useState<string>("");
  const [showUser, setShowUser] = useState<any>(null);
  const [roomJoined, setRoomJoined] = useState<boolean>(false);
  //const [, setVideoStream] = useState(null);
  //const [includeHost] = useState(noHostCam !== undefined ? false : true);
  const [hasRundown, setHasRundown] = useState<boolean>(true);
  const [hasChyron, setHasChyron] = useState<boolean>(true);
  const [hasTicker, setHasTicker] = useState<boolean>(true);
  const [isRundownVisible, setIsRundownVisible] = useState<boolean>(false);
  const [isChyronVisible, setIsChyronVisible] = useState<boolean>(false);
  const [, setBrowserSourceId] = useState(null);
  const [shareid, setShareId] = useState(null);
  const [isLive, setIsLive] = useState<boolean>(false);
  const [onReconnect, setOnReconnect] = useState<boolean>(false);
  const [viewerName, setViewerName] = useState<string>(
    localStorage.getItem(`chat_dspName`) || ""
  );
  const [showImg, setShowImg] = useState<string>("");

  const [rundownTopic] = useState<string>("");

  const [showTitle, setShowTitle] = useState<string | null>(null);

  const [cameraClass, setCameraClass] = useState<string>("");

  const createEmptyAudioTrack = () => {
    const ctx = new AudioContext();
    const oscillator = ctx.createOscillator();
    const mediaStream = new MediaStream();

    const destination = ctx.createMediaStreamDestination();
    oscillator.connect(destination);
    mediaStream.addTrack(destination.stream.getAudioTracks()[0]);

    oscillator.start();

    const track = mediaStream.getAudioTracks()[0];
    return Object.assign(track, { enabled: false });
  };

  const createEmptyVideoTrack = ({ width, height }) => {
    const canvas = Object.assign(document.createElement("canvas"), {
      width,
      height,
    });
    canvas.getContext("2d").fillRect(0, 0, width, height);

    const stream = canvas.captureStream();
    const track = stream.getVideoTracks()[0];

    return Object.assign(track, { enabled: false });
  };

  const onAppFocus = () => {
    console.log("Window regained focus");
  };

  const fetchData = async () => {
    const postData = { roomid: roomid };
    const showInfo = await fetchShowByRoom(postData, getBearerHeaders());

    if (showInfo.success && showInfo.items.length > 0) {
      const showData = showInfo.items[0].show;
      setShowTitle(showData.showTitle);
      setShowUser(showInfo.items[0].User);
      setShareId(showInfo.items[0].shareid);
      setIsLive(showInfo.items[0].islive);
      if (showInfo.items[0].imagedata !== "") {
        setShowImg(showInfo.items[0].imagedata);
      }

      //console.log("showInfo", showInfo);
    }
  };

  useEffect(() => {
    //console.log("viewerName", viewerName);
  }, [viewerName]);

  useEffect(() => {
    //console.log("rundownTopic", rundownTopic);
  }, [rundownTopic]);

  useEffect(() => {
    if (RoomCams.showStatus) {
      const jsonData = JSON.parse(RoomCams.showStatus);
      if (jsonData.data.islive) {
        setIsLive(true);
        fetchData();
      } else {
        setIsLive(false);
      }
    }
  }, [RoomCams.showStatus]);

  useEffect(() => {
    if (RoomCams.sceneCameras.length) {
      //console.log("RoomCams.sceneCameras", RoomCams.sceneCameras);
      const hostCam = RoomCams.sceneCameras.filter(
        (cam) => cam.data.type === "host"
      );

      const availcams = RoomCams.sceneCameras.filter(
        (cam) => cam.data.pos === "stage" || cam.data.pos === "solo"
      );
      setAvailableCams(availcams.length);
      if (availcams.length > 0) {
        setCameraClass(`grid_${availcams.length}`);
      }

      setHasStreams(availcams.length > 0 ? true : false);

      if (hostCam.length) {
        setBrowserSourceId(hostCam[0].data.showSourceId);
        setHasTicker(hostCam[0].data.roomsettings.tickerEnabled);
        setHasChyron(hostCam[0].data.roomsettings.chyronEnabled);
        setHasRundown(hostCam[0].data.roomsettings.rundownEnabled);
        setCameraClass(hostCam[0].data.cameraClass);
        setIsRundownVisible(hostCam[0].data.roomsettings.isRundownVisible);
        setIsChyronVisible(hostCam[0].data.roomsettings.isChyronVisible);
        //console.log("HOST CAM", hostCam);
      }

      const hasSoloStream = RoomCams.sceneCameras.filter(
        (cam) => cam.data.pos === "solo"
      );

      setHasASoloedStream(hasSoloStream.length > 0 ? "has-solo" : "");
    }
  }, [RoomCams.sceneCameras]);

  useEffect(() => {
    if (RoomCams.userid && RoomCams.peerId && !roomJoined) {
      const mediaStream = new MediaStream([
        createEmptyAudioTrack(),
        createEmptyVideoTrack({ width: 640, height: 480 }),
      ]);

      // Add video and audio tracks to the MediaStream
      //mediaStream.addTrack(videoTrack);
      //mediaStream.addTrack(audioTrack);
      const data = {
        roomid,
        userid: RoomCams.userid,
        peerId: RoomCams.peerId,
        name: viewerName,
        tagline: "",
        type: "watcher",
        pos: "bg",
        isMuted: true,
        isVideoShowing: false,
      };
      RoomCams.requestJoin(data, mediaStream);
      RoomCams.setMyUserData(data);

      RoomCams.addStream(mediaStream, data);
      RoomCams.setMyStream(mediaStream);
      setRoomJoined(true);
      setOnReconnect(false);
    }
  }, [RoomCams.userid, RoomCams.peerId, onReconnect]);

  useEffect(() => {
    //console.log("CONNECT SOCKETS", RoomCams.webSocket.current);
  }, [RoomCams.webSocket.current]);

  useEffect(() => {
    window.addEventListener("focus", onAppFocus);

    fetchData();
  }, []);

  return (
    <>
      <Header />
      {watchConfirm && isLive ? (
        <div className="flex flex-col sm:flex-row h-[calc(100vh-40px)]">
          <div className="flex flex-1 flex-col xs:flex-row h-full">
            <div className="scene sm:px-2 sm:mt-4">
              <div
                className={`video-wrap aspect-video w-full flex flex-wrap relative overflow-hidden max-w-[1400px] sm:m-auto studio ${cameraClass}`}
              >
                <StageManager
                  RoomCams={RoomCams}
                  onCameraClass={setCameraClass}
                  onSoloedStream={setHasASoloedStream}
                  offlineMsg={t("notices.offline")}
                />

                {RoomCams.webSocket && shareid && (
                  <OverlayDrawer
                    showTheTicker={hasTicker}
                    showChyron={hasChyron}
                    showRundown={hasRundown}
                    rundownTopic={rundownTopic}
                    topics={topicData}
                    themeConfig={themeKharysCrib}
                    socket={RoomCams.webSocket.current}
                    shareid={showUser.id}
                    rundownDefaultVisible={isRundownVisible}
                    chyronDefaultVisible={isChyronVisible}
                  />
                )}
              </div>
              <div className="px-2 py-5 max-w-[1400px] sm:m-auto">
                {showUser && (
                  <h3 className="text-2xl font-semibold leading-6 ">
                    {showUser.display_name}'s Show
                  </h3>
                )}
                {RoomCams.sceneCameras.length && hasStreams && (
                  <h3>Watching: {showTitle}</h3>
                )}
              </div>
            </div>
          </div>

          <div className="flex bottom-0 sm:relative sm:max-w-[360px] sm:max-w-auto mx-auto w-full h-[70%] overflow-x-hidden overflow-y-auto flex-col justify-start border border-x-[#14202e] border-b-[#14202e] border-t-0 rounded-t-md sm:border-r-0 sm:border-b-0 sm:rounded-t-none sm:h-full sm:px-0 xs:w-[300px] sm:w-[320px] sm:p-0 sm:border-l sm:border-l-[#14202e] z-1">
            {/* <div className="px-2 py-3 rounded-t-md bg-red  sm:rounded-t-none">
            {showTitle ? showTitle : "Show Chat"}
          </div> */}
            <Chat
              socket={RoomCams.webSocket.current}
              name={RoomCams.myUserData?.name}
              roomid={roomid}
              userid={RoomCams.userid}
              showHeader={true}
              headerLabel={showTitle + " Chat" || "Show Chat"}
              onDspNameUpdate={setViewerName}
              chatType="WATCH_CHAT"
            />
          </div>
        </div>
      ) : (
        <div className="flex flex-col sm:flex-row h-[calc(100vh-40px)]">
          <div className="flex flex-1 flex-col xs:flex-row h-full">
            <div className="scene sm:px-2 sm:mt-4">
              <div
                className={`video-wrap aspect-video w-full flex flex-wrap relative overflow-hidden max-w-[1400px] sm:m-auto studio ${cameraClass}`}
                style={{
                  backgroundImage: `url(${showImg})`,
                  backgroundSize: "cover",
                }}
              >
                {availableCams > 0 && isLive ? (
                  <button
                    onClick={() => setWatchConfirm(true)}
                    className={`${tw.btn.reg} bg-blue-charcoal-500 hover:bg-blue-charcoal-700 text-white font-bold py-2 px-4 rounded`}
                  >
                    Watch Show Live
                  </button>
                ) : (
                  <p className="uppercase text-sm text-gray-500">
                    {t("notices.offline")}
                  </p>
                )}
              </div>
            </div>
          </div>

          <div className="flex bottom-0 sm:relative sm:max-w-[360px] sm:max-w-auto mx-auto w-full h-[450px] overflow-x-hidden overflow-y-auto flex-col justify-start border border-x-[#14202e] border-b-[#14202e] border-t-0 rounded-t-md sm:border-r-0 sm:border-b-0 sm:rounded-t-none sm:h-full sm:px-0 xs:w-[300px] sm:w-[320px] sm:p-0 sm:border-l sm:border-l-[#14202e] z-1"></div>
        </div>
      )}
    </>
  );
};

export default Watch;
