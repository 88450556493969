import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ActionButton from "../../components/ActionButton";
import ImageWithFallback from "../../components/ImageWithFallback";

const MomentSection = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { moment, index } = props;
  const keyWordArr = moment.keywords ? moment.keywords.split(", ") : [];

  const testFunc = () => {};

  const loadVideo = (projectData) => {
    console.log("Load Video", projectData);
    console.log(projectData);
    navigate(`/vido-editor`, {
      state: { ...projectData, videoType: "MOMENT" },
    });
  };

  const updateTitle = (event) => {
    console.log("PROJ ID", moment.projectId);
    console.log("TITLE", event.target.innerText);
  };

  const getSentimentLabel = (sentimentRating) => {
    const sentimentValue =
      sentimentRating === "negative"
        ? "low"
        : sentimentRating === "neutral"
        ? "neutral"
        : "high";
    return sentimentValue;
  };

  const secondsToTimecode = (durationInSecs) => {
    const hours = Math.floor(durationInSecs / 3600);
    const minutes = Math.floor((durationInSecs % 3600) / 60);
    const seconds = Math.floor(durationInSecs % 60);
    const timecode =
      ("0" + hours).slice(-2) +
      ":" +
      ("0" + minutes).slice(-2) +
      ":" +
      ("0" + seconds).slice(-2);
    return timecode;
  };

  return (
    <div className="video-box" key={index}>
      <div className="img-container">
        <ImageWithFallback
          className="edit-moment-video"
          imgPath={moment.thumbnail}
          imgAlt=""
          onClick={() => loadVideo(moment)}
        />
        <span className="clip-time">{secondsToTimecode(moment.start)}</span>
        <span
          className={`sentiment-val ${getSentimentLabel(
            moment.sentimentRating.toLowerCase()
          )}`}
        >
          {moment.sentimentRating}
        </span>
        <div className="controls">
          <i className="fa-solid fa-play"></i>
        </div>
      </div>
      <div className="caption">
        <br />
        <span className="tiny">{moment.projectId}</span>
        <br />
        <span
          className="title-input moment-title"
          contenteditable="true"
          onInput={(e) => updateTitle(e)}
        >
          {moment.title}
        </span>
        <hr />
        <div className="keywords">
          <strong>Keywords:</strong>
          <br />
          {keyWordArr
            ? keyWordArr.map((word) => <span className="keyword">{word}</span>)
            : null}
        </div>
        <hr />
        <span className="transcript-title">
          <strong>{t("transcript")}:</strong>
        </span>
        <span className="transcript"> {moment.transcript}...</span>
      </div>
      <div className="action-wrapper">
        <ActionButton
          icon="fa-photo-film"
          label={t("buttons.edit_moment")}
          extraClass="edit-video"
          action={() => loadVideo(moment)}
        />
        {moment.clipPath ? (
          <ActionButton
            icon="fa-solid fa-download"
            label={t("buttons.export")}
            extraClass="download-btn"
            action={() => testFunc()}
          />
        ) : (
          <ActionButton
            icon="fa-solid fa-download"
            label={t("buttons.export")}
            extraClass="download-btn"
            action={() => testFunc()}
          />
        )}
      </div>
    </div>
  );
};

export default MomentSection;
