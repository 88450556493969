import React from "react";
import { useTranslation } from "react-i18next";
import ActionButtonGroup from "./ActionButtonGroup";
import Breadcrumps from "./Breadcrumbs";

const EditorHeader = ({ videoType, processVideo }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="w-full bg-ssblue border border-ssgray shadow rounded-md relative transition-colors duration-300 ease-in-out card md:flex md:items-center md:justify-between md:space-x-5 p-4">
        <div className="flex items-start space-x-5">
          <h1 className="text-2xl block leading-tight">
            {t("streamslicer.editor_title")}
          </h1>
        </div>
        <ActionButtonGroup
          videoType={videoType}
          saveSliceAction={processVideo}
          saveNewSliceAction={null}
          cloneProjectAction={processVideo}
        />
      </div>
      <Breadcrumps />
    </>
  );
};

export default EditorHeader;
