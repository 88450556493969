import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { io } from "socket.io-client";
import Peer from "peerjs";
import { getServer } from "../helpers/GetServer";
import useStreamManager from "./useStreamManager";

export interface RoomCamProps {
  roomId?: string;
  stream?: MediaStream;
  host?: boolean;
}

export interface RoomCameraProps {
  userid: string;
  stream: MediaStream;
  data: any;
}

export type RoomCameras = RoomCameraProps[];

export interface IamProps {
  to: string;
  from: string;
}

export interface UserProps {
  roomid: string;
  userid: string;
  peerId: string;
  name: string;
  tagline: string;
  type: string;
  pos: string;
  isMuted?: boolean;
  isVideoShowing: boolean;
  isNameShowing?: boolean;
  userImg?: any;
  showid?: string;
  showSourceId?: string;
  roomsettings?: any;
}

export interface ShowStatusData {
  islive: boolean;
}
export interface ShowStatusProps {
  id: number;
  data: ShowStatusData;
}

const useRoomCams = ({ roomId, stream, host }: RoomCamProps) => {
  const StreamManager = useStreamManager();
  const { roomid } = useParams();
  //const [webSocket, setWebSocket] = useState<Socket>(null);
  const [socketSet, setSocketSet] = useState(false);
  const [room] = useState(roomId ? roomId : roomid);
  const [isHost] = useState(host ? host : false);
  const [userid, setUserid] = useState(null);
  //const [peerConnection, setPeerConnection] = useState<Peer>(null);
  const [peerId, setPeerId] = useState<string>("");
  const [camToRemove, setCamToRemove] = useState(null);
  const [peerList, setPeerList] = useState({});
  const [, setName] = useState("");
  const [, setTagline] = useState("");
  const [myStream, setMyStream] = useState<MediaStream | null>(
    stream ? stream : null
  );
  const [mySharedStream, setMySharedStream] = useState<MediaStream | null>(
    null
  );
  const [isPeerConnected, setIsPeerConnected] = useState(false);
  const [myAudioStream, setMyAudioStream] = useState<MediaStream | null>(null);
  const [userList, setUserList] = useState([]);
  const [isSharing, setIsSharing] = useState(false);
  const [isSharingAudio, setIsSharingAudio] = useState(false);
  const [camToUpdate, setCamToUpdate] = useState(null);
  const [myUserData, setMyUserData] = useState<UserProps>(null);
  const [shareUserData, setShareUserData] = useState<UserProps>(null);
  const [audioUserData, setAudioUserData] = useState<UserProps>(null);
  const [emitCamUpdate, setEmitCamUpdate] = useState(false);
  const [showStatus, setShowStatus] = useState<string>(null);
  const myStreamRef = useRef<MediaStream | null>(null);
  const mySharedStreamRef = useRef<MediaStream | null>(null);
  const myAudioStreamRef = useRef<MediaStream | null>(null);
  const isSharingRef = useRef(null);
  const isSharingAudioRef = useRef(null);
  myStreamRef.current = myStream;
  mySharedStreamRef.current = mySharedStream;
  myAudioStreamRef.current = myAudioStream;
  isSharingRef.current = isSharing;
  isSharingAudioRef.current = isSharingAudio;

  const myUserDataRef = useRef(myUserData);
  myUserDataRef.current = myUserData;

  const shareDataRef = useRef(null);
  shareDataRef.current = shareUserData;

  const audioDataRef = useRef(null);
  audioDataRef.current = audioUserData;

  const sceneCameras = StreamManager.streams;
  const streamsRef = useRef(StreamManager.streams);
  streamsRef.current = StreamManager.streams;

  const webSocket = useRef(null);
  const peerConnection = useRef(null);

  const addStream = (videoStream, data) => {
    StreamManager.addStream(videoStream, data);
  };

  const leave = () => {
    webSocket.current.emit("leave-room", roomid, userid);
  };

  const addUserToShow = (userid, pos, nameShowingStatus) => {
    setEmitCamUpdate(true);
    StreamManager.addUserToShow(userid, pos, nameShowingStatus);
  };

  const requestJoin = (joinData, usersStream?) => {
    if (usersStream) {
      setMyStream(usersStream);
    }
    webSocket.current.emit("join-room", joinData);
    setCamToUpdate({
      userid: joinData.userid,
      data: joinData,
    });
  };

  const toggleMute = (userid: string | number, muted: boolean) => {
    StreamManager.toggleMute(userid, muted);
  };

  const soloStream = (userid) => {
    setEmitCamUpdate(true);
    StreamManager.soloUser(userid);
  };

  const unSoloStream = (userid) => {
    setEmitCamUpdate(true);
    StreamManager.unSoloUser(userid);
  };

  const pipStream = (userid, isPip) => {
    setEmitCamUpdate(true);
    StreamManager.pipUser(userid, isPip);
  };

  const toggleNames = (isNameShowing) => {
    setEmitCamUpdate(true);
    StreamManager.toggleUserNames(isNameShowing);
  };

  const updateStream = (userid, stream) => {
    StreamManager.updateUsersStream(userid, stream);
  };

  const shareScreen = (user, stream, userData) => {
    setShareUserData(userData);
    setMySharedStream(stream);
    setIsSharing(true);
    //console.log("Share stream.", peerList);
    StreamManager.updateUsersStream(user, stream);
    // call all peers and send my stream)
    const callOptions = { metadata: { user: userData } };
    StreamManager.streams.forEach((cam) => {
      if (cam.userid !== user && cam.userid !== userid) {
        //console.log("CALL PEER SHARE");
        peerConnection.current.call(cam.data.peerId, stream, callOptions);
      }
    });
  };

  const unshare = (useridToRemove) => {
    //console.log("Remove", useridToRemove);
    StreamManager.removeStream(useridToRemove);
    setIsSharing(false);
    webSocket.current.emit("remove-stream", roomid, useridToRemove);
  };

  const addAudio = (audioStream, data) => {
    // console.log("Add audio to stream", audioStream, data);
    setMyAudioStream(audioStream);
    setAudioUserData(data);
    setIsSharingAudio(true);
    StreamManager.updateUsersStream(data.userid, audioStream);
    const callOptions = { metadata: { user: data } };
    StreamManager.streams.forEach((cam) => {
      if (
        cam.userid !== data.userid &&
        cam.userid !== userid &&
        cam.data.type !== "audio"
      ) {
        // console.log("CALL PEER", cam.userid, data.userid, userid);
        peerConnection.current.call(cam.data.peerId, audioStream, callOptions);
      }
    });
  };

  const removeAudio = (useridToRemove) => {
    StreamManager.removeStream(useridToRemove);
    setIsSharingAudio(false);
    webSocket.current.emit("remove-stream", roomid, useridToRemove);
  };

  const onWebsocketConnect = (data) => {
    const userToConnect = data.peerId;

    setUserList((prev) => [
      ...prev,
      { [data.peerId]: { name: data.name, tagline: data.tagline } },
    ]);

    const receivedCalls = {};
    const connectedPeers = {};

    const callOptions = { metadata: { user: myUserDataRef.current } };
    const callUser = peerConnection.current.call(
      data.peerId,
      myStreamRef.current,
      callOptions
    );

    // console.log("ON NEW USER CONNECTED", isSharingRef.current);
    if (isSharingRef.current) {
      console.log("SEND SHARED SCREEN");
      const shareCallOptions = { metadata: { user: shareDataRef.current } };

      peerConnection.current.call(
        data.peerId,
        mySharedStreamRef.current,
        shareCallOptions
      );
    }

    if (isSharingAudioRef.current) {
      // console.log("SEND SHARED SCREEN");
      const shareCallOptions = { metadata: { user: audioDataRef.current } };

      peerConnection.current.call(
        data.peerId,
        myAudioStreamRef.current,
        shareCallOptions
      );
    }

    connectedPeers[userToConnect] = callUser;

    setPeerList(connectedPeers);

    callUser.on("stream", (userVideoStream) => {
      //console.log("ON RECEIVE PEER STREAM ", data.peerId);
      if (!receivedCalls[data.peerId]) {
        receivedCalls[data.peerId] = true;
        addStream(userVideoStream, data);
      }
    });

    callUser.on("error", function (err) {
      console.log("Call User Error 1", isHost);
      console.log("Err", err);
    });
  };

  const onUpdateCams = (data) => {
    data.forEach((cam) => {
      StreamManager.updateStreamDataByUser(cam.userid, cam);
    });
    const myData = data.filter((cam) => cam.userid === userid);
    if (myData[0]) {
      setMyUserData(myData[0]);
    }
  };

  const onRemoveCam = (userToRemove) => {
    //console.log("update-cams", data);
    StreamManager.removeStream(userToRemove);
  };

  const onUserDisconnected = (user) => {
    //console.log("USER DICONNECTED", user, userList[user]);
    if (userList[user]) {
      peerList[user].close();
    }
    setCamToRemove(user.userid);
  };

  const handleShowStatusUpdate = (data) => {
    setShowStatus(data);
  };

  const startPeerConnection = () => {
    const receivedCalls = {};
    if (!webSocket.current) {
      webSocket.current = io(getServer());
    }

    peerConnection.current = new Peer({
      host: "/",
      port: 9000,
      secure: window.location.hostname === "localhost" ? false : true,
    });

    console.log(
      "LISTEN COUNT",
      peerConnection.current.listenerCount("connection")
    );
    //setPeerConnection(peerConn);
    //setWebSocket(ws);

    webSocket.current.on("me", (myUserId) => {
      setUserid(myUserId);
    });

    peerConnection.current.on("open", (id) => {
      setIsPeerConnected(true);
      //console.log("SET PEER ID", id);
      setPeerId(id);
      const allCalls = [];

      peerConnection.current.on("call", (call) => {
        //console.log("-- Answered peer call from ", call.peer, isSharing);
        allCalls[call.peer] = call;
        const userId = allCalls[call.peer].metadata.user.userid;
        const isHost = allCalls[call.peer].metadata.user.type === "host";
        setMyStream((prev) => prev);
        allCalls[call.peer].answer(myStreamRef.current);

        if (isHost) {
          //console.log(allCalls[call.peer].metadata.user, myUserDataRef.current);
          myUserDataRef.current.isNameShowing =
            allCalls[call.peer].metadata.user.isNameShowing;
          const myUpdatedData = myUserDataRef.current;
          myUpdatedData.isNameShowing =
            allCalls[call.peer].metadata.user.isNameShowing;
          //console.log("myUpdatedData", myUpdatedData);
          setMyUserData(myUpdatedData);
        }

        allCalls[call.peer].on("stream", (userConnectedStream) => {
          if (!receivedCalls[userId]) {
            receivedCalls[userId] = userConnectedStream;
            const userData = {
              ...call.metadata.user,
            };
            //setEmitCamUpdate(true);
            addStream(userConnectedStream, userData);
          } else {
            if (receivedCalls[userId].id !== userConnectedStream.id) {
              receivedCalls[userId] = userConnectedStream;
              addStream(userConnectedStream, call.metadata.user);
              /* StreamManager.updateStreamByPeerId(
                call.peer,
                userConnectedStream
              ); */
            }
          }
        });
      });
    });

    peerConnection.current.on("disconnected", (user) => {
      console.log("PEER ON DISCONECTED", user);
    });

    peerConnection.current.on("close", (user) => {
      console.log("PEER CONNECTION CLOSED", user, isPeerConnected);
      setIsPeerConnected(false);
    });

    peerConnection.current.on("error", function (err) {
      console.log("Peer Error 1");
      console.log("Err", err);
    });

    peerConnection.current.oniceconnectionstatechange = function () {
      switch (peerConnection.current.iceConnectionState) {
        case "disconnected":
        case "failed":
          // Handle the interruption
          // This could involve alerting the user, attempting to reconnect, etc.
          console.log("ICE STATE", peerConnection.current.iceConnectionState);
          console.log("RECONNECTING PEER CONNECTION");
          startPeerConnection();
          break;
        case "closed":
          // Handle the peer connection being closed
          console.log("ICE STATE", peerConnection.current.iceConnectionState);
          break;
      }
    };
  };

  useEffect(() => {
    if (StreamManager.streams) {
      streamsRef.current = StreamManager.streams;
    }
  }, [StreamManager.streams]);

  useEffect(() => {
    if (webSocket && StreamManager.streamProps.length && emitCamUpdate) {
      webSocket.current.emit("update-cams", roomid, StreamManager.streamProps);
      setEmitCamUpdate(false);
    }
  }, [StreamManager.streamProps]);

  useEffect(() => {
    if (camToUpdate) {
      StreamManager.updateStreamDataByUser(
        camToUpdate.userid,
        camToUpdate.data
      );
    }
  }, [camToUpdate]);

  useEffect(() => {
    if (myUserData && myUserData.userid) {
      StreamManager.updateStreamDataByUser(myUserData.userid, myUserData);
    }
  }, [myUserData]);

  useEffect(() => {
    if (camToRemove) {
      StreamManager.removeStream(camToRemove);
    }
  }, [camToRemove]);

  useEffect(() => {
    if (webSocket.current && myStream && !socketSet) {
      setSocketSet(true);
      webSocket.current.on("connect", () => {
        //console.log("webSocket connected:", userid);
      });

      webSocket.current.on("user-connected", onWebsocketConnect);
      webSocket.current.on("update-cams", onUpdateCams);
      webSocket.current.on("remove-stream", onRemoveCam);
      webSocket.current.on("user-disconnected", onUserDisconnected);
      webSocket.current.on(
        "shareable-show-status-update",
        handleShowStatusUpdate
      );
    }

    /* return () => {
      if (myStream) {
        myStream.getTracks().forEach((track) => {
          console.log("STOPPING TRACK Room Cams", track);
          track.stop();
        });
      }
    }; */
  }, [webSocket, myStream]);

  useEffect(() => {
    startPeerConnection();

    return () => {
      if (webSocket.current) {
        //console.log("Room cam cleanup");
        webSocket.current.off("user-connected", onWebsocketConnect);
        webSocket.current.off("update-cams", onUpdateCams);
        webSocket.current.off("remove-stream", onRemoveCam);
        webSocket.current.off("user-disconnected", onUserDisconnected);
        webSocket.current.off(
          "shareable-show-status-update",
          handleShowStatusUpdate
        );
        /* if (myStream) {
          myStream.getTracks().forEach((track) => {
            console.log("STOPPING TRACK", track);
            track.stop();
          });
        } */
      }
    };
  }, []);

  return {
    room,
    sceneCameras,
    userid,
    addStream,
    requestJoin,
    setMyStream,
    setName,
    setTagline,
    leave,
    setMyUserData,
    setEmitCamUpdate,
    myUserData,
    addUserToShow,
    peerId,
    webSocket,
    soloStream,
    unSoloStream,
    toggleNames,
    shareScreen,
    unshare,
    updateStream,
    addAudio,
    removeAudio,
    toggleMute,
    pipStream,
    showStatus,
  };
};

export default useRoomCams;
