import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";

export interface NavItemProps {
  dest: string;
  label: string;
  icon: any;
}

const NavItem = ({ dest, label, icon }: NavItemProps) => {
  const currentPath = window.location.pathname;
  const liClass =
    "flex  border-b border-slate-700 hover:bg-slate-700 transition-colors duration-300";
  const aClass =
    "block w-full p-2 text-right no-underline hover:text-yellow-600 transition-colors duration-300";
  const aClassActive =
    "block w-full p-2 text-right text-yellow-600 no-underline hover:text-yellow-600 transition-colors duration-300 bg-slate-800";

  return (
    <li className={liClass}>
      <Link to={dest} className={currentPath === dest ? aClassActive : aClass}>
        {label} <FontAwesomeIcon icon={icon} className="ml-2" />
      </Link>
    </li>
  );
};

export default NavItem;
