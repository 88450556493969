import React from "react";
import useNotifications from "../../hooks/useNotifications";

const NotificationSwitch = () => {
  const notis = useNotifications();

  const requestPermissions = () => {
    if (!notis.hasNotificationPermission) {
      notis.requestPermission();
    }
  };

  return (
    <>
      {notis.notificationCapable ? (
        <>
          <h2>Notifications</h2>
          <label>
            Would you like to receive notifications when segment times are low?
          </label>{" "}
          <input
            type="checkbox"
            onChange={requestPermissions}
            checked={notis.hasNotificationPermission}
          />
        </>
      ) : (
        <span>
          To enable notifications save this site to your homescreen and launch
          it via the ShoPro Icon.{" "}
          <a
            href="https://support.apple.com/guide/iphone/bookmark-favorite-webpages-iph42ab2f3a7/ios"
            target="inst"
          >
            ios users see here.
          </a>{" "}
          |{" "}
          <a
            href="https://support.google.com/android/answer/9450271?hl=en"
            target="inst"
          >
            Android users see here.
          </a>
        </span>
      )}
    </>
  );
};

export default NotificationSwitch;
