import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { io, Socket } from "socket.io-client";
import axios from "axios";
import Header from "../layout/Header";
import { ShowData, TopicData, TopicDataArray } from "../../types/Types";
import { dateDisplay, formatTimestamp } from "../../helpers/Dates";
import CardReadOnly from "./components/CardReadOnly";
import { calculateTimeDuration, dateToTime } from "../../helpers/TimeCode";
import { getServer } from "../../helpers/GetServer";
import ShowTime from "../../features/Shopro/components/ShowTime";
import { PasswordPrompt } from "./components/PasswordPrompt";
import useTracking from "../../hooks/useTracking";
import Chat from "../../features/controlCenter/components/Chat/Index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComments } from "@fortawesome/free-solid-svg-icons";
import { tw } from "../../helpers/Styles";
import { useTranslation } from "react-i18next";

export interface ShareableShowProps {
  joinedShowid?: string;
  hasHeader?: boolean;
  isInControlCenter?: boolean;
  useChat?: boolean;
  onShowStatusChanged?: (status: boolean) => void;
  onElapsedTime?: (showtime: string) => void;
  onSourceId?: (sourceid: string) => void;
  onOwnerId?: (ownerid: string) => void;
  controlCenterRef?: any;
}

const ShareableShow = ({
  joinedShowid,
  hasHeader,
  isInControlCenter,
  useChat,
  onShowStatusChanged,
  onElapsedTime,
  onSourceId,
  onOwnerId,
  controlCenterRef,
}: ShareableShowProps) => {
  useTracking();
  const { shareid } = useParams();
  const { t } = useTranslation();

  const [showidToLoad, setShowIdToLoad] = useState(
    isInControlCenter === true ? joinedShowid : shareid
  );

  //console.log("joinedShowid", joinedShowid, shareid, showidToLoad);

  const today = new Date();
  const [topics, setTopics] = useState<TopicDataArray>([]);
  const [showId, setShowId] = useState<number>(0);
  const [showOwnerId, setShowOwnerId] = useState(null);
  const [, setEpidsode] = useState<string | number>("Episode #");
  const [showTitle, setShowTitle] = useState<string>("The Show");
  const [showDate, setShowDate] = useState<string | number>(dateDisplay(today));
  const [showUrl, setShowUrl] = useState<string>("");
  const [estimatedShowTime, setEstimatedShowTime] = useState<number>(0);
  const [isShowLive, setIsLive] = useState<boolean>(false);
  const [currentTopic, setCurrentTopic] = useState<number>(-1);
  const [defaultPageTitle, setDefaultPageTitle] = useState<string>("");
  const [lastTopicStart, setLastTopicStart] = useState<number>(0);
  const [elapsedTime, setElapsedTime] = useState<string>("");
  const [showStartTime, setShowStartTime] = useState<string>("");
  const [showStartTimeStamp, setShowStartTimeStamp] = useState<number>(0);
  //const [isVisible, setIsVisible] = useState(true);
  const [fullShowData, setFullShowData] = useState<ShowData>();
  const [doingShowLoad, setDoingShowLoad] = useState<boolean>();
  const [isWideView, setIsWideView] = useState<boolean>(false);
  const [websocket, setWebsocket] = useState<Socket>();
  const [webSocketId, setWebSocketId] = useState(null);
  const [socketResultData, setSocketResultData] = useState<any>(null);
  const [socketStatusData, setSocketStatusData] = useState<any>(null);
  const [socketShowData, setSocketShowData] = useState<any>(null);
  const [isPasswordProtected, setIsPasswordProtected] = useState<
    boolean | null
  >(null);
  const [chatOpen, setChatOpen] = useState(false);
  const [showPassword, setShowPassword] = useState<string>("default_show_pass");
  const [usersPassword, setUsersPassword] =
    useState<string>("default_user_pass");
  const fullWidthClass =
    isInControlCenter && isInControlCenter === true ? "full-width" : "";

  const convertTextLinksToHtml = (text: string) => {
    // Regular expression to match URLs in text
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const parts = text.split(urlRegex);

    const elements = parts.map((part, index) => {
      if (urlRegex.test(part)) {
        // If the part is a URL, return an anchor tag
        return (
          <a href={part} key={index} target="_blank" rel="noopener noreferrer">
            {part}
          </a>
        );
      } else {
        // If the part is not a URL, return plain text
        return part;
      }
    });

    return elements;
  };

  const handleVisibilityChange = () => {
    if (document.hidden) {
      //setIsVisible(false);
    } else {
      //console.log(isVisible);
      //setIsVisible(true);
      setDoingShowLoad(true);
      loadShow();
    }
  };

  const loadShow = () => {
    if (showidToLoad !== undefined && showidToLoad !== null) {
      const postData = {
        showid: showidToLoad,
      };

      if (!doingShowLoad) {
        axios.post("/api/sharedshow", postData).then((response) => {
          if (response.data.success && response.data.items) {
            const jsonResult = response.data.items.show;
            setShowOwnerId(response.data.items.userid);
            getsourcesId(response.data.items.userid);
            setFullShowData(response.data.items);
            setShowId(response.data.items.id);
            setCurrentTopic(
              isShowLive || response.data.items.islive
                ? response.data.items.currenttopic
                : -1
            );
            setEpidsode(jsonResult.episode);
            setShowTitle(jsonResult.showTitle);
            setShowUrl(jsonResult.epUrlId);
            setIsLive(response.data.items.islive);

            setLastTopicStart(response.data.items.lasttopicstart);
            const showStart = new Date(response.data.items.starttime);
            setShowStartTimeStamp(response.data.items.starttime);
            setShowStartTime(dateToTime(showStart));
            setEstimatedShowTime(0);
            setShowDate(jsonResult.show_date);
            setTopics(jsonResult.task_list_items);
            const estTime = jsonResult.task_list_items.reduce(
              (total: number, topic: TopicData) =>
                total + Number(topic.topic_time),
              0
            );

            setEstimatedShowTime(estTime);
            const now = new Date();
            const elapsed = calculateTimeDuration(
              dateToTime(showStart),
              dateToTime(now)
            );

            if (response.data.endtime !== null) {
              const elapsed = calculateTimeDuration(
                dateToTime(new Date(response.data.items.starttime)),
                dateToTime(new Date(response.data.items.endtime))
              );

              setElapsedTime(elapsed);
            } else {
              setElapsedTime(isShowLive ? elapsed : "--:--:--");
            }

            document.title = `Join the show - ${jsonResult.showTitle} `;
            setDoingShowLoad(false);
            //console.log("PASSWORD", response.data.items.password);
            if (
              response.data.items.password === null ||
              response.data.items.password.length === 0
            ) {
              // console.log("NO PASSWORD");
              setIsPasswordProtected(false);
            } else {
              setShowPassword(response.data.items.password);
              setIsPasswordProtected(true);
            }
          }
        });
      }
    }
  };

  const getsourcesId = (userid) => {
    const postData = {
      userid: userid,
    };
    axios.post("/api/showsourceid", postData).then((response) => {
      if (onSourceId) {
        onSourceId(response.data.items.sourceid);
      }
    });
  };

  const handleShowTopicUpdate = (data) => {
    const resultData = JSON.parse(data);
    setSocketResultData(resultData);
  };

  const handleShowStatusUpdate = (data) => {
    const resultData = JSON.parse(data);
    setSocketStatusData(resultData);
  };

  const handleShowDataUpdate = (data) => {
    const resultData = JSON.parse(data);
    setSocketShowData(resultData);
  };

  const handleUsersPassword = (pass: string) => {
    setUsersPassword(pass);
  };

  const toggleChat = () => {
    setChatOpen(!chatOpen);
  };

  useEffect(() => {
    if (onOwnerId && showOwnerId) {
      onOwnerId(showOwnerId);
    }
  }, [showOwnerId]);

  useEffect(() => {
    if (showPassword === usersPassword) {
      setIsPasswordProtected(false);
    }
  }, [isPasswordProtected, showPassword, usersPassword]);

  useEffect(() => {
    if (socketShowData) {
      if (showId === socketShowData.id) {
        setDoingShowLoad(true);
        loadShow();
      }
    }
  }, [socketShowData]);

  useEffect(() => {
    if (showidToLoad) {
      loadShow();
    }
  }, [showidToLoad]);

  useEffect(() => {
    if (joinedShowid) {
      console.log(joinedShowid);
      setShowIdToLoad(joinedShowid);
      loadShow();
    }
  }, [joinedShowid]);

  useEffect(() => {
    if (socketStatusData) {
      if (showId === socketStatusData.id) {
        setIsLive(socketStatusData.data.islive);
        if (!socketStatusData.data.islive) {
          setCurrentTopic(-1);
          //loadShow();
        } else {
          setCurrentTopic(0);
        }
      }
    }
  }, [socketStatusData]);

  useEffect(() => {
    if (socketResultData) {
      if (socketResultData.data.showid === showId) {
        setCurrentTopic(socketResultData.data.topicid);
        setLastTopicStart(socketResultData.data.topicstart);
        const showStart = new Date(socketResultData.data.starttime);
        setShowStartTimeStamp(socketResultData.data.starttime);
        setShowStartTime(dateToTime(showStart));
        const now = new Date();
        const elapsed = calculateTimeDuration(
          dateToTime(showStart),
          dateToTime(now)
        );
        setElapsedTime(elapsed);
      }
    }
  }, [socketResultData]);

  useEffect(() => {
    if (!isShowLive) {
      setCurrentTopic(-1);
    } else {
      //console.log("SET TOPIC", fullShowData.currenttopic);
      setCurrentTopic(fullShowData.currenttopic);
    }
    if (onShowStatusChanged) {
      onShowStatusChanged(isShowLive);
    }
  }, [isShowLive]);

  useEffect(() => {
    if (websocket) {
      websocket.connect();
      websocket.on("connect", () => {
        //console.log("Connected to server");
        setWebSocketId(websocket.id);
        websocket.on("shareable-topic-update", handleShowTopicUpdate);
        websocket.on("shareable-show-status-update", handleShowStatusUpdate);
        websocket.on("shareable-show-data-update", handleShowDataUpdate);
        //websocket.emit("join-chat", { roomid: shareid });
      });
    }

    return () => {
      if (websocket) {
        websocket.off("shareable-topic-update", handleShowTopicUpdate);
        websocket.off("shareable-show-status-update", handleShowStatusUpdate);
        websocket.off("shareable-show-data-update", handleShowDataUpdate);
        //console.log("Disconnected to server");
        websocket.disconnect();
      }
    };
  }, [websocket]);

  useEffect(() => {
    let timerInt: NodeJS.Timeout;

    if (isShowLive) {
      timerInt = setTimeout(() => {
        const now = new Date();
        const elapsed = calculateTimeDuration(showStartTime, dateToTime(now));
        setElapsedTime(elapsed);
        if (onElapsedTime) {
          onElapsedTime(elapsed);
        }
      }, 1000);
    }
    return () => {
      clearTimeout(timerInt);
    };
  }, [elapsedTime, isShowLive]);

  useEffect(() => {
    /*eventTracker({category:'PAGE_LOAD',action:"SHAREABLE_SHO"});
     ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
      title: "Shopro",
    }); */
    setWebsocket(io(getServer()));
    setDefaultPageTitle(document.title);
    loadShow();
    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.title = defaultPageTitle;
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);
  return (
    <>
      {hasHeader !== false && (
        <>
          {isShowLive ? (
            <div className="flex flex-row p-1 bg-[#ff002a] sticky top-0 z-10 justify-center font-bold uppercase">
              {t("show_is_live")} - {elapsedTime}
            </div>
          ) : (
            <Header />
          )}
        </>
      )}
      <div
        className={isInControlCenter ? "w-full" : "w-full p-2 "}
        key="main"
        data-testid="shareable-show"
      >
        {topics.length && !isPasswordProtected ? (
          <>
            <div
              className={
                isInControlCenter
                  ? `flex flex-col my-3`
                  : `flex flex-col sm:flex-row`
              }
            >
              <div
                className={
                  isInControlCenter
                    ? "w-full justify-center text-center"
                    : "w-full md:w-1/2 lg:w-4/12 border-b pb-4 sm:pb-0 mb-4 border-b-white sm:border-b-0 sm:border-r sm:border-white text-center sm:text-left sm:border-b-none"
                }
              >
                <span className="show-title text-4xl">{showTitle}</span>
                <br />
                <span className="show-date">{formatTimestamp(showDate)}</span>
                <br />
                <span className="show-url">
                  {convertTextLinksToHtml(showUrl)}
                </span>
              </div>
              <div
                className={
                  isInControlCenter
                    ? "w-full mt-3"
                    : "w-full md:w-1/2 lg:w-8/12 sm:pl-5"
                }
              >
                <ShowTime
                  isLive={isShowLive}
                  showHasEnded={fullShowData?.endtime !== null ? true : false}
                  showDate={showDate}
                  elapsed={elapsedTime}
                  startTime={showStartTimeStamp}
                  endTime={
                    fullShowData?.endtime !== null ? fullShowData?.endtime : 0
                  }
                  estimatedShowTime={estimatedShowTime}
                  topics={topics}
                />
              </div>
            </div>
            {isInControlCenter !== true && (
              <div className="show-controls w-full">
                <button
                  className={`${tw.btn.action} bg-blue hover:bg-blue2`}
                  onClick={() => setIsWideView(!isWideView)}
                >
                  {isWideView ? "Normal View" : "Short View"}
                </button>
              </div>
            )}
            <div
              className={`grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 topics altcard ${fullWidthClass} ${
                isWideView ? "reorder" : ""
              }`}
            >
              {topics.map((topic, index) => (
                <div
                  className={isWideView ? `${tw.card} wide` : `${tw.card} `}
                  key={index}
                >
                  <CardReadOnly
                    index={index}
                    cardData={topic}
                    allTopics={topics}
                    islive={isShowLive}
                    currentTopic={currentTopic}
                    startTime={lastTopicStart}
                    showStartTimeStamp={showStartTimeStamp}
                    showDate={showDate}
                    isInControlCenter={isInControlCenter}
                    controlCenterRef={controlCenterRef}
                  />
                </div>
              ))}
            </div>
            {useChat !== false && (
              <>
                <div
                  className={
                    chatOpen
                      ? `w-[250px] h-[450px] bottom-0 right-3 fixed border-[#213343] bg-[#202935] z-10 transition-all duration-300 border `
                      : "w-[250px] h-[450px] bottom-[-100%] right-3 fixed border-[#213343] z-10 bg-[#202935] transition-all duration-300 border"
                  }
                >
                  <Chat
                    socket={websocket}
                    name={""}
                    roomid={shareid}
                    userid={webSocketId}
                    chatType="SHOW_CHAT"
                    headerLabel="Show Chat"
                    closeBtn={toggleChat}
                  />
                </div>
                <div className="chat-btn fixed z-5 bottom-[20px] right-[20px] text-sm border rounded-full p-2 bg-sky-800 hover:bg-sky-500 transition-colors duration-300 pointer">
                  <FontAwesomeIcon icon={faComments} onClick={toggleChat} />
                </div>
              </>
            )}
          </>
        ) : isPasswordProtected ? (
          <PasswordPrompt onpassword={handleUsersPassword} />
        ) : (
          <>
            <div className="col1">
              <div className="section-wrap centered">
                This show does not exist.
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default ShareableShow;
