import React, { useEffect, useRef, useState } from "react";
import AudioLevel from "./AudioLevel";
import StreamOptions, { Solutions } from "./StreamOptions";
import toast from "react-hot-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMicrophoneSlash } from "@fortawesome/free-solid-svg-icons";

export interface UserStreamProps {
  userid: string;
  stream?: MediaStream | null;
  cameraClass?: string;
  userData: any;
  myid?: string;
  isVideoShowing?: boolean;
  isAudioMuted?: boolean;
  onCreatedStream: (stream) => void;
  onSoloStream?: (userid) => void;
  onUnSoloStream?: (userid) => void;
  addToShow?: (userid, pos) => void;
  showTitles?: boolean;
  hasAdminTools?: boolean;
  userImg?: any;
  cameraPos?: string;
  isGreenRoom?: boolean;
  isOwner?: boolean;
  toggleMute?: (userid: string | number, muteStatus: boolean) => void;
}

const UserStream = ({
  userid,
  stream,
  cameraClass,
  onCreatedStream,
  userData,
  myid,
  isAudioMuted,
  isVideoShowing,
  addToShow,
  showTitles,
  hasAdminTools,
  onSoloStream,
  onUnSoloStream,
  userImg,
  cameraPos,
  isGreenRoom,
  isOwner,
  toggleMute,
}: UserStreamProps) => {
  const [thisVideoStream, setThisVideoStream] = useState(null);
  const [leftPosPercent, setLeftPosPercent] = useState(0);
  const [topPosPercent, setTopPosPercent] = useState(0);
  const [heightPercent, setHeightPercent] = useState(0);
  const [widthPercent, setWidthPercent] = useState(0);
  const [isSolo, setIsSolo] = useState(userData.pos === "solo" ? true : false);
  const [isHostControlled] = useState(isOwner ? isOwner : false);
  //const [killCamOnExit] = useState(releaseCamera ? releaseCamera : false);
  const [isNameShowing, setIsNameShowing] = useState<boolean>(
    showTitles !== null ? showTitles : true
  );
  const [adminToolsShowing, setAdminToolsShowing] = useState(false);
  const [solutionList, setSolutionList] = useState<Solutions>();

  const videoRef = useRef(null);
  const wrapperRef = useRef(null);
  const timeoutRef = useRef(null);
  const intStart = useRef<Date | null>(null);
  const showAudioLevels = false;

  const addVideoStream = (stream) => {
    if (thisVideoStream) {
      //videoRef.current.srcObject = null;
    } else {
      //console.log("SET PLAY PROMISE");
      const playPromise = videoRef.current.play();

      if (playPromise !== undefined) {
        playPromise
          .then(() => {
            // Video is playing
            //console.log("VIDEO IS PLAYING");
          })
          .catch((error) => {
            console.error("Autoplay was prevented: ", error);
            // You can inform the user and provide a play button or other user-initiated action.
            if (videoRef.current) {
              console.log("PLAY VIDEO");
              videoRef.current.play();
            }
          });
      }
    }

    setThisVideoStream(stream);
  };

  const setAdminTools = (action) => {
    switch (action.toUpperCase()) {
      case "ADD-STAGE":
        addUserToStage(userid, "stage");
        break;

      case "GREEN-ROOM":
        addUserToStage(userid, "green-room");
        break;

      case "UN-SOLO":
        unSoloStream(userid);
        break;

      case "SOLO-USER":
        soloStream(userid);
        break;

      case "ADD-OBS":
        getObsUrl();
        break;

      case "TOGGLE-MUTE":
        toggleMute(userid, !userData.isMuted);
        break;
    }
  };

  const getObsUrl = () => {
    const urlParams = `${window.location.protocol}//${window.location.hostname}/stream/${userData.roomid}/${userData.peerId}`;
    navigator.clipboard.writeText(urlParams);
    toast(`${userData.name}'s individual stream URL copied to clipboard.`);
    setAdminToolsShowing(false);
  };

  const addUserToStage = (userid, pos) => {
    setAdminToolsShowing(false);
    addToShow(userid, pos);
  };

  const soloStream = (userid) => {
    onSoloStream(userid);
    setIsSolo(true);
    setAdminToolsShowing(false);
  };

  const calculateLeftPos = (userid) => {
    const parentElement = document.getElementById("video-stage");
    const targetElement = document.getElementById(`wrap-${userid}`);
    if (!targetElement || !parentElement) {
      return 0;
    }

    const boundingBox = targetElement.getBoundingClientRect();
    const parentBoundingBox = parentElement.getBoundingClientRect();

    const leftPosition =
      boundingBox.left - parentBoundingBox.left + window.pageXOffset; // Adjust for scroll
    const parentWidth = parentElement.getBoundingClientRect().width;
    return (leftPosition / parentWidth) * 100;
  };

  const calculateTopPos = (userid) => {
    const parentElement = document.getElementById("video-stage");
    const targetElement = document.getElementById(`wrap-${userid}`);
    if (!targetElement || !parentElement) {
      return 0;
    }

    const boundingBox = targetElement.getBoundingClientRect();
    const parentBoundingBox = parentElement.getBoundingClientRect();

    const topPosition =
      boundingBox.top - parentBoundingBox.top + window.pageYOffset; // Adjust for scroll
    const parentHeight = parentElement.getBoundingClientRect().height;

    return (topPosition / parentHeight) * 100;
  };

  const calculateHeightPercentage = (userid) => {
    const parentElement = document.getElementById("video-stage");
    const targetElement = document.getElementById(`wrap-${userid}`);

    if (!targetElement || !parentElement) {
      return 0;
    }

    const boundingBox = targetElement.getBoundingClientRect();
    const parentBoundingBox = parentElement.getBoundingClientRect();

    const elementHeight = boundingBox.height;
    const parentHeight = parentBoundingBox.height;

    return (elementHeight / parentHeight) * 100;
  };

  const calculateWidthPercentage = (userid) => {
    const parentElement = document.getElementById("video-stage");
    const targetElement = document.getElementById(`wrap-${userid}`);

    if (!targetElement || !parentElement) {
      return 0;
    }

    const boundingBox = targetElement.getBoundingClientRect();
    const parentBoundingBox = parentElement.getBoundingClientRect();

    const elementWidth = boundingBox.width;
    const parentWidth = parentBoundingBox.width;

    return (elementWidth / parentWidth) * 100;
  };

  const onAni = () => {
    setLeftPosPercent(calculateLeftPos(userid));
    setTopPosPercent(calculateTopPos(userid));
    setHeightPercent(calculateHeightPercentage(userid));
    setWidthPercent(calculateWidthPercentage(userid));
    const currentTime = new Date();
    const elapsedMilliseconds =
      currentTime.getTime() - (intStart.current?.getTime() || 0);

    if (elapsedMilliseconds >= 600) {
      clearInterval(timeoutRef.current);
    }
  };

  const unSoloStream = (userid) => {
    onUnSoloStream(userid);
    setIsSolo(false);
    setAdminToolsShowing(false);
  };

  useEffect(() => {
    //console.log("cameraPos", cameraPos, userData);
    const tempSolitions = [];

    if (cameraPos === "green-room") {
      tempSolitions.push({
        name: "Add To Stage",
        clickMethod: "add-stage",
      });
    } else {
      if (userData.type !== "share") {
        tempSolitions.push({
          name: "Move Backstage",
          clickMethod: "green-room",
        });
      }
    }
    if (isSolo) {
      tempSolitions.push({
        name: userData.type !== "share" ? "Un Solo User" : "Un Share Screen",
        clickMethod: "un-solo",
      });
    } else {
      tempSolitions.push({
        name: userData.type !== "share" ? "Solo User" : "Share Screen",
        clickMethod: "solo-user",
      });
    }
    if (userData.isMuted) {
      tempSolitions.push({
        name: "Unmute",
        clickMethod: "toggle-mute",
      });
    } else {
      tempSolitions.push({
        name: "Mute",
        clickMethod: "toggle-mute",
      });
    }
    if (userData.type !== "share") {
      tempSolitions.push({
        name: "Add To OBS",
        clickMethod: "add-obs",
      });
    }

    setSolutionList(tempSolitions);
  }, [cameraPos, isSolo, userData]);

  useEffect(() => {
    intStart.current = new Date();
    if (timeoutRef.current) {
      clearInterval(timeoutRef.current);
    }

    timeoutRef.current = setInterval(onAni, 20);
  }, [cameraClass]);

  useEffect(() => {
    if (userData.pos) {
      setIsSolo(userData.pos === "solo" ? true : false);
    }
  }, [userData.pos]);

  useEffect(() => {
    if (stream) {
      //console.log("ATTACH STREAM", userData.type, stream);
      videoRef.current.srcObject = stream;
    }
    /* return () => {
      console.log("STOPPING STREAM?", stream);
      if (stream) {
        stream.getTracks().forEach((track) => {
          //console.log("STOPPING TRACK", track);
          track.stop();
        });
      }
    }; */
  }, [stream]);

  useEffect(() => {
    if (showTitles !== null && showTitles !== undefined) {
      setIsNameShowing(showTitles);
    }
  }, [showTitles]);

  useEffect(() => {
    if (stream) {
      addVideoStream(stream);
      if (myid === userid) {
        videoRef.current.muted = true;
      }
    } else {
      navigator.mediaDevices
        .getUserMedia({
          video: true,
          audio: true,
        })
        .then((myStream) => {
          addVideoStream(myStream);
          onCreatedStream(myStream);
          videoRef.current.muted = true;
        });
    }
  }, []);

  return (
    <div
      key={`uservid_${myid}`}
      className={
        userData.type === "audio"
          ? "absolute r-[300]"
          : `relative flex flex-col aspect-video w-full transition-all duration-300 z-10 ${
              !isGreenRoom && "overflow-hidden"
            } ${cameraClass} ${userData.type}`
      }
      id={`wrap-${userid}`}
      data-left={leftPosPercent}
      data-top={topPosPercent}
      data-height={heightPercent}
      data-width={widthPercent}
      ref={wrapperRef}
      onTransitionEnd={onAni}
      onTransitionEndCapture={onAni}
    >
      <video
        className={
          userImg !== undefined && userImg !== null
            ? "w-full transition-all duration-300 aspect-video hidden"
            : isVideoShowing
            ? "w-full transition-all duration-300 aspect-video "
            : "w-full transition-all duration-300 aspect-video hidden"
        }
        data-id="tester"
        id={userid}
        ref={videoRef}
        autoPlay={true}
        playsInline
        muted={
          userData.type === "audio" || userData.type === "share"
            ? isHostControlled
              ? true
              : false
            : userid === myid
            ? true
            : isAudioMuted
        }
      ></video>

      <div
        className={
          userImg === undefined || userImg === null || !isVideoShowing
            ? "hidden"
            : "user-img-wrap flex w-full h-auto aspect-video justify-center flex-wrap bg-[#14202e]"
        }
      >
        <div className="circle-container">
          <img className="preview-img" src={userImg} />
        </div>
      </div>

      <div
        className={
          isVideoShowing
            ? "vid-img hidden"
            : "vid-img use-image object-cover w-full h-full"
        }
      >
        <img
          src="/img/chat/vid-off.png"
          className="object-cover h-auto w-full"
        />
      </div>

      {isAudioMuted === true && (
        <div className="muted-video absolute top-1 left-1 text-xs">
          <FontAwesomeIcon icon={faMicrophoneSlash} />
        </div>
      )}
      {hasAdminTools && !adminToolsShowing && (
        <StreamOptions
          userid={userid}
          solutions={solutionList}
          optionAction={setAdminTools}
        />
      )}
      {thisVideoStream !== null && showAudioLevels && (
        <AudioLevel stream={thisVideoStream} />
      )}

      {userData.name && isGreenRoom ? (
        <div
          className={
            isNameShowing ? "name-wrap on p-2 text-xs" : "name-wrap hidden"
          }
        >
          <span className="display-name block">{userData.name}</span>
          {userData.tagline && (
            <>
              <span className="tag-line block">{userData.tagline}</span>
            </>
          )}
        </div>
      ) : (
        <div
          className={`
            
              absolute top-${
                isNameShowing ? "1" : "[-100px]"
              } right-1 transition-all duration-500 p-2 bg-[#14202e] min-w-[150px] text-right bg-opacity-80 rounded-lg ease-in-out
             `}
        >
          <span className="block text-base ">{userData.name}</span>
          {userData.tagline && (
            <span className="text-sm block -mb-2 -mx-2 py-1 px-2 rounded-b-lg bg-red bg-opacity-80">
              {userData.tagline}
            </span>
          )}
        </div>
      )}
    </div>
  );
};

export default UserStream;
