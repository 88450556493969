import React from "react";

const LiveShowItem = ({ show, user, image }) => {
  const { showTitle } = show;

  return (
    <div
      key={show.id}
      className="flex flex-col flex-1 items-center justify-center"
    >
      <div className="block aspect-video w-auto max-w-[720px]">
        <img src={image} />
      </div>
      <div className="flex flex-col w-full justify-center items-center">
        <h3 className="my-2 text-3xl font-semibold">{showTitle}</h3>
        {user.display_name !== null && user.display_name !== "" && (
          <div>Hosted by {user.display_name}</div>
        )}
        <div>
          <a
            href={`/watch/${user.roomid}`}
            className="block no-underline p-3 bg-blue-charcoal-600 hover:bg-tangerine-600 rounded-md text-sm mt-2 text-center transition-colors duration-300 ease-in-out"
          >
            WATCH LIVE NOW
          </a>
        </div>
      </div>
    </div>
  );
};

export default LiveShowItem;
