import React from "react";
import { useTranslation } from "react-i18next";
import ActionButton from "../../../components/ActionButton";
import { faClone, faFloppyDisk } from "@fortawesome/free-solid-svg-icons";

export const ActionButtonGroup = ({
  videoType,
  saveSliceAction,
  saveNewSliceAction,
  cloneProjectAction,
}) => {
  const { t } = useTranslation();

  return (
    <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-3 sm:space-y-0 sm:space-x-reverse md:mt-0 md:flex-row md:space-x-3">
      {videoType === "MOMENT" ? (
        <ActionButton
          icon={faFloppyDisk}
          label={t("buttons.save_moment")}
          extraClass="bg-green text-white hover:bg-greenHover hover:text-white"
          action={saveSliceAction}
        />
      ) : null}

      {videoType === "PROJECT" ? (
        <>
          <ActionButton
            icon={faFloppyDisk}
            label={t("buttons.save_new_clip")}
            extraClass="bg-green text-white hover:bg-greenHover hover:text-white"
            action={saveNewSliceAction}
          />
          <ActionButton
            icon={faClone}
            label={t("buttons.clone_project")}
            extraClass="bg-blue text-white hover:bg-blueHover hover:text-white"
            action={cloneProjectAction}
          />
        </>
      ) : null}
    </div>
  );
};

export default ActionButtonGroup;
