import React, { Fragment, useEffect, useRef, useState } from "react";
import { getBearerHeaders } from "../../../helpers/GetBearerHeaders";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark, faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { Dialog, Transition } from "@headlessui/react";
import { tw } from "../../../helpers/Styles";

const ShareWindow = ({
  showid,
  shareid,
  close,
  onshareId,
  pass,
  onSharePassUpdate,
  isOpen,
}) => {
  const [open, setOpen] = useState(isOpen);
  const currentURL = window.location.href;
  const urlParts = new URL(currentURL);
  const domainWithPort = urlParts.host;
  const [showShareId, setShareId] = useState<string>(shareid);
  //const [showQr, setShowQr] = useState<boolean>(false);
  const [imgData, setImgData] = useState<string>("");
  const [passUpdated, setPassUpdated] = useState<boolean>();
  const [passCleared, setPassCleared] = useState<boolean>();
  const [lastPass, setLastPass] = useState<string>(pass);
  const inputRef = useRef<HTMLInputElement>(null);
  const passRef = useRef<HTMLInputElement>(null);
  const timeoutRef = useRef(null);

  const copyToClipboard = () => {
    if (inputRef.current) {
      inputRef.current.select();
      document.execCommand("copy");
      window.getSelection()?.removeAllRanges();
      close();
    }
  };

  const useQr = () => {
    const postData = {
      url: `https://${domainWithPort}/shared/${showShareId}`,
    };
    axios
      .post("/api/v1/generateqr", postData, getBearerHeaders())
      .then((response) => {
        if (response.data.success) {
          //console.log(response.data.items);
          //setShowQr(true);
          setImgData(response.data.items);
        }
      });
  };

  const doPasswordSaveApiCal = (postData, type) => {
    axios
      .post("/api/v1/updateshowpass", postData, getBearerHeaders())
      .then((response) => {
        if (response.data.success) {
          clearTimeout(timeoutRef.current);
          if (type === "update") {
            setPassUpdated(true);
          } else {
            setPassCleared(true);
          }
          onSharePassUpdate(postData.pass);
          timeoutRef.current = setTimeout(() => {
            if (type === "update") {
              setPassUpdated(false);
            } else {
              setPassCleared(false);
            }
          }, 5000);
        } else {
          if (type === "update") {
            setPassUpdated(false);
          } else {
            setPassCleared(false);
          }
        }
      });
  };

  const updatePassword = () => {
    const postData = {
      shareid: showShareId,
      pass: passRef.current.value,
    };
    console.log("Post Data", postData);
    doPasswordSaveApiCal(postData, "update");
  };

  const clearPassword = () => {
    const postData = {
      shareid: showShareId,
      pass: "",
    };
    doPasswordSaveApiCal(postData, "clear");
    setLastPass("");
    passRef.current.value = "";
  };

  const updateShareId = () => {
    const postData = {
      showid: showid,
    };
    axios
      .post("/api/v1/generateshareid", postData, getBearerHeaders())
      .then((response) => {
        if (response.data.success) {
          setShareId(response.data.items.shareid);
          onshareId(response.data.items.shareid);
          useQr();
        }
      });
  };

  useEffect(() => {
    setShareId(shareid);
  }, [shareid]);

  useEffect(() => {
    useQr();
  }, [showShareId]);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  useEffect(() => {
    if (showShareId === null || showShareId === "") {
      updateShareId();
    } else {
      useQr();
    }
  }, []);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-[260]" onClose={close}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className={tw.modalBg} />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 left-0 flex max-w-full pr-5">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-300 sm:duration-300"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-300 sm:duration-300"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col overflow-y-auto bg-white drop-shadow-lg border-r border-ssblue">
                    <div className="bg-ssblue px-4 py-6 sm:px-6">
                      <div className="flex items-center justify-between">
                        <Dialog.Title className="text-base font-semibold leading-6 text-white">
                          Share
                        </Dialog.Title>
                        <div className="ml-3 flex h-7 items-center ">
                          <FontAwesomeIcon
                            className="relative rounded-md bg-ssbluehover text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white pointer"
                            icon={faCircleXmark}
                            onClick={close}
                          />
                        </div>
                      </div>
                      <div className="mt-1">
                        <p className="text-sm text-indigo-300">
                          Share this URL with your co-hosts and guest so
                          everyone is on the same page.
                        </p>
                      </div>
                    </div>
                    <div className="relative flex-1 px-4 py-1 sm:px-6 bg-ssblue2">
                      <div className="space-y-6 pb-5 pt-6">
                        <div>
                          <label className="block text-sm font-medium leading-6 text-white">
                            Share URL
                          </label>
                          <div className="mt-2">
                            <input
                              type="text"
                              name="project-name"
                              id="project-name"
                              ref={inputRef}
                              className="block w-full rounded-md border-0 p-1.5 bg-slate-300 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              value={`https://${domainWithPort}/shared/${showShareId}`}
                              readOnly
                            />
                          </div>
                        </div>
                        <div>
                          <label className="block text-sm font-medium leading-6 text-white">
                            Password Protect Share Url?
                          </label>
                          <div className="mt-2">
                            <input
                              name="project-name"
                              id="project-name"
                              className="block w-full rounded-md border-0 p-1.5 bg-slate-300 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              ref={passRef}
                              type="text"
                              maxLength={20}
                              placeholder="Enter a password"
                              defaultValue={lastPass}
                            />
                          </div>
                          <button
                            className={
                              passUpdated
                                ? `${tw.btn.action} bg-green hover:bg-green2 text-black hover:text-white rounded-sm mt-2`
                                : `${tw.btn.action} bg-blue hover:bg-blue2 text-white hover:text-white rounded-sm mt-2`
                            }
                            onClick={updatePassword}
                          >
                            {passUpdated ? (
                              <>
                                <FontAwesomeIcon icon={faThumbsUp} />{" "}
                              </>
                            ) : null}
                            Set Password
                          </button>{" "}
                          <button
                            className={
                              passCleared
                                ? `${tw.btn.action} bg-green rounded-sm hover:bg-green2 text-black hover:text-white`
                                : `${tw.btn.action} bg-gray-500 rounded-sm hover:bg-blue2 text-black hover:text-white`
                            }
                            onClick={clearPassword}
                          >
                            {passCleared ? (
                              <>
                                <FontAwesomeIcon icon={faThumbsUp} /> Cleared
                              </>
                            ) : (
                              "Clear"
                            )}
                          </button>
                        </div>
                      </div>
                      <div className="flex flex-col items-center justify-center py-20">
                        <div className="qr-wrap">
                          <img src={imgData} />
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-shrink-0 justify-end px-4 py-4 bg-ssblue">
                      <button
                        type="button"
                        className={`${tw.btn.reg} bg-white hover:bg-gray-500 text-black hover:text-white mr-1`}
                        onClick={close}
                      >
                        Close
                      </button>
                      <button
                        type="button"
                        className={`${tw.btn.reg} bg-blue hover:bg-blueHover text-white mr-1`}
                        onClick={copyToClipboard}
                      >
                        Copy URL
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>

    /* 
    <div className="modal-overlay">
      <div className="share-window">
        <span>Share</span>
        <hr />
        {showQr ? (
          <div className="qr-wrap">
            <img src={imgData} />
            <button onClick={close}>Close</button>
          </div>
        ) : (
          <>
            <span className="small">
              Share this URL with your co-hosts and guest so everyone is on the
              same page.
            </span>
            <br />
            <input
              ref={inputRef}
              type="text"
              value={`https://${domainWithPort}/shared/${showShareId}`}
              readOnly
            ></input>
            <br />
            <label className="small">Password protect share url?</label>
            <br />
            <input
              className="password"
              ref={passRef}
              type="text"
              maxLength={20}
              placeholder="Enter a password"
              defaultValue={lastPass}
            />
            <button
              className={passUpdated ? "green" : ""}
              onClick={updatePassword}
            >
              {passUpdated ? (
                <>
                  <FontAwesomeIcon icon={faThumbsUp} />{" "}
                </>
              ) : null}
              Set Password
            </button>{" "}
            <button
              className={passCleared ? "green" : ""}
              onClick={clearPassword}
            >
              {passCleared ? (
                <>
                  <FontAwesomeIcon icon={faThumbsUp} /> Cleared
                </>
              ) : (
                "Clear"
              )}
            </button>
            <hr />
            <div className="action-group">
              <button className="red" onClick={close}>
                Close
              </button>{" "}
              <button className="blue" onClick={copyToClipboard}>
                Copy URL
              </button>{" "}
              <button className="blue" onClick={useQr}>
                USE QR
              </button>
            </div>
          </>
        )}
      </div>
    </div> */
  );
};

export default ShareWindow;
