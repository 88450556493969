import React from "react";
import Header from "../../layout/Header";

import useTracking from "../../../hooks/useTracking";
import useScrollTop from "../../../hooks/useScrollTop";
import AuthMonitor from "../../../helpers/AuthMonitor";

export const BrowserSources = () => {
  useTracking();
  useScrollTop();
  AuthMonitor();

  const allSources = [
    {
      name: "Full Overlay",
      initials: "FA",
      href: "#",
      members: 16,
      bgColor: "bg-pink-600",
      path: "/sources/overlay",
      desc: "Contains the Chyron, Rundown, and Ticker.",
    },
    {
      name: "Chyron",
      initials: "CH",
      href: "#",
      members: 12,
      bgColor: "bg-purple-600",
      path: "/sources/chyron",
      desc: "Displays the currentl selected segment on the screen.",
    },
    {
      name: "Rundown",
      initials: "RD",
      href: "#",
      members: 16,
      bgColor: "bg-yellow",
      path: "/sources/rundown",
      desc: "Displays where in the segment list the show is at",
    },
    {
      name: "Ticker",
      initials: "TK",
      href: "#",
      members: 8,
      bgColor: "bg-sky-500",
      path: "/sources/ticker",
      desc: "The ticker is shown on the bottom of the screen.",
    },
  ];

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <>
      <Header />

      <div className="m-3">
        <h2 className="text-lg font-medium text-white">Browser Sources</h2>
        <span className="text-sm text-gray-300">
          Browser sources are typically used with streaming software like OBS or
          Streamlabs.{" "}
          <a href="https://obsproject.com/kb/browser-source" target="_link">
            Learn More
          </a>
        </span>
        <ul
          role="list"
          className="mt-3 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-4"
        >
          {allSources.map((project) => (
            <li
              key={project.name}
              className="col-span-1 flex rounded-md shadow-sm"
            >
              <div
                className={classNames(
                  project.bgColor,
                  "flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white"
                )}
              >
                {project.initials}
              </div>
              <div className="flex flex-1 items-center justify-between truncate rounded-r-md border-b border-r border-t border-slate-700 bg-ssblue2 ">
                <div className="flex-1 truncate px-4 py-2 text-sm">
                  <a
                    href={project.path}
                    className="text-white font-medium hover:text-gray-600 no-underline text-lg"
                  >
                    {project.name}
                  </a>
                  <p className="text-gray-500">{project.desc}</p>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default BrowserSources;
