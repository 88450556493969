import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ProjectCard from "./ProjectCard";
import UploadForm from "./Upload";
//import ApiRequest from "../../helpers/ApiRequest";
import Header from "../../../../components/layout/Header";
import SSHeader from "../../components/SSHeader";
import CreateNew from "../../components/CreateNew";
import {
  fetchDeleteProject,
  fetchProjectsByUser,
} from "../../../../api-requests/Index";
import { getBearerHeaders } from "../../../../helpers/GetBearerHeaders";
import { UserContext } from "../../../../context/UserContext";
import ErrorAlert from "../../../../components/ErrorAlert";
import { AlertLabelProps } from "../../../../types/Types";
import LoadingSpinner from "../../../../features/Shopro/components/LoadingSpinner";
import { io, Socket } from "socket.io-client";
import Cookies from "js-cookie";
import { getServer } from "../../../../helpers/GetServer";

const ProjectsPage = ({ userId }) => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const [websocket, setWebSocket] = useState<Socket>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [projectData, setProjectData] = useState([]);
  const [isProcessing] = useState(false);
  const [isAlertVisible, setIsAlertVisible] = useState<boolean>(false);
  const [alertLabels, setAlertLabels] = useState<AlertLabelProps>();
  const [progressData, setProgressData] = useState({});

  const onImportProgress = (data) => {
    setProgressData(data);
  };

  const onImportError = (data) => {
    console.log("IMPORT ERROR", data);
    const labels = {
      title: t("error"),
      message: t(data.msg),
      confirmLabel: t("ok"),
      onConfirm: () => onImportErrorConfirm(),
      confirmClass: "bg-blue hover:bg-blueHover",
    };
    setIsLoading(false);
    setAlertLabels(labels);
    setIsAlertVisible(true);
  };

  const onImportErrorConfirm = () => {
    loadProjectData();
    setIsAlertVisible(false);
  };

  const onImportDone = (data) => {
    console.log("IMPORT COMPLETE", data);
    setIsLoading(true);
    loadProjectData();
  };

  const loadProjectData = async () => {
    try {
      const postData = {
        userId: user.id,
      };
      const projects = await fetchProjectsByUser(postData, getBearerHeaders());
      if (projects.success) {
        setProjectData(projects.items);
      }
      setIsLoading(false);
    } catch (e) {
      const labels = {
        title: t("error"),
        message: e.message,
        confirmLabel: t("ok"),
        onConfirm: () => setIsAlertVisible(false),
        confirmClass: "bg-blue hover:bg-blueHover",
      };
      setIsLoading(false);
      setAlertLabels(labels);
      setIsAlertVisible(true);
    }
  };

  const confirmDeleteProject = (projectId) => {
    const labels = {
      title: "Delete Project",
      message: "Are you sure you want to delete this project?",
      confirmLabel: "Yes, Delete!",
      cancelLabel: t("cancel"),
      onConfirm: () => deleteProject(projectId),
      onCancel: () => setIsAlertVisible(false),
      confirmClass: "bg-blue hover:bg-blueHover",
    };
    setIsLoading(false);
    setAlertLabels(labels);
    setIsAlertVisible(true);
  };

  const deleteProject = async (projectId) => {
    console.log("DELETE PROJECT", projectId);
    setIsAlertVisible(false);
    const postData = {
      id: projectId,
    };
    const deletedProject = await fetchDeleteProject(
      postData,
      getBearerHeaders()
    );
    if (deletedProject.success) {
      setIsLoading(true);
      loadProjectData();
    }
  };

  useEffect(() => {
    console.log("PROJECT DATA", projectData);
  }, [projectData]);

  useEffect(() => {
    loadProjectData();
  }, [userId]);

  useEffect(() => {
    if (websocket) {
      websocket.connect();
      websocket.on("connect", () => {
        //console.log("SOCKET CONNECTED", websocket.id);
        //setWebSocketId(websocket.id);
        websocket.on("worker_process", onImportProgress);
        websocket.on("import_complete", onImportDone);
        websocket.on("import_error", onImportError);

        //setWebsocketConnected(true);
      });

      websocket.on("disconnect", () => {
        console.log("SOCKET DISCONNECTED");
        websocket.off("worker_process", onImportProgress);
        websocket.off("import_error", onImportError);
        websocket.off("import_complete", onImportDone);
        //setWebsocketConnected(false);
      });
    }
  }, [websocket]);

  useEffect(() => {
    const jwtToken = Cookies.get("jwt");
    const queryparam = jwtToken ? `?token=${jwtToken}` : null;
    setWebSocket(io(getServer() + queryparam));
    return () => {
      if (websocket) {
        websocket.off("worker_process", onImportProgress);
        websocket.off("import_complete", loadProjectData);
        websocket.disconnect();
      }
    };
  }, []);

  return (
    <>
      <Header />
      {isLoading && <LoadingSpinner />}
      {isAlertVisible && (
        <ErrorAlert
          title={alertLabels.title}
          message={alertLabels.message}
          confirmLabel={alertLabels.confirmLabel}
          cancelLabel={alertLabels.cancelLabel}
          onConfirm={alertLabels.onConfirm}
          confirmClass={alertLabels.confirmClass}
          onCancel={alertLabels.onCancel}
          isAlertVisible={isAlertVisible}
        />
      )}
      <SSHeader />
      <UploadForm
        key="upload-form"
        processing={isProcessing}
        onUploadSuccess={loadProjectData}
      />
      <div
        className={
          projectData.length > 0
            ? "flex flex-col sm:gap-2 sm:grid sm:grid-cols-1 md:grid-cols-2 xl:grid-cols-4 mt-1 mx-2"
            : "no-content"
        }
      >
        {projectData.length > 0 ? (
          projectData.map((project, index) => (
            <ProjectCard
              project={project}
              key={`sspc-${index}`}
              progressData={progressData}
              deleteProject={confirmDeleteProject}
            />
          ))
        ) : (
          <CreateNew label={t("notices.no_projects")} />
        )}
      </div>
    </>
  );
};

export default ProjectsPage;
