import React from "react";
import { useNavigate } from "react-router-dom";
import { faLeftLong, faRotateRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export interface BackBtnProps {
  isStudio: boolean;
}

const StandaloneControls = ({ isStudio }: BackBtnProps) => {
  const navigate = useNavigate();
  const btn = isStudio
    ? "mt-1 text-black bg-gray-500 hover:bg-gray-700 hover:text-white transition rounded-md px-2 py-1 border border-gray-700 pointer drop-shadow-md"
    : "mt-1 text-black bg-ssred hover:bg-sangria-950 hover:text-white transition rounded-md px-2 py-1 border border-gray-700 pointer drop-shadow-md";
  return (
    <>
      {window.matchMedia("(display-mode: standalone)").matches && (
        <>
          <div className="flex-shrink-0 ml-4 mr-2">
            <FontAwesomeIcon
              icon={faLeftLong}
              onClick={() => navigate(-1)}
              className={btn}
            />
          </div>
          <div className="flex-shrink-0 mr-3">
            <FontAwesomeIcon
              icon={faRotateRight}
              onClick={() => window.location.reload()}
              className={btn}
            />
          </div>
        </>
      )}
    </>
  );
};
export default StandaloneControls;
