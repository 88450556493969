import React, { useContext, useEffect, useState } from "react";
import Header from "../../components/layout/Header";
//import { UserContext } from "../../context/UserContext";
import useTracking from "../../hooks/useTracking";
import { getBearerHeaders } from "../../helpers/GetBearerHeaders";
import axios from "axios";
import { ShowDataArray } from "../../types/Types";
import ShowCard from "./components/ShowCard";
//import "../../sass/dashboard.scss";
import { Link, useNavigate } from "react-router-dom";
import ShowStats from "./components/ShowStats";
import {
  GetFromLocalStorage,
  SaveToLocalStorage,
} from "../../helpers/LocalStorage";
// import Team from "./components/Team";
//import { faCalendarPlus } from "@fortawesome/free-solid-svg-icons";
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UserContext } from "../../context/UserContext";
import { v4 as uuidv4 } from "uuid";
import { tw } from "../../helpers/Styles";
import Tabs from "../../components/Tabs";
import NoPlans from "./components/NoPlans";
import BrowserSources from "./components/BrowserSources";
import LoadingSpinner from "../../features/Shopro/components/LoadingSpinner";
import { useTranslation } from "react-i18next";
import AuthMonitor from "../../helpers/AuthMonitor";
import RssFeedReader from "../FeedReader/components/RssFeedReader";
import useExperiments from "../../hooks/useExperiments";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import AddFeed from "../FeedReader/components/AddFeed";
import { fetchFeeds } from "../../api-requests/Index";
import toast, { Toaster } from "react-hot-toast";

const Dashboard = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const inExperiment = useExperiments();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [shows, setShows] = useState<ShowDataArray>([]);
  const [filteredShows, setFilteredShows] = useState(shows);
  const [currentFilter, setCurrentFilter] = useState<string>("SCHEDULED");
  const [totalShowTimes, setTotalShowTimes] = useState<number>();
  const [completedShows, setCompletedShows] = useState<number>();
  const [avgShowLength, setAvgShowLength] = useState<number>();
  const [roomid, setRoomid] = useState<string>(user.roomid);
  const [showAddFeed, setShowAddFeed] = useState<boolean>(false);
  const [selectedFeedIndex, setSelectedFeedIndex] = useState<number>(-1);
  const [feedUrlList, setFeedUrlList] = useState<any[]>([]);
  const [includeDefaultFeeds, setIncludeDefaultFeeds] = useState<boolean>(null);
  AuthMonitor();

  const tablist = [
    { label: t("dashboard.tabs.all"), value: "all" },
    { label: t("dashboard.tabs.scheduled"), value: "scheduled" },
    { label: t("dashboard.tabs.ended"), value: "ended" },
  ];

  const defaultFeedUrlList = [
    { feedurl: "https://www.tmz.com/rss.xml", title: "TMZ" },
    { feedurl: "https://theshaderoom.com/feed/", title: "The Shade Room" },
    { feedurl: "https://www.etonline.com/news/rss", title: "etonline News" },
    {
      feedurl:
        "https://www.youtube.com/feeds/videos.xml?playlist_id=PLrEnWoR732-BHrPp_Pm8_VleD68f9s14-",
      title: "Youtube Popular Right Now",
    },
    {
      feedurl: "https://news.google.com/rss?hl=en-US&gl=US&ceid=US:en",
      title: "Top stories - Google News",
    },
    {
      feedurl:
        "https://news.google.com/rss/topics/CAAqJggKIiBDQkFTRWdvSUwyMHZNREpxYW5RU0FtVnVHZ0pWVXlnQVAB?hl=en-US&gl=US&ceid=US%3Aen",
      title: "Entertainment - Latest - Google News",
    },
  ];

  const eventTracker = useTracking();

  const getAllShows = () => {
    axios.post("/api/v1/getshows", {}, getBearerHeaders()).then((response) => {
      console.log("SHOWS", response);
      const showResultData = response.data.items.filter((show: any) => {
        const parsedShow = show.show;
        return show.show !== null && parsedShow !== null;
      });

      const endedShows = showResultData.filter((show) => show.endtime !== null);

      let totalTime = 0;

      endedShows.forEach((item) => {
        // Convert milliseconds to seconds by dividing by 1000
        const startTimeInSeconds = item.starttime / 1000;
        const endTimeInSeconds = item.endtime / 1000;

        // Calculate time spent in seconds
        const timeSpentInSeconds = endTimeInSeconds - startTimeInSeconds;

        // Add to total time
        totalTime += timeSpentInSeconds;
      });
      const averageLength = endedShows.length
        ? totalTime / endedShows.length
        : 0;
      // console.log("Ended shows", totalTime, endedShows);
      setTotalShowTimes(totalTime);
      setCompletedShows(endedShows.length);
      setAvgShowLength(averageLength);
      setShows(showResultData);
      setIsLoading(false);
    });
  };

  const filterShows = (filterType: string) => {
    setCurrentFilter(filterType);
    switch (filterType.toUpperCase()) {
      case "ALL":
        const filteredShows = [...shows];
        filteredShows.sort((a, b) => {
          const dateA = new Date(a.updatedAt);
          const dateB = new Date(b.updatedAt);

          // Compare the Date objects
          if (dateA > dateB) return -1;
          if (dateA < dateB) return 1;
          return 0;
        });
        setFilteredShows(filteredShows);
        break;
      case "SCHEDULED":
        const scheduledShows = shows.filter((show) => show.endtime === null);
        scheduledShows.sort((a, b) => {
          const dateA = new Date(a.updatedAt);
          const dateB = new Date(b.updatedAt);

          // Compare the Date objects
          if (dateA > dateB) return -1;
          if (dateA < dateB) return 1;
          return 0;
        });
        setFilteredShows(scheduledShows);
        break;
      case "ENDED":
        const endedShows = shows.filter((show) => show.endtime !== null);
        endedShows.sort((a, b) => {
          const dateA = new Date(a.updatedAt);
          const dateB = new Date(b.updatedAt);

          // Compare the Date objects
          if (dateA > dateB) return -1;
          if (dateA < dateB) return 1;
          return 0;
        });
        setFilteredShows(endedShows);
        break;
    }
  };

  const planNewShow = () => {
    SaveToLocalStorage("lvs", -1);
    navigate("/planner?ns=t");
  };

  const onFeedOpenChange = (isOpen: boolean, index: number) => {
    if (isOpen) {
      setSelectedFeedIndex(index);
    }
  };

  const onFeedUpdate = () => {
    fetchFeedsData();
    toast("New feed url added.");
  };

  const fetchFeedsData = async () => {
    const response = await fetchFeeds({}, getBearerHeaders());
    if (response.success) {
      //console.log("FEEDS", response.items);
      setFeedUrlList(
        response.items.length > 0
          ? includeDefaultFeeds
            ? [...defaultFeedUrlList, ...response.items]
            : response.items
          : defaultFeedUrlList
      );
    }
  };

  useEffect(() => {
    if (includeDefaultFeeds !== null) {
      fetchFeedsData();
      SaveToLocalStorage(
        "includeDefaultFeeds",
        JSON.stringify(includeDefaultFeeds)
      );
    }
  }, [includeDefaultFeeds]);

  useEffect(() => {
    //console.log("Shows", shows);
    filterShows("SCHEDULED");
  }, [shows]);

  useEffect(() => {
    //console.log(user);
    fetchFeedsData();
    if (!user.roomid) {
      const newRoomId = uuidv4();
      //console.log("CREATE A ROOMID", newRoomId);
      const postData = { roomid: newRoomId };
      axios
        .post("/api/v1/updateroom", postData, getBearerHeaders())
        .then((response) => {
          if (response.data.success) {
            //console.log("ROOM CREATED", newRoomId);
            setRoomid(newRoomId);
          }
        });
    }
    const isIncluded = GetFromLocalStorage("includeDefaultFeeds");
    setIncludeDefaultFeeds(isIncluded ? JSON.parse(isIncluded) : true);

    eventTracker({ category: "PAGE_LOAD", action: "DASHBOARD" });
    getAllShows();
  }, []);
  return (
    <>
      <Header />
      {isLoading && <LoadingSpinner />}
      <div
        className={`${tw.cardPlain} md:flex md:items-center md:justify-between md:space-x-5  m-2 p-4`}
      >
        <div className="flex items-start space-x-5">
          <div className="">
            <h3 className={tw.text.cardTitle}>{t("dashboard.title")}</h3>
          </div>
        </div>
        <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-3 sm:space-y-0 sm:space-x-reverse md:mt-0 md:flex-row md:space-x-3">
          <button
            type="button"
            className={`${tw.btn.reg} bg-green-700 text-white hover:bg-green-900 hover:text-white border border-slate-900 rounded-md sm:mr-2`}
            onClick={planNewShow}
          >
            {t("buttons.plan_new_show")}
          </button>
          <Link
            type="button"
            className={`${tw.btn.reg} bg-sky-700 text-white hover:bg-sky-900 hover:text-white border border-slate-900 no-underline rounded-md uppercase text-center`}
            to={`/studio/${user.roomid}`}
          >
            {t("buttons.host_new_show")}
          </Link>
        </div>
      </div>

      <div className="flex flex-col sm:flex-row gap-2 m-2">
        <div className={`w-full sm:w-2/4 ${tw.cardPlain} p-2`}>
          <ShowStats
            completedShowTime={Number(totalShowTimes)}
            completedShows={completedShows}
            showCount={shows.length}
            avgShowLength={avgShowLength}
          />
        </div>
        <div className={`w-full sm:w-2/4 ${tw.cardPlain} p-2`}>
          <BrowserSources />
        </div>
      </div>

      <div className="flex flex-col sm:flex-row m-2 gap-2">
        <div
          className={`${
            inExperiment
              ? "w-full sm:w-2/4 md:max-h-[calc(100vh-40px-72px-266px)] overflow-y-auto"
              : "w-full"
          } ${tw.cardPlain} p-2`}
        >
          <div className="border-b border-slate-800 p-1  sm:flex sm:items-center sm:justify-between">
            <h3 className={tw.text.cardTitle}>Shows</h3>
          </div>

          <Tabs
            tablist={tablist}
            onFilter={filterShows}
            currentFilter={currentFilter}
          />

          {filteredShows.length ? (
            <ul
              role="list"
              className={`sm:gap-2 sm:grid sm:grid-cols-1 md:grid-cols-2 ${
                inExperiment ? "xl:grid-cols-2" : "xl:grid-cols-4"
              } mt-1`}
            >
              {filteredShows.map((show, index) => (
                <ShowCard
                  key={`card-${index}`}
                  showData={show}
                  shows={shows}
                  roomid={roomid}
                />
              ))}
            </ul>
          ) : (
            <div className="flex justify-center">
              {!shows.length && <NoPlans />}
            </div>
          )}
        </div>
        {inExperiment && (
          <div
            className={`w-full sm:w-2/4 ${tw.cardPlain} p-2 max-h-[calc(100vh-40px-72px-266px)] overflow-y-auto`}
          >
            <div className="border-b border-slate-800 p-1  sm:flex sm:items-center sm:justify-between">
              <h3 className={tw.text.cardTitle}>
                Feeds
                <div className="flex text-xs item-center">
                  <input
                    type="checkbox"
                    className="mr-2"
                    defaultChecked={includeDefaultFeeds}
                    onChange={(e) => setIncludeDefaultFeeds(e.target.checked)}
                  />
                  Include default feeds
                </div>
              </h3>

              <button
                className={`${tw.btn.reg} bg-sky-700 text-white hover:bg-sky-900 hover:text-white border border-slate-900 no-underline rounded-md uppercase text-center py-1`}
                onClick={() => setShowAddFeed(true)}
              >
                <FontAwesomeIcon icon={faPlus} />
              </button>
            </div>
            {feedUrlList.map((feed, index) => (
              <RssFeedReader
                key={index}
                feedUrl={feed.feedurl}
                title={feed.title}
                feedid={feed.id}
                onOpenChange={onFeedOpenChange}
                selectedFeedIndex={selectedFeedIndex}
                index={index}
                shows={shows}
              />
            ))}
          </div>
        )}
      </div>
      <Toaster
        toastOptions={{
          className: "",
          style: {
            border: "1px solid #0d1014",
            padding: "10px",
            color: "#ffffff",
            backgroundColor: "#14202e",
            fontSize: "0.9rem",
          },
        }}
      />
      <AddFeed
        isOpen={showAddFeed}
        onClose={setShowAddFeed}
        onFeedUpdate={onFeedUpdate}
      />
    </>
  );
};

export default Dashboard;
