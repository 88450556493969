import React from "react";
import {
  calOverUnder,
  calculateTimeDuration,
  startEndTimeOffest,
} from "../../../helpers/TimeCode";
import { TopicData } from "../../../types/Types";
import { useTranslation } from "react-i18next";

export interface SegmentProps {
  itemData: TopicData;
}
const SegmentTimes = ({ itemData }: SegmentProps) => {
  const { t } = useTranslation();
  const defaultTimeCode = "--:--:--";
  const offsetResults = startEndTimeOffest(
    itemData.start_time,
    itemData.end_time,
    itemData.topic_time
  );

  const overUnder = calOverUnder([
    {
      start_time: itemData.start_time,
      end_time: itemData.end_time,
      topic_time: itemData.topic_time,
    },
  ]);

  return (
    <span className="text-xs sorthide">
      <span className="block font-bold text-gray-400">
        {t("planner.segment_times")}:
      </span>
      {t("planner.start")}:{" "}
      {itemData.start_time !== null ? itemData.start_time : defaultTimeCode} -
      {t("planner.end")}:{" "}
      {itemData.end_time !== null ? itemData.end_time : defaultTimeCode} /
      {t("planner.duration")}:{" "}
      {itemData.start_time !== null && itemData.end_time !== null
        ? calculateTimeDuration(itemData.start_time, itemData.end_time)
        : defaultTimeCode}
      {itemData.end_time !== null && (
        <>
          {" "}
          <span
            className={
              offsetResults.hilo === "hi"
                ? "text-red-600 block"
                : offsetResults.hilo === "lo"
                ? "text-green block"
                : ""
            }
          >
            {overUnder}
          </span>
        </>
      )}
    </span>
  );
};

export default SegmentTimes;
