import React, { useEffect, useState, Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { tw } from "../helpers/Styles";

interface AlertProps {
  title: string;
  message: string;
  confirmLabel: string;
  cancelLabel?: string;
  confirmClass?: string;
  cancelClass?: string;
  headerClass?: string;
  bgClass?: string;
  isAlertVisible?: boolean;
  onConfirm: () => void;
  onCancel?: () => void;
}

const ErrorAlert = ({
  title,
  message,
  confirmLabel,
  cancelLabel,
  confirmClass,
  cancelClass,
  onConfirm,
  onCancel,
  isAlertVisible,
}: AlertProps) => {
  //const [isOpen, setIsOpen] = useState(true);
  const [open, setOpen] = useState(isAlertVisible);
  const cancelButtonRef = useRef(null);

  const handleClose = () => {
    setOpen(false);
    if (onCancel) onCancel();
  };

  const handleConfirm = () => {
    setOpen(false);
    onConfirm();
  };

  useEffect(() => {
    setOpen(isAlertVisible);
  }, [isAlertVisible]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[250]"
        initialFocus={cancelButtonRef}
        onClose={handleClose}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className={tw.modalBg} />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex flex-1 min-h-full w-screen sm:w-auto m-auto items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-md border border-rose-700 bg-ssblue2 text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-lg">
                <div className="px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-300 sm:mx-0 sm:h-10 sm:w-10">
                      <FontAwesomeIcon
                        icon={faExclamationTriangle}
                        onClick={onCancel}
                        className="text-red-900"
                      />
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-white"
                      >
                        {title}
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-white">{message}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-rose-700 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    className={`inline-flex w-full justify-center px-3 py-2 text-sm font-semibold shadow-sm sm:ml-2 sm:w-auto transition-colors duration-200 ${confirmClass}`}
                    onClick={handleConfirm}
                  >
                    {confirmLabel}
                  </button>
                  {cancelLabel && (
                    <button
                      type="button"
                      className={`mt-3 inline-flex w-full justify-center px-3 py-2 text-sm font-semibold shadow-sm ring-1 ring-inset ring-gray-300 sm:mt-0 sm:w-auto transition-colors duration-200 ${cancelClass}`}
                      onClick={handleClose}
                      ref={cancelButtonRef}
                    >
                      {cancelLabel}
                    </button>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ErrorAlert;
