import { useEffect, useState } from "react";
import ReactGA from "react-ga4";

export interface AnalyticsTrackingProps {
  category: string;
  action: string;
}

const useTracking = () => {
  const currentHost = window.location.hostname;
  const [isLive] = useState<boolean>(
    currentHost === "localhost" || currentHost.includes("alpha") ? false : true
  );
  const eventTracker = ({ category, action }: AnalyticsTrackingProps) => {
    // console.log("Event Track:", category, action);
    if (isLive) {
      ReactGA.event({
        category: category,
        action: action,
      });
    }
  };

  useEffect(() => {
    /* console.log(
      "Page load sent",
      window.location.pathname + window.location.search
    ); */
    if (isLive) {
      ReactGA.send({
        hitType: "pageview",
        page: window.location.pathname + window.location.search,
        title: "Shopro",
      });
    }
    return () => {};
  }, []);

  return eventTracker;
};

export default useTracking;
