import React from "react";

const SectionHeader = ({ label }) => {
  return (
    <div className="section-header">
      <h2 className="">{label}</h2>
    </div>
  );
};

export default SectionHeader;
