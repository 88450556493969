import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClapperboard } from "@fortawesome/free-solid-svg-icons";

/* const solutions = [
  { name: "Add To Show", href: "#" },
  { name: "Engagement", href: "#" },
  { name: "Security", href: "#" },
  { name: "Integrations", href: "#" },
  { name: "Automations", href: "#" },
  { name: "Reports", href: "#" },
]; */

export interface Solution {
  name: string;
  clickMethod: string;
}

export type Solutions = Solution[];

export interface StreamOptionsProps {
  userid?: string;
  solutions?: Solutions;
  optionAction: (action) => void;
}

const StreamOptions = ({
  userid,
  solutions,
  optionAction,
}: StreamOptionsProps) => {
  const [solutionList, setSolutions] = useState<Solution[]>(solutions);

  const onBtnAction = (action) => {
    optionAction(action);
  };

  useEffect(() => {
    if (solutions) {
      setSolutions(solutions);
    }
  }, [solutions]);

  return (
    <Popover className="absolute top-1 right-1 z-100 ">
      <Popover.Button className="inline-flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900">
        <div className="user-tools-ctrl text-xs rounded-sm bg-gray-500 p-1 inline-block text-black border border-gray-400 shadow-md pointer">
          <FontAwesomeIcon icon={faClapperboard} />
        </div>
      </Popover.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel className="absolute left-1/2 z-10 mt-2 flex w-screen max-w-min -translate-x-32 px-4 top-1 drop-shadow-lg">
          <div className="w-28 shrink rounded-md bg-white text-xs font-semibold leading-6 text-gray-900 shadow-lg ring-1 ring-gray-900/5 py-1">
            {solutionList &&
              solutionList.map((item) => (
                <a
                  key={item.name + userid}
                  className="block px-2 text-black hover:bg-amber-400 pointer no-underline border-b p-2"
                  onClick={() => onBtnAction(item.clickMethod)}
                >
                  {item.name}
                </a>
              ))}
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};

export default StreamOptions;
