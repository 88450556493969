import React, { useEffect, useState } from "react";
import useRoomCams from "../../hooks/useRoomCams";
import { useParams } from "react-router-dom";
import "../../sass/camSizes.scss";
import OverlayDrawer from "../../components/sources/OverlayDrawer";
import { themeKharysCrib } from "../../data/Themes";
import { apiUserBySource } from "../../api-requests/Index";
import { getBearerHeaders } from "../../helpers/GetBearerHeaders";
import StageManager from "../Shopro/components/StageManager";

const ChatScene = () => {
  const { roomid, noHostCam } = useParams();
  const RoomCams = useRoomCams({ roomId: roomid });
  const [, setHasASoloedStream] = useState<string>("");
  const [roomJoined, setRoomJoined] = useState<boolean>(false);
  const [includeHost] = useState(noHostCam !== undefined ? false : true);
  const [hasRundown, setHasRundown] = useState<boolean>(false);
  const [hasChyron, setHasChyron] = useState<boolean>(false);
  const [hasTicker, setHasTicker] = useState<boolean>(false);
  const [browserSourceId, setSourceid] = useState(null);
  const [ownerId, setOwnerId] = useState(null);
  const [cameraClass, setCameraClass] = useState<string>("");

  const createEmptyAudioTrack = () => {
    const ctx = new AudioContext();
    const oscillator = ctx.createOscillator();
    const mediaStream = new MediaStream();

    const destination = ctx.createMediaStreamDestination();
    oscillator.connect(destination);
    mediaStream.addTrack(destination.stream.getAudioTracks()[0]);

    oscillator.start();

    const track = mediaStream.getAudioTracks()[0];
    return Object.assign(track, { enabled: false });
  };

  const createEmptyVideoTrack = ({ width, height }) => {
    const canvas = Object.assign(document.createElement("canvas"), {
      width,
      height,
    });
    canvas.getContext("2d").fillRect(0, 0, width, height);

    const stream = canvas.captureStream();
    const track = stream.getVideoTracks()[0];

    return Object.assign(track, { enabled: false });
  };

  useEffect(() => {
    if (RoomCams.sceneCameras.length) {
      const hostCam = RoomCams.sceneCameras.filter(
        (cam) => cam.data.type === "host"
      );

      if (hostCam.length) {
        console.log("hostCam", hostCam[0].data);
        setSourceid(hostCam[0].data.showSourceId);
        setHasTicker(hostCam[0].data.roomsettings.tickerEnabled);
        setHasChyron(hostCam[0].data.roomsettings.chyronEnabled);
        setHasRundown(hostCam[0].data.roomsettings.rundownEnabled);
        setCameraClass(hostCam[0].data.cameraClass);
        //setIsUserNameShowing(hostCam[0].data.isNameShowing);
      }
    }
  }, [RoomCams.sceneCameras]);

  useEffect(() => {
    if (RoomCams.userid && RoomCams.peerId && !roomJoined) {
      const mediaStream = new MediaStream([
        createEmptyAudioTrack(),
        createEmptyVideoTrack({ width: 640, height: 480 }),
      ]);

      // Add video and audio tracks to the MediaStream
      //mediaStream.addTrack(videoTrack);
      //mediaStream.addTrack(audioTrack);
      const data = {
        roomid,
        userid: RoomCams.userid,
        peerId: RoomCams.peerId,
        name: "watcher",
        tagline: "",
        type: "watcher",
        pos: "bg",
        isMuted: true,
        isVideoShowing: false,
      };
      RoomCams.requestJoin(data, mediaStream);
      RoomCams.setMyUserData(data);

      RoomCams.addStream(mediaStream, data);
      RoomCams.setMyStream(mediaStream);
      setRoomJoined(true);
    }
  }, [RoomCams.userid, RoomCams.peerId]);

  useEffect(() => {
    if (browserSourceId) {
      const fetchData = async () => {
        const userInfo = await apiUserBySource(
          { showData: browserSourceId },
          getBearerHeaders()
        );
        console.log(userInfo.items.userid);
        setOwnerId(userInfo.items.userid);
      };
      fetchData();
    }
  }, [browserSourceId]);

  return (
    <div className="scene">
      <div className={`video-wrap ${cameraClass}`}>
        <StageManager
          RoomCams={RoomCams}
          onCameraClass={setCameraClass}
          onSoloedStream={setHasASoloedStream}
        />

        {RoomCams.webSocket && browserSourceId && ownerId && includeHost && (
          <OverlayDrawer
            showTheTicker={hasTicker}
            showChyron={hasChyron}
            showRundown={hasRundown}
            rundownTopic={""}
            topics={{}}
            themeConfig={themeKharysCrib}
            socket={RoomCams.webSocket.current}
            shareid={ownerId}
          />
        )}
      </div>
    </div>
  );
};

export default ChatScene;
