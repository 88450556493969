import axios from "axios";

export const apiWriteTopic = async (data: any, headers: any) => {
  const res = await axios.post(`/api/v1/writecurrenttopic`, data, headers);
  return res.data;
};

export const fetchGetShows = async (data: any, headers: any) => {
  const res = await axios.post("/api/v1/getShows", data, headers);
  return res.data;
};

export const fetchTkRf = async (data: any, headers: any) => {
  const res = await axios.post("/api/refreshtoken", data, headers);
  return res.data;
};
export const apiUpdateConfig = async (data: any, headers: any) => {
  const res = await axios.post(`/api/v1/updateconfig`, data, headers);
  return res.data;
};

export const apiGetSourceId = async (data: any, headers: any) => {
  const res = await axios.post("/api/v1/getsourceid", data, headers);
  return res.data;
};

export const fetchAvailableAudio = async (data: any, headers: any) => {
  const res = await axios.post("/api/v1/getAvailableAudio", data, headers);
  return res.data;
};

export const apiUserBySource = async (data: any, headers: any) => {
  const res = await axios.post("/api/sources/userbysource", data, headers);
  return res.data;
};

export const fetchShowByRoom = async (data: any, headers: any) => {
  const res = await axios.post("/api/getShowByRoom", data, headers);
  return res.data;
};

export const fetchGetFeed = async (data: any, headers: any) => {
  const res = await axios.post("/api/v1/get-feed", data, headers);
  return res.data;
};

export const fetchFixDbShows = async (data: any, headers: any) => {
  const res = await axios.post("/api/v1/fix-db-shows", data, headers);
  return res.data;
};

export const fetchFeeds = async (data: any, headers: any) => {
  const res = await axios.post("/api/v1/get-my-feeds", data, headers);
  return res.data;
};

export const fetchAiPrompt = async (data: any, headers: any) => {
  const res = await axios.post("/api/v1/gemini-call", data, headers);
  return res.data;
};

/* ... STREAM SLICER ENDPOINT */

export const fetchProjectsByUser = async (data: any, headers: any) => {
  const res = await axios.post("/api/v1/ss/projectsByUser", data, headers);
  return res.data;
};

export const fetchImportVideo = async (data: any, headers: any) => {
  const res = await axios.post("/api/v1/ss/ytimport", data, headers);
  return res.data;
};

export const postImportLocal = async (data: any, headers: any) => {
  const res = await axios.post("/api/v1/ss/localimport", data, headers);
  return res.data;
};

export const fetchDeleteProject = async (data: any, headers: any) => {
  const res = await axios.post("/api/v1/ss/deleteProject", data, headers);
  return res.data;
};
