import React, { ChangeEvent, useEffect, useState, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { tw } from "../../../helpers/Styles";
import { formatDateForInputField } from "../../../helpers/Dates";

export interface ShowEditorProps {
  isEditing?: boolean;
  onCancelEdit: (isEdit: boolean) => void;
  setCurrentTime?: (time: number) => void;
  onSaveShow?: (value: any | number, field: string) => void;
  showData?: any;
  cardData?: any;
  onTopicUpdate?: (
    event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>,
    cardIndex: number
  ) => void;
  onTopicDelete?: (cardIndex: number) => void;
  setCurrentTopic?: (cardIndex: number) => void;
  onSave?: (cardIndex: number) => void;
}

const ShowEditor = ({
  showData,
  isEditing,
  onCancelEdit,
  onSaveShow,
}: ShowEditorProps) => {
  const [tempItemData, setTempItemData] = useState(showData);
  const [isEditorOpen, setIsEditorOpen] = useState(false);
  const [open, setOpen] = useState(isEditing);
  //const [checked, setChecked] = useState<boolean>(false);

  //const [editorDspProps, setEditorDspProps] = useState("translate-x-0");

  const titlePlaceHolder = "Your Show Title...";
  const urlPlaceHolder = "https://www.yourshowurl.com";

  /* const handleValueUpdate = (
    event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>,
    field: string
  ) => {
    const fieldVal = event.target.value;
    const topicData = {
      ...tempItemData,
      [field]: fieldVal,
      isNewTopic: false,
    };

    setCurrentTime(Number(topicData.topic_time) * 60);
    setTempItemData(topicData);
  }; */

  /*
    <!-- Background backdrop, show/hide based on slide-over state. -->
    <!--
          Slide-over panel, show/hide based on slide-over state.

          Entering: "transform transition ease-in-out duration-500 sm:duration-700"
            From: "translate-x-full"
            To: "translate-x-0"
          Leaving: "transform transition ease-in-out duration-500 sm:duration-700"
            From: "translate-x-0"
            To: "translate-x-full"
        -->
  */

  const handleValueUpdate = (
    event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>,
    field: string
  ) => {
    const fieldVal = event.target.value;
    const topicData = {
      ...tempItemData,
      [field]: fieldVal,
    };

    //setCurrentTime(Number(topicData.topic_time) * 60);
    setTempItemData(topicData);
  };

  /* const handleCheck = () => {
    //console.log("Is Checked?", !checked);
    setChecked(!checked);
    const topicData = {
      ...tempItemData,
      includeInRundown: !checked,
      isNewTopic: false,
    };
    setTempItemData(topicData);
  }; */

  const saveShow = () => {
    if (onSaveShow) {
      Object.entries(tempItemData).forEach(([key, value]) => {
        //console.log(key, value);
        onSaveShow(value, key);
      });
    }
    //onSaveShow(tempItemData);
    setIsEditorOpen(false);
    onCancelEdit(false);
  };

  const onCancel = () => {
    setIsEditorOpen(false);
    onCancelEdit(false);
  };

  useEffect(() => {
    //setEditorDspProps(isEditorOpen ? "translate-x-0" : "translate-x-full");
  }, [isEditorOpen]);

  useEffect(() => {
    setOpen(isEditing);
  }, [isEditing]);

  useEffect(() => {
    if (open !== null) {
      if (!open) {
        setIsEditorOpen(false);
        onCancelEdit(false);
      }
    }
  }, [open]);

  /* useEffect(() => {
    //setIncludeInRunDown(tempItemData.includeInRundown);
    if (tempItemData) {
      setChecked(tempItemData.includeInRundown);
    }
  }, [tempItemData]); */

  useEffect(() => {
    setTempItemData(showData);
  }, [showData]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-[260]" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className={tw.modalBg} />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 left-0 flex max-w-full pr-5">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-300 sm:duration-300"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-300 sm:duration-300"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col overflow-y-auto bg-white drop-shadow-lg border-r border-ssblue">
                    <div className="bg-ssblue px-4 py-6 sm:px-6">
                      <div className="flex items-center justify-between">
                        <Dialog.Title className="text-base font-semibold leading-6 text-white">
                          Edit Show
                        </Dialog.Title>
                        <div className="ml-3 flex h-7 items-center pointer">
                          <FontAwesomeIcon
                            className="relative rounded-md bg-ssbluehover text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                            icon={faCircleXmark}
                            onClick={onCancel}
                          />
                        </div>
                      </div>
                      <div className="mt-1">
                        <p className="text-sm text-indigo-300">
                          Fill in the information below to modify your show.
                        </p>
                      </div>
                    </div>
                    <div className="relative flex-1 px-4 py-1 sm:px-6 bg-ssblue2">
                      <div className="space-y-6 pb-5 pt-6">
                        <div>
                          <label className="block text-sm font-medium leading-6 text-white">
                            Episode Number / Show Id
                          </label>
                          <div className="mt-2">
                            <input
                              type="number"
                              name="project-name"
                              id="project-name"
                              className="block w-16 rounded-md border-0 p-1.5 bg-slate-300 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              value={tempItemData ? tempItemData.episode : ""}
                              onChange={(e) => handleValueUpdate(e, "episode")}
                            />
                          </div>
                        </div>
                        <div>
                          <label className="block text-sm font-medium leading-6 text-white">
                            Show Title
                          </label>
                          <div className="mt-2">
                            <input
                              type="text"
                              name="project-name"
                              id="project-name"
                              className="block w-full rounded-md border-0 p-1.5 bg-slate-300 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              value={tempItemData ? tempItemData.showTitle : ""}
                              onChange={(e) =>
                                handleValueUpdate(e, "showTitle")
                              }
                              placeholder={titlePlaceHolder}
                            />
                          </div>
                        </div>
                        <div>
                          <label className="block text-sm font-medium leading-6 text-white">
                            Show Date
                          </label>
                          <div className="mt-2">
                            <input
                              type="datetime-local"
                              name="show-date"
                              id="show-date"
                              className="block w-1/2 rounded-md border-0 p-1.5 bg-slate-300 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              value={
                                tempItemData
                                  ? formatDateForInputField(
                                      new Date(tempItemData.showDate)
                                    )
                                  : ""
                              }
                              onChange={(e) => handleValueUpdate(e, "showDate")}
                            />
                          </div>
                        </div>
                        <div>
                          <label className="block text-sm font-medium leading-6 text-white">
                            Show URL
                          </label>
                          <div className="mt-2">
                            <input
                              type="text"
                              name="show-url"
                              id="show-url"
                              className="block w-full rounded-md border-0 p-1.5 bg-slate-300 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              value={tempItemData ? tempItemData.showUrl : ""}
                              onChange={(e) => handleValueUpdate(e, "showUrl")}
                              placeholder={urlPlaceHolder}
                            />
                          </div>
                        </div>
                        <div>
                          <button
                            className={`${tw.btn.reg} bg-disabled hover:bg-greenHover border border-gray-800 text-xs text-gray-500 hover:text-white`}
                          >
                            Schedule Youtube Live Stream?
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-shrink-0 justify-end px-4 py-4 bg-ssblue">
                      <button
                        type="button"
                        className={`${tw.btn.reg} bg-red hover:bg-redHover text-white mr-1`}
                        onClick={onCancel}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className={`${tw.btn.reg} bg-green-800 hover:bg-green-950 text-white hover:text-white`}
                        onClick={saveShow}
                      >
                        Save Show
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ShowEditor;
