import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { io, Socket } from "socket.io-client";
import axios from "axios";
import { getBearerHeaders } from "../../../helpers/GetBearerHeaders";
import { getServer } from "../../../helpers/GetServer";
import { TopicDataArray } from "../../../types/Types";
import "../../../sass/themes/theme-kharyscrib.scss";
import "../../../sass/themes/theme-pinky.scss";
import "../../../sass/themes/theme-playhouse.scss";
import "../../../sass/themes/theme-redlatex.scss";
import "../../../sass/themes/theme-topdown.scss";
import "../../../sass/themes/theme-sportyblue.scss";
import "../../../sass/themes/theme-corp.scss";

export interface RundownProps {
  rnData?: any;
  defaultTheme?: string;
  socketserver?: Socket;
  isProAccount?: boolean;
  isBrandingGlobal?: boolean;
  isInControlCenter?: boolean;
  joinedShowid?: string;
  isEmbeded?: boolean;
  onRundownTopicChange?: (topic: string) => void;
}

const Rundown = ({
  rnData,
  defaultTheme,
  socketserver,
  isProAccount,
  isBrandingGlobal,
  isInControlCenter,
  joinedShowid,
  isEmbeded,
  onRundownTopicChange,
}: RundownProps) => {
  const { sourceid } = useParams();
  const [showidToLoad, setShowIdToLoad] = useState(
    isInControlCenter === true ? joinedShowid : sourceid
  );
  const [sourceUser, setSourceUser] = useState<number>(0);
  const [showData, setShowData] = useState<TopicDataArray>(null);
  const [filteredShowData, setFilteredShowData] =
    useState<TopicDataArray>(null);
  const [selectedTopic, setSelectedTopic] = useState<string>(null);
  const [isVisible, setIsVisible] = useState<boolean>(true);
  const [theme, setTheme] = useState<string>(
    defaultTheme ? defaultTheme : "kharyscrib"
  );
  const [sourceisPro, setSourceIsPro] = useState<boolean>(
    isProAccount ? isProAccount : false
  );
  const [pingCount, setPingCount] = useState<number>(0);
  const [pingData, setPingData] = useState<any>();
  const [wsTopicdata, setWsTopicData] = useState<string>(null);
  const [wsdata, setWsData] = useState<string>(null);
  const [websocket, setWebSocket] = useState<Socket>();
  const [showRundown] = useState<boolean>(true);

  const onTopicUpdate = (data) => {
    //console.log("On Rundown topic", data);
    setWsTopicData(data);
  };

  const onRundownVisibilityChange = (data) => {
    //console.log("ON RUNDOWN VISIBILITY", data);
    setWsData(data);
  };

  const emitToggleMsg = () => {
    const msg = {
      sourceid: sourceid,
      eventtype: "RUNDOWNTOGGLE",
      visible: isVisible,
    };
    //console.log("emit", msg);
    return msg;
  };

  const onPing = (data) => {
    //console.log("on rundown ping", data);
    setPingCount((prev) => prev + 1);
    setPingData(data);
  };

  const limitRundown = (dataToFilter, currentTopic) => {
    const sliceLimit = 7;
    const intervalSize = 6;
    let finalData;

    // Filter data to include only topics with includeInRundown not set to false
    const filteredData = dataToFilter.filter(
      (topic) => topic.includeInRundown !== false
    );

    if (filteredData.length > sliceLimit - 1) {
      // Find the index of the current topic
      const index = filteredData.findIndex(
        (item) => item.topic === currentTopic
      );
      if (index >= 0) {
        // Calculate the interval start and end indices
        const intervalStart = Math.floor(index / intervalSize) * intervalSize;
        const intervalEnd = Math.min(
          intervalStart + intervalSize,
          filteredData.length - 1
        );

        // Ensure we have at least 7 items in the interval
        const endIndex = Math.min(intervalEnd, index + sliceLimit - 1);
        const startIndex = Math.min(endIndex - (sliceLimit - 1), intervalStart);

        // Slice the filtered data based on the start and end indices
        finalData = filteredData.slice(startIndex, endIndex + 1);
      } else {
        finalData = filteredData.slice(0, 7);
      }
    } else {
      finalData = filteredData;
    }

    setFilteredShowData(finalData);
  };

  const connectShow = () => {
    const postData = { showData: showidToLoad };
    const apiUrl = showidToLoad ? "/api/sources/rundown" : "/api/v1/rundown";
    //console.log("socketserver", socketserver);
    //setWebSocket(socketserver ? socketserver : io(getServer()));
    if (socketserver) {
      setWebSocket(socketserver);
    } else {
      setWebSocket(io(getServer()));
    }
    axios.post(apiUrl, postData, getBearerHeaders()).then((response) => {
      if (response.data.success) {
        //console.log(response);
        // setTickerText(response.data.items);
        setSelectedTopic(response.data.items.topic);
        setShowData(response.data.items.show);
        limitRundown(response.data.items.show, response.data.items.topic);
        setTheme(response.data.items.theme);
        console.log(response.data.items.show);
      }
    });
    if (showidToLoad) {
      axios
        .post("/api/sources/userbysource", postData, getBearerHeaders())
        .then((response) => {
          if (response.data.success) {
            setSourceUser(Number(response.data.items.userid));
            setSourceIsPro(
              response.data.items.accounttype.toUpperCase() === "PRO"
                ? true
                : false
            );
          }
        });
    }
    if (rnData) {
      setSelectedTopic(rnData.topic);
      setShowData(rnData.show);
      limitRundown(rnData.show, rnData.topic);

      if (showData === null) {
        console.log("No show data");
      }
    }
  };

  useEffect(() => {
    if (defaultTheme) {
      setTheme(defaultTheme);
    }
  }, [defaultTheme]);

  useEffect(() => {
    if (pingData) {
      if (pingData.sourceid === sourceid && websocket) {
        websocket.emit("obs-wss", emitToggleMsg());
      }
    }
  }, [pingCount]);

  useEffect(() => {
    if (wsTopicdata) {
      const resultData = JSON.parse(wsTopicdata);
      if (resultData.id === sourceUser) {
        //console.log("Selected Topic", resultData.data, onRundownTopicChange);
        setSelectedTopic(resultData.data);
        setShowData(resultData.show);
        limitRundown(resultData.show, resultData.data);
        if (onRundownTopicChange) {
          console.log("CALL RDTOPIC CHANGE");
          onRundownTopicChange(resultData.data);
        }
      }
    }
  }, [wsTopicdata]);

  useEffect(() => {
    if (wsdata) {
      const resultData = JSON.parse(wsdata);
      if (resultData.id === sourceUser) {
        setIsVisible(resultData.visible);
      }
    }
  }, [wsdata]);

  useEffect(() => {
    if (selectedTopic && onRundownTopicChange) {
      //onRundownTopicChange(selectedTopic);
      //console.log("SELECTED TOPIC", selectedTopic);
    }
  }, [selectedTopic]);

  useEffect(() => {
    if (websocket) {
      if (!socketserver) {
        websocket.connect();
        //console.log("RUNDOWN NEW WS CONNECTION", websocket);
        websocket.on("connect", () => {
          console.log("Connected?", websocket);
          websocket.emit("obs-wss", emitToggleMsg());

          //console.log("RUNDOWN CONNECTED TO WS");
          websocket.on("topic-update", onTopicUpdate);
          websocket.on("obs-rundown-toggle", onRundownVisibilityChange);
          websocket.on("bs-ping", onPing);
        });
      } else {
        websocket.on("topic-update", onTopicUpdate);
        websocket.on("obs-rundown-toggle", onRundownVisibilityChange);
        websocket.on("bs-ping", onPing);
      }

      websocket.on("disconnect", () => {
        console.log("RUNDOWN DISCONNECTED FROM WS");
        websocket.off("topic-update", onTopicUpdate);
        websocket.off("obs-rundown-toggle", onRundownVisibilityChange);
        websocket.off("bs-ping", onPing);
        websocket.disconnect();
      });
    }

    return () => {
      if (websocket) {
        websocket.off("topic-update", onTopicUpdate);
        websocket.off("obs-rundown-toggle", onRundownVisibilityChange);
        websocket.off("bs-ping", onPing);
      }
    };
  }, [websocket]);

  useEffect(() => {
    if (joinedShowid) {
      setShowIdToLoad(joinedShowid);
    }
  }, [joinedShowid]);

  useEffect(() => {
    if (showidToLoad) {
      connectShow();
    } else {
      if (rnData) {
        setSelectedTopic(rnData.topic);
        setShowData(rnData.show);
        limitRundown(rnData.show, rnData.topic);

        if (showData === null) {
          //console.log("No show data");
        }
      }
    }
  }, [showidToLoad]);

  useEffect(() => {}, []);

  return (
    <>
      {showRundown && (
        <div
          key={`rdl-${sourceid}`}
          className={
            isVisible
              ? `rundown ${theme} on ${isInControlCenter && "studio"} ${
                  isEmbeded ? "emdeded" : ""
                }`
              : `rundown ${theme} ${isInControlCenter && "studio"} ${
                  isEmbeded ? "emdeded" : ""
                }`
          }
        >
          <ul key="rundown-list">
            {filteredShowData &&
              filteredShowData.map((topic, index) =>
                topic.includeInRundown !== false ? (
                  <li
                    className={
                      topic.topic === selectedTopic
                        ? "selected"
                        : topic.is_complete
                        ? "done"
                        : ""
                    }
                    key={`runitem-${index}`}
                  >
                    {topic.topic}
                  </li>
                ) : null
              )}
            {!sourceisPro && !isBrandingGlobal ? (
              <li
                key="footerimg"
                style={{ paddingBottom: "0px", textAlign: "center" }}
              >
                <img src="/img/shopro-powered.png" width="100px" />
              </li>
            ) : null}
          </ul>
        </div>
      )}
    </>
  );
};

export default Rundown;
