import React, { useEffect, useState } from "react";
import SceneItem from "./SceneItem";
import { SceneProps } from "../../../types/Types";
import {
  GetFromLocalStorage,
  SaveToLocalStorage,
} from "../../../helpers/LocalStorage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const SceneSelector = ({
  scenes,
  selectedScene,
  websocket,
  isActive,
  isStreaming,
  isRecording,
}: SceneProps) => {
  const [hiddenBtns, setHiddenButtons] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const [scenesArr, setScenesArr] = useState([]);

  const onSceneToggle = (scene: string, visible: boolean) => {
    //console.log("Scene", scene, visible);
    if (!visible) {
      if (!hiddenBtns.includes(scene)) {
        setHiddenButtons((prevHiddenBtns) => [...prevHiddenBtns, scene]);
      }
    } else {
      if (hiddenBtns.includes(scene)) {
        setHiddenButtons((prevHiddenBtns) =>
          prevHiddenBtns.filter((btn) => btn !== scene)
        );
      }
    }
  };

  useEffect(() => {
    //console.log("hiddenBtns", hiddenBtns);
    if (hiddenBtns.length) {
      SaveToLocalStorage("hbtn", JSON.stringify(hiddenBtns));
    }
  }, [hiddenBtns]);

  useEffect(() => {
    //console.log(scenes);
    if (scenes && scenes.length > 0) {
      const sortedScenes = [...scenes];
      sortedScenes.sort((a, b) => {
        const sceneNameA = a.sceneName.toUpperCase(); // Convert to uppercase for case-insensitive sorting
        const sceneNameB = b.sceneName.toUpperCase();

        if (sceneNameA < sceneNameB) {
          return -1;
        }
        if (sceneNameA > sceneNameB) {
          return 1;
        }
        return 0; // If scene names are equal
      });
      //console.log(scenes);
      setScenesArr(sortedScenes);
    }
  }, [scenes]);

  useEffect(() => {
    const hbtn = GetFromLocalStorage("hbtn");
    if (hbtn) {
      const hiddenBtns = JSON.parse(hbtn);
      if (hiddenBtns.length) {
        /* console.log(
          "hiddenBtns",
          hiddenBtns,
          hiddenBtns.includes("preload-vert")
        ); */
        setHiddenButtons(hiddenBtns);
      }
    }
  }, []);

  return (
    <div
      className={isActive ? "scene-selector active" : "scene-selector"}
      key="scenes"
    >
      <p key="title">Scene Selector - [ Current: {selectedScene} ] </p>
      <div className="status-wrap">
        REC: <span className={isRecording ? "circle green" : "circle"}></span>{" "}
        STREAMING:{" "}
        <span className={isStreaming ? "circle green" : "circle"}></span>
        <span className="" onClick={() => setShowAll(!showAll)}>
          {showAll ? (
            <FontAwesomeIcon icon={faEye} />
          ) : (
            <FontAwesomeIcon className="red-text" icon={faEyeSlash} />
          )}
        </span>
      </div>
      <hr key="spacer" />
      <div className="scene-selector-wrap" key="wrap">
        {scenesArr && scenesArr.length
          ? scenesArr.map((scene, index) => (
              <SceneItem
                key={index}
                scene={scene}
                selectedScene={selectedScene}
                websocket={websocket}
                id={index}
                hiddenBtns={hiddenBtns}
                onHideToggle={onSceneToggle}
                showAll={showAll}
              />
            ))
          : null}
      </div>
    </div>
  );
};

export default SceneSelector;
