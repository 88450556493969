import React from "react";
import LiveShowItem from "./LiveShow";

const LiveNow = ({ shows }) => {
  return (
    <div className="mx-auto mt-10 max-w-7xl px-6 sm:mt-20 pt-24 lg:px-8">
      <div className="text-center">
        <h2 className="text-base font-semibold text-indigo-600 tracking-wide uppercase">
          Live Now
        </h2>
        <p className="mt-1 text-4xl font-extrabold text-white sm:text-5xl sm:tracking-tight lg:text-6xl">
          Live Shows
        </p>
        <p className="max-w-xl mt-5 mx-auto text-xl text-gray-500">
          Tune in to live shows, podcasts, and live events.
        </p>
      </div>
      <div className="flex flex-col sm:flex-row gap-8 sm:gap-2 mt-12">
        {shows &&
          shows.map((show) => (
            <LiveShowItem
              show={show.show}
              user={show.User}
              image={show.imagedata}
            />
          ))}
      </div>
    </div>
  );
};

export default LiveNow;
