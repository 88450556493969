import React, { useEffect, useState, Fragment, ChangeEvent } from "react";
import { Dialog, Transition } from "@headlessui/react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { tw } from "../../../helpers/Styles";
import { useTranslation } from "react-i18next";
import { getBearerHeaders } from "../../../helpers/GetBearerHeaders";
import axios from "axios";

export interface ShowPickerProps {
  storyData: any;
  isEditOpen: boolean;
  shows: any;
  onClose: (isOpen: boolean) => void;
}
const ShowPicker = ({
  storyData,
  isEditOpen,
  shows,
  onClose,
}: ShowPickerProps) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(isEditOpen);
  const [tempItemData, setTempItemData] = useState(null);
  const [checked, setChecked] = useState<boolean>(true);
  const [selectedShowIndex, setSelectedShowIndex] = useState<number>(0);

  const topicPlaceHolder = t("planner.editor.topic_placeholder");
  const tickerPlaceHolder = t("planner.editor.ticker_placeholder");
  const notesPlaceHolder = t("planner.editor.notes_placeholder");
  const scriptPlaceHolder = t("planner.editor.script_placeholder");

  //const [editorDspProps, setEditorDspProps] = useState("translate-x-0");
  const handleValueUpdate = (
    event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>,
    field: string
  ) => {
    const fieldVal = event.target.value;
    const topicData = {
      ...tempItemData,
      [field]: fieldVal,
      isNewTopic: false,
    };

    //setCurrentTime(Number(topicData.topic_time) * 60);
    setTempItemData(topicData);
  };

  const handleCheck = () => {
    setChecked(!checked);
    const topicData = {
      ...tempItemData,
      includeInRundown: !checked,
      isNewTopic: false,
    };
    setTempItemData(topicData);
  };

  const handleSave = async () => {
    if (selectedShowIndex !== null) {
      shows[selectedShowIndex].show.task_list_items.push(tempItemData);
      const showId = shows[selectedShowIndex].id;
      const postData = {
        showid: showId,
        showData: shows[selectedShowIndex].show,
      };
      await axios.post("/api/v1/saveshow", postData, getBearerHeaders());
      onClose(false);
    }
  };

  useEffect(() => {
    setOpen(isEditOpen);
  }, [isEditOpen]);

  useEffect(() => {
    if (storyData) {
      setTempItemData(storyData);
    }
  }, [storyData]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-[260]" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className={tw.modalBg} />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 left-0 flex max-w-full pr-5">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-300 sm:duration-300"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-300 sm:duration-300"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col overflow-y-auto bg-white drop-shadow-lg border-r border-ssblue ">
                    <div className="bg-ssblue px-4 py-6 sm:px-6">
                      <div className="flex items-center justify-between">
                        <Dialog.Title className="text-base font-semibold leading-6 text-white">
                          Add Card To Show
                        </Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <FontAwesomeIcon
                            className="relative rounded-md bg-ssbluehover text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white pointer"
                            icon={faCircleXmark}
                            onClick={() => onClose(false)}
                          />
                        </div>
                      </div>
                      <div className="mt-1">
                        <p className="text-sm text-indigo-300">
                          {t("planner.editor.subtitle")}
                        </p>
                      </div>
                    </div>
                    <div className="relative flex-1 px-4 py-1 sm:px-6 bg-ssblue2">
                      <div className="space-y-6 pb-5 pt-6">
                        <div>
                          <label className="block text-sm font-medium leading-6 text-white">
                            Choose A Show to Add Your Segment To
                          </label>
                          <div className="mt-2">
                            <select
                              onChange={(e) =>
                                setSelectedShowIndex(Number(e.target.value))
                              }
                              className="block w-full rounded-md border-0 p-1.5 bg-slate-300 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            >
                              {shows &&
                                shows.map((show, index) => (
                                  <>
                                    {show.endtime === null && (
                                      <option key={index} value={index}>
                                        {show.show.showTitle}
                                      </option>
                                    )}
                                  </>
                                ))}
                            </select>
                          </div>
                        </div>
                        <div>
                          <label className="block text-sm font-medium leading-6 text-white">
                            {t("planner.editor.topic_time")}
                          </label>
                          <div className="mt-2">
                            <input
                              type="number"
                              min="1"
                              max="500"
                              name="project-name"
                              id="project-name"
                              className="block w-16 rounded-md border-0 p-1.5 bg-slate-300 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              value={
                                tempItemData ? tempItemData.topic_time : ""
                              }
                              onChange={(e) =>
                                handleValueUpdate(e, "topic_time")
                              }
                            />
                          </div>
                        </div>
                        <div>
                          <label className="block text-sm font-medium leading-6 text-white">
                            {t("planner.editor.slug")}
                          </label>
                          <div className="mt-2">
                            <input
                              type="text"
                              name="project-name"
                              id="project-name"
                              className="block w-full rounded-md border-0 p-1.5 bg-slate-300 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              value={tempItemData ? tempItemData.topic : ""}
                              onChange={(e) => handleValueUpdate(e, "topic")}
                              placeholder={topicPlaceHolder}
                            />
                          </div>
                        </div>
                        <div>
                          <label className="block text-sm font-medium leading-6 text-white">
                            {t("ticker")}
                          </label>
                          <div className="mt-2">
                            <input
                              type="text"
                              name="project-name"
                              id="project-name"
                              className="block w-full rounded-md border-0 p-1.5 bg-slate-300 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              value={
                                tempItemData ? tempItemData.ticker_label : ""
                              }
                              onChange={(e) =>
                                handleValueUpdate(e, "ticker_label")
                              }
                              placeholder={tickerPlaceHolder}
                            />
                          </div>
                        </div>
                        <div>
                          <label className="block text-sm font-medium leading-6 text-white">
                            {t("planner.notes")}
                          </label>
                          <div className="mt-2">
                            <textarea
                              id="notes"
                              name="notes"
                              rows={4}
                              className="block w-full rounded-md border-0 p-1.5 bg-slate-300 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              value={tempItemData ? tempItemData.notes : ""}
                              onChange={(e) => handleValueUpdate(e, "notes")}
                              placeholder={notesPlaceHolder}
                            ></textarea>
                          </div>
                        </div>
                        <div>
                          <label className="block text-sm font-medium leading-6 text-white">
                            {t("planner.script")}
                          </label>
                          <div className="mt-2">
                            <textarea
                              id="script"
                              name="script"
                              rows={4}
                              className="block w-full rounded-md border-0 p-1.5 bg-slate-300 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              value={
                                tempItemData && tempItemData.script
                                  ? tempItemData.script
                                  : ""
                              }
                              onChange={(e) => handleValueUpdate(e, "script")}
                              placeholder={scriptPlaceHolder}
                            ></textarea>
                          </div>
                        </div>
                        <fieldset>
                          <div className="mt-2 space-y-4">
                            <div className="relative flex items-start">
                              <div className="absolute flex h-6 items-center">
                                <input
                                  id="privacy-public"
                                  name="privacy"
                                  aria-describedby="privacy-public-description"
                                  type="checkbox"
                                  className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                  defaultChecked={checked}
                                  onChange={handleCheck}
                                />
                              </div>
                              <div className="pl-7 text-sm leading-6">
                                <label className="font-medium text-white">
                                  {t("planner.editor.rundown_include")}
                                </label>
                                <p
                                  id="privacy-public-description"
                                  className="text-white"
                                >
                                  {t("planner.editor.rundown_sub")}
                                </p>
                              </div>
                            </div>
                          </div>
                        </fieldset>
                      </div>
                    </div>
                    <div className="flex flex-shrink-0 justify-end px-4 py-4 bg-ssblue">
                      <button
                        type="button"
                        className={`${tw.btn.reg} bg-red hover:bg-redHover text-white mr-1`}
                        onClick={() => onClose(false)}
                      >
                        {t("cancel")}
                      </button>
                      <button
                        type="button"
                        className={`${tw.btn.reg} bg-green-800 hover:bg-green-950 text-white hover:text-white`}
                        onClick={handleSave}
                      >
                        {t("planner.save_segment")}
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ShowPicker;
