import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import ImageWithFallback from "../../components/ImageWithFallback";
//import { tw } from "../../../../helpers/Styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilm,
  faPlayCircle,
  faScissors,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import CardProcessing from "./CardProcessing";
import { secondsToTimecode } from "../Video/helpers/helpers";

const ProjectCard = ({ project, progressData, deleteProject }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const updateProjectTitle = (event) => {
    console.log("UpdateProject Title:", event.target.value);
  };

  const trashProject = (projectId) => {
    deleteProject(projectId);
  };

  const loadVideo = (projectData) => {
    navigate(`/streamslicer/video-editor/${projectData.id}`, {
      state: { ...projectData, videoType: "PROJECT" },
    });
  };

  const goToSlices = (projectId) => {
    navigate(`/moments/${projectId}`);
  };

  return (
    <div
      className={`flex flex-col col-span-1 divide-y divide-slate-800 border border-slate-800 rounded-md bg-ssblue shadow hover:bg-ssblue2 transition-colors duration-300 mt-3 sm:mt-0 justify-between h-full`}
      key={project.projectId}
    >
      {project.status.toUpperCase() === "PROCESSING" ? (
        <CardProcessing
          project={project}
          progressData={progressData}
          deleteProject={trashProject}
        />
      ) : (
        <>
          <div className="flex w-full flex-col">
            <div className="aspect-video rounded-t-md relative">
              <ImageWithFallback
                className="aspect-video pointer rounded-t-md opacity-65 hover:opacity-100 transition-opacity duration-300"
                imgPath={project.thumbnailPath.replace("public/", "")}
                imgAlt=""
                onClick={() => loadVideo(project)}
              />
              <div
                className="absolute bottom-3 left-3"
                key={`controls=${project.projectId}`}
              >
                <FontAwesomeIcon
                  icon={faPlayCircle}
                  className="text-white text-3xl"
                />
              </div>
            </div>
            <div className="mt-2 p-2">
              <span
                className="block font-semibold"
                onChange={(e) => updateProjectTitle(e)}
              >
                {project.name}
              </span>
              <span className="block">
                {" "}
                {t("project_id")}: {project.id}
              </span>
              <span className="block text-sm text-gray-400">
                {t("created")}: {new Date(project.createdAt).toLocaleString()}
              </span>
              <span className="block text-sm text-gray-400">
                {t("streamslicer.project_length")}:{" "}
                <time>{secondsToTimecode(project.duration)}</time>
              </span>
              <div className="text-sm text-gray-400">
                {t("streamslicer.generated_clips")} - N/A |{" "}
                {t("streamslicer.saved_clips")} - N/A
              </div>
            </div>
          </div>

          <div className="-mt-px flex divide-x divide-slate-900 bg-blue rounded-bl-md rounded-br-md">
            {!project.transcribed ? (
              <div className="flex w-0 flex-1">
                <button
                  className={`flex-1 bg-blue hover:bg-blueHover p-2 rounded-bl-md uppercase font-semibold`}
                  onClick={() => loadVideo(project)}
                >
                  <FontAwesomeIcon className="mr-2" icon={faScissors} />{" "}
                  {t("buttons.edit")}
                </button>
              </div>
            ) : (
              <div className="flex w-0 flex-1">
                <button
                  className={`flex-1 bg-blue hover:bg-blueHover p-2 rounded-bl-md uppercase font-semibold`}
                  onClick={() => goToSlices(project.projectId)}
                >
                  <FontAwesomeIcon className="mr-2" icon={faFilm} />{" "}
                  {t("buttons.clip_moments")}
                </button>
              </div>
            )}
            <div className="flex w-0 flex-1">
              <button
                className={`flex-1 bg-blue hover:bg-blueHover p-2 rounded-br-md uppercase font-semibold`}
                onClick={() => trashProject(project.id)}
              >
                <FontAwesomeIcon className="mr-2" icon={faTrash} />{" "}
                {t("buttons.delete")}
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ProjectCard;
