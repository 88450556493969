import { useEffect, useState } from "react";

export interface OptionProps {
  badge?: string;
  body?: string;
  icon?: string;
  image?: string;
  vibrate?: [];
}

export interface NotificationOptions {
  title: string;
  options?: OptionProps;
}
const useNotifications = () => {
  const notificationCapable = "Notification" in window;
  const [hasNotificationPermission, setHasNotificationPermission] =
    useState<boolean>(
      notificationCapable ? Notification.permission === "granted" : false
    );
  const [permission, setPermission] = useState<string>(
    notificationCapable ? Notification.permission : "NA"
  );

  const notification = ({ title, options }: NotificationOptions) => {
    if (hasNotificationPermission) {
      console.log("New Notification", title, options);
      if (options) {
        new Notification(title, options);
      } else {
        new Notification(title);
      }
    }
  };

  const requestPermission = () => {
    if (notificationCapable) {
      Notification.requestPermission().then((permission) => {
        // If the user accepts, let's create a notification
        console.log("permission", permission);
        if (permission === "granted") {
          setPermission("granted");
          setHasNotificationPermission(true);
        }
      });
    }
  };

  useEffect(() => {
    if (!notificationCapable) {
      //alert("THIS BROWSER DOES NOT SUPPORT DESKTOP NOTIFICATIONS");
    } else if (Notification.permission === "granted") {
      console.log("PERMISSION ALREADY GRANTED");
      setPermission("granted");
      setHasNotificationPermission(true);
    } else if (Notification.permission !== "denied") {
      setPermission("denied");
      setHasNotificationPermission(false);
    }
  }, []);

  return {
    notification,
    hasNotificationPermission,
    permission,
    notificationCapable,
    requestPermission,
  };
};

export default useNotifications;
