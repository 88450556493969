import React, { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Ticker from "./Ticker";
import ChyronSource from "./ChyronSource";
import Header from "../../layout/Header";
import SourceEditor from "./SourceEditor";
import Rundown from "./RunndownSource";
import { getBearerHeaders } from "../../../helpers/GetBearerHeaders";
import axios from "axios";
import Overlay from "./OverlaySource";
import { UserContext } from "../../../context/UserContext";
import useTracking from "../../../hooks/useTracking";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faSliders } from "@fortawesome/free-solid-svg-icons";

export const SelectedSources = () => {
  useTracking();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const { sourceType } = useParams();
  const [theme, setTheme] = useState<string>();
  const [isEditingTheme, setIsEditingTheme] = useState<boolean>(false);
  const tickerText =
    " CURRENT TOPIC: The Current Topic Goes Here | SHOPRO LIVE • Some Text about a segment • Some more text • SHOPRO.LIVE";
  //console.log(sourceType);
  const topicData = {
    topic: "Segment 1",
    show: [
      { topic: "Segment 1" },
      { topic: "Segment 2" },
      { topic: "Segment 3" },
      { topic: "Segment 4" },
      { topic: "Segment 5" },
    ],
  };

  const onThemeChoice = (userTheme: string) => {
    const postData = {
      theme: userTheme,
    };

    axios.post("/api/v1/settheme", postData, getBearerHeaders()).then(() => {});
    setTheme(userTheme);
  };

  const loadIframe = () => {};

  return (
    <>
      <Header classname="bg-transparent" />
      <div className="absolute top-0 left-0 w-screen h-screen cover bg-theme-room">
        {sourceType?.toUpperCase() === "TICKER" && (
          <>
            <SourceEditor
              label={t("ticker")}
              sourcetype="ticker"
              onTheme={onThemeChoice}
              onUriReady={loadIframe}
              isEditing={isEditingTheme}
              onCancelEdit={setIsEditingTheme}
            />
            <Ticker
              tickerString={tickerText}
              defaultTheme={theme}
              isProAccount={
                user.accounttype.toUpperCase() === "PRO" ? true : false
              }
            />
          </>
        )}

        {sourceType?.toUpperCase() === "OVERLAY" && (
          <>
            <SourceEditor
              label="Full Overlay"
              sourcetype="overlay"
              onTheme={onThemeChoice}
              onUriReady={loadIframe}
              isEditing={isEditingTheme}
              onCancelEdit={setIsEditingTheme}
            />
            <Overlay
              defaultTheme={theme}
              rnData={topicData}
              topicString="Current Segment"
              tickerString={tickerText}
              isProAccount={
                user.accounttype.toUpperCase() === "PRO" ? true : false
              }
            />
          </>
        )}
        {sourceType?.toUpperCase() === "CHYRON" && (
          <>
            <SourceEditor
              label="Chyron"
              sourcetype="topic"
              onTheme={onThemeChoice}
              onUriReady={loadIframe}
              isEditing={isEditingTheme}
              onCancelEdit={setIsEditingTheme}
            />
            <ChyronSource text="Current Segment" defaultTheme={theme} />
          </>
        )}
        {sourceType?.toUpperCase() === "RUNDOWN" && (
          <>
            <SourceEditor
              label="Rundown"
              sourcetype="rundown"
              onTheme={onThemeChoice}
              onUriReady={loadIframe}
              isEditing={isEditingTheme}
              onCancelEdit={setIsEditingTheme}
            />
            <Rundown rnData={topicData} defaultTheme={theme} />
          </>
        )}
        <button
          className="fixed top-2 left-[135px] bg-blue-charcoal-700 hover:bg-tangerine-700 text-white text-xs py-1 rounded-sm z-[250] border border-ssblue2 px-3 uppercase font-semibold transition-colors duration-300 shadow "
          onClick={() => navigate("/")}
        >
          <FontAwesomeIcon className={`mr-1`} icon={faArrowLeft} /> Back
        </button>
        <button
          className="fixed top-2 left-[210px] bg-blue-charcoal-700 hover:bg-tangerine-700 text-white text-xs py-1 rounded-sm z-[250] border border-ssblue2 px-3 uppercase font-semibold transition-colors duration-300 shadow "
          onClick={() => setIsEditingTheme(!isEditingTheme)}
        >
          <FontAwesomeIcon className={`mr-1`} icon={faSliders} /> Theme Details
        </button>
      </div>
    </>
  );
};

export default SelectedSources;
