import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilm } from "@fortawesome/free-solid-svg-icons";

const pages = [{ name: "Editor", href: "#", current: true }];

export default function Breadcrumps() {
  return (
    <nav className="flex w-full mt-2" aria-label="Breadcrumb">
      <ol
        role="list"
        className="flex space-x-4 rounded-md bg-ssblue border border-ssgray px-6  w-full"
      >
        <li className="flex">
          <div className="flex items-center">
            <Link
              to="/streamslicer"
              className="text-sky-500 hover:text-sky-200"
            >
              <FontAwesomeIcon icon={faFilm} />
              <span className="sr-only">Home</span>
            </Link>
          </div>
        </li>
        {pages.map((page) => (
          <li key={page.name} className="flex">
            <div className="flex items-center">
              <svg
                className="h-full w-6 flex-shrink-0 text-slate-800"
                viewBox="0 0 24 44"
                preserveAspectRatio="none"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
              </svg>
              <span
                className="ml-4 text-sm font-medium text-sky-200"
                aria-current={page.current ? "page" : undefined}
              >
                {page.name}
              </span>
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
}
