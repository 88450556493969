import { faGears } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import { tw } from "../../helpers/Styles";

export interface TeleprompterProps {
  script: string;
  onTeleprompterSettings: (speed: number, fontSize: number) => void;
  onClose: () => void;
  settings: any;
  elapsedTopicTime: any;
}
const Teleprompter = ({
  script,
  onClose,
  onTeleprompterSettings,
  settings,
  elapsedTopicTime,
}: TeleprompterProps) => {
  const [play, setPlay] = useState<boolean>(false);
  const [speed, setSpeed] = useState(settings.speed);
  const [fontSize, setFontSize] = useState(settings.textsize);
  const [showSettings, setShowSettings] = useState(false);

  const handleKeyDown = (e: KeyboardEvent) => {
    e.preventDefault();
    //console.log(e.code, play);
    if (e.code.toUpperCase() === "SPACE") {
      //console.log(e.code, play);
      setPlay((prevPlay) => !prevPlay);
    }
  };

  useEffect(() => {
    onTeleprompterSettings(speed, fontSize);
  }, [speed, fontSize]);

  useEffect(() => {
    const handleScroll = () => {
      if (play) {
        const element = document.querySelector(".overflow-y-scroll"); // Selecting the overflow-scroll div
        if (element) {
          const currentScroll = element.scrollTop;
          const maxScroll = element.scrollHeight - element.clientHeight;
          if (currentScroll >= maxScroll) {
            //element.scrollTop = 0;
          } else {
            element.scrollTop += 1;
          }
        }
      }
    };

    const scroller = setInterval(handleScroll, speed);

    return () => {
      clearInterval(scroller);
    };
  }, [play, speed]);

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <>
      <div
        className={`fixed inset-0 bg-slate-950 transition-opacity duration-200 bg-opacity-90 z-[500]`}
      ></div>
      <div className="fixed top-0 flex justify-center w-full items-center z-[510] bg-black py-5  border-b border-slate-700">
        {!showSettings && (
          <>
            <button
              className={`${tw.btn.action} bg-gray-500 rounded-sm mr-2`}
              onClick={() => setPlay(play ? false : true)}
            >
              {play ? "STOP" : "START"}
            </button>
            <button
              className={`${tw.btn.action} bg-gray-500 rounded-sm mr-2`}
              onClick={onClose}
            >
              Close
            </button>
            <button
              className={`${tw.btn.action} bg-gray-500 rounded-sm`}
              onClick={() => setShowSettings(!showSettings)}
            >
              <FontAwesomeIcon icon={faGears} />
            </button>
          </>
        )}

        <div className="fixed right-3 top-1">{elapsedTopicTime}</div>

        {showSettings && (
          <>
            <div className="w-55 mr-2">
              <span className="text-sm block">
                Scroll Speed - {126 - speed}
              </span>
              <input
                type="range"
                min="25"
                max="125"
                defaultValue={126 - speed}
                onChange={(e) => setSpeed(126 - Number(e.currentTarget.value))}
              ></input>
            </div>
            <div className="w-55 mr-2">
              <span className="text-sm block">Text Size - {fontSize}</span>
              <input
                type="range"
                min="16"
                max="150"
                defaultValue={fontSize}
                onChange={(e) => setFontSize(Number(e.currentTarget.value))}
              ></input>
            </div>
            <div className="w-5">
              <button
                className={`${tw.btn.action} bg-gray-500 mr-2 rounded-sm`}
                onClick={() => setShowSettings(!showSettings)}
              >
                <FontAwesomeIcon icon={faGears} />
              </button>
            </div>
          </>
        )}
      </div>
      <div className="fixed overflow-y-scroll h-full z-[501] px-10 sm:px-48 pt-24 pb-96">
        <p
          className="break-word sm:break-normal"
          style={{
            fontFamily: "sans-serif",
            fontSize: fontSize,
            lineHeight: "150%",
            marginBottom: "60px",
            color: "rgba(255,255,255,0.9)",
            fontStyle: "normal",
            fontWeight: 500,
            transition: "all 0.2s",
            outline: "none",
          }}
        >
          {script}
        </p>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <p>[ ------- END ------- ] </p>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    </>
  );
};

export default Teleprompter;
