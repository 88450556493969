import React from "react";
import { secondsToTimecode } from "../../../helpers/TimeCode";
import { tw } from "../../../helpers/Styles";
import { useTranslation } from "react-i18next";

export interface ShowStatProps {
  completedShowTime: number;
  completedShows: number;
  showCount: number;
  avgShowLength: number;
}

const ShowStats = ({
  completedShowTime,
  completedShows,
  showCount,
  avgShowLength,
}: ShowStatProps) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="m-1 p-2">
        <div className="divide-y divide-slate-800">
          <h1 className={`${tw.text.cardTitle} `}>{t("show_stats")}</h1>
          <div className="px-4 py-2 sm:grid sm:grid-cols-2 sm:gap-2 sm:px-0">
            <span>{t("total_show_time")}:</span>
            <span className="">{secondsToTimecode(completedShowTime)}</span>
          </div>
          <div className="px-4 py-2 sm:grid sm:grid-cols-2 sm:gap-2 sm:px-0">
            <span>{t("avg_show_length")}:</span>
            <span className="">{secondsToTimecode(avgShowLength)}</span>
          </div>
          <div className="px-4 py-2 sm:grid sm:grid-cols-2 sm:gap-2 sm:px-0">
            <span>{t("completed_shows")}:</span>{" "}
            <span className="">{completedShows}</span>
          </div>
          <div className="px-4 py-2 sm:grid sm:grid-cols-2 sm:gap-2 sm:px-0">
            <span>{t("total_shows")}:</span>
            <span className="">{showCount}</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShowStats;
