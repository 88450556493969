import React, { useEffect, useRef } from "react";

type DefaultValue = string | { input: string; output: string };
export interface OptionSelectorProps {
  options: any;
  defaultValue: DefaultValue;
  onOptionClick: (type: string, val: string) => void;
  optionType: string;
  onCancel: () => void;
}

const OptionSelector = ({
  options,
  defaultValue,
  onOptionClick,
  optionType,
  onCancel,
}: OptionSelectorProps) => {
  const optionsDivRef = useRef(null);
  const handleOptionClick = (val: string, type: string, label: string) => {
    console.log("HANDLE CLICK START");
    console.log("Selected", val, type, label);
    onCancel();
    if (onOptionClick) {
      console.log("CALLING ON OPTION CLICK");
      onOptionClick(type, val);
    }

    console.log("HANDLE CLICK DONE", optionType);
  };

  useEffect(() => {
    console.log("defaultValue", defaultValue);
    function handleClickOutside(event) {
      if (
        optionsDivRef.current &&
        !optionsDivRef.current.contains(event.target)
      ) {
        onCancel();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  /**
   * 
   * cursor: pointer;
        font-size: 0.9rem;
        padding: 0.5rem;
        border-bottom: solid 1px #2e4155;
        &.active {
          background-color: #22326f;
        }
        &:last-child {
          border-bottom: none;
        }

        &:hover {
          background-color: #2e4155;
        }
   */
  const titleStyle =
    "flex text-sm p-[0.5rem] border-b-[1px] border-[#2e4155] bg-slate-950 text-slate-300 font-semibold items-center justify-center uppercase";
  const optionStyle =
    "cursor-pointer text-sm p-[0.5rem] border-b-[1px] border-[#2e4155]  hover:bg-[#2e4155] truncate";
  const optionStyleActive =
    "cursor-pointer text-sm p-[0.5rem] border-b-[1px] bg-[#22326f] border-[#2e4155] hover:bg-[#2e4155] truncate";
  return (
    <div
      className="absolute w-[300px] bg-[#171d23] h-auto min-h-[200px] max-h-[300px] z-10 border top-[-100px] overflow-hidden overflow-y-scroll border-[#2e4155] "
      ref={optionsDivRef}
    >
      <ul>
        {options.map((device, index) => (
          <li
            key={index}
            value={device.value}
            title={device.label}
            onClick={
              device.value !== "TITLE"
                ? () =>
                    handleOptionClick(device.value, device.type, device.label)
                : null
            }
            className={
              device.value === "TITLE"
                ? titleStyle
                : device.type === "video"
                ? defaultValue === device.value
                  ? optionStyleActive
                  : optionStyle
                : device.type === "audio"
                ? defaultValue === device.value
                  ? optionStyleActive
                  : optionStyle
                : device.type === "output"
                ? defaultValue === device.value
                  ? optionStyleActive
                  : optionStyle
                : optionStyle
            }
          >
            {device.label}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default OptionSelector;
