import React, { Fragment, useContext, useState } from "react";
import {
  faArrowUpFromBracket,
  faRightFromBracket,
  faUser,
  faUserLock,
  faCircleUser,
  faVideo,
  faTv,
  faCalendarDays,
  faTableColumns,
  faBars,
  faCircleXmark,
  faBug,
  faHammer,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import useIsAuthenticated from "../../hooks/useIsAuthenticated";
import { UserContext } from "../../context/UserContext";
import { Dialog, Transition } from "@headlessui/react";
import { tw } from "../../helpers/Styles";
import { useTranslation } from "react-i18next";
import useExperiments from "../../hooks/useExperiments";
import useIsAdmin from "../../hooks/useIsAdmin";
import NavItem from "./components/NavItem";

const SideBar = () => {
  const { user } = useContext(UserContext);
  const hasExperimentAccess = useExperiments();
  const hasAdminRights = useIsAdmin();
  const { t } = useTranslation();
  //const [isOpen, setIsOpen] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const isLoggedIn = useIsAuthenticated();

  const liClass =
    "flex  border-b border-slate-700 hover:bg-slate-700 transition-colors duration-300";
  const aClass =
    "block w-full p-2 text-right no-underline hover:text-yellow-600 transition-colors duration-300";

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-[260]" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className={tw.modalBg} />
          </Transition.Child>

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-5">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-300 sm:duration-300"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-300 sm:duration-300"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-[300px] max-w-sm">
                    <div className="flex h-full flex-col overflow-y-auto bg-white shadow-xl">
                      <div className="bg-ssblue px-4 py-6 sm:px-6">
                        <div className="flex items-center justify-between">
                          <Dialog.Title className="font-semibold leading-6 text-white">
                            {isLoggedIn && (
                              <span className="text-sm">
                                <FontAwesomeIcon icon={faCircleUser} />{" "}
                                {user.username.split("@")[0]} -{" "}
                                {user.accounttype.toUpperCase()}
                              </span>
                            )}
                            <div className="absolute top-4 right-5 items-center cursor-pointer transition-colors">
                              <FontAwesomeIcon
                                className=" relative rounded-md bg-ssbluehover text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                icon={faCircleXmark}
                                onClick={() => setOpen(false)}
                              />
                            </div>
                          </Dialog.Title>
                        </div>
                      </div>
                      <div className="relative flex-1 p-0 bg-ssblue2">
                        <div className="space-y-6 pb-5">
                          <ul className="flex flex-col hover:text-yellow">
                            <NavItem
                              dest="/"
                              icon={faTableColumns}
                              label={t("nav.dashboard")}
                            />

                            {isLoggedIn ? (
                              <>
                                <NavItem
                                  dest="/planner"
                                  icon={faCalendarDays}
                                  label={t("nav.planner")}
                                />
                                <NavItem
                                  dest={`/studio/${user.roomid}`}
                                  icon={faVideo}
                                  label={t("nav.studio")}
                                />

                                {hasExperimentAccess && (
                                  <NavItem
                                    dest={`/streamslicer`}
                                    icon={faVideo}
                                    label={t("nav.streamslicer")}
                                  />
                                )}

                                <NavItem
                                  dest={`/account`}
                                  icon={faUser}
                                  label={t("nav.account")}
                                />

                                {user.accounttype === "free" && (
                                  <li className={liClass}>
                                    <Link to="/upgrade" className={aClass}>
                                      {t("nav.upgrade")}{" "}
                                      <FontAwesomeIcon
                                        icon={faArrowUpFromBracket}
                                        className="ml-2"
                                      />
                                    </Link>
                                  </li>
                                )}

                                <li className={liClass}>
                                  <a
                                    href="https://www.youtube.com/@Shoprolive"
                                    target="_ext"
                                    className={aClass}
                                  >
                                    {t("nav.tutorials")}{" "}
                                    <FontAwesomeIcon
                                      icon={faTv}
                                      className="ml-2"
                                    />
                                  </a>
                                </li>
                                {hasAdminRights && (
                                  <NavItem
                                    dest={`/thor`}
                                    icon={faHammer}
                                    label={"Thor"}
                                  />
                                )}
                                <NavItem
                                  dest={`/logout`}
                                  icon={faRightFromBracket}
                                  label={t("nav.logout")}
                                />
                              </>
                            ) : (
                              <>
                                <li>
                                  <Link to="/upgrade" className={aClass}>
                                    {t("nav.tutorials")}{" "}
                                    <FontAwesomeIcon
                                      icon={faArrowUpFromBracket}
                                      className="ml-2"
                                    />
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/login" className={aClass}>
                                    {t("nav.login")}{" "}
                                    <FontAwesomeIcon icon={faUserLock} />
                                  </Link>
                                </li>
                              </>
                            )}
                          </ul>
                        </div>
                      </div>
                      <div className="flex flex-shrink-0 justify-end px-4 py-4 bg-ssblue text-xs">
                        <p className="text-right">
                          <Link to="/tos">{t("nav.terms")}</Link> |{" "}
                          <Link to="/privacy">{t("nav.privacy")}</Link> |{" "}
                          <Link
                            to="https://github.com/kmallea/shopro-public/issues/new?assignees=&labels=bug&projects=&template=bug_report.md&title="
                            target="_link"
                          >
                            {t("nav.report_issue")}{" "}
                            <FontAwesomeIcon icon={faBug} />
                          </Link>
                          <br />
                          <span className="small">&copy; 2023 ShoPro</span>
                        </p>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <i
        className="fixed top-[5px] right-5 text-lg z-20 cursor-pointer hover:text-black transition-colors duration-300"
        onClick={() => setOpen(!open)}
      >
        <FontAwesomeIcon icon={faBars} />
      </i>
      {/* 
      <i
        className="fa fa-bars nav-toggle"
        onClick={() => setIsOpen(isOpen ? false : true)}
      >
        <FontAwesomeIcon icon={faBars} />
      </i>
      <div
        className={isOpen ? "nav-bg open" : "nav-bg"}
        onClick={() => setIsOpen(isOpen ? false : true)}
      ></div>
      <nav role="navigation" className={isOpen ? "open" : ""}>
        <div className="nav-header">
          {isLoggedIn && (
            <>
              <span>
                <FontAwesomeIcon icon={faCircleUser} />{" "}
                {user.username.split("@")[0]} - {user.accounttype.toUpperCase()}
                <br />
              </span>
              <br />
            </>
          )}
          <i
            className="fa fa-close nav-toggle"
            onClick={() => setIsOpen(isOpen ? false : true)}
          >
            <FontAwesomeIcon icon={faClose} />
          </i>
        </div>
        
        <div className="nav-footer">
          <p>
            <Link to="/tos">Terms</Link> | <Link to="/privacy">Privacy</Link> |{" "}
            <Link
              to="https://github.com/kmallea/shopro-public/issues/new?assignees=&labels=bug&projects=&template=bug_report.md&title="
              target="_link"
            >
              Report a bug <FontAwesomeIcon icon={faBug} />
            </Link>
            <br />
            <span className="small">&copy; 2023 ShoPro</span>
          </p>
        </div>
      </nav> */}
    </>
  );
};

export default SideBar;
