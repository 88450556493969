import React from "react";
import StreamSlicerNav from "./Nav/StreamSlicerNav";
import { tw } from "../../../helpers/Styles";

const SSHeader = () => {
  return (
    <div
      className={`${tw.cardPlain} md:flex md:items-center md:justify-between md:space-x-5 m-2 px-4 pt-1`}
    >
      <div className="flex items-start space-x-5">
        <div className="">
          <h3 className={tw.text.cardTitle}>StreamSlicer</h3>
        </div>
      </div>
      <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-3 sm:space-y-0 sm:space-x-reverse md:mt-0 md:flex-row md:space-x-3">
        <StreamSlicerNav />
      </div>
    </div>
  );
};

export default SSHeader;
