import { useState, useEffect, useContext } from "react";
import { UserContext } from "../context/UserContext";
import { AdminUsers } from "../data/AdminUsers";

const useIsAdmin = () => {
  const { user } = useContext(UserContext);
  const [isInAdmin, setIsInAdmin] = useState(false);

  useEffect(() => {
    setIsInAdmin(
      AdminUsers.filter((experiment) => experiment === user.username).length > 0
    );
    return () => {};
  }, []);

  return isInAdmin;
};

export default useIsAdmin;
