import React from "react";
import { tw } from "../../../helpers/Styles";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const BrowserSources = () => {
  const { t } = useTranslation();
  const allSources = [
    {
      name: t("full_overlay"),
      initials: "FA",
      href: "#",
      members: 16,
      bgColor: "bg-pink-600",
      path: "/sources/overlay",
      desc: "Contains the Chyron, Rundown, and Ticker.",
    },
    {
      name: t("chyron"),
      initials: "CH",
      href: "#",
      members: 12,
      bgColor: "bg-purple-600",
      path: "/sources/chyron",
      desc: "Displays the currentl selected segment on the screen.",
    },
    {
      name: t("rundown"),
      initials: "RD",
      href: "#",
      members: 16,
      bgColor: "bg-yellow",
      path: "/sources/rundown",
      desc: "Displays where in the segment list the show is at",
    },
    {
      name: t("ticker"),
      initials: "TK",
      href: "#",
      members: 8,
      bgColor: "bg-sky-500",
      path: "/sources/ticker",
      desc: "The ticker is shown on the bottom of the screen.",
    },
  ];

  return (
    <>
      <div className="border-b border-slate-800 p-1">
        <h3 className={tw.text.cardTitle}>{t("browser_sources")}</h3>
        <p>
          Browser sources are essential elements like tickers, rundowns, and
          chyrons that are used in OBS (Open Broadcaster Software) to display
          real-time data such as show titles, schedules, and lower-third
          graphics, enhancing the viewer's experience and keeping the audience
          informed.
        </p>
      </div>
      <ul role="list" className="mt-3 grid grid-cols-2 gap-5 sm:grid-cols-2 ">
        {allSources.map((project) => (
          <li
            key={project.name}
            className="col-span-1 flex rounded-md shadow-sm"
          >
            <Link to={project.path} className="w-full no-underline">
              <div className="flex flex-1 items-center justify-between truncate rounded-md border-b border border-slate-700 bg-ssblue hover:bg-ssblue2 transition-color duration-300">
                <div className="flex-1 truncate px-4 py-2 text-sm">
                  <h3 className="text-white font-medium no-underline text-lg">
                    {project.name}
                  </h3>

                  <p className="text-gray-500">{project.desc}</p>
                </div>
              </div>
            </Link>
          </li>
        ))}
      </ul>
    </>
  );
};

export default BrowserSources;
