import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import SectionHeader from "../../components/SectionHeader";

import MomentsTabs from "./MomentsTabs";
import MomentSection from "./MomentSection";
import { useParams } from "react-router-dom";
import ApiRequest from "../../helpers/ApiRequest";
import Remoment from "./Remoment";
import { IS_API_VERSION } from "../../Const/Constants";

const MomentsPage = (props) => {
  const { t } = useTranslation();
  const { projectId } = useParams();
  const [momentsData, setMoments] = useState([]);
  const [projectDuration] = useState(0);
  const [userStatus] = useState(0);

  useEffect(() => {
    ApiRequest.post("/api/momentsByProject", {
      projectId: projectId,
    }).then((momentDataResults) => {
      setMoments(momentDataResults.data);
    });
  }, [projectId]);

  return (
    <>
      <div className="content" id="main-content">
        <SectionHeader label={t("saved_moments")} />
        <MomentsTabs userStatus={userStatus} duration={projectDuration} />
        {IS_API_VERSION ? <Remoment /> : null}
        {momentsData.length > 0 ? (
          <div className="video-grid" key="vgrid1">
            {momentsData.map((moment, index) => (
              <MomentSection moment={moment} index={index} />
            ))}
          </div>
        ) : (
          <div className="no-content" key="nocon">
            <p>
              {t("notices.no_moments")} {projectId}
            </p>
          </div>
        )}
      </div>
    </>
  );
};

export default MomentsPage;
