import React, { useEffect, useRef, useState } from "react";

export interface AudioLevelProps {
  stream: any;
  fillColor?: string;
}
const AudioLevel = ({ stream, fillColor }: AudioLevelProps) => {
  const [canvas, setCanvas] = useState(null);
  const [canvasContext, setCanvasContext] = useState(null);

  const audioLevelMeter = useRef(null);

  useEffect(() => {
    if (stream && canvasContext) {
      const audioContext = new window.AudioContext();
      const analyser = audioContext.createAnalyser();
      const microphoneInput = audioContext.createMediaStreamSource(stream);

      // Create a GainNode to control the audio output
      const gainNode = audioContext.createGain();
      gainNode.gain.value = 0; // Mute the audio

      microphoneInput.connect(analyser);
      analyser.connect(gainNode); // Connect to the GainNode
      gainNode.connect(audioContext.destination); // Connect the GainNode to the audio destination

      analyser.fftSize = 256;
      const bufferLength = analyser.frequencyBinCount;
      const dataArray = new Uint8Array(bufferLength);

      function drawAudioLevel() {
        analyser.getByteFrequencyData(dataArray);

        canvasContext.fillStyle = "#14202e";
        canvasContext.fillRect(0, 0, canvas.width, canvas.height);

        canvasContext.fillStyle = fillColor ? fillColor : "green";
        for (let i = 0; i < dataArray.length; i++) {
          const barX = canvas.width - (dataArray[i] / 256) * canvas.width;
          const y = 0;
          const barWidth = canvas.width - barX;
          const barHeight = canvas.height;

          canvasContext.fillRect(0, y, barWidth, barHeight);
        }

        requestAnimationFrame(drawAudioLevel);
      }

      /* function drawAudioLevel() {
        analyser.getByteFrequencyData(dataArray);

        canvasContext.fillStyle = "grey";
        canvasContext.fillRect(0, 0, canvas.width, canvas.height);

        canvasContext.fillStyle = "green";
        let total = 0;
        for (let i = 0; i < dataArray.length; i++) {
          total += dataArray[i];
        }
        const average = total / dataArray.length;
        const barWidth = (average / 256) * canvas.width;

        canvasContext.fillRect(0, 0, barWidth, canvas.height);

        requestAnimationFrame(drawAudioLevel);
      } */

      drawAudioLevel();
    }
  }, [stream, canvasContext]);

  useEffect(() => {
    const canvas = document.createElement("canvas");

    setCanvas(canvas);
    setCanvasContext(canvas.getContext("2d"));
    audioLevelMeter.current.appendChild(canvas);

    canvas.width = audioLevelMeter.current.clientWidth;
    canvas.height = 10;
  }, []);

  return (
    <>
      <div className="flex w-full justify-center" ref={audioLevelMeter}></div>
    </>
  );
};

export default AudioLevel;
