import React, { useContext, useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import "../../sass/control-center.scss";
import { useParams } from "react-router-dom";
import { io, Socket } from "socket.io-client";
import useRoomCams from "../../hooks/useRoomCams";
import Chat from "./components/Chat/Index";
import GuestControls from "./components/GuestControls";
import CamSceneControl from "./components/CamSceneControl";
import Shopro from "../../features/Shopro/Index";
import toast, { Toaster } from "react-hot-toast";
import Switch from "./components/Switch";
import {
  GetFromLocalStorage,
  SaveToLocalStorage,
} from "../../helpers/LocalStorage";
import { getServer } from "../../helpers/GetServer";
import VideoChatCanvas from "./components/VideoChatCanvas";
import JoinShow from "./components/JoinShow";
import { tw } from "../../helpers/Styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import StudioHeader from "../../components/layout/StudioHeader";
import OverlayDrawer from "../../components/sources/OverlayDrawer";
import { themeKharysCrib } from "../../data/Themes";
import { UserContext } from "../../context/UserContext";
import StageManager from "../Shopro/components/StageManager";
import GreenRoomCam from "./components/GreenRoomCam";
import SceneSettings from "./components/Scenes/SceneSettings";
import { useTranslation } from "react-i18next";
import useExperiments from "../../hooks/useExperiments";
import AuthMonitor from "../../helpers/AuthMonitor";
import useCanvaseScreenshot from "../../hooks/useCanvasScreenshot";
import { getBearerHeaders } from "../../helpers/GetBearerHeaders";
import {
  UpdateShowByFields,
  UploadShowCapture,
} from "../../api-requests/Updates";
import AudioLevel from "./components/AudioLevel";

export interface StudioSettings {
  soloHost?: boolean;
  rundownEnabled?: boolean;
  chyronEnabled?: boolean;
  tickerEnabled?: boolean;
  guestShareEnabled?: boolean;
  guestRundownEnabled?: boolean;
  startShowWithRec?: boolean;
}

const ControlCenter = () => {
  const { t } = useTranslation();
  const canvasRef = useRef(null);
  const { roomid } = useParams();
  const { user } = useContext(UserContext);
  const inExperiment = useExperiments();
  const { takeScreenshot } = useCanvaseScreenshot();
  AuthMonitor();

  const [roomId] = useState(roomid ? roomid : uuidv4());
  const [myStudioSettings, setMyStudioSettings] = useState<StudioSettings>(
    JSON.parse(GetFromLocalStorage("std-setts"))
  );

  if (roomid === undefined) {
    window.location.assign(`${window.location.href}/${roomId}`);
  }
  //RoomCams.setRoom((_prev) => roomId);

  //const [cameras, setCameras] = useState<CameraItemArray | null>(null);
  //const [isLoading, setIsLoading] = useState<boolean>(true);
  const [websocket, setWebsocket] = useState<Socket>(null);
  const showRecControls = false;
  const [cameraClass, setCameraClass] = useState<string>("");
  const [hasASoloedStream, setHasASoloedStream] = useState<string>("");
  const [isRecording, setIsRecording] = useState<boolean>(false);
  const [isStreaming, setIsStreaming] = useState<boolean>(false);
  const [isSharingScreen, setIsSharingScreen] = useState<boolean>(false);
  const [isShowLive, setIsShowLive] = useState(false);
  const [showSourceId, setShowSourceId] = useState("");
  const [recordingLabel, setRecordingLabel] =
    useState<string>("Start Recording");
  const [streamingLabel] = useState<string>("Start Streaming");
  const [videoStream, setVideoStream] = useState(null);
  //const [tempVideoStream, setTempVideoStream] = useState(null);
  //const [streamSet, setStreamSet] = useState(false);
  const [userImg, setUserImg] = useState(null);
  const [sharedScreenStream, setSharedScreenStream] = useState(null);
  const [shareUserId, setShareUserId] = useState(null);
  const [localStream] = useState(null);
  const [peerConnection] = useState(null);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [videoBlob, setVideoBlob] = useState(null);
  const [timesRecorded, setTimesRecorded] = useState(0);
  //const [audioSource, setAudioSource] = useState<string>();
  //const [videoSource, setVideoSource] = useState<string>();
  const [isVideoShowing, setIsVideoShowing] = useState(true);
  const [elapsedTime, setElapsedTime] = useState(null);
  const [isMuted, setIsMuted] = useState(false);
  const [layout, setLayout] = useState("auto");
  const [roomJoined, setRoomJoined] = useState(false);
  const [, setRemoteStart] = useState(false);
  const [shareid, setShareid] = useState(null);
  const [showId, setShowId] = useState(null);
  const [isUserNameShowing, setIsUserNameShowing] = useState(false);

  const [myPos, setMyPos] = useState("stage");
  const [name, setName] = useState("Khary");
  const [showMediaSettings] = useState(false);
  const [roomReady, setRoomReady] = useState(false);
  const [isLeftToggled, setIsLeftToggled] = useState(false);
  const [isRightToggled, setIsRightToggled] = useState(false);
  const [triggerGoLive, setTriggerGoLive] = useState(false);
  const [lastAudioId, setLastAudioId] = useState(0);
  /**
   * SIDE SECTION SETTING
   */

  const [sideSection, setSideSection] = useState("green-room");

  const [startShowWithRec, setStartShowWithRec] = useState<boolean>(
    myStudioSettings && myStudioSettings.startShowWithRec !== undefined
      ? myStudioSettings.startShowWithRec
      : true
  );
  const [soloHostOnTopicChange, setSoloHostOnTopicChange] = useState<boolean>(
    myStudioSettings && myStudioSettings.soloHost !== undefined
      ? myStudioSettings.soloHost
      : true
  );

  const [hasRundown, setHasRundown] = useState<boolean>(
    myStudioSettings && myStudioSettings.rundownEnabled !== undefined
      ? myStudioSettings.rundownEnabled
      : true
  );
  const [hasChyron, setHasChyron] = useState<boolean>(
    myStudioSettings && myStudioSettings.chyronEnabled !== undefined
      ? myStudioSettings.chyronEnabled
      : true
  );
  const [hasTicker, setHasTicker] = useState<boolean>(
    myStudioSettings && myStudioSettings.tickerEnabled !== undefined
      ? myStudioSettings.tickerEnabled
      : true
  );

  const [guestShareEnabled, setGuestShareEnabled] = useState<boolean>(
    myStudioSettings && myStudioSettings.guestShareEnabled !== undefined
      ? myStudioSettings.guestShareEnabled
      : false
  );
  const [guestRundownEnabled, setGuestRundownEnabled] = useState<boolean>(
    myStudioSettings && myStudioSettings.guestRundownEnabled !== undefined
      ? myStudioSettings.guestRundownEnabled
      : true
  );

  const sceneSettingsEnabled = inExperiment ? true : false;
  const [tagline, setTagline] = useState("@shoprolive");
  const RoomCams = useRoomCams({ roomId, host: true });

  const sharedStreamRef = useRef(sharedScreenStream);
  sharedStreamRef.current = sharedScreenStream;

  const sharedScreenUidRef = useRef(shareUserId);
  sharedScreenUidRef.current = shareUserId;

  const controlCenterRef = useRef(null);
  const thisVideoRef = useRef(null);
  thisVideoRef.current = videoStream;

  const roomCamRef = useRef(null);
  const videoStageRef = useRef(null);
  const captureTimeoutRef = useRef(null);
  roomCamRef.current = RoomCams.sceneCameras;

  const toggleRecording = () => {
    if (!isRecording) {
      setIsRecording(true);
      startRecording();
      setRecordingLabel("Stop Recording");
    } else {
      setIsRecording(false);
      stopRecording();
      setRecordingLabel("Start Recording");
    }
    /*  if (audioSource && videoSource) {
    } */
  };

  const toggleStreaming = () => {
    if (!isStreaming) {
      setIsStreaming(true);
      //startStreaming();
      //setStreamingLabel("Stop Streaming");
    } else {
      setIsStreaming(false);
      //stopStreaming();
      //setStreamingLabel("Start Streaming");
    }
  };

  const downloadVideo = () => {
    if (videoBlob) {
      const fileName = Number(new Date());
      const url = URL.createObjectURL(videoBlob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${fileName}.webm`;
      a.click();
      URL.revokeObjectURL(url);
      setVideoBlob(null);
      setRecordedChunks([]);
    }
  };

  const sendOfferToAntMedia = (_offer) => {
    //console.log(offer);
  };

  const startRecording = async () => {
    if (videoStream) {
      if (startShowWithRec) {
        setTriggerGoLive(true);
      }
      //console.log("START RECORDING");
      canvasRef.current = document.getElementById("video-canvas");
      const canvas = canvasRef.current;
      canvas.getContext("2d");

      // Get audio stream
      const audioStream = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });

      // Combine video and audio streams
      const combinedStream = new MediaStream([
        ...canvas.captureStream().getVideoTracks(),
        ...audioStream.getAudioTracks(),
      ]);

      const mediaRecorder = new MediaRecorder(combinedStream);

      mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          setRecordedChunks([...recordedChunks, event.data]);
        }
      };

      mediaRecorder.onstop = () => {
        setTimesRecorded((prev) => prev + 1);
      };

      mediaRecorder.start();
      setMediaRecorder(mediaRecorder);
    }
  };

  const stopRecording = () => {
    // console.log("STOP RECORDING");
    if (mediaRecorder) {
      if (startShowWithRec) {
        setTriggerGoLive(false);
      }
      mediaRecorder.stop();
    }
  };

  const onOverlayUpdate = (data) => {
    //console.log("OVERLAY UPDATE", data);
    setMyStudioSettings((prevSettings) => ({
      ...prevSettings,
      isRundownVisible: data.isRundownVisible,
      isChyronVisible: data.isChyronVisible,
    }));
  };

  /* const stopCamera = (stream) => {
    console.log("STOP CAM");
    stream.getTracks().forEach((track) => {
      track.stop();
    });
  }; */

  /* const onComponentUpdate = (props) => {
    switch (props.type) {
      case "ADDED_CAMERA":
        if (props.cameras[0]) {
          
          setAudioSource(props.cameras.audioId);
          setVideoSource(props.cameras.videoId);
        }

        break;
    }
  }; */

  /* const updateStream = (stream) => {
    //setStreamSet(false);
    setTempVideoStream(stream);

    if (videoStream) {
      if (videoStream.id !== stream.id) {
        if (!showMediaSettings) {
          console.log("UPDATED STREAM ---");
          console.log("-- stream ID:", stream.id, stream);
          console.log("-- video stream ID:", videoStream.id);
          //stopCamera(videoStream);
          setVideoStream(stream);
        }
      }
    } else {
      console.log("SET NEW STREAM");
      setVideoStream(stream);
    }
  }; */

  const activateRoom = () => {
    joinRoom();
    setRoomReady(true);
  };

  /* useEffect(() => {
    if (showMediaSettings === false && tempVideoStream) {
      updateStream(tempVideoStream);
    }
  }, [showMediaSettings]); */

  const shareScreen = async () => {
    if (isSharingScreen) {
      if (sharedScreenStream) {
        sharedStreamRef.current.getTracks().forEach((track) => track.stop());
        //setVideoStream(defaultStream);
        //RoomCams.shareScreen(defaultStream);
        RoomCams.unshare(shareUserId);
        setIsSharingScreen(false);
      }
    } else {
      navigator.mediaDevices
        .getDisplayMedia({
          video: true,
          audio: {
            echoCancellation: true,
            noiseSuppression: true,
            sampleRate: 44100,
            //suppressLocalAudioPlayback: true,
          },
        })
        .then((stream) => {
          const newShareUserId = Number(new Date());
          sharedScreenUidRef.current = newShareUserId;
          setShareUserId(newShareUserId);

          const data = {
            roomid,
            userid: newShareUserId,
            peerId: RoomCams.peerId,
            name,
            tagline,
            type: "share",
            pos: "share",
            isMuted: false,
            isVideoShowing: true,
            isUserNameShowing,
            userImg,
            showid: shareid,
            cameraClass,
            roomsettings: myStudioSettings,
          };
          setSharedScreenStream(stream);
          sharedStreamRef.current = stream;
          RoomCams.addStream(stream, data);
          RoomCams.shareScreen(newShareUserId, stream, data);

          stream.getVideoTracks()[0].onended = function () {
            const mediaTrack = sharedStreamRef.current.getTracks()[0];
            mediaTrack.stop();
            RoomCams.unshare(sharedScreenUidRef.current);
            setIsSharingScreen(false);
          };

          setIsSharingScreen(true);
        })
        .catch((_err) => {});
    }
  };

  const addAudioStream = (stream) => {
    console.log("SEND AUDIO STREAM");
    const audioId = Number(new Date());
    const data = {
      roomid,
      userid: audioId,
      peerId: RoomCams.peerId,
      name,
      tagline,
      type: "audio",
      pos: "stage",
      isMuted: false,
      isVideoShowing: true,
      isUserNameShowing,
      userImg,
      showid: shareid,
      cameraClass,
      roomsettings: myStudioSettings,
    };
    RoomCams.addStream(stream, data);
    RoomCams.addAudio(stream, data);
    setLastAudioId(audioId);
  };

  const stopAudioStream = () => {
    if (lastAudioId !== 0) {
      console.log("STOP AUDIO STREAM", lastAudioId);
      RoomCams.removeAudio(lastAudioId);
      setLastAudioId(0);
    }
  };

  const addStreamToShow = (userId, pos) => {
    RoomCams.addUserToShow(userId, pos, isUserNameShowing);
  };

  const inviteUser = () => {
    const urlParams = `${window.location.protocol}//${window.location.hostname}/join/${roomId}`;
    navigator.clipboard.writeText(urlParams);
    //alert("Invite URL copied to clipboard.");
    toast("Invite URL copied to clipboard.");
  };

  const shareWatch = () => {
    const urlParams = `${window.location.protocol}//${window.location.hostname}/watch/${roomId}`;
    navigator.clipboard.writeText(urlParams);
    //alert("Invite URL copied to clipboard.");
    toast("Share URL copied to clipboard.");
  };

  const copyRoomUrl = (nohost?) => {
    let roomUrl = `${window.location.protocol}//${window.location.hostname}/room-scene/${roomId}`;
    if (nohost !== undefined && nohost) {
      roomUrl = `${roomUrl}/nohost`;
    }

    navigator.clipboard.writeText(roomUrl);
    //alert("Room Scene URL copied to clipboard.");
    toast("Room Scene URL copied to clipboard.");
  };

  const onNextTopic = (_topic: number) => {
    if (soloHostOnTopicChange) {
      RoomCams.soloStream(RoomCams.userid);
    }
  };

  const onPrevTopic = (topic: number) => {
    console.log("TOPIC CHANGED: Prev", topic);
  };

  const toggleSideBars = (side) => {
    switch (side) {
      case "left":
        setIsLeftToggled(!isLeftToggled);
        break;

      case "right":
        setIsRightToggled(!isRightToggled);
        break;
    }
  };

  const onShareId = (id) => {
    setShareid(id);
    if (RoomCams.myUserData) {
      const data = {
        roomid,
        userid: RoomCams.userid,
        peerId: RoomCams.peerId,
        name,
        tagline,
        type: "host",
        pos: RoomCams.myUserData.pos,
        isMuted: RoomCams.myUserData.isMuted,
        isVideoShowing: RoomCams.myUserData.isVideoShowing,
        userImg,
        showid: id,
        showSourceId,
        cameraClass,
        roomsettings: myStudioSettings,
      };
      RoomCams.setEmitCamUpdate(true);
      RoomCams.setMyUserData(data);
    }
  };

  const joinRoom = () => {
    const data = {
      roomid,
      userid: RoomCams.userid,
      peerId: RoomCams.peerId,
      name,
      tagline,
      type: "host",
      pos: "stage",
      isMuted: false,
      isVideoShowing: true,
      userImg,
      showid: shareid,
      showSourceId,
      cameraClass,
      roomsettings: myStudioSettings,
    };

    //setStreamSet(false);
    if (!roomJoined) {
      RoomCams.requestJoin(data, videoStream);
      RoomCams.addStream(videoStream, data);
      RoomCams.setMyUserData(data);
      setRoomJoined(true);
    } else {
      RoomCams.addStream(videoStream, data);
      RoomCams.setMyStream(videoStream);
      RoomCams.setMyUserData(data);
    }
  };

  const captureScreen = async () => {
    const screenData = await takeScreenshot(
      document.getElementById("video-stage")
    );

    // console.log("SCREEN DATA", screenData, showId);
    const headers = getBearerHeaders();
    const updatedHeaders = { ...headers, "Content-Type": "application/json" };
    const data = {
      showid: showId,
      imagedata: screenData,
    };
    await UploadShowCapture(data, updatedHeaders);
    //console.log("SAVED SCREEN CAPTURE", savedScreenCapture);

    /* const data = {
      showid: showId,
      imagedata: screenData,
    };
    const headers = getBearerHeaders(); */
    //const imageSaveResults = await UpdateShowByFields(data, headers);
    /* console.log("IMAGE SAVE RESULTS", imageSaveResults); */
  };

  useEffect(() => {
    const syncShow = async () => {
      const data = {
        showid: showId,
        liveinstudio: 1,
      };
      const headers = getBearerHeaders();
      await UpdateShowByFields(data, headers);
      //console.log("UPDATED SHOW", updatedShow);
    };
    if (showId && showId !== -1) {
      syncShow();
    }
  }, [showId]);

  useEffect(() => {
    if (videoStream) {
      RoomCams.updateStream(RoomCams.userid, videoStream);
    }
  }, [videoStream]);

  useEffect(() => {
    setMyStudioSettings((prevSettings) => ({
      ...prevSettings,
      chyronEnabled: hasChyron,
    }));
  }, [hasChyron]);

  useEffect(() => {
    setMyStudioSettings((prevSettings) => ({
      ...prevSettings,
      tickerEnabled: hasTicker,
    }));
  }, [hasTicker]);

  useEffect(() => {
    setMyStudioSettings((prevSettings) => ({
      ...prevSettings,
      rundownEnabled: hasRundown,
    }));
  }, [hasRundown]);

  useEffect(() => {
    setMyStudioSettings((prevSettings) => ({
      ...prevSettings,
      soloHost: soloHostOnTopicChange,
    }));
  }, [soloHostOnTopicChange]);

  useEffect(() => {
    setMyStudioSettings((prevSettings) => ({
      ...prevSettings,
      soloHost: soloHostOnTopicChange,
    }));
  }, [soloHostOnTopicChange]);

  useEffect(() => {
    if (myStudioSettings) {
      SaveToLocalStorage("std-setts", JSON.stringify(myStudioSettings));
      const data = {
        roomid,
        userid: RoomCams.userid,
        peerId: RoomCams.peerId,
        name,
        tagline,
        type: "host",
        pos: myPos,
        isMuted,
        isVideoShowing,
        isNameShowing: isUserNameShowing,
        userImg,
        showid: shareid,
        showSourceId,
        cameraClass,
        roomsettings: myStudioSettings,
      };
      RoomCams.setEmitCamUpdate(true);
      RoomCams.setMyUserData(data);
    }
  }, [myStudioSettings]);

  useEffect(() => {
    setMyStudioSettings((prevSettings) => ({
      ...prevSettings,
      guestRundownEnabled: guestRundownEnabled,
    }));
  }, [guestRundownEnabled]);

  useEffect(() => {
    setMyStudioSettings((prevSettings) => ({
      ...prevSettings,
      startShowWithRec: startShowWithRec,
    }));
  }, [startShowWithRec]);

  useEffect(() => {
    if (peerConnection && localStream && isStreaming) {
      localStream
        .getTracks()
        .forEach((track) => peerConnection.addTrack(track, localStream));
      peerConnection
        .createOffer()
        .then((offer) => peerConnection.setLocalDescription(offer))
        .then(() => {
          // Send the offer to Ant Media Server using a signaling server
          // Implement a signaling server or use a third-party service for this purpose
          sendOfferToAntMedia(peerConnection.localDescription);
        });
    }
  }, [peerConnection, localStream]);

  useEffect(() => {
    if (RoomCams.userid && RoomCams.peerId) {
      const data = {
        roomid,
        userid: RoomCams.userid,
        peerId: RoomCams.peerId,
        name,
        tagline,
        type: "host",
        pos: myPos,
        isMuted,
        isVideoShowing,
        isNameShowing: isUserNameShowing,
        userImg,
        showid: shareid,
        showSourceId,
        cameraClass,
        roomsettings: myStudioSettings,
      };
      RoomCams.setEmitCamUpdate(true);
      RoomCams.setMyUserData(data);
    }
  }, [isMuted, isVideoShowing, showSourceId, cameraClass]);

  useEffect(() => {
    if (isUserNameShowing !== null && RoomCams.sceneCameras.length > 0) {
      RoomCams.toggleNames(isUserNameShowing);
    }
  }, [isUserNameShowing]);

  useEffect(() => {
    if (websocket) {
      websocket.connect();
    }
  }, [websocket]);

  useEffect(() => {
    RoomCams.setName(name);
    RoomCams.setTagline(tagline);
  }, [name, tagline]);

  useEffect(() => {
    if (recordedChunks.length > 0) {
      const vidBlob = new Blob(recordedChunks, { type: "video/webm" });
      setVideoBlob(vidBlob);
    }
  }, [timesRecorded]);

  useEffect(() => {
    if (videoBlob) {
      downloadVideo();
    }
  }, [videoBlob]);

  useEffect(() => {
    setRemoteStart(isShowLive);
    const msg = {
      sourceid: roomid,
      eventtype: "CHANGELIVESTATUS",
      visible: true,
    };
    RoomCams.webSocket.current.emit("obs-wss", msg);
    if (isShowLive) {
      captureScreen();
      if (captureTimeoutRef.current) {
        clearTimeout(captureTimeoutRef.current);
      }
      captureTimeoutRef.current = setTimeout(() => {
        captureScreen();
      }, 120000);
    } else {
      if (captureTimeoutRef.current) {
        clearTimeout(captureTimeoutRef.current);
      }
    }
  }, [isShowLive]);

  useEffect(() => {
    setWebsocket(io(getServer()));

    return () => {
      if (websocket) {
        websocket.disconnect();
      }

      /* if (thisVideoRef.current) {
        thisVideoRef.current.getTracks().forEach((track: any) => {
          if (track.readyState === "live") {
            console.log("STOPPING TRACK", track.kind, track.readyState);
            track.stop();
          }
          console.log("STOPPED TRACK", track.readyState);
        });
        setVideoStream(null);
      } */
    };
  }, []);

  return (
    <>
      <StudioHeader
        classname="studio bg-gray-500 border-b border-b-slate-800"
        islive={isShowLive}
        headerSectionLabel="STUDIO | ALPHA"
        inviteUser={inviteUser}
        shareShow={shareWatch}
        isRoomReady={roomReady}
        startRecording={toggleRecording}
        startStreaming={toggleStreaming}
        isRecording={isRecording}
        isStreaming={isStreaming}
        captureScreen={captureScreen}
      />
      <div>
        <Toaster
          toastOptions={{
            className: "",
            style: {
              border: "1px solid #0d1014",
              padding: "10px",
              color: "#ffffff",
              backgroundColor: "#14202e",
              fontSize: "0.9rem",
            },
          }}
        />
      </div>
      {roomReady && RoomCams.sceneCameras ? (
        <div className="control-center flex h-[calc(100vh-40px)] ">
          <div
            ref={controlCenterRef}
            className={`${
              isLeftToggled ? "w-0" : "w-[320px]"
            } overflow-x-hidden overflow-y-auto flex-col justify-start border-r border-r-[#14202e] transition-all duration-300`}
          >
            <div
              className={`fixed bg-[#14202e] flex justify-center items-center hover:bg-gray-500 border-r border-r-slate-800 border-t border-t-slate-800 bg-repeat transition-color duration-300 w-4 h-10 bottom-0 pointer ${
                isLeftToggled ? "left-0" : "left-[320px]"
              }`}
              id="left-handle"
              onClick={() => toggleSideBars("left")}
            >
              <FontAwesomeIcon
                className="text-xs text-slate-500 hover:text-slate-800"
                icon={isLeftToggled ? faChevronRight : faChevronLeft}
              />
            </div>
            <Shopro
              showAppHeader={false}
              isInControlCenter={true}
              useChat={false}
              onElapsedTime={setElapsedTime}
              onShowStatusChange={setIsShowLive}
              onNextTopic={onNextTopic}
              onPrevTopic={onPrevTopic}
              onShareIdChange={onShareId}
              onShowIdChange={setShowId}
              onSourceId={setShowSourceId}
              controlCenterRef={controlCenterRef}
              goLive={triggerGoLive}
            />
          </div>
          <div className="flex flex-1 h-full justify-start flex-col items-center p-2">
            <div
              ref={videoStageRef}
              id="video-stage"
              className={`video-wrap w-full max-w-[1300px] flex flex-wrap relative overflow-hidden items-center  aspect-video `}
            >
              <StageManager
                RoomCams={RoomCams}
                layout={layout}
                onCameraClass={setCameraClass}
                onSoloedStream={setHasASoloedStream}
                onMyPos={setMyPos}
                isOwner={true}
              />

              {websocket && (
                <OverlayDrawer
                  showTheTicker={hasTicker}
                  showChyron={hasChyron}
                  showRundown={hasRundown}
                  rundownTopic={""}
                  topics={[]}
                  themeConfig={themeKharysCrib}
                  socket={websocket}
                  shareid={user.id}
                  isShowHost={true}
                  onOverlayUpdate={onOverlayUpdate}
                  elapsedTime={elapsedTime}
                  showElapsedTime={true}
                  isLive={isShowLive}
                />
              )}
              {inExperiment && (
                <VideoChatCanvas
                  videoStreams={RoomCams.sceneCameras}
                  layout={layout}
                  isRecording={isRecording}
                  isStreaming={isStreaming}
                />
              )}
            </div>
            <CamSceneControl
              onNameToggle={setIsUserNameShowing}
              isUserNameShowing={isUserNameShowing}
              onShare={shareScreen}
              isSharingScreen={isSharingScreen}
              streams={RoomCams.sceneCameras}
              isSoloed={hasASoloedStream}
              onSceneView={setLayout}
              userid={RoomCams.userid}
              onSoloStream={RoomCams.soloStream}
              onUnSoloStream={RoomCams.unSoloStream}
              onPipStream={RoomCams.pipStream}
            />
            <div className="flex w-full justify-center border border-slate-800 mt-0.5 ">
              <AudioLevel stream={videoStream} fillColor={"#630c17"} />
            </div>

            {showRecControls ? (
              <div key={`action-grp`} className="action-group">
                <button
                  className={isRecording ? "red" : ""}
                  onClick={toggleRecording}
                >
                  {recordingLabel}
                </button>

                <button className={"disabled"} onClick={toggleStreaming}>
                  {streamingLabel}
                </button>
              </div>
            ) : null}

            <GuestControls
              onVideoToggle={setIsVideoShowing}
              onAudioToggle={setIsMuted}
              onNewVideoStream={setVideoStream}
              onSettingsToggle={null}
              defaultSettingsValue={showMediaSettings}
              leaveRoom={null}
              inviteUser={inviteUser}
              currentStream={videoStream}
            />
          </div>
          <div
            className={`${
              isRightToggled ? "w-0 overflow-hidden" : "w-[300px]"
            } flex-col border-l border-l-[#14202e] transition-all duration-300`}
          >
            <div
              className={`fixed flex bg-[#14202e] hover:bg-gray-500 border-l border-l-slate-800 border-t justify-center items-center border-t-slate-800 bg-repeat transition-color duration-300 w-4 h-10 bottom-0 pointer  ${
                isRightToggled ? "right-0" : "right-[300px]"
              }`}
              id="right-handle"
              onClick={() => toggleSideBars("right")}
            >
              <FontAwesomeIcon
                className="text-xs text-slate-500 hover:text-slate-800 "
                icon={isRightToggled ? faChevronLeft : faChevronRight}
              />
            </div>
            <div className="h-1/2">
              <div className=" bg-redHover">
                <ul className="flex divide-x divide-blue-charcoal-950 items-center justify-between text-xs uppercase font-semibold">
                  <li
                    className={
                      sideSection === "green-room"
                        ? "flex w-full bg-blue-charcoal-700 hover:bg-red p-1 pointer justify-center transition duration-300"
                        : "flex w-full hover:bg-red p-1 pointer justify-center transition duration-300"
                    }
                    onClick={() => setSideSection("green-room")}
                  >
                    {t("studio.guests")}
                  </li>
                  {sceneSettingsEnabled && (
                    <li
                      className={
                        sideSection === "scenes"
                          ? "flex w-full bg-blue-charcoal-700 hover:bg-red p-1 pointer justify-center transition duration-300"
                          : "flex w-full hover:bg-red p-1 pointer justify-center transition duration-300"
                      }
                      onClick={() => setSideSection("scenes")}
                    >
                      {t("studio.scenes")}
                    </li>
                  )}
                  <li
                    className={
                      sideSection === "room-settings"
                        ? "flex w-full bg-blue-charcoal-700 p-1 hover:bg-red pointer justify-center transition duration-300"
                        : "flex w-full p-1 hover:bg-red pointer justify-center transition duration-300"
                    }
                    onClick={() => setSideSection("room-settings")}
                  >
                    {t("studio.settings")}
                  </li>
                </ul>
              </div>

              <div
                className={`p-1 grid grid-cols-2 gap-1 green-room ${
                  RoomCams.sceneCameras.filter(
                    (cam) => cam.data.pos === "green-room"
                  ).length > 0
                    ? "open"
                    : ""
                }`}
              >
                {sideSection === "green-room" && (
                  <>
                    {RoomCams.sceneCameras.map(
                      (camera, index) =>
                        camera.data.pos !== "bg" &&
                        camera.data.type !== "audio" && (
                          <div
                            key={`wrap_${index}`}
                            className={`${
                              camera.data.pos === "stage"
                                ? "bg-green-900"
                                : camera.data.pos === "solo"
                                ? "bg-sky-900"
                                : "bg-rose-900"
                            } p-0 relative rounded-sm wrap  ${camera.data.pos}`}
                          >
                            <GreenRoomCam
                              cameraInfo={camera}
                              userid={RoomCams.userid}
                              cameraClass={cameraClass}
                              addStreamToShow={addStreamToShow}
                              soloStream={RoomCams.soloStream}
                              unsoloStream={RoomCams.unSoloStream}
                              toggleMute={RoomCams.toggleMute}
                            />
                          </div>
                        )
                    )}
                  </>
                )}
              </div>
              {sideSection === "scenes" && (
                <div className="flex flex-col overflow-y-auto h-full">
                  <SceneSettings
                    addStream={addAudioStream}
                    stopAudioStream={stopAudioStream}
                  />
                </div>
              )}
              {sideSection === "room-settings" && (
                <div className="flex flex-col overflow-y-auto h-full p-2">
                  <span className="font-semibold">Add Cameras To OBS</span>
                  <div className="grid grid-cols-2 gap-1 mt-2">
                    <button
                      className={`${tw.btn.action} bg-blue hover:bg-blueHover`}
                      onClick={() => copyRoomUrl(false)}
                    >
                      + OBS Full Scene
                    </button>
                    <button
                      className={`${tw.btn.action} bg-blue hover:bg-blueHover`}
                      onClick={() => copyRoomUrl(true)}
                    >
                      + OBS w/o Host
                    </button>
                  </div>

                  <span className="mt-4 font-semibold">Room Settings</span>

                  <div className="divide-y divide-slate-700">
                    {inExperiment && (
                      <div className="flex flex-row w-full py-2">
                        <span className="w-3/4 text-sm">
                          Start/Stop Show When Recording?
                        </span>
                        <div className="w-1/4 inline-flex justify-end">
                          <Switch
                            checked={startShowWithRec}
                            onCheckChange={setStartShowWithRec}
                          />
                        </div>
                      </div>
                    )}
                    <div className="flex flex-row w-full py-2">
                      <span className="w-3/4 text-sm">
                        Solo Host on Segment Change?
                      </span>
                      <div className="w-1/4 inline-flex justify-end">
                        <Switch
                          checked={soloHostOnTopicChange}
                          onCheckChange={setSoloHostOnTopicChange}
                        />
                      </div>
                    </div>
                    <div className="flex flex-row py-2">
                      <span className="w-3/4 text-sm">
                        Enable Rundown in Full Scene
                      </span>
                      <div className="w-1/4 inline-flex justify-end">
                        <Switch
                          checked={hasRundown}
                          onCheckChange={setHasRundown}
                        />
                      </div>
                    </div>
                    <div className="flex flex-row py-2">
                      <span className="w-3/4 text-sm">
                        Enable Chyron in Full Scene
                      </span>
                      <div className="w-1/4 inline-flex justify-end">
                        <Switch
                          checked={hasChyron}
                          onCheckChange={setHasChyron}
                        />
                      </div>
                    </div>
                    <div className="flex flex-row py-2">
                      <span className="w-3/4 text-sm">
                        Enable Ticker in Full Scene
                      </span>
                      <div className="w-1/4 inline-flex justify-end">
                        <Switch
                          checked={hasTicker}
                          onCheckChange={setHasTicker}
                        />
                      </div>
                    </div>
                  </div>
                  <br />
                  <span className="font-semibold">Guest Settings</span>
                  <div className="flex flex-row mt-2">
                    <span className="w-3/4 text-sm">
                      Allow Guests to Share Screen?
                    </span>
                    <div className="w-1/4 inline-flex justify-end">
                      <Switch
                        checked={guestShareEnabled}
                        onCheckChange={setGuestShareEnabled}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="flex flex-row mt-2">
                    <span className="w-3/4 text-sm">
                      Show Rundown to Guests?
                    </span>
                    <div className="w-1/4 inline-flex justify-end">
                      <Switch
                        checked={guestRundownEnabled}
                        onCheckChange={setGuestRundownEnabled}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="h-1/2">
              <Chat
                socket={RoomCams.webSocket.current}
                name={RoomCams.myUserData?.name}
                roomid={roomid}
                userid={RoomCams.userid}
                headerLabel="Show Chat"
              />
            </div>
          </div>
        </div>
      ) : (
        <JoinShow
          onButtonAction={activateRoom}
          defaultBtnVal={showMediaSettings}
          btnLabel="Enter Studio"
          canJoinShow={true}
          updateStream={setVideoStream}
          myStream={videoStream}
          onName={setName}
          onTagline={setTagline}
          name={name}
          tagline={tagline}
          onUserImg={setUserImg}
          headerLabel="Host A Sho..."
        />
      )}
    </>
  );
};

export default ControlCenter;
