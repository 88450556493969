import React, { useEffect, useRef, useState } from "react";
import { Socket } from "socket.io-client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleXmark,
  faEye,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";
import { chatTimeDisplay } from "../../../../helpers/Dates";
import { tw } from "../../../../helpers/Styles";

export interface ChatMessage {
  msg: string;
  roomid: string;
  from: string;
  to?: string;
  name: string;
  time: number;
  type: string;
}

export type ChatMessageList = ChatMessage[];

export interface ChatProps {
  socket: Socket;
  name: string;
  roomid: string;
  userid: string;
  chatType?: string;
  headerLabel?: string;
  closeBtn?: () => void;
  isOpen?: boolean;
  showHeader?: boolean;
  chatBotText?: string;
  onDspNameUpdate?: (name: string) => void;
}

const Chat = ({
  socket,
  name,
  roomid,
  userid,
  chatType,
  headerLabel,
  closeBtn,
  showHeader,
  isOpen,
  onDspNameUpdate,
  chatBotText,
}: ChatProps) => {
  const [chat, setChat] = useState<ChatMessageList>([]);
  const [dspName, setDspName] = useState(
    name || localStorage.getItem(`chat_dspName`)
  );
  const [msg, setMsg] = useState<string>("");
  const [, setConnectedToChat] = useState(false);
  const [lastRoomId, setLastRoomId] = useState(null);
  const [useShowHeader] = useState(showHeader === false ? false : true);
  const [totalViewers, setTotalViewers] = useState(0);
  const [lastChatBotText, setLastChatBotText] = useState("");

  const chatListRef = useRef(null);
  const dspNameRef = useRef(null);

  const chatBubbles = {
    me: "pl-4 pr-2 py-1 text-right mb-1.5 align-start rounded-md max-w-[90%] bg-[#293645] ml-auto",
    system: "px-2 py-1 text-center text-xs mb-1.5 align-start w-full ",
    them: "pl-2 pr-4 py-1 mb-1.5 align-start rounded-md w-auto max-w-[90%] bg-[#115f83] mr-auto",
  };

  /* const sendPrivateMsg = () => {
    const data = {
      msg,
      roomid,
      from: userid,
      name:dspName,
      time: Number(new Date()),
      type: "private",
    };
    setChat((prev) => [...prev, data]);
    console.log("SEND MESSAGE", data);
    socket.emit("chat-message", data);
    setMsg("");
  }; */

  const sendMsg = () => {
    if (msg.trim().length > 0) {
      const data = {
        msg,
        roomid,
        from: userid,
        name: dspName,
        time: Number(new Date()),
        type: "public",
      };
      setChat((prev) => [...prev, data]);
      socket.emit("chat-message", data);
      setMsg("");
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault(); // Prevent line break
      sendMsg();
    }
  };

  const renderMessage = (message) => {
    const urlRegex = /https?:\/\/\S+|www\.\S+|\S+\.\S+/gi;
    const messageWithLinks = message.replace(urlRegex, (url) => {
      // Check if the URL has a protocol or starts with 'www.'. If not, prepend 'http://'
      if (!url.startsWith("http") && !url.startsWith("www.")) {
        url = "https://" + url;
      }
      return `<a href="${url}" target="_blank">${url}</a>`;
    });

    return messageWithLinks;
  };

  const onChatUserConnected = (data) => {
    // console.log("NEW CHAT CONNECTION", data);
    const msgData = {
      roomid,
    };
    socket.emit("request-connections", msgData);
    if (
      data.type &&
      data.type.toUpperCase() !== "WATCHER" &&
      data.name.toUpperCase() !== "WATCHER"
    ) {
      // console.log("SEND MSG TO CHAT");
      const msgData = {
        msg: `-- ${
          data.name === "watcher" ? "Guest" : data.name
        } joined the chat --`,
        roomid,
        from: "0",
        name: "system",
        time: Number(new Date()),
        type: "public",
      };
      setChat((prev) => [...prev, msgData]);
    }
  };

  const onChatUserDisconnected = (data) => {
    if (
      data.type &&
      data.type.toUpperCase() !== "WATCHER" &&
      data.name !== "watcher"
    ) {
      const msgData = {
        msg: `-- ${
          data.name === "watcher" ? "Guest" : data.name
        } left the chat --`,
        roomid,
        from: "0",
        name: "system",
        time: Number(new Date()),
        type: "public",
      };
      setChat((prev) => [...prev, msgData]);
    }
    const msgData = {
      roomid,
    };
    socket.emit("request-connections", msgData);
  };

  const onChatMessage = (data) => {
    setChat((prev) => [...prev, data]);
  };

  const onConnectionCount = (data) => {
    //console.log("CONNECTION COUNT", data);
    if (data.roomid === roomid) {
      setTotalViewers(data.numUsers);
    }
  };

  const addChatListeners = () => {
    if (socket) {
      socket.on("chat-user-connected", onChatUserConnected);
      socket.on("chat-user-disconnected", onChatUserDisconnected);
      socket.on("chat-message", onChatMessage);
      socket.on("user-count", onConnectionCount);
      const msgData = {
        roomid,
      };
      socket.emit("request-connections", msgData);
    }
  };

  useEffect(() => {
    console.log("chatBotText", chatBotText);
    if (lastChatBotText !== chatBotText) {
      setLastChatBotText(chatBotText);
      const msgData = {
        msg: `${chatBotText}`,
        roomid,
        from: "0",
        name: "Chatbot",
        time: Number(new Date()),
        type: "public",
      };
      setChat((prev) => [...prev, msgData]);
    }
  }, [chatBotText]);

  useEffect(() => {
    if (dspName && dspName !== "") {
      if (onDspNameUpdate) {
        onDspNameUpdate(dspName);
      }
      localStorage.setItem(`chat_dspName`, dspName);
      if (chatType === "SHOW_CHAT" || chatType === "WATCH_CHAT") {
        //addChatListeners();
        if (roomid !== lastRoomId) {
          socket.emit("leave-chat", { roomid: lastRoomId, name: dspName });
        }

        socket.emit("join-chat", {
          roomid: roomid,
          name: dspName,
          type: "viewer",
        });
        //setChat(JSON.parse(localStorage.getItem(`chat_${roomid}`)) || []);
        setConnectedToChat(true);
        setLastRoomId(roomid);
        //console.log("JOIN CHAT", roomid, dspName);
      }
    }
  }, [dspName, roomid]);

  useEffect(() => {
    //localStorage.setItem(`chat_${roomid}`, JSON.stringify(chat));
    if (dspName && dspName !== "") {
      chatListRef.current.scrollTop = chatListRef.current.scrollHeight;
    }
  }, [chat, dspName]);

  useEffect(() => {
    addChatListeners();

    return () => {
      if (socket) {
        const data = {
          msg: `-- ${dspName} left the chat --`,
          roomid,
          from: "0",
          name: "system",
          time: Number(new Date()),
          type: "public",
        };
        setChat((prev) => [...prev, data]);
        socket.emit("chat-message", data);

        socket.off("chat-user-connected", onChatUserConnected);
        socket.off("chat-user-disconnected", onChatUserDisconnected);
        socket.off("chat-message", onChatMessage);
        socket.off("user-count", onConnectionCount);
      }
    };
  }, [socket]);

  useEffect(() => {
    if (isOpen) {
    }
  }, []);

  return (
    <div key="chat-wrap" className="chat-wrap flex h-full flex-col">
      {useShowHeader && (
        <div className="bg-redHover p-3">
          {headerLabel || "Chat"}
          <span className="text-xs ml-2">
            <FontAwesomeIcon icon={faEye} /> {totalViewers}
          </span>
          {closeBtn && (
            <FontAwesomeIcon
              className="absolute right-3 top-3 pointer"
              onClick={closeBtn}
              icon={faCircleXmark}
            />
          )}
        </div>
      )}
      {dspName && dspName !== "" ? (
        <>
          <ul
            key="chatlist"
            ref={chatListRef}
            className=" flex flex-1 flex-col p-2.5 overflow-y-auto"
          >
            {chat &&
              chat.map((msg, index) => (
                <li
                  key={`msg_${index}`}
                  className={
                    msg.from === userid || msg.name === dspName
                      ? `${chatBubbles.me}`
                      : msg.name === "system"
                      ? `${chatBubbles.system}`
                      : `${chatBubbles.them}`
                  }
                >
                  <span className="block text-sm font-semibold">
                    {msg.name}
                  </span>
                  <span
                    className="text-sm"
                    dangerouslySetInnerHTML={{ __html: renderMessage(msg.msg) }}
                  />
                  <span className="block text-xs">
                    {chatTimeDisplay(new Date(msg.time))}
                  </span>
                </li>
              ))}
          </ul>
          <div className="flex justify-center p-1.5 border-t border-t-[#14202e]">
            <input
              type="text"
              value={msg}
              onChange={(e) => setMsg(e.currentTarget.value)}
              onKeyDown={handleKeyDown}
              className="w-10/12 text-black px-2 py-1 mr-1"
            ></input>

            <div className="w-2/12 rounded-md p-2 pointer justify-center items-center border border-sky-900 bg-slate-900 hover:bg-sky-600 transition-colors duration-300">
              <FontAwesomeIcon
                className="shadow-lg"
                onClick={sendMsg}
                icon={faPaperPlane}
              />
            </div>
          </div>
        </>
      ) : (
        <div className="flex w-full flex-col justify-center items-center p-4">
          <label>Enter your name to join the chat</label>
          <input
            className="w-2/3 text-black px-2 py-1 mr-1 border border-sky-900 rounded-md mt-2"
            ref={dspNameRef}
            type="text"
            defaultValue={dspName}
          />
          <button
            className={`${tw.btn.reg} bg-blue-charcoal-500 mt-2 rounded-sm w-1/2`}
            onClick={() => setDspName(dspNameRef.current.value)}
          >
            Join Chat
          </button>
        </div>
      )}
    </div>
  );
};

export default Chat;
