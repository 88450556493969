import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { tw } from "../../../helpers/Styles";

const ActionButton = ({ label, icon, extraClass, action }) => {
  return (
    <>
      <div
        className={`${tw.btn.reg} border border-slate-900 rounded-md sm:mr-2 pointer ${extraClass}`}
        onClick={action}
      >
        <FontAwesomeIcon icon={icon} /> <span>{label}</span>
      </div>
    </>
  );
};

export default ActionButton;
