import React, {
  ChangeEvent,
  Fragment,
  useEffect,
  useRef,
  useState,
} from "react";
import useSourceid from "../../../hooks/useSourceid";
import axios from "axios";
import { getBearerHeaders } from "../../../helpers/GetBearerHeaders";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Transition, Dialog } from "@headlessui/react";
import { tw } from "../../../helpers/Styles";

export interface SourceEditorProps {
  label: string;
  sourcetype: string;
  isEditing?: boolean;
  onCancelEdit: (isEdit: boolean) => void;
  onTheme: (theme: string) => void;
  onUriReady: (uri: string) => void;
}
const SourceEditor = ({
  label,
  sourcetype,
  onTheme,
  onUriReady,
  isEditing,
  onCancelEdit,
}: SourceEditorProps) => {
  const currentURL = window.location.href;
  const urlParts = new URL(currentURL);
  const domainWithPort = urlParts.host;
  const protocal = domainWithPort.includes("localhost") ? "http" : "https";
  const source = useSourceid();
  const [sourceid, setSourceid] = useState<string>("");
  const [theme, setTheme] = useState<string>("");
  const [checked, setChecked] = useState<boolean>(true);
  const [autoHideSeconds, setAutoHideSeconds] = useState<number>(45);
  const [didInitialLoad, setDidInitialLoad] = useState<boolean>(false);
  const [open, setOpen] = useState(isEditing);

  const inputRef = useRef<HTMLInputElement>(null);

  const themeList = [
    { label: "Khary's Crib (Beta)", value: "kharyscrib" },
    { label: "Sporty Blue (Beta)", value: "sportyblue" },
    /* { label: "Corporate", value: "corp" },
    { label: "Flower Power", value: "flowerpower" },
    { label: "Glow", value: "glow" },
    { label: "Play House", value: "playhouse" },
    { label: "Pinky", value: "pinky" },
    { label: "Red Latex", value: "redlatex" },
    { label: "Sporty", value: "sporty" },
    
    { label: "Top Down", value: "topdown" },
    { label: "Transparent Black", value: "transparentblack" },
    { label: "Transparent White", value: "transparentwhite" }, */
  ];

  const timerOptions = [
    { value: 10, option: "10 sec" },
    { value: 30, option: "30 sec" },
    { value: 45, option: "45 sec" },
    { value: 60, option: "60 sec" },
    { value: 90, option: "1 1/2 min" },
    { value: 120, option: "2 min" },
    { value: 150, option: "2 1/2 min" },
    { value: 300, option: "5 min" },
    { value: 600, option: "10 min" },
  ];

  const copyToClipboard = () => {
    if (inputRef.current) {
      inputRef.current.select();
      document.execCommand("copy");
      window.getSelection()?.removeAllRanges();
      onCancelEdit(false);
    }
  };

  const handleCheck = () => {
    setChecked(!checked);
  };

  const onCancel = () => {
    //setIsEditorOpen(false);
    onCancelEdit(false);
  };

  const chooseTheme = (e: ChangeEvent<HTMLSelectElement>) => {
    setTheme(e.currentTarget.value);
    onTheme(e.currentTarget.value);
  };

  const onAutoHideTimeSelect = (e: ChangeEvent<HTMLSelectElement>) => {
    setAutoHideSeconds(Number(e.currentTarget.value));
  };

  const updateToken = () => {
    axios
      .post("/api/v1/generatenewtoken", {}, getBearerHeaders())
      .then((response) => {
        //console.log(response.data);
        if (response.data.success) {
          //console.log(response.data);
          setSourceid(response.data.items.sourceid);
        }
      });
  };

  useEffect(() => {
    if (open !== null) {
      if (!open) {
        //setIsEditorOpen(false);
        onCancelEdit(false);
      }
    }
  }, [open]);

  useEffect(() => {
    setOpen(isEditing);
  }, [isEditing]);

  useEffect(() => {
    if (didInitialLoad) {
      const props = {
        sourceid: sourceid,
        autohide: checked ? autoHideSeconds : 0,
      };
      axios
        .post("/api/v1/settopictickerprops", props, getBearerHeaders())
        .then((response) => {
          // console.log(source, response);
          if (response.data.success) {
            //console.log("CHYRON Settings updated");
          }
        });
    }
  }, [autoHideSeconds, checked]);

  useEffect(() => {
    //console.log("SOURCE LOADED", source);
    if (source === null) {
      updateToken();
    } else {
      setSourceid(source);
      //console.log("SEND URI");
      onUriReady(
        `${protocal}://${domainWithPort}/sources/${sourcetype}/${source}`
      );
      const postData = { showData: source };
      const apiUrl = source ? "/api/sources/ticker" : "/api/v1/getticker";
      axios.post(apiUrl, postData, getBearerHeaders()).then((response) => {
        if (response.data.success) {
          setTheme(response.data.items.theme);
          onTheme(response.data.items.theme);
          setAutoHideSeconds(response.data.items.autohide);
          setDidInitialLoad(true);
        }
      });
    }
  }, [source]);

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-[260]" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className={tw.modalBg} />
          </Transition.Child>

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 left-0 flex max-w-full pr-5">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-300 sm:duration-300"
                  enterFrom="-translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-300 sm:duration-300"
                  leaveFrom="translate-x-0"
                  leaveTo="-translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                    <div className="flex h-full flex-col overflow-y-auto bg-white drop-shadow-lg border-r border-ssblue ">
                      <div className="bg-ssblue px-4 py-6 sm:px-6">
                        <div className="flex items-center justify-between">
                          <Dialog.Title className="text-base font-semibold leading-6 text-white">
                            Source: {label.toUpperCase()}
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <FontAwesomeIcon
                              className="relative rounded-md bg-ssbluehover text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white pointer"
                              icon={faCircleXmark}
                              onClick={onCancel}
                            />
                          </div>
                        </div>
                        <div className="mt-1">
                          <p className="text-sm text-indigo-300">
                            Sources are optimized for 1920x1080 resolutions
                          </p>
                        </div>
                      </div>
                      <div className="relative flex-1 px-4 py-1 sm:px-6 bg-ssblue2">
                        <div className="space-y-6 pb-5 pt-6">
                          <div>
                            <label className="block text-sm font-medium leading-6 text-white">
                              Use the url below to embed this source into a
                              browser source in{" "}
                              <a href="https://obsproject.com/" target="_link">
                                OBS
                              </a>{" "}
                              or{" "}
                              <a href="https://streamlabs.com/" target="_link">
                                Streamlabs
                              </a>
                              . NOTE: DO NOT SHARE YOUR SOURCE URL!
                            </label>
                            <div className="mt-2">
                              <input
                                key="sourcelabel"
                                ref={inputRef}
                                type="text"
                                value={`${protocal}://${domainWithPort}/sources/${sourcetype}/${sourceid}`}
                                readOnly={true}
                                className="block w-full rounded-md border-0 p-1.5 bg-slate-300 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                            </div>
                          </div>
                          <div>
                            <label className="block text-sm font-medium leading-6 text-white">
                              Choose a theme for your source:
                            </label>
                            <div className="mt-2">
                              <select
                                key="theme-changer"
                                value={theme}
                                onChange={(e) => chooseTheme(e)}
                                className="block w-full rounded-md border-0 p-1.5 bg-slate-300 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              >
                                {themeList.map((item, index) => (
                                  <option key={index} value={item.value}>
                                    {item.label}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          {sourcetype.toUpperCase() === "TOPIC" ||
                            (sourcetype.toUpperCase() === "OVERLAY" && (
                              <div>
                                <div className="relative flex items-start">
                                  <div className="absolute flex h-6 items-center">
                                    <input
                                      id="privacy-public"
                                      name="privacy"
                                      aria-describedby="privacy-public-description"
                                      type="checkbox"
                                      className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                      defaultChecked={checked}
                                      onChange={handleCheck}
                                    />
                                  </div>
                                  <div className="pl-7 text-sm leading-6">
                                    <label className="font-medium text-white">
                                      Auto hide Chyron on topic change?
                                    </label>
                                  </div>
                                </div>
                              </div>
                            ))}
                          {checked && (
                            <div>
                              <label className="block text-sm font-medium leading-6 text-white">
                                Time before the chyron hides after a segment
                                update?
                              </label>
                              <div className="mt-2">
                                <select
                                  key="autohide-times"
                                  value={autoHideSeconds}
                                  onChange={(e) => onAutoHideTimeSelect(e)}
                                  className="block w-full rounded-md border-0 p-1.5 bg-slate-300 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                >
                                  {timerOptions.map((timerOpt, index) => (
                                    <option key={index} value={timerOpt.value}>
                                      {timerOpt.option}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="flex flex-shrink-0 justify-end px-4 py-4 bg-ssblue">
                        <button
                          type="button"
                          className={`${tw.btn.reg} bg-blue hover:bg-blue2 text-white mr-1`}
                          onClick={copyToClipboard}
                        >
                          Copy URL
                        </button>
                        <button
                          type="button"
                          className={`${tw.btn.reg} bg-blue hover:bg-blue2 text-white hover:text-white mr-1`}
                          onClick={updateToken}
                        >
                          Generate New URL
                        </button>
                        <button
                          type="button"
                          className={`${tw.btn.reg} bg-red hover:bg-redHover text-white hover:text-white`}
                          onClick={onCancel}
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default SourceEditor;
