import React, { useContext, useRef } from "react";
import Header from "../layout/Header";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";
import { UserContext } from "../../context/UserContext";
import Cookies from "js-cookie";
import useTracking from "../../hooks/useTracking";
import useScrollTop from "../../hooks/useScrollTop";

const Upgrade = () => {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const formRef = useRef(null);
  useTracking();
  useScrollTop();

  const setCookie = () => {
    // Calculate the expiration time 5 minutes from now
    const expirationTime = new Date(new Date().getTime() + 5 * 60 * 1000); // 5 minutes in milliseconds

    // Set the cookie with a name, value, and expiration time
    Cookies.set("ral", "true", { expires: expirationTime });
  };

  const beginCheckout = () => {
    if (formRef.current) {
      if (user.isloggedin) {
        formRef.current.submit();
      } else {
        setCookie();
        navigate("/signup");
      }
    }
  };

  return (
    <>
      <Header />
      <div className="pages-wrap">
        <div className="row">
          <div className="col centered buy">
            <h1>Pro</h1>
            <span className="sale-price">
              $9<span className="cents">.99</span>
              <span className="small">/mo</span>
            </span>

            <span className="button" onClick={beginCheckout}>
              SIGN UP
            </span>
            <hr />

            <ul>
              <li>
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  style={{ color: "#2bff00" }}
                />{" "}
                <span>Unlimited Shows</span>
              </li>

              <li>
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  style={{ color: "#2bff00" }}
                />{" "}
                <span>Share Show With Team</span>
              </li>
              <li>
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  style={{ color: "#2bff00" }}
                />{" "}
                <span>Live Share Syncing</span>
              </li>
              <li>
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  style={{ color: "#2bff00" }}
                />{" "}
                <span>Browser Sources</span>
              </li>
              <li>
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  style={{ color: "#2bff00" }}
                />{" "}
                <span>Teleprompter</span>
              </li>
              <li>
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  style={{ color: "#2bff00" }}
                />{" "}
                <span>Remove Branding</span>
              </li>
            </ul>
          </div>
          <div className="col centered buy">
            <h1>Basic</h1>
            <span className="sale-price">$0</span>

            {user.accounttype === "free" ? (
              <button className="disabled">Current Plan</button>
            ) : (
              <Link className="button" to="/signup">
                {user.accounttype === "free" ? "Current Plan" : "Sign Up"}
              </Link>
            )}
            <hr />

            <ul>
              <li>
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  style={{ color: "#2bff00" }}
                />{" "}
                <span>2 Shows</span>
              </li>

              <li>
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  style={{ color: "#2bff00" }}
                />{" "}
                <span>Share Show With Team</span>
              </li>
              <li>
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  style={{ color: "#2bff00" }}
                />{" "}
                <span>Live Share Syncing</span>
              </li>
              <li>
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  style={{ color: "#2bff00" }}
                />{" "}
                <span>Browser Sources</span>
              </li>
              <li>
                <FontAwesomeIcon
                  icon={faCircleXmark}
                  style={{ color: "#ff1100" }}
                />{" "}
                <span>Teleprompter</span>
              </li>
              <li>
                <FontAwesomeIcon
                  icon={faCircleXmark}
                  style={{ color: "#ff1100" }}
                />{" "}
                <span>Remove Branding</span>
              </li>
            </ul>
          </div>

          <div className="col centered buy">
            <h1>Trial</h1>
            <span className="sale-price">$0</span>

            {user.accounttype === "free" || user.accounttype === "pro" ? (
              <Link className="button disabled" to="/demo">
                Try
              </Link>
            ) : (
              <Link className="button" to="/demo">
                Try
              </Link>
            )}

            <hr />

            <ul>
              <li>
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  style={{ color: "#2bff00" }}
                />{" "}
                <span>
                  1 Show -
                  <span className="tiny">(Saved locally in your browser)</span>
                </span>
              </li>

              <li>
                <FontAwesomeIcon
                  icon={faCircleXmark}
                  style={{ color: "#ff1100" }}
                />{" "}
                <span>Share Show With Team</span>
              </li>
              <li>
                <FontAwesomeIcon
                  icon={faCircleXmark}
                  style={{ color: "#ff1100" }}
                />{" "}
                <span>Browser Sources</span>
              </li>
              <li>
                <FontAwesomeIcon
                  icon={faCircleXmark}
                  style={{ color: "#ff1100" }}
                />{" "}
                <span>Teleprompter</span>
              </li>
              <li>
                <FontAwesomeIcon
                  icon={faCircleXmark}
                  style={{ color: "#ff1100" }}
                />{" "}
                <span>Remove Branding</span>
              </li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col full">
            <h1>FAQ</h1>
            <br />
            <hr />
            <br />
            <p>
              Why would I share a show?
              <br />
              <span>
                Sharing your show allows you to share your show schedule with
                guests, co-host, producers or even your audience so they can
                keep up with you and see exactly where you are on the show
                schedule.
              </span>
            </p>
            <p>
              What is Live Share Syncing?
              <br />
              <span>
                After sharing a show and you "go live" those that have the share
                link will see similar to what you see on the dashboard.
              </span>
            </p>
            <p>
              What's a browser source?
              <br />
              <span>
                A browser source is content that can used within streaming and
                recording tools like{" "}
                <Link to="https://obsproject.com/" target="_linl">
                  OBS
                </Link>
                ,{" "}
                <Link to="https://streamlabs.com/" target="_link">
                  Streamlabs
                </Link>{" "}
                and others.
              </span>
            </p>
            <p>
              What browser sources do you offer?
              <br />
              <span>
                We provide sources for chyron (segment titles), show ticker,
                run-down and full overlays.
              </span>
            </p>
          </div>
        </div>
      </div>
      <form
        ref={formRef}
        action="/checkout/create-checkout-session"
        method="POST"
      >
        {/* Add a hidden field with the lookup_key of your Price */}
        <input type="hidden" name="lookup_key" value="Shopro1" />
      </form>
    </>
  );
};

export default Upgrade;
