export const topicData = [
  {
    color: null,
    notes: "",
    topic: "Starting Soon...",
    end_time: null,
    isNewTopic: false,
    start_time: "00:00:00",
    topic_time: "1",
    is_complete: false,
    show_in_list: false,
    ticker_label: "Donate via Cashapp @kharyscrib ...",
    includeInRundown: false,
  },
  {
    color: null,
    notes: "",
    topic: "Tuesday Sep 20",
    script:
      "What’s goodington pretty people. Tuesday evening, my name is khary this is my crib! We have another dope show for you tonight… The Colorado Buffaloes took on Colorado state over the weekend and it was a shoot out and a little bit of controversy. We'll get into it. Jeezy doesn't seem to like marriage or perhaps its who he's married too, at least that's how the internet feels. We'll talk about it. Some new music has dropped and so has cash app. We got this that and a bunch more to talk about tonight. Im joined by the homie Beez Buddah, The Beautiful Trish. Tonights show is brought to you by Sho Pro. If you wanna run your Sho like a Pro visit ShoPro.live. Whats up to all my houseguests in the chat Phone lines are open 813-559-3339. How is everybody doing?",
    end_time: null,
    isNewTopic: false,
    start_time: null,
    topic_time: "8",
    is_complete: false,
    show_in_list: false,
    ticker_label: "Call in Live - 813-559-3339",
    includeInRundown: true,
  },
  {
    color: null,
    notes:
      "The Colorado State University Police Department and local authorities have looked into threats -- including death threats -- against Colorado State defensive back Henry Blackburn and his family, athletic director Joe Parker told ESPN on Monday.\n\nTravis hunter went to the hospital and has a lacerated liver\n\nhttps://www.espn.com/college-football/story/_/id/38433243/colorado-state-player-family-receive-death-threats-hit\n\n",
    topic: "Colorado VS Colorado State",
    end_time: null,
    isNewTopic: false,
    start_time: null,
    topic_time: 10,
    is_complete: false,
    show_in_list: false,
    ticker_label: "Its PRIME TIME!",
    includeInRundown: true,
  },
  {
    color: null,
    notes:
      "Winning time on HBO is over, I lasted only 2 season and a show called Winning time ended on the Celtics beating the lakers\n\nhttps://www.vulture.com/article/winning-time-canceled-season-2-series-finale-explained.html\n",
    topic: "Winning time a loss?",
    script:
      "\nIn the original ending of season two, a version of which was sent to critics earlier in the summer, the action ended with a somber Magic Johnson sitting on the floor of the Lakers locker-room showers and absorbing the team’s heartbreaking defeat at the hands of the Boston Celtics. But in the version which aired on HBO (and streamed on Max), that scene is followed by one set five days later. Team owner Jerry Buss (John C. Reilly) and daughter Jeanie (Hadley Robinson) are shown walking alone on the court at the Forum with Jerry talking about his daughter one day taking control of the team and all they had already accomplished. Viewers then see a montage (set to Pat Benatar’s 1982 hit “Shadows of the Night”) featuring the real-life characters from the show along with updates on what they went on to achieve. The new ending was filmed back in January, long before the show was canceled and prior to the start of the WGA and SAG strikes.\n\n",
    end_time: null,
    isNewTopic: false,
    start_time: null,
    topic_time: "5",
    is_complete: false,
    show_in_list: false,
    ticker_label: "",
    includeInRundown: true,
  },
  {
    color: null,
    notes: "",
    topic: "Jeezy Files for Divorce",
    script:
      "Was watching the lead attorney on YouTube and he was talking about it. He estimated this is going to be an expensive divorce. Just the initial filing was probably a $7500 retainer. \n\nJay Walker Jenkins vs Jeannie Camtu Mai Jenkins\nAka Jezzy vs Jeannie\n\nA lot of women online have been happy about this. Some say because Jeannie once said something about black is where you play…",
    end_time: null,
    isNewTopic: false,
    start_time: null,
    topic_time: 10,
    is_complete: false,
    show_in_list: false,
    ticker_label: "Jeezy ready to be single again?",
    includeInRundown: true,
  },
  {
    color: null,
    notes:
      "Diddy dropped his album “the love album: off the grid”\n\nNas dropped “magic 3” reportedly his last album with hit boy.",
    topic: "New Albums, Classic Artists",
    end_time: null,
    isNewTopic: false,
    start_time: null,
    topic_time: 10,
    is_complete: false,
    show_in_list: false,
    ticker_label: "The old heads got fire?",
    includeInRundown: true,
  },
  {
    color: null,
    notes:
      "Can you trust it?\nAre we putting too much dependency on electronic funds?",
    topic: 'Can you rely on "Cash Apps"?',
    end_time: null,
    isNewTopic: false,
    start_time: null,
    topic_time: 10,
    is_complete: false,
    show_in_list: false,
    ticker_label: "Cash App's Been Down...",
    includeInRundown: true,
  },
  {
    color: null,
    notes:
      "As the title yes. Recently i found out the box my husband and I use to store our toys in was not complete anymore. Figured we just misplaced some of the items (we've been on a few trips without the kids) I shrugged it off.\n\nToday i find a box in my son's room with the items I was missing. I'm mad and angry, but i don't know how to confront him. The box is still there. I also haven't told my husband yet. Im really unsure what the best next move is.\n\nHas anybody encountered this? What would you do?\n\nUpdate: I sent him a text message telling him I found the box in his room. I said I wasn't mad, but it wasn't his to take and I'm disappointed he went through our personal stuff. I also mentioned that he can always reach out to me or his father if he is unsure of anything or wants to discuss. We are always there. He was responsive and apologized for taking our stuff. He wouldn't do it again and asked on how to source his own stuff. We hugged later on and all is allright.\n\nThanks to the few people with proper advice.\n\nEdit: I'm looking for real advice on how to deal with this, not for creepy guys in my DM to tell me i should seduce him. There are other subreddits for that.\n\nhttps://www.reddit.com/r/sex/comments/16kzclc/my_f41_son_has_been_stealing_my_sex_toys_and_i/",
    topic: "Ever borrow something weird?",
    end_time: null,
    isNewTopic: false,
    start_time: null,
    topic_time: 10,
    is_complete: false,
    show_in_list: false,
    ticker_label: "You borrowed what from who?",
    includeInRundown: true,
  },
  {
    color: null,
    notes: "",
    topic: "See ya next time",
    end_time: null,
    isNewTopic: false,
    start_time: null,
    topic_time: "2",
    is_complete: false,
    show_in_list: false,
    ticker_label: "",
    includeInRundown: true,
  },
  {
    color: null,
    notes: "",
    topic: "Beezo @Iam_da_real_beez_buddah",
    end_time: null,
    isNewTopic: false,
    start_time: null,
    topic_time: 10,
    is_complete: false,
    show_in_list: false,
    ticker_label: "",
    includeInRundown: false,
  },
  {
    color: null,
    notes: "",
    topic: "Khary / @okkhary",
    end_time: null,
    isNewTopic: false,
    start_time: null,
    topic_time: 10,
    is_complete: false,
    show_in_list: false,
    ticker_label: "",
    includeInRundown: false,
  },
  {
    color: null,
    notes: "",
    topic: "Trish / @lavenderhersheydiaries",
    end_time: null,
    isNewTopic: false,
    start_time: null,
    topic_time: 10,
    is_complete: false,
    show_in_list: false,
    ticker_label: "",
    includeInRundown: false,
  },
];
