import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const MomentsTabs = (props) => {
  const { userStatus, duration } = props;
  // const [userData, setUserData] = useState([]);
  const [rangeVal] = useState([]);
  const [currentTab, setCurrentTab] = useState("MOMENTS");
  const { t } = useTranslation();
  let searchTimeout;

  const toggleMomentsClips = (type) => {
    console.log("toggle", type);
    setCurrentTab(type);
  };

  const clearMarks = () => {
    const marks = document.querySelectorAll("mark");
    const videoBoxesHighlighted = document.querySelectorAll(
      ".video-box.highlighted"
    );
    const videoBoxesHidden = document.querySelectorAll(".video-box.hide");
    marks.forEach((highlighted) => {
      const text = highlighted.closest("span");
      if (!text) {
        highlighted.innerHTML = highlighted.innerHTML.replace(/<\/?mark>/g, "");
      } else {
        text.innerHTML = text.innerHTML.replace(/<\/?mark>/g, "");
      }
    });
    videoBoxesHighlighted.forEach((vid) => {
      vid.classList.remove("highlighted");
    });
    videoBoxesHidden.forEach((vid) => {
      vid.classList.remove("hide");
    });
  };

  const delayedSearch = (event) => {
    clearTimeout(searchTimeout);
    searchTimeout = setTimeout(() => {
      search(event);
    }, 500);
  };

  const search = (event) => {
    const prompt = event.target.value;
    clearMarks();
    const searchText = prompt.trim();
    if (searchText.length) {
      const fieldsToSearch = document.querySelectorAll(
        ".moment-title, .transcript"
      );
      let hasMatches = false;
      fieldsToSearch.forEach((text) => {
        const regex = new RegExp(searchText, "gi"); // "gi" means "global" and "case-insensitive"
        const matches = text.innerHTML.match(regex);

        if (matches) {
          text.innerHTML = text.innerHTML.replace(regex, "<mark>$&</mark>");
          hasMatches = true;
        }
      });

      if (hasMatches) {
        const newMarks = document.querySelectorAll("mark");
        newMarks.forEach((highlighted) => {
          highlighted.closest(".video-box").classList.add("highlighted");
        });

        const videoBoxes = document.querySelectorAll(".video-box");

        videoBoxes.forEach((vid) => {
          if (!vid.classList.contains("highlighted")) {
            vid.classList.add("hide");
          }
          //
        });
      } else {
        alert(`${searchText} not found`);
      }
    }
  };

  return (
    <>
      <div className="tab-header">
        <div
          className={currentTab === "MOMENTS" ? `tab active` : `tab`}
          onClick={() => toggleMomentsClips("MOMENTS")}
        >
          <span>
            <i class="fa-solid fa-clapperboard"></i>{" "}
            {t("tabs.generated_moments")}
          </span>
        </div>
        <div
          className={currentTab === "CLIPS" ? `tab active` : `tab`}
          onClick={() => toggleMomentsClips("CLIPS")}
        >
          <span>
            <i class="fa-solid fa-clapperboard"></i> {t("tabs.saved_moments")}
          </span>
        </div>
        <div className="tab search-box">
          <input
            type="text"
            placeholder="Enter a search term and press enter"
            onChange={(e) => delayedSearch(e)}
          ></input>
        </div>
      </div>
    </>
  );
};

export default MomentsTabs;
