import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { io, Socket } from "socket.io-client";
import { getBearerHeaders } from "../../../helpers/GetBearerHeaders";
import { getServer } from "../../../helpers/GetServer";
import "../../../sass/themes/theme-kharyscrib.scss";
import "../../../sass/themes/theme-flowerpower.scss";
import "../../../sass/themes/theme-pinky.scss";
import "../../../sass/themes/theme-playhouse.scss";
import "../../../sass/themes/theme-sporty.scss";
import "../../../sass/themes/theme-glow.scss";

export interface TopicProps {
  text?: string;
  initialVisibility?: boolean;
  defaultTheme?: string;
  socketserver?: Socket;
  isInControlCenter?: boolean;
  joinedShowid?: string;
  isEmbeded?: boolean | null;
}
const ChyronSource = ({
  text,
  initialVisibility,
  defaultTheme,
  socketserver,
  isInControlCenter,
  joinedShowid,
  isEmbeded,
}: TopicProps) => {
  const { sourceid } = useParams();
  const [showidToLoad, setShowIdToLoad] = useState(
    isInControlCenter === true ? joinedShowid : sourceid
  );
  const [topicText, setTopicText] = useState<string>(text || "");
  const [sourceUser, setSourceUser] = useState<number>(0);
  const [isVisible, setIsVisible] = useState<boolean>(
    initialVisibility ? initialVisibility : true
  );
  const [wsTopicdata, setWsTopicData] = useState<string>(null);
  const [wsdata, setWsData] = useState<string>(null);
  const [pingCount, setPingCount] = useState<number>(0);
  const [pingData, setPingData] = useState<any>();
  const [websocket, setWebSocket] = useState<Socket>();
  const [autoHideSeconds, setAutoHideSeconds] = useState<number>(45);
  //const [hadInitialLoad, setHadInitialLoad] = useState<boolean>(false);
  let timeout = useRef(null);

  const [theme, setTheme] = useState<string>(
    defaultTheme ? defaultTheme : "kharyscrib"
  );

  const onTopicUpdate = (data) => {
    setWsTopicData(data);
  };

  const onChyronVisibleChange = (data) => {
    setWsData(data);
    clearTimeout(timeout.current);
    //console.log("CLEAR TIMEOUT");
  };

  const onPing = (data) => {
    setPingCount((prev) => prev + 1);
    setPingData(data);
  };

  const emitToggleMsg = () => {
    const msg = {
      sourceid: showidToLoad,
      eventtype: "CHYRONTOGGLE",
      visible: isVisible,
    };

    return msg;
  };

  const connectShow = () => {
    const postData = { showData: showidToLoad };

    const apiUrl = showidToLoad ? "/api/sources/topic" : "/api/v1/gettopic";
    if (socketserver) {
      setWebSocket(socketserver);
    } else {
      setWebSocket(io(getServer()));
    }

    axios.post(apiUrl, postData, getBearerHeaders()).then((response) => {
      if (response.data.success) {
        setTheme(response.data.items.theme);
        setTopicText(response.data.items.topic);
        setAutoHideSeconds(response.data.items.autoHide);
        if (response.data.items.autoHide === 0) {
          clearTimeout(timeout.current);
        }

        if (showidToLoad) {
          axios
            .post("/api/sources/userbysource", postData, getBearerHeaders())
            .then((userResponse) => {
              if (userResponse.data.success) {
                setSourceUser(Number(userResponse.data.items.userid));
                if (response.data.items.autoHide > 0) {
                  //console.log("SET TIMEOUT?", response.data.items.autoHide);
                  timeout.current = window.setTimeout(() => {
                    setIsVisible(false);
                  }, response.data.items.autoHide * 1000);
                }
              }
            });
        }
      }
    });
  };

  /* useEffect(() => {
    console.log("autoHideSeconds update", autoHideSeconds);
  }, [autoHideSeconds]); */

  useEffect(() => {
    if (defaultTheme) {
      setTheme(defaultTheme);
    }
  }, [defaultTheme]);

  useEffect(() => {
    if (websocket) {
      websocket.emit("obs-wss", emitToggleMsg());
    }
  }, [topicText]);

  useEffect(() => {
    if (pingData) {
      if (pingData.sourceid === sourceid && websocket) {
        websocket.emit("obs-wss", emitToggleMsg());
      }
    }
  }, [pingCount]);

  useEffect(() => {
    if (websocket) {
      websocket.emit("obs-wss", emitToggleMsg());
    }
  }, [isVisible]);

  useEffect(() => {
    if (wsTopicdata) {
      const resultData = JSON.parse(wsTopicdata);
      if (resultData.id === sourceUser) {
        setTopicText(resultData.data);
        setIsVisible(true);
        // console.log("Clear Timeout");
        clearTimeout(timeout.current);
        //console.log("SET TIMEOUT?", autoHideSeconds);
        if (autoHideSeconds > 0) {
          //console.log("Yes", autoHideSeconds);
          timeout.current = window.setTimeout(() => {
            //console.log("setIsVisible false", autoHideSeconds);
            setIsVisible(false);
          }, autoHideSeconds * 1000);
        }
      }
    }
  }, [wsTopicdata]);

  useEffect(() => {
    if (wsdata) {
      const resultData = JSON.parse(wsdata);
      if (resultData.id === sourceUser) {
        setIsVisible(resultData.visible);
      }
    }
  }, [wsdata]);

  useEffect(() => {
    if (websocket) {
      if (!socketserver) {
        websocket.connect();
        websocket.on("connect", () => {
          websocket.emit("obs-wss", emitToggleMsg());
          websocket.on("topic-update", onTopicUpdate);
          websocket.on("obs-chyron-toggle", onChyronVisibleChange);
          websocket.on("bs-ping", onPing);
        });
      } else {
        websocket.emit("obs-wss", emitToggleMsg());
        websocket.on("topic-update", onTopicUpdate);
        websocket.on("obs-chyron-toggle", onChyronVisibleChange);
        websocket.on("bs-ping", onPing);
      }

      websocket.on("disconnect", () => {
        websocket.off("topic-update", onTopicUpdate);
        websocket.off("obs-chyron-toggle", onChyronVisibleChange);
        websocket.off("bs-ping", onPing);
        //socket.disconnect();
      });
    }
  }, [websocket]);

  useEffect(() => {
    if (joinedShowid) {
      setShowIdToLoad(joinedShowid);
    }
  }, [joinedShowid]);

  useEffect(() => {
    if (showidToLoad) {
      connectShow();
    }
  }, [showidToLoad]);

  useEffect(() => {}, []);

  return (
    <div
      key={sourceUser}
      className={
        isVisible
          ? `topic-container on ${theme} ${
              isInControlCenter && "studio hidden md:flex"
            } ${isEmbeded && "emdeded"}`
          : `topic-container ${theme} ${
              isInControlCenter && "studio hidden md:flex"
            } ${isEmbeded && "emdeded"}`
      }
    >
      <div className="topic-source">
        <span>{topicText}</span>
      </div>
    </div>
  );
};

export default ChyronSource;
