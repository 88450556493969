export const secondsToVideoTimecode = (seconds, frameRate) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = Math.floor(seconds % 60);
  const frames = Math.floor((seconds % 1) * frameRate);

  const formattedHours = String(hours).padStart(2, "0");
  const formattedMinutes = String(minutes).padStart(2, "0");
  const formattedSeconds = String(remainingSeconds).padStart(2, "0");
  const formattedFrames = String(frames).padStart(2, "0");

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}:${formattedFrames}`;
};

export const percentToSeconds = (percent, totalDuration) => {
  const seconds = (percent / 100) * totalDuration;
  return seconds;
};

export const calcPercentage = (duration, time) => {
  const percent = (time / duration) * 100;
  return percent;
};

export const secondsToTimecode = (seconds) => {
  var hours = Math.floor(seconds / 3600);
  var minutes = Math.floor((seconds % 3600) / 60);
  seconds = Math.floor(seconds % 60);

  var timecode =
    ("0" + hours).slice(-2) +
    ":" +
    ("0" + minutes).slice(-2) +
    ":" +
    ("0" + seconds).slice(-2);
  return timecode;
};

export const timeCodeToSeconds = (timeCode) => {
  const parts = timeCode.split(":");

  // Extract hours, minutes, seconds, and milliseconds
  const hours = parseInt(parts[0]);
  const minutes = parseInt(parts[1]);
  const seconds = parseInt(parts[2]);
  const milliseconds = parseInt(parts[3]);

  // Calculate total seconds
  const totalSeconds =
    hours * 3600 + minutes * 60 + seconds + milliseconds / 1000;

  return totalSeconds;
};
