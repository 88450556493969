import React, { useEffect, useState } from "react";
import {
  AdjustmentsVerticalIcon,
  CalendarIcon,
  ChatBubbleLeftRightIcon,
  //Cog6ToothIcon,
  ComputerDesktopIcon,
  //DevicePhoneMobileIcon,
  //FingerPrintIcon,
  MagnifyingGlassIcon,
  ShareIcon,
  UserGroupIcon,
  VideoCameraIcon,
} from "@heroicons/react/20/solid";

//import LogoCloud from "./components/LogoCloud";
import Features from "./components/Features";
import SecondaryFeatures from "./components/SecondaryFeatures";
//import Stats from "./components/Stats";
import Footer from "./components/Footer";
import Hero from "./components/Hero";
import Cta from "./components/Cta";
import { useTranslation } from "react-i18next";
import { PublicLiveShowsInStudio } from "../../../api-requests/Public";
import LiveNow from "./components/LiveNow";
import Cookies from "js-cookie";
import { fetchTkRf } from "../../../api-requests/Index";

export default function Home() {
  const { t } = useTranslation();
  const [liveShows, setLiveShows] = useState([]);

  const titles = [
    { label: t("home.podcast"), color: "orange" },
    { label: t("home.liveStream"), color: "white" },
    { label: t("home.liveEvent"), color: "red" },
    { label: t("home.liveContent"), color: "blue" },
    { label: t("home.podcast"), color: "orange" },
  ];

  const primaryFeatures = [
    {
      name: "Rundown Planning.",
      description:
        "Effortlessly create schedules for podcasts, live streams, and live events, tailoring them to their specific needs. Each schedule can be finely tuned by defining start times for segments and allocating time for each.",
      href: "#",
      icon: CalendarIcon,
    },
    {
      name: "Segment Planning.",
      description:
        "ShoPro offers real-time updates, enabling users to keep a finger on the pulse of their content delivery. Participants in the schedule see the current segment, time remaining in the current segment, and estimated start times for upcoming segments, ensuring smooth execution and accurate timing.",
      href: "#",
      icon: CalendarIcon,
    },
    {
      name: "OBS Overlays.",
      description:
        "For seamless integration with OBS (Open Broadcaster Software), ShoPro generates overlays that encompass the essential elements of a professional broadcast. This includes a rundown, chyron, and ticker, making it a breeze to create engaging and visually appealing content.",
      href: "#",
      icon: ComputerDesktopIcon,
    },
  ];
  const secondaryFeatures = [
    {
      name: "Total Show Control.",
      description:
        "From planning schedules to executing live events, ShoPro Studio puts you in command, ensuring a flawless and engaging production.",
      icon: AdjustmentsVerticalIcon,
    },
    {
      name: "Camera Integration.",
      description:
        "Easily connect your camera and guest cameras to OBS, ensuring a professional and polished broadcast.",
      icon: VideoCameraIcon,
    },
    {
      name: "Guest Management.",
      description:
        "Invite guests to join your show seamlessly, managing their participation and scenes within OBS for a smooth and collaborative experience.",
      icon: UserGroupIcon,
    },
    {
      name: "Real-Time Collaboration.",
      description:
        "Users can effortlessly share their schedules with multiple participants, allowing for a synchronized and organized approach to content production, whether it's a podcast, live stream, or live event.",
      icon: ShareIcon,
    },
    {
      name: "Teleprompter.",
      description:
        "Experience seamless content delivery with ShoPro's integrated web-based teleprompter. Whether you're hosting a live event, podcast, or live stream, our teleprompter is your trusty companion for a flawless presentation.",
      icon: MagnifyingGlassIcon,
    },
    {
      name: "Guest Chat.",
      description:
        "Seamlessly communicate during live shows, whether you're in the planning stages using the ShoPro Planner or actively broadcasting with the ShoPro Studio.",
      icon: ChatBubbleLeftRightIcon,
    },
  ];
  /* const stats = [
  { id: 1, name: "Creators on the platform", value: "10" },
  { id: 2, name: "Daily requests", value: "2" },
  { id: 3, name: "Uptime guarantee", value: "99.9%" },
  { id: 4, name: "Shows created", value: "60" },
]; */
  const footerNavigation = {
    solutions: [
      { name: "Hosting", href: "#" },
      { name: "Data Services", href: "#" },
      { name: "Uptime Monitoring", href: "#" },
      { name: "Enterprise Services", href: "#" },
    ],
    support: [
      { name: "Pricing", href: "#" },
      { name: "Documentation", href: "#" },
      { name: "Guides", href: "#" },
      { name: "API Reference", href: "#" },
    ],
    company: [
      { name: "About", href: "#" },
      { name: "Blog", href: "#" },
      { name: "Jobs", href: "#" },
      { name: "Press", href: "#" },
      { name: "Partners", href: "#" },
    ],
    legal: [
      { name: "Claim", href: "#" },
      { name: "Privacy", href: "#" },
      { name: "Terms", href: "#" },
    ],
    social: [
      {
        name: "Instagram",
        href: "https://instagram.com/shoprolive",
        icon: (props) => (
          <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
            <path
              fillRule="evenodd"
              d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
              clipRule="evenodd"
            />
          </svg>
        ),
      },
      {
        name: "X",
        href: "https://twitter.com/shoprolive",
        icon: (props) => (
          <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
            <path d="M13.6823 10.6218L20.2391 3H18.6854L12.9921 9.61788L8.44486 3H3.2002L10.0765 13.0074L3.2002 21H4.75404L10.7663 14.0113L15.5685 21H20.8131L13.6819 10.6218H13.6823ZM11.5541 13.0956L10.8574 12.0991L5.31391 4.16971H7.70053L12.1742 10.5689L12.8709 11.5655L18.6861 19.8835H16.2995L11.5541 13.096V13.0956Z" />
          </svg>
        ),
      },
      {
        name: "YouTube",
        href: "https://youtube.com/@shoprolive",
        icon: (props) => (
          <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
            <path
              fillRule="evenodd"
              d="M19.812 5.418c.861.23 1.538.907 1.768 1.768C21.998 8.746 22 12 22 12s0 3.255-.418 4.814a2.504 2.504 0 0 1-1.768 1.768c-1.56.419-7.814.419-7.814.419s-6.255 0-7.814-.419a2.505 2.505 0 0 1-1.768-1.768C2 15.255 2 12 2 12s0-3.255.417-4.814a2.507 2.507 0 0 1 1.768-1.768C5.744 5 11.998 5 11.998 5s6.255 0 7.814.418ZM15.194 12 10 15V9l5.194 3Z"
              clipRule="evenodd"
            />
          </svg>
        ),
      },
    ],
  };

  const checkToken = async () => {
    const jwtToken = Cookies.get("jwt");
    const jwtRefreshToken = Cookies.get("rftk");
    const hasCookie = jwtToken ? true : false;
    const hasRefreshCookie = jwtRefreshToken ? true : false;

    if (!hasCookie && hasRefreshCookie) {
      console.log("No JWT, but has refresh token");
      const refreshToken = await fetchTkRf({ urtk: jwtRefreshToken }, {});
      if (refreshToken.success) {
        console.log("Refresh token success");
        Cookies.set("jwt", refreshToken.items.token, {
          expires: new Date(refreshToken.items.expires * 1000),
        });
        window.location.reload();
      }
    }
    console.log("No Authentication cookies found.");
    return new Promise((resolve) => {
      resolve({ hasCookie, hasRefreshCookie });
    });
  };

  useEffect(() => {
    //document.title = "ShoPro Studio";

    const fetchData = async () => {
      const checkuser = await checkToken();
      console.log("Check user", checkuser);
      const liveShowResults = await PublicLiveShowsInStudio({}, {});
      if (liveShowResults.success) {
        setLiveShows(liveShowResults.items);
      }
    };

    fetchData();
  }, []);
  return (
    <div className="bg-gray-900">
      <main>
        <Hero titles={titles} />
        {/* <LogoCloud /> */}
        <Features primaryFeatures={primaryFeatures} />
        {liveShows && liveShows.length > 0 && <LiveNow shows={liveShows} />}
        <SecondaryFeatures secondaryFeatures={secondaryFeatures} />
        {/* <Stats stats={stats} /> */}
        <Cta />
      </main>

      <Footer footerNavigation={footerNavigation} />
    </div>
  );
}
