import React, { useEffect, useRef, useState } from "react";
import { tw } from "../../../../helpers/Styles";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera, faFilm } from "@fortawesome/free-solid-svg-icons";
import { getBearerHeaders } from "../../../../helpers/GetBearerHeaders";
import { fetchAvailableAudio } from "../../../../api-requests/Index";
import Audio from "./components/Audio";

export interface SceneSettingsProps {
  addStream: (stream: any) => void;
  stopAudioStream: () => void;
}

const SceneSettings = ({ addStream, stopAudioStream }: SceneSettingsProps) => {
  const { t } = useTranslation();

  const [availableAudio, setAvailableAudio] = useState([]);
  const [playingId, setPlayingId] = useState(0);

  //const videoUploadLimit = 5;

  const fileInput = useRef<HTMLInputElement>(null);

  const handleFileOpen = () => {
    fileInput.current.click();
  };
  const handleFileChange = (event) => {
    console.log("Selected file: ", event.target.files[0].name);
  };

  const handleAudioPlay = (audio) => {
    stopAudioStream();
    const audioContext = new AudioContext();
    const audioPath = audio.fileLocation; // replace with your audio file path

    fetch(audioPath)
      .then((response) => response.arrayBuffer())
      .then((arrayBuffer) => audioContext.decodeAudioData(arrayBuffer))
      .then((audioBuffer) => {
        console.log("audioBuffer", audioBuffer);
        const sourceNode = audioContext.createBufferSource();
        sourceNode.buffer = audioBuffer;

        const destinationNode = audioContext.createMediaStreamDestination();
        sourceNode.connect(destinationNode);
        sourceNode.start();
        const stream = destinationNode.stream;

        console.log("Audio stream: ", stream);
        const canvas = document.createElement("canvas");
        canvas.width = 1920; // Set the width of the canvas
        canvas.height = 1080; // Set the height of the canvas
        const context = canvas.getContext("2d");
        context.fillStyle = "black";
        context.fillRect(0, 0, canvas.width, canvas.height);

        // Capture a stream from the canvas
        const videoStream = canvas.captureStream(25); // 25 frames per second

        // Add the video stream to the audio stream
        const videoTrack = videoStream.getVideoTracks()[0];
        stream.addTrack(videoTrack);
        addStream(stream);
      })
      .catch((error) => console.error(error));
    setPlayingId(audio.id);
  };

  const handleAudioStop = () => {
    stopAudioStream();
    setPlayingId(0);
  };

  useEffect(() => {
    const fetchData = async () => {
      const result = await fetchAvailableAudio({}, getBearerHeaders());
      console.log("Available audio: ", result);
      if (result.success) {
        setAvailableAudio(result.items);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="flex flex-col w-full divide-y divide-slate-700 mb-10">
      <div className="p-2 font-semibold">{t("studio.scene_settings")}</div>
      <div className="p-2">
        <h3 className="">{t("studio.video_clips")}</h3>

        <div className="flex justify-center mt-3">
          <button
            className={`${tw.btn.reg} bg-blue-charcoal-500 w-8/12 rounded-sm text-xs`}
            onClick={handleFileOpen}
          >
            <FontAwesomeIcon icon={faFilm} className="mr-2" />{" "}
            {t("buttons.add_video_clips")}
          </button>
          <input
            type="file"
            ref={fileInput}
            onChange={handleFileChange}
            style={{ display: "none" }}
            accept="video/*"
          />
        </div>
      </div>
      <div className="p-2">
        <h3 className="">{t("studio.audio")}</h3>
        {availableAudio.map((audio, index) => (
          <Audio
            key={index}
            audio={audio}
            onAudioPlay={handleAudioPlay}
            onAudioStop={handleAudioStop}
            playingId={playingId}
          />
        ))}
        <div className="flex justify-center mt-3">
          <button
            className={`${tw.btn.reg} bg-blue-charcoal-500 w-8/12 rounded-sm text-xs`}
          >
            <FontAwesomeIcon icon={faCamera} className="mr-2" />{" "}
            {t("studio.add_audio")}
          </button>
        </div>
      </div>
      <div className="p-2 ">
        <h3 className="">{t("studio.cameras")}</h3>

        <div className="flex justify-center mt-3">
          <button
            className={`${tw.btn.reg} bg-gray-800 text-gray-700 w-8/12 rounded-sm text-xs`}
          >
            <FontAwesomeIcon icon={faCamera} className="mr-2" />{" "}
            {t("studio.add_another_camera")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SceneSettings;
