export const tw = {
  card: "bg-ssblue hover:bg-ssbluehover border border-ssgray shadow rounded-md relative transition-colors duration-300 ease-in-out card",
  cardPlain:
    "bg-ssblue border border-ssgray shadow rounded-md relative transition-colors duration-300 ease-in-out card",
  cardInner: "p-2 h-full",
  btn: {
    reg: "inline-block text-sm p-2 uppercase font-bold transition-colors duration-300 ease-in-out",
    active:
      "inline-block p-2 text-blue-600 bg-gray-100 active dark:bg-gray-800 dark:text-blue-500",
    action:
      "text-xs font-semibold inline-block pt-1 pb-1 pr-2 pl-2 uppercase font-medium transition-colors duration-300 ease-in-out",
  },
  text: {
    cardTitle: "text-2xl block leading-tight",
  },
  hr: "border-b border-ssgray",
  modalBg:
    "fixed inset-0 bg-slate-600 bg-opacity-95 transition-opacity duration-200 ",
  dashtabs: {
    reg: "text-white rounded-md border-slate-800 px-2 py-1 text-base hover:bg-gray-500 pointer transition-colors duration-300 ease-in-out",
    active:
      "text-white rounded-md px-2 py-1 text-base bg-gray-500 hover:bg-gray-300 hover:text-slate-900 pointer transition-colors duration-300 ease-in-out ",
  },
  tabs: {
    reg: "text-white rounded-sm px-2 py-1 text-xs hover:bg-gray-500 pointer transition-colors duration-300 ease-in-out rounded-b-none",
    active:
      "text-white rounded-sm px-2 py-1 text-xs bg-gray-500 hover:bg-gray-300 hover:text-slate-900 pointer transition-colors duration-300 ease-in-out rounded-b-none",
  },
  sstabs: {
    reg: "text-white rounded-md border border-slate-800 px-4 py-1 text-base hover:bg-sky-500 pointer transition-colors duration-300 ease-in-out no-underline mr-2",
    active:
      "text-white rounded-md px-4 py-1 border text-base border-slate-700 bg-sky-700 hover:bg-sky-700 pointer transition-colors duration-300 ease-in-out no-underline mr-2 active ",
  },
};
