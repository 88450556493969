import React, { Fragment, useEffect, useState } from "react";
import { EnlargedNoteProps } from "../../../types/Types";
import { Dialog, Transition } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { tw } from "../../../helpers/Styles";

const EnlargedNote = ({ note, onClose, isOpen }: EnlargedNoteProps) => {
  const [open, setOpen] = useState(isOpen);
  const convertTextLinksToHtml = (text: string) => {
    // Regular expression to match URLs in text
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const parts = text.split(urlRegex);

    const elements = parts.map((part, index) => {
      if (urlRegex.test(part)) {
        // If the part is a URL, return an anchor tag
        return (
          <a href={part} key={index} target="_blank" rel="noopener noreferrer">
            {part}
          </a>
        );
      } else {
        // If the part is not a URL, return plain text
        return part;
      }
    });

    return elements;
  };

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-[250]" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className={tw.modalBg} />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full min-w-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="divide-y divide-slate-900 overflow-hidden relative transform rounded-md border border-slate-500 bg-ssblue2 text-left shadow-xl transition-all w-full sm:my-8 sm:w-full sm:max-w-6xl mx-1">
                <div className="px-4 py-5 sm:px-6 bg-ssblue">
                  <div className="flex items-center justify-between">
                    <Dialog.Title className="text-base font-semibold leading-6 text-white">
                      Notes
                    </Dialog.Title>
                    <div className="ml-3 flex h-7 items-center">
                      <FontAwesomeIcon
                        className="relative rounded-md bg-ssbluehover text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white cursor-pointer"
                        icon={faCircleXmark}
                        onClick={onClose}
                      />
                    </div>
                  </div>
                </div>
                <div className="px-4 py-2 sm:p-4">
                  <div className="min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <span className="text-2xl ">
                      {note &&
                        note.split("\n").map((line, index) => (
                          <span key={index} className="enlarged-note">
                            {convertTextLinksToHtml(line)}
                            <br />
                          </span>
                        ))}
                    </span>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default EnlargedNote;
