export const getServer = (): string => {
  const host = window.location.hostname;
  let server: string;

  if (host === "localhost") {
    server = "http://localhost:5000";
  } else if (host === "alpha.shopro.live") {
    server = "https://alpha.shopro.live";
  } else if (host === "shopro.live") {
    server = "https://shopro.live";
  } else {
    server = "https://shopro.live";
  }

  return server;
};
