import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { io, Socket } from "socket.io-client";
import axios from "axios";
import { getBearerHeaders } from "../../../helpers/GetBearerHeaders";
import { getServer } from "../../../helpers/GetServer";

import "../../../sass/themes/theme-kharyscrib.scss";
import "../../../sass/themes/theme-pinky.scss";
import "../../../sass/themes/theme-playhouse.scss";
import "../../../sass/themes/theme-redlatex.scss";
import "../../../sass/themes/theme-transparentblack.scss";
import "../../../sass/themes/theme-transparentwhite.scss";

import ChyronSource from "./ChyronSource";
import Ticker from "./Ticker";
import Rundown from "./RunndownSource";
/* import CanvasDrawer from "../CanvasDrawer";
import { themeKharysCrib } from "../../../data/Themes"; */

export interface OverlayProps {
  rnData?: any;
  tickerString?: string;
  topicString?: string;
  defaultTheme?: string;
  isProAccount?: boolean;
}

const Overlay = ({
  defaultTheme,
  topicString,
  tickerString,
  rnData,
  isProAccount,
}: OverlayProps) => {
  const { sourceid } = useParams();
  //const [sourceUser, setSourceUser] = useState<number>(0);
  const [sourceisPro, setSourceIsPro] = useState<boolean>(
    isProAccount ? isProAccount : false
  );
  const [theme, setTheme] = useState<string>(
    defaultTheme ? defaultTheme : "kharyscrib"
  );
  const [websocket, setWebSocket] = useState<Socket>();
  const [pageReady, sePageReady] = useState<boolean>(false);
  const [rundownTopic] = useState<string>("");
  const styleSet = [
    {
      kharyscrib: {
        style: {
          position: "fixed",
          top: "30px",
          right: "30px",
          zindex: 9999,
        },
      },
      corp: {
        style: {
          position: "fixed",
          top: "30px",
          right: "30px",
          zindex: 9999,
        },
      },
      flowerpower: {
        style: { position: "fixed", top: "30px", left: "30px" },
      },
      glow: {
        style: {
          position: "fixed",
          top: "30px",
          right: "30px",
          zindex: 9999,
        },
      },
      playhouse: {
        style: { position: "fixed", top: "30px", right: "30px" },
      },
      pinky: {
        style: {
          position: "fixed",
          top: "30px",
          left: "30px",
          zindex: 9999,
        },
      },
      redlatex: {
        style: { position: "fixed", bottom: "60px", right: "30px" },
      },
      sporty: { style: { position: "fixed", top: "30px", left: "30px" } },
      sportyblue: {
        style: { position: "fixed", top: "30px", left: "30px" },
      },
      topdown: {
        style: { position: "fixed", bottom: "60px", right: "30px" },
      },
      transparentblack: {
        style: {
          position: "fixed",
          top: "30px",
          right: "30px",
          zindex: 9999,
        },
      },
      transparentwhite: {
        style: {
          position: "fixed",
          top: "30px",
          right: "30px",
          zindex: 9999,
        },
      },
    },
  ];

  useEffect(() => {
    if (defaultTheme) {
      setTheme(defaultTheme);
    }
  }, [defaultTheme]);

  useEffect(() => {
    console.log("RUNDOWN TOPIC", rundownTopic, topicString, tickerString);
  }, [rundownTopic]);

  useEffect(() => {
    if (websocket) {
      websocket.connect();
      websocket.on("connect", () => {
        //console.log("OVERLAY SOCKET CONNECTED");
        sePageReady(true);
      });

      websocket.on("disconnect", () => {
        //console.log("OVERLAY SOCKET CONNECTED");
        websocket.disconnect();
      });
    }
  }, [websocket]);

  useEffect(() => {
    const postData = { showData: sourceid };
    const apiUrl = sourceid ? "/api/sources/rundown" : "/api/v1/rundown";
    if (sourceid) {
      // get the user and see if they are pro
      axios
        .post("/api/sources/userbysource", postData, getBearerHeaders())
        .then((response) => {
          //console.log("SOURCE USER");
          if (response.data.success) {
            setSourceIsPro(
              response.data.items.accounttype.toUpperCase() === "PRO"
                ? true
                : false
            );
          }
        });
    }
    setWebSocket(io(getServer()));
    axios.post(apiUrl, postData, getBearerHeaders()).then((response) => {
      if (response.data.success) {
        //console.log(response);
        setTheme(response.data.items.theme);
        /* console.log(
          "styleSet",
          styleSet[0][String(response.data.items.theme)],
          response.data.items.theme
        ); */
      }
    });
  }, []);

  return (
    <>
      {pageReady ? (
        <>
          {!sourceisPro ? (
            <div style={styleSet[0][String(theme)].style}>
              <img src="/img/shopro-powered.png" />
            </div>
          ) : null}
          <ChyronSource
            defaultTheme={theme}
            text={topicString}
            socketserver={websocket}
          />
          <Rundown
            defaultTheme={theme}
            rnData={rnData}
            socketserver={websocket}
            isBrandingGlobal={true}
            onRundownTopicChange={() => {}}
          />
          <Ticker
            defaultTheme={theme}
            tickerString={tickerString}
            socketserver={websocket}
            isBrandingGlobal={true}
          />
        </>
      ) : null}
    </>
  );
};

export default Overlay;
