// eslint-disable-next-line
import React, { useRef, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { secondsToVideoTimecode, calcPercentage } from "../helpers/helpers";

export const Slider = ({
  duration,
  videoClipStart,
  videoClipEnd,
  videoRef,
  frameRate,
  onSliderUpdate,
  playheadPosition,
  videoPlayTime,
  playheadTimeOn,
}) => {
  const { t } = useTranslation();

  const [startTimeCode, setStartTimeCode] = useState(
    secondsToVideoTimecode(videoClipStart, frameRate)
  );
  const [endTimeCode, setEndTimeCode] = useState(
    secondsToVideoTimecode(videoClipEnd, frameRate)
  );
  const [isSliding, setIsSliding] = useState(false);
  const [clipTimeLength, setClipTimeLength] = useState("");

  const [trackBackground, setTrackBackground] = useState(
    `linear-gradient(to right, #dadae5 ${calcPercentage(
      duration,
      videoClipStart
    )}% , #3264fe ${calcPercentage(
      duration,
      videoClipStart
    )}% , #3264fe ${calcPercentage(
      duration,
      videoClipEnd
    )}%, #dadae5 ${calcPercentage(duration, videoClipEnd)}%)`
  );

  const current_clip_length_string = t("current_clip_length");

  const slider1 = useRef(null);
  const slider2 = useRef(null);
  const startTime = useRef(null);
  const endTime = useRef(null);
  const sliderTrack = useRef(null);
  const range1 = useRef(null);
  const range2 = useRef(null);
  const sliderRef = useRef(null);
  const playheadTime = useRef(null);

  const padZero = (number, length = 2) => {
    return String(number).padStart(length, "0");
  };

  const rangeDif = (time1, time2) => {
    var [h1, m1, s1, ms1] = time1.split(":");
    var [h2, m2, s2, ms2] = time2.split(":");

    var date1 = new Date();
    date1.setHours(h1, m1, s1, ms1);
    var date2 = new Date();
    date2.setHours(h2, m2, s2, ms2);

    var difference = date2 - date1;
    var milliseconds = difference % 1000;

    difference = (difference - milliseconds) / 1000;
    var seconds = difference % 60;
    difference = (difference - seconds) / 60;
    var minutes = difference % 60;
    var hours = (difference - minutes) / 60;

    var formattedTime = `${padZero(hours)}:${padZero(minutes)}:${padZero(
      seconds
    )}`;

    return formattedTime;
  };

  const onSliderMouseDown = () => {
    setIsSliding(true);
  };

  const onSliderMouseUp = (event) => {
    setTimeout(function () {
      setIsSliding(false);
      if (event.target.id === "slider-1") {
        updateClipTime(duration, slider1.current.value, "STARTTIME");
        updateVideoTime((Number(slider1.current.value) / 100) * duration);
        onSliderUpdate({
          slider1: slider1.current.value,
          slider2: slider2.current.value,
          isSliding: false,
        });
      } else {
        updateClipTime(duration, slider2.current.value, "ENDTIME");
        onSliderUpdate({
          slider1: slider1.current.value,
          slider2: slider2.current.value,
          isSliding: false,
        });
      }
    }, 200);
  };

  const onSliderTrackClick = (event) => {
    const rangeWidth = sliderRef.current.offsetWidth;
    const clickPosition =
      event.clientX - sliderRef.current.getBoundingClientRect().left;
    const positionPercentage = (clickPosition / rangeWidth) * 100;
    const lowVal = Number(slider1.current.value);
    const highVal = Number(slider2.current.value);
    if (
      !isSliding &&
      positionPercentage >= lowVal &&
      positionPercentage <= highVal
    ) {
      updateVideoTime((positionPercentage / 100) * duration);
    }
  };

  const updateVideoTime = (time) => {
    videoRef.current.currentTime = time;
  };

  const calculateTimes = (duration, percent, target) => {
    const currentTime = (percent * duration) / 100;
    const hours = Math.floor(currentTime / 3600);
    const minutes = Math.floor((currentTime - hours * 3600) / 60);
    const seconds = Math.floor(currentTime % 60);
    const milliseconds = Math.floor((currentTime % 1) * 1000);
    if (target === "STARTTIME") {
      setStartTimeCode(
        `${hours.toString().padStart(2, "0")}:${minutes
          .toString()
          .padStart(2, "0")}:${seconds
          .toString()
          .padStart(2, "0")}:${milliseconds
          .toString()
          .padStart(3, "0")
          .substr(0, 2)}`
      );
    }

    if (target === "ENDTIME") {
      setEndTimeCode(
        `${hours.toString().padStart(2, "0")}:${minutes
          .toString()
          .padStart(2, "0")}:${seconds
          .toString()
          .padStart(2, "0")}:${milliseconds
          .toString()
          .padStart(3, "0")
          .substr(0, 2)}`
      );
    }
  };

  const updateClipTime = (duration, percent, target) => {
    calculateTimes(duration, percent, target);
  };

  function fillColor(duration, percent, target) {
    const sliderMaxValue = slider1.current.max;
    const percent1 = (slider1.current.value / sliderMaxValue) * 100;
    const percent2 = (slider2.current.value / sliderMaxValue) * 100;
    setTrackBackground(
      `linear-gradient(to right, #dadae5 ${percent1}% , #3264fe ${percent1}% , #3264fe ${percent2}%, #dadae5 ${percent2}%)`
    );
    calculateTimes(duration, percent, target);
    onSliderUpdate({
      slider1: slider1.current.value,
      slider2: slider2.current.value,
      isSliding: isSliding,
    });
  }

  useEffect(() => {
    setClipTimeLength(
      ` ${current_clip_length_string}: ${rangeDif(startTimeCode, endTimeCode)}`
    );
  }, [startTimeCode, endTimeCode, current_clip_length_string]);

  return (
    <>
      <div className="playhead-time-wrap w-[90%] m-auto block relative">
        <div
          className={
            playheadTimeOn === "on"
              ? `playhead-time absolute top-[-13px] left-0 z-[150] w-[52px] h-[10px] text-xs opacity-100  ${playheadTimeOn}`
              : `playhead-time absolute top-[-13px] left-0 z-[150] w-[52px] h-[10px] text-xs opacity-0`
          }
          style={{ left: playheadPosition }}
          id="playhead-time"
        >
          <span ref={playheadTime} id="play-time-text">
            {videoPlayTime}
          </span>
        </div>
      </div>
      <div className="play-head-wrap w-[90%] m-auto block relative">
        <div
          className="absolute top-[16px] left-0 z-[150] border-x-4 border-b-8 border"
          id="playhead"
          style={{ left: playheadPosition }}
        ></div>
      </div>
      <div className="range-container" ref={sliderRef}>
        <div
          ref={sliderTrack}
          className="slider-track"
          id="slider-track"
          style={{ background: trackBackground }}
          onClick={(e) => onSliderTrackClick(e)}
        >
          <input
            ref={slider1}
            type="range"
            min="0"
            max="100"
            defaultValue={calcPercentage(duration, videoClipStart)}
            id="slider-1"
            step="0.0001"
            onMouseDown={() => onSliderMouseDown()}
            onMouseUp={(e) => onSliderMouseUp(e)}
            onMouseMove={(e) =>
              fillColor(duration, e.target.value, "STARTTIME")
            }
          />
          <input
            ref={slider2}
            type="range"
            min="0"
            max="100"
            defaultValue={calcPercentage(duration, videoClipEnd)}
            id="slider-2"
            step="0.0001"
            onMouseDown={() => onSliderMouseDown()}
            onMouseUp={(e) => onSliderMouseUp(e)}
            onMouseMove={(e) => fillColor(duration, e.target.value, "ENDTIME")}
          />
        </div>
      </div>
      <div className="values">
        <span ref={startTime} id="startTime">
          {startTimeCode}
        </span>
        <span className="small"> to </span>
        <span ref={endTime} id="endTime">
          {endTimeCode}
        </span>
        <span ref={range1} id="range1" className="hide">
          0
        </span>
        <span ref={range2} id="range2" className="hide">
          100
        </span>
        <div className="clip-len text-xs" id="clip-len">
          {clipTimeLength}
        </div>
      </div>
    </>
  );
};

export default Slider;
