import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const CardProcessing = ({ project, progressData, deleteProject }) => {
  const { t } = useTranslation();
  const [progressPercent, setProgressPercent] = useState(0);
  const [progressMsg, setProgressMsg] = useState("");

  const trashProject = (projectId) => {
    console.log("DELETE", projectId);
    deleteProject(projectId);
  };

  useEffect(() => {
    if (progressData) {
      if (progressData.projectId === project.id) {
        setProgressPercent(progressData.data.progress);
        setProgressMsg(t(progressData.data.msg));
      }
    }
  }, [progressData]);

  return (
    <>
      <div className="flex flex-col relative aspect-video rounded-t-md justify-center items-center bg-gray-900">
        <div className="uppercase text-xs text-gray-500">
          {t("menu.processing")}
        </div>
        <div className="h-2 border border-gray-600 w-8/12 mt-1">
          <div
            className="h-full bg-fuchsia-600"
            style={{ width: `${progressPercent}%` }}
          ></div>
          <div className="text-xs text-gray-600 mt-1 w-full text-center">
            {progressMsg}
          </div>
        </div>
      </div>
      <div className="mt-2 p-2 text-gray-600">
        <span className="block font-semibold">
          {t("streamslicer.untitled_project")}
        </span>
        <span className="block">
          {" "}
          {t("project_id")}: {project.id}
        </span>
        <span className="block text-sm ">{t("created")}: 00/00/0000</span>
        <span className="block text-sm ">
          {t("streamslicer.project_length")}: <time>00:00:00</time>
        </span>
        <div className="text-sm ">
          {t("streamslicer.generated_clips")} - 0 |{" "}
          {t("streamslicer.saved_clips")} - 0
        </div>
      </div>
      <div className="flex flex-row divide-x divide-slate-900 mt-2 ">
        <button
          className={`flex-1 bg-disabled hover:bg-blueHover p-2 rounded-bl-md rounded-br-md uppercase font-semibold`}
          onClick={() => trashProject(project.id)}
        >
          &nbsp;
        </button>
      </div>
    </>
  );
};

export default CardProcessing;
