import React, { useEffect, useState } from "react";

import useRoomCams from "../../hooks/useRoomCams";
import UserStream from "./components/UserStream";
import { useParams } from "react-router-dom";
import "../../sass/camSizes.scss";
import Rundown from "../../components/sources/BrowserSources/RunndownSource";
import ChyronSource from "../../components/sources/BrowserSources/ChyronSource";
import Ticker from "../../components/sources/BrowserSources/Ticker";
import VideoChatCanvas from "./components/VideoChatCanvas";

const CanvasStream = () => {
  const { roomid, noHostCam } = useParams();
  const RoomCams = useRoomCams({ roomId: roomid });
  const [hasASoloedStream, setHasASoloedStream] = useState<string>("");
  const [roomJoined, setRoomJoined] = useState<boolean>(false);
  const [, setVideoStream] = useState(null);
  const [includeHost] = useState(noHostCam !== undefined ? false : true);
  const [hasRundown, setHasRundown] = useState<boolean>(false);
  const [hasChyron, setHasChyron] = useState<boolean>(false);
  const [hasTicker, setHasTicker] = useState<boolean>(false);
  const [browserSourceId, setBrowserSourceId] = useState(null);

  const [cameraClass, setCameraClass] = useState<string>("");

  const createEmptyAudioTrack = () => {
    const ctx = new AudioContext();
    const oscillator = ctx.createOscillator();
    const mediaStream = new MediaStream();

    const destination = ctx.createMediaStreamDestination();
    oscillator.connect(destination);
    mediaStream.addTrack(destination.stream.getAudioTracks()[0]);

    oscillator.start();

    const track = mediaStream.getAudioTracks()[0];
    return Object.assign(track, { enabled: false });
  };

  const createEmptyVideoTrack = ({ width, height }) => {
    const canvas = Object.assign(document.createElement("canvas"), {
      width,
      height,
    });
    canvas.getContext("2d").fillRect(0, 0, width, height);

    const stream = canvas.captureStream();
    const track = stream.getVideoTracks()[0];

    return Object.assign(track, { enabled: false });
  };

  useEffect(() => {
    if (RoomCams.sceneCameras.length) {
      const hostCam = RoomCams.sceneCameras.filter(
        (cam) => cam.data.type === "host"
      );

      const availcams = RoomCams.sceneCameras.filter(
        (cam) => cam.data.pos === "stage" || cam.data.pos === "solo"
      );
      if (availcams.length > 0) {
        setCameraClass(`grid_${availcams.length}`);
      }

      if (hostCam.length) {
        setBrowserSourceId(hostCam[0].data.showSourceId);
        setHasTicker(hostCam[0].data.roomsettings.tickerEnabled);
        setHasChyron(hostCam[0].data.roomsettings.chyronEnabled);
        setHasRundown(hostCam[0].data.roomsettings.rundownEnabled);
        setCameraClass(hostCam[0].data.cameraClass);
      }

      const hasSoloStream = RoomCams.sceneCameras.filter(
        (cam) => cam.data.pos === "solo"
      );

      setHasASoloedStream(hasSoloStream.length > 0 ? "has-solo" : "");
    }
  }, [RoomCams.sceneCameras]);

  useEffect(() => {
    if (RoomCams.userid && RoomCams.peerId && !roomJoined) {
      const mediaStream = new MediaStream([
        createEmptyAudioTrack(),
        createEmptyVideoTrack({ width: 640, height: 480 }),
      ]);

      // Add video and audio tracks to the MediaStream
      //mediaStream.addTrack(videoTrack);
      //mediaStream.addTrack(audioTrack);
      const data = {
        roomid,
        userid: RoomCams.userid,
        peerId: RoomCams.peerId,
        name: "watcher",
        tagline: "",
        type: "watcher",
        pos: "bg",
        isMuted: true,
        isVideoShowing: false,
      };
      RoomCams.requestJoin(data, mediaStream);
      RoomCams.setMyUserData(data);

      RoomCams.addStream(mediaStream, data);
      RoomCams.setMyStream(mediaStream);
      setRoomJoined(true);
    }
  }, [RoomCams.userid, RoomCams.peerId]);

  useEffect(() => {}, []);

  return (
    <>
      <div className="scene">
        <div id="video-stage" className={`video-wrap ${cameraClass}`}>
          {RoomCams.sceneCameras
            .filter(
              (camera) =>
                camera.data.pos === "stage" || camera.data.pos === "solo"
            )
            .sort((a, b) => {
              // Sort by type, putting 'host' first
              if (a.data.type === "host" && b.data.type !== "host") {
                return -1;
              } else if (a.data.type !== "host" && b.data.type === "host") {
                return 1;
              } else {
                return 0;
              }
            })
            .filter((cam) => {
              if (!includeHost) {
                return cam.data.type !== "host" ? cam : null;
              } else {
                return cam;
              }
            })
            .map(
              (camera, index) =>
                (camera.data.pos === "stage" || camera.data.pos === "solo") && (
                  <UserStream
                    key={`${camera.userid}_${index}`}
                    myid={RoomCams.userid}
                    userid={camera.userid}
                    stream={camera.stream}
                    userData={camera.data}
                    onCreatedStream={setVideoStream}
                    cameraClass={`${cameraClass} ${camera.data.pos} ${hasASoloedStream}`}
                    isAudioMuted={camera.data.isMuted}
                    isVideoShowing={camera.data.isVideoShowing}
                    showTitles={camera.data.isNameShowing}
                    userImg={camera.data.userImg}
                  />
                )
            )}

          {hasRundown &&
            RoomCams.webSocket &&
            browserSourceId &&
            includeHost && (
              <Rundown
                isInControlCenter={true}
                joinedShowid={browserSourceId}
                socketserver={RoomCams.webSocket.current}
                isEmbeded={true}
              />
            )}
          {hasChyron &&
            RoomCams.webSocket &&
            browserSourceId &&
            includeHost && (
              <ChyronSource
                isInControlCenter={true}
                joinedShowid={browserSourceId}
                socketserver={RoomCams.webSocket.current}
                isEmbeded={true}
              />
            )}
          {hasTicker &&
            RoomCams.webSocket &&
            browserSourceId &&
            includeHost && (
              <Ticker
                isInControlCenter={true}
                joinedShowid={browserSourceId}
                isProAccount={true}
                socketserver={RoomCams.webSocket.current}
                isEmbeded={true}
              />
            )}
        </div>
      </div>
      <VideoChatCanvas
        videoStreams={RoomCams.sceneCameras}
        layout={""}
        isRecording={true}
        isStreaming={false}
      />
    </>
  );
};

export default CanvasStream;
