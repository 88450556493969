import React from "react";
import Header from "../layout/Header";

const NotFound = () => {
  return (
    <>
      <Header />
      <div className="main">
        <div className="center-wrap">
          <img
            src="https://media.tenor.com/lx2WSGRk8bcAAAAC/pulp-fiction-john-travolta.gif"
            alt="Not Found"
          />
          <hr />
          <p>404 PAGE NOT FOUND</p>
        </div>
      </div>
    </>
  );
};

export default NotFound;
