import React, { useContext, useEffect, useRef, useState } from "react";
import Header from "../layout/Header";
import NotificationSwitch from "../account/NotificationSwitch";
import { UserContext } from "../../context/UserContext";
import axios from "axios";
import { getBearerHeaders } from "../../helpers/GetBearerHeaders";

const Settings = () => {
  const { user } = useContext(UserContext);

  const [speed, setSpeed] = useState(user.config.speed);
  const [fontSize, setFontSize] = useState(user.config.textsize);
  const [prefix, setPrefix] = useState(user.config.tickerPrefix);
  const [suffix, setSuffix] = useState(user.config.tickerSuffix);
  const [seperator, setSeperator] = useState(user.config.tickerSeperator);
  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [hadInitialLoad, setHadInitialLoad] = useState<boolean>(false);

  const prefixRef = useRef(null);
  const suffixRef = useRef(null);
  const seperatorRef = useRef(null);

  const updateConfig = () => {
    const updatedConfig = {
      ...user.config,
      speed,
      textsize: fontSize,
      tickerPrefix: prefix,
      tickerSuffix: suffix,
      tickerSeperator: seperator,
    };

    console.log(user.config, updatedConfig);
    if (hasChanges) {
      axios
        .post(
          "/api/v1/updateconfig",
          { config: updatedConfig },
          getBearerHeaders()
        )
        .then((response) => {
          console.log(response);
          setHasChanges(false);
        });
    }
  };

  useEffect(() => {
    if (hadInitialLoad) {
      setHasChanges(true);
    }
    setHadInitialLoad(true);

    console.log(fontSize);
  }, [speed, fontSize, prefix, suffix, seperator]);
  return (
    <>
      <Header />
      <div className="main">
        <div className="pages-wrap settings">
          <h1>Settings:</h1>
          <hr />
          <NotificationSwitch />
          <hr />
          <h2>Teleprompter Options</h2>
          <label>Scroll Speed</label> {41 - speed}
          <br />
          <input
            type="range"
            min="1"
            max="40"
            defaultValue={41 - speed}
            onChange={(e) => setSpeed(41 - Number(e.currentTarget.value))}
          ></input>
          <br />
          <label>Text Size</label> {fontSize}
          <br />
          <input
            type="range"
            min="16"
            max="100"
            defaultValue={fontSize}
            onChange={(e) => setFontSize(Number(e.currentTarget.value))}
          ></input>
          <hr />
          <h2>Ticker Options</h2>
          <label>Prefix:</label>
          <br />
          <input
            ref={prefixRef}
            type="text"
            defaultValue={prefix}
            maxLength={250}
            onChange={(e) => setPrefix(e.currentTarget.value)}
          ></input>
          <br />
          <label>Suffix:</label>
          <br />
          <input
            ref={suffixRef}
            type="text"
            defaultValue={suffix}
            maxLength={250}
            onChange={(e) => setSuffix(e.currentTarget.value)}
          ></input>
          <br />
          <label>Seperator:</label>
          <br />
          <input
            className="seperator-label"
            ref={seperatorRef}
            type="text"
            defaultValue={seperator}
            maxLength={1}
            onChange={(e) => setSeperator(e.currentTarget.value)}
          ></input>
          <br />
          <div>
            <button
              className={!hasChanges ? "grey" : ""}
              onClick={updateConfig}
            >
              Update Settings
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Settings;
