import { useEffect, useState } from "react";

export interface CameraItem {
  index: number;
  videoId: string;
  audioId: string;
}

export type CameraItemArray = CameraItem[];

const useGetMediaDevices = () => {
  const [videoDevices, setVideoDevices] = useState([]);
  const [audioDevices, setAudioDevices] = useState([]);
  const [outputDevices, setOutputDevices] = useState([]);
  const [defaultCam, setDefaultCam] = useState<CameraItem | null>(null);
  const [defaultAudio, setDefaultAudio] = useState<CameraItem | null>(null);
  const [defaultOutput] = useState<CameraItem | null>(null);

  useEffect(() => {
    const getDevices = async () => {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const systemVideoDevices = [
        {
          value: "TITLE",
          label: "Choose a camera",
          type: "video",
        },
      ];
      const systemAudioDevices = [
        {
          value: "TITLE",
          label: "Choose a microphone",
          type: "audio",
        },
      ];
      const systemOutputDevices = [
        {
          value: "TITLE",
          label: "Choose a speaker",
          type: "output",
        },
      ];
      devices.forEach((device, i) => {
        if (device.kind === "videoinput") {
          systemVideoDevices.push({
            value: device.deviceId,
            label: device.label || "camera " + (i + 1),
            type: "video",
          });
        }
        if (device.kind === "audioinput") {
          systemAudioDevices.push({
            value: device.deviceId,
            label: device.label || "microphone " + (i + 1),
            type: "audio",
          });
        }
        if (device.kind === "audiooutput") {
          systemOutputDevices.push({
            value: device.deviceId,
            label: device.label || "speaker " + (i + 1),
            type: "output",
          });
        }
      });

      const audioStream = navigator.mediaDevices.getUserMedia({ audio: true });
      const videoStream = navigator.mediaDevices.getUserMedia({ video: true });
      audioStream.then((mediaStream) => {
        const audioTrack = mediaStream.getAudioTracks()[0];
        const settings = audioTrack.getSettings();
        const deviceId = settings.deviceId;
        const defaultAudio = {
          videoId: "",
          audioId: deviceId,
          index: 0,
        };
        setDefaultAudio(defaultAudio);
      });
      videoStream.then((mediaStream) => {
        const videoTrack = mediaStream.getVideoTracks()[0];
        const settings = videoTrack.getSettings();
        const deviceId = settings.deviceId;
        const defaultVideo = {
          videoId: deviceId,
          audioId: "",
          index: 0,
        };
        setDefaultCam(defaultVideo);
      });

      setVideoDevices(systemVideoDevices);
      setAudioDevices(systemAudioDevices);
      setOutputDevices(systemOutputDevices);
      return {
        videodevices: systemVideoDevices,
        audiodevices: systemAudioDevices,
        outputdevices: systemOutputDevices,
      };
    };

    getDevices();
  }, []);

  return {
    audioDevices,
    videoDevices,
    outputDevices,
    defaultCam,
    defaultAudio,
    defaultOutput,
  };
};

export default useGetMediaDevices;
